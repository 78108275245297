import { useMutation, useQuery } from "@tanstack/react-query";
import { getLocalStorageItem, localStorageKeys } from "hooks";
import { axios } from "utils";
import removeNullProperties from "utils/helpers/removeNullProperties";
import QuereyKeys from "./queryKeys";

// POST CheckIn habitbetter api
const GoalCheckInPost = (body) => {
  const method = "POST";
  const url = `/api/check-in`;
  return axios({ method, url, data: removeNullProperties(body) });
};

export const useGoalCheckInPostMutation = () => {
  return useMutation(GoalCheckInPost);
};

// get mygoal list data
const GetMyGoals = (queryArg) => {
  let query = "";
  queryArg?.tags?.map((el) => {
    if (query.length < 1) {
      query += "?tags[]=" + el;
    } else {
      query += "&tags[]=" + el;
    }
    return el;
  });
  const method = "GET";
  const url = `/api/goals${query}`;
  return axios({
    method,
    url,
    params: removeNullProperties({
      ...queryArg,
      tags: null,
    }),
  });
};

export const useMyGoalsQuery = (data) => {
  return useQuery([QuereyKeys.MY_GOALS, data], () => GetMyGoals(data), {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};

// POST create goal api
const CreateGoal = (body) => {
  const method = Boolean(body?.goal_id) ? "PUT" : "POST";
  const url = `/api/goals`;
  return axios({ method, url, data: removeNullProperties(body) });
};

export const useCreateGoalMutation = () => {
  return useMutation(CreateGoal);
};

// get goal data by id
const GetGoalById = (queryArg) => {
  const method = "GET";
  const url = `/api/goal/${queryArg?.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetGoalByIdQuery = (data) => {
  return useQuery([QuereyKeys.GOAL_BY_ID, data], () => GetGoalById(data), {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};
