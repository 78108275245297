import { Button, useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useForgotPasswordQuery, VerifyOtpQuery } from "queries/Auth";
import React, { memo, useEffect, useState } from "react";
import { intervalToDuration } from "date-fns";
import { useFormContext } from "react-hook-form";
import { useInterval } from "hooks";
import useLocalStorage from "utils/useLocalStorage";
import OtpInput from "react-otp-input";

const zeroPad = (num, places) => String(num).padStart(places, "0");

const formattedTime = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return `${zeroPad(duration.minutes, 2)}:${zeroPad(duration.seconds, 2)}`;
};

/* Step-1 */
const VerifyOtpForm = (props) => {
  const { setFormStep, isLogin } = props;
  //states

  const [otpTimer, setOtpTimer] = useLocalStorage("otp-timer-signup", 60);
  const isSmallScreen = useMediaQuery("(max-width:950px)");
  const { search } = useLocation();
  const paramEmaill = search.split("=")[1];

  const [isTimerPlaying, setIsTimerPlaying] = useState(false);

  //useEffects
  useEffect(() => {
    if (otpTimer > 0) {
      setIsTimerPlaying(true);
    }
  }, []);

  //customHooks
  useInterval(
    () => {
      if (otpTimer > 0) {
        setOtpTimer((prevOtpTimer) => prevOtpTimer - 1);
      } else {
        setIsTimerPlaying(false);
      }
    },
    isTimerPlaying ? 1000 : null
  );

  //queries

  const { mutate: ResendOtp } = VerifyOtpQuery();
  const { mutate: ForgotPassResend } = useForgotPasswordQuery();

  //handlers
  const handleResendOTP = () => {
    setOtpTimer(60);
    setIsTimerPlaying(true);
    if (isLogin) {
      ForgotPassResend({
        email: getValues("email"),
      });
    } else {
      ResendOtp({
        email: getValues("email") || paramEmaill,
      });
    }
  };
  const handleResendOTPViaLogin = () => {
    setOtpTimer(60);
    setIsTimerPlaying(true);

    ResendOtp({
      email: paramEmaill || "",
    });
  };

  const navigate = useNavigate();
  const {
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useFormContext();
  const { otp } = watch();

  return (
    <div className="flex flex-col mt-20">
      <div className="text-center">
        <p className="text-2xl text-hb_blue-400">Verify your email address</p>
      </div>
      <div>
        <p
          className="text-md mt-8 rounded  py-4 px-12 text-center"
          style={{ background: "#d4edda", color: "#1b5e20" }}
        >
          We've sent a verification code to your email -{" "}
          <span className="font-semibold">
            {getValues("email") || paramEmaill}
          </span>
        </p>
      </div>

      <div className="mt-8 flex flex-col items-center justify-center">
        <p className="mb-4 text-lg font-medium text-hb_blue-400">
          Enter One Time Password
        </p>
        <OtpInput
          value={otp}
          onChange={(value) => {
            setValue("otp", value);
          }}
          numInputs={4}
          separator={<span>-</span>}
          inputStyle={{
            background: "#F3F6F9",
            borderRadius: "4px",
            margin: ".5rem",
            color: "black",
            padding: ".5rem .2rem",
            width: isSmallScreen ? "2rem" : "4rem",
          }}
          hasErrored={Boolean(errors.otp)}
          errorStyle={{ border: "1px solid red" }}
          focusStyle={{ outline: "none" }}
          isInputNum
        />
        <div className="flex w-full justify-center p-3">
          <p className="text-xs" style={{ color: "red" }}>
            {errors && errors.otp && `${errors.otp.message}`}
          </p>
        </div>
      </div>

      <div className="mb-6 flex flex-col items-end">
        <Button disabled={isTimerPlaying} onClick={handleResendOTP}>
          Resend One Time Password
        </Button>
        <p className="relative -left-3 text-sm font-medium">
          Time until One Time Password expires: {formattedTime(otpTimer)}
        </p>
      </div>

      <Button
        form={isLogin ? "login-form" : "signup-form"}
        type="submit"
        className="mb-4 w-full"
        variant="contained"
        sx={{ background: "#FF6C44!important", color: "white" }}
      >
        Verify Email
      </Button>

      <div className="mb-6 flex items-center justify-center">
        <p className="mx-4 text-sm text-hb_blue-800">
          Already have an account?{""}
        </p>

        <Button
          sx={{ color: "#FF6C44" }}
          className="text-sm"
          onClick={() => {
            if (!isLogin) {
              navigate(-1);
            }
            reset();
            setFormStep(0);
          }}
        >
          Log In
        </Button>
      </div>
    </div>
  );
};
export default memo(VerifyOtpForm);
