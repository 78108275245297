import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from "@tanstack/react-query";
import RoutesPage from "./Routes/index";
import PrimaryTheme from "./Themes/PrimaryTheme";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./App.css";
import { requestForToken } from "firebaseConfig";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV || "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
    Sentry.feedbackIntegration({
      showBranding: false,
      showName: false,
      showEmail: false,
      colorScheme: "light",
      triggerLabel: "Feedback",
      triggerAriaLabel: "Feedback",
      formTitle: "CareBetter Feedback",
      submitButtonLabel: "Submit",
      messagePlaceholder:
        "How can we improve CareBetter? You may also report a bug here.",
      themeLight: {
        accentBackground: "#213F61",
      },
    }),
  ],
  // Tracing
  tracesSampleRate: 0.25,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/api\.carebetter\.com\/api/,
    /^https:\/\/stage\.api\.carebetter\.com\/api/,
    /^https:\/\/api\.habitbetter\.com\/api/,
    /^https:\/\/stage\.api\.habitbetter\.com\/api/,
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.25,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const queryClient = new QueryClient({
    // These shouold fire once per query/mutation and wrap
    queryCache: new QueryCache({
      onError: (error) => {
        Sentry.captureException(error);
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        Sentry.captureException(error);
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: false,
        staleTime: Infinity,
        retry: 1,
      },
    },
  });
  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
  );

  const fullPageReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    requestForToken();
    const handleStorageChange = (event) => {
      if (event.key === "AUTH_TOKEN") {
        fullPageReload();
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={PrimaryTheme}>
            <Elements stripe={stripePromise}>
              <RoutesPage />
            </Elements>
          </ThemeProvider>
        </StyledEngineProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          panelPosition="bottom"
          buttonPosition="bottom-left"
        />
      </QueryClientProvider>
    </div>
  );
}

export default App;
