import { useState, forwardRef } from "react";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CustomTextField = forwardRef((props, ref) => {
  //  states
  const [showPassword, setShowPassword] = useState(false);
  //Handlers
  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    label,
    variant,
    type,
    error,
    helperText,
    className,
    // ref,
    required,
    fullWidth,
    size,
    name,
    labelColor,
    disabled,
    startAdornment,
    endAdornment,
    capitalize,
    placeholder,
    rows,
    multiline,
    maxLength,
    InputLabelProps,
    ...restOfTextFieldProps
  } = props;
  return (
    <FormControl
      className={`${className} ${fullWidth ? "w-full" : ""} mui-textfield`}
    >
      <TextField
        type={type}
        className="text-base  text-hb_blue-350"
        capitalize={capitalize}
        fullWidth={fullWidth}
        {...restOfTextFieldProps}
        ref={ref}
        name={name}
        size={size}
        rows={rows}
        multiline={multiline}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        variant={variant}
        color={labelColor}
        error={Boolean(error)}
        maxLength={maxLength}
        inputProps={InputLabelProps}
        InputProps={{
          startAdornment: startAdornment ? startAdornment : null,
          endAdornment: (
            <>
              {type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : endAdornment ? (
                endAdornment
              ) : null}
            </>
          ),
        }}
      />
      <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
});
CustomTextField.defaultProps = {
  label: "",
  variant: "standard",
  ref: null,
  type: "text",
  error: false,
  helperText: "",
  className: null,
  // ref: null,
  required: false,
  fullWidth: true,
  size: "medium",
  disabled: false,
  placeholder: "",
};

export default CustomTextField;
