import { Button, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetBrowseHabitsQuery,
  usePostBrowseHabitsMutation,
} from "queries/Habit";
import HabitCard from "components/HabitCard";
import moment from "moment";
import { ConfirmDialog } from "components";
import GreenTickIcon from "assets/Icons/GreenTick.svg";
import AlertIcon from "assets/Icons/alert.svg";
import { routes } from "Routes/RouteConstants";

const BrowseHabits = (props) => {
  const navigate = useNavigate();
  //use state
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  //queries
  const { data, dataUpdatedAt } = useGetBrowseHabitsQuery({
    tags: [],
    q: props?.searchText?.length > 0 ? props?.searchText : null,
  });
  const {
    mutateAsync: addBrowseHabit,
    data: addBrowseHabitData,
    error: addBrowseHabitError,
  } = usePostBrowseHabitsMutation();

  //Use useMemo
  const { browseHabitData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        browseHabitData: data?.data,
      };
    } else {
      return { browseHabitData: {} };
    }
  }, [dataUpdatedAt]);

  useEffect(() => {
    if (addBrowseHabitData) {
      setShowSuccessModal(true);
    }
  }, [addBrowseHabitData]);

  useEffect(() => {
    if (addBrowseHabitError) {
      setShowErrorModal(true);
    }
  }, [addBrowseHabitError]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <Fragment>
      <div className="sm:m-6 px-4 sm:px-0 sm:gap-5">
        <div className="mb-20 sm:my-0">
          {Object.entries(browseHabitData)?.map(([key, value]) => {
            return (
              <div
                className="mt-4"
                style={{ width: windowWidth - 36, maxWidth: "650px" }}
              >
                <div className=" flex flex-row justify-between mb-2">
                  <p className="text-base font-medium text-hb_blue-700">
                    {key}
                  </p>
                  <Button
                    sx={{ padding: 0 }}
                    className="text-base font-medium text-hb_orange-dark"
                    onClick={() => {
                      navigate(routes.SELECT_CATEGORY.pathname, {
                        state: {
                          data: value?.[0],
                          title: key,
                        },
                      });
                    }}
                  >
                    View All
                  </Button>
                </div>
                <div className="flex overflow-x-scroll flex-row gap-4 sm:grid sm:grid-cols-2">
                  {value?.map((item) => (
                    <HabitCard
                      key={item?.id}
                      habit={item}
                      onClickCard={() =>
                        navigate(routes.BROWSE_DETAIL.pathname, {
                          state: { item },
                        })
                      }
                      onClickPlus={() =>
                        addBrowseHabit({
                          id: item?.id,
                          body: {
                            coach_id: item?.coach_id,
                            color: item.color,
                            start_date:
                              item?.start_date ?? moment().format("YYYY-MM-DD"),
                            why: item?.why,
                            name: item?.name,
                            frequency_payload: item?.frequency_payload,
                            period_type: item?.period_type,
                            frequency: item?.frequency,
                            streak_target: item?.streak_target,
                            tags:
                              item.tags.length > 0
                                ? item?.tags?.map((el) => el.id)
                                : null,
                            notification: item?.notification,
                            notification_timings: item?.notification_timings,
                            goal: item?.goal,
                            shared_to_coach: item?.shared_to_coach,
                            public: item?.public,
                            end_date: item?.end_date,
                            category_id: item?.category_id,
                          },
                        }).catch((e) => {
                          console.log("e", e);
                        })
                      }
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="GreenTickIcon"
              style={{ height: 60, width: 60 }}
              src={GreenTickIcon}
            />
            <p className="font-semibold text-base mt-4">Success</p>
            <p className="font-normal text-base mt-10 text-center">
              Habit added succesfully!
            </p>
          </div>
        }
        open={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        continueEdit={() => setShowSuccessModal(false)}
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="AlertIcon"
              style={{ height: 60, width: 60 }}
              src={AlertIcon}
            />
            <p className="font-semibold text-base mt-4">Alert!</p>
            <p className="font-normal text-base mt-10 text-center ">
              Already taken this Habit!
            </p>
          </div>
        }
        open={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        continueEdit={() => setShowErrorModal(false)}
      />
    </Fragment>
  );
};

export default BrowseHabits;
