import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios, getLocalStorageItem, localStorageKeys } from "utils";
import removeNullProperties from "utils/helpers/removeNullProperties";
import QuereyKeys from "./queryKeys";

// get my courses data
const GetMyCourses = () => {
  const method = "GET";
  const url = `/api/courses/my`;
  return axios({
    method,
    url,
  });
};

export const useGetMyCoursesQuery = () => {
  return useQuery([QuereyKeys.MY_COURSES], GetMyCourses, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};

// get complete courses data
const GetCompleteCourses = () => {
  const method = "GET";
  const url = `/api/courses/complete`;
  return axios({
    method,
    url,
  });
};

export const useGetCompleteCoursesQuery = () => {
  return useQuery([QuereyKeys.COMPLETE_COURSES], GetCompleteCourses, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};

// get all courses data
const GetAllCourses = (queryArg) => {
  let query = "";
  queryArg?.tags?.map((el) => {
    if (query.length < 1) {
      query += "?tags[]=" + el;
    } else {
      query += "&tags[]=" + el;
    }
    return el;
  });
  queryArg?.category?.map((el) => {
    if (query.length < 1) {
      query += "?category[]=" + el;
    } else {
      query += "&category[]=" + el;
    }
    return el;
  });
  const method = "GET";
  const url = `/api/courses${query}`;
  return axios({
    method,
    url,
    params: removeNullProperties({
      ...queryArg,
      tags: null,
      category: null,
    }),
  });
};

export const useAllCoursesQuery = (payload) => {
  return useQuery(
    [QuereyKeys.ALL_COURSES, payload],
    () => GetAllCourses(payload),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

//post rearrange
const PostRearrange = (data) => {
  const method = "POST";
  const url = `/api/courses/order`;
  return axios({ method, url, data });
};

export const usePostRearrangeMutation = () => {
  return useMutation(PostRearrange, {
    onSuccess: (response) => {},
  });
};

// courses select
const PostSelect = (data) => {
  const method = "POST";
  const url = `/api/courses/select`;
  return axios({ method, url, data: removeNullProperties(data) });
};

export const usePostSelectMutation = () => {
  return useMutation(
    PostSelect,
    {
      onError: (e) => {
        console.log(e);
      },
    },
    { useErrorBoundary: true }
  );
};

// get courses by Id data
const GetCoursesById = (arg) => {
  console.log("arg", arg);
  const method = "GET";
  const url = `/api/courses/${arg.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetCoursesByIdQuery = (payload) => {
  return useQuery(
    [QuereyKeys.COURSE_BY_ID, payload?.id],
    () => GetCoursesById(payload),
    {
      enabled:
        Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)) &&
        Boolean(payload?.id),
    }
  );
};

// courses start
const PostStartCourse = (data) => {
  const method = "POST";
  const url = `/api/courses/${data.id}/start`;
  return axios({ method, url });
};

export const usePostStartCourseMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    PostStartCourse,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QuereyKeys.COURSE_BY_ID);
        queryClient.invalidateQueries(QuereyKeys.ALL_COURSES);
      },
      onError: (e) => {
        console.log(e);
      },
    },
    { useErrorBoundary: true }
  );
};

// courses Why
const PostCourseWhy = (data) => {
  const method = "PUT";
  const url = `/api/courses/why`;
  return axios({ method, url, data });
};

export const usePostCourseWhyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    PostCourseWhy,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QuereyKeys.COURSE_BY_ID);
        queryClient.invalidateQueries(QuereyKeys.ALL_COURSES);
      },
      onError: (e) => {
        console.log(e);
      },
    },
    { useErrorBoundary: true }
  );
};

// get course lesson by id
const GetCourseLessonById = (queryArg) => {
  const method = "GET";
  const url = `/api/courses/${queryArg.course_id}/lesson/${queryArg.lesson_id}`;
  return axios({
    method,
    url,
  });
};

export const useGetCourseLessonByIdQuery = (payLoad) => {
  return useQuery(
    [QuereyKeys.COURSES_LESSON, payLoad],
    () => GetCourseLessonById(payLoad),
    {
      enabled:
        Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)) &&
        payLoad?.type !== "daily",
    }
  );
};

// POST Complete course lesson by id
const SetCompleteCourseLessonById = (queryArg) => {
  const method = "POST";
  const url = `/api/courses/${queryArg.course_id}/lesson/${queryArg.lesson_id}/complete`;
  return axios({
    method,
    url,
    data: removeNullProperties(queryArg.body),
  });
};

export const useSetCompleteCourseLessonByIdMutation = () => {
  return useMutation(
    SetCompleteCourseLessonById,
    {
      onSuccess: () => {},
      onError: (e) => {
        console.log(e);
      },
    },
    { useErrorBoundary: true }
  );
};

//daily-practice
const SetCompleteDailyPractice = (queryArg) => {
  const method = "POST";
  const url = `/api/daily-practice`;
  return axios({
    method,
    url,
    data: removeNullProperties(queryArg.body),
  });
};

export const useSetCompleteDailyPracticeMutation = () => {
  return useMutation(
    SetCompleteDailyPractice,
    {
      onSuccess: () => {},
      onError: (e) => {
        console.log(e);
      },
    },
    { useErrorBoundary: true }
  );
};

// get all favorite data
const GetAllFavorite = (queryArg) => {
  let query = "";
  queryArg?.tags?.map((el) => {
    if (query.length < 1) {
      query += "?tags[]=" + el;
    } else {
      query += "&tags[]=" + el;
    }
    return el;
  });

  const method = "GET";
  const url = `/api/favourites${query}`;
  return axios({
    method,
    url,
    params: removeNullProperties({
      ...queryArg,
      tags: null,
      category: null,
    }),
  });
};

export const useAllFavoriteQuery = (payload) => {
  return useQuery(
    [QuereyKeys.FAVORITE_LIST, payload],
    () => GetAllFavorite(payload),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

// get courses current
const GetCurrentCourses = (arg) => {
  // console.log("arg", arg);
  const method = "GET";
  const url = `/api/courses/current`;
  return axios({
    method,
    url,
  });
};

export const useGetCurrentCoursesQuery = (payload) => {
  return useQuery(
    [QuereyKeys.CURRENT_COURSE],
    () => GetCurrentCourses(payload),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

// get daily prompts data
const GetDailyPrompts = () => {
  const method = "GET";
  const url = `/api/daily-practice/coach`;
  return axios({
    method,
    url,
  });
};

export const useGetDailyPromptsQuery = () => {
  return useQuery([QuereyKeys.DAILY_PROMPTS], GetDailyPrompts, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};

// get daily practice data id
const GetDailyPracticeById = (queryArg) => {
  const method = "GET";
  const url = `/api/daily-practice/${queryArg.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetDailyPracticeByIdQuery = (payLoad) => {
  return useQuery(
    [QuereyKeys.DAILY_PRACTICE_ID, payLoad],
    () => GetDailyPracticeById(payLoad),
    {
      enabled: Boolean(payLoad?.type === "daily"),
    }
  );
};

//useGetDailyPracticeQuery
const GetDailyPractice = () => {
  const method = "GET";
  const url = `/api/daily-practice`;
  return axios({
    method,
    url,
  });
};

export const useGetDailyPracticeQuery = (payLoad) => {
  return useQuery(
    [QuereyKeys.DAILY_PRACTICE],
    () => GetDailyPractice(payLoad),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};
