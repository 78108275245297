import React, { useEffect, useMemo, useState } from "react";
import MobileHeader from "components/MobileHeader";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import ReactPlayer from "react-player";
import { Button, Grid } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { routes } from "Routes/RouteConstants";

const JournalDetailScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { journalData: jd } = state || {};

  //use state
  const [journalData, setJournalData] = useState(
    jd.ujournalable_type === "journals" ? jd.ujournalable : jd
  );
  const [imagesApi, setImagesApi] = useState([]);
  const [journalText, setJournalText] = useState();
  const [journalTitle, setJournalTitle] = useState();
  const [tagsData, setTags] = useState([]);

  const setDetailsData = () => {
    setJournalTitle(journalData.name);
    setJournalText(journalData.description);
    setTags(journalData?.tags ?? []);
    setImagesApi(jd?.journalImages?.map((el, index) => el?.image) ?? []);
  };

  //useeffect
  useEffect(() => {
    if (journalData?.name) setDetailsData();
  }, [journalData]);

  useEffect(() => {
    if (state?.type === "individual") {
      setJournalTitle(journalData?.lesson?.journal_prompt);
      setJournalText(journalData?.journal_entry);
      setTags(journalData?.tags ?? []);
      setImagesApi(journalData?.images?.map((el, index) => el?.image) ?? []);
    }
  }, [state?.type, state]);

  //render
  const renderVideoContainer = (item) => {
    return (
      <Grid xs={12} md={9} className="overflow-hidden rounded-2xl mb-4">
        <ReactPlayer url={item?.video} height={220} width={"100%"} controls />
      </Grid>
    );
  };

  return (
    <div className="w-full sm:pl-8 sm:mt-8">
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title={journalData?.lesson?.title ?? "My Journal"}
        trailing={
          jd?.ujournalable_type === "journals" && (
            <button
              className="px-2 text-gray-500"
              onClick={() =>
                navigate(routes.EDIT_JOURNAL.pathname, {
                  state: {
                    journalData: {
                      name: journalTitle,
                      description: journalText,
                      freestyle: journalData?.freestyle,
                      is_favourite: journalData?.is_favourite,
                      shared_to_coach: journalData?.shared_to_coach,
                      tags: tagsData,
                      images: imagesApi,
                      id: journalData?.id,
                    },
                  },
                })
              }
            >
              Edit
            </button>
          )
        }
      />
      {/* Body */}
      <div className="mx-4 sm:grid sm:grid-cols-2 sm:gap-4">
        <div>
          <div className="max-w-lg">
            {journalData?.lesson?.videos?.length > 0 &&
              renderVideoContainer(journalData?.lesson?.videos[0])}
          </div>
          <div className="text-sm max-w-lg">
            {moment(journalData.created_at).format("MM/DD/YY HH:mm:ss")}
          </div>
          <div className="bg-hb_orange-light p-2 rounded-lg mt-2 mb-4 text-gray-500 max-w-lg">
            {journalTitle}
          </div>
          <div className="max-w-lg">
            <div className="text-sm">Journal Entry</div>
            <div
              className="bg-hb_light_blue-100 p-2 rounded-lg mt-2 mb-4 text-gray-500"
              dangerouslySetInnerHTML={{
                __html: journalText,
              }}
            />
          </div>
        </div>
        <div>
          <div className="max-w-lg">
            {imagesApi.length > 0 && (
              <Carousel
                autoPlay
                infiniteLoop
                showArrows={false}
                showThumbs={0}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = {
                    marginLeft: 20,
                    backgroundColor: "white",
                    cursor: "pointer",
                    width: 12,
                    height: 12,
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: "#FF6C44",
                    position: "relative",
                  };
                  const style = isSelected
                    ? { ...defStyle, backgroundColor: "#FF6C44" }
                    : { ...defStyle };
                  return (
                    <button
                      style={style}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      tabIndex={0}
                      aria-label={`${label} ${index + 1}`}
                    ></button>
                  );
                }}
              >
                {imagesApi?.map((it) => {
                  return (
                    <img
                      src={it}
                      alt={it}
                      style={{ width: "100%", height: 240 }}
                    />
                  );
                })}
              </Carousel>
            )}
            {Boolean(tagsData?.length) && (
              <Grid item xs={12} md={8}>
                <p className="font-semibold py-2">Tags</p>
                <div className="flex flex-wrap gap-3 flex-row">
                  {tagsData?.map((it, idx) => (
                    <div className="items-center">
                      <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                        {it.name}
                      </p>
                    </div>
                  ))}
                </div>
              </Grid>
            )}
          </div>
          {jd?.ujournalable_type === "journals" && (
            <Button
              variant="contained"
              className="hidden sm:flex px-2 text-white w-full mt-4 bg-hb_orange-dark"
              onClick={() =>
                navigate(routes.EDIT_JOURNAL.pathname, {
                  state: {
                    journalData: {
                      name: journalTitle,
                      description: journalText,
                      freestyle: journalData?.freestyle,
                      is_favourite: journalData?.is_favourite,
                      shared_to_coach: journalData?.shared_to_coach,
                      tags: tagsData,
                      images: imagesApi,
                      id: journalData?.id,
                    },
                  },
                })
              }
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default JournalDetailScreen;
