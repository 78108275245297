import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CameraIcon from "assets/Icons/camera.svg";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useForm } from "react-hook-form";
import { UploadJournalImageQuery } from "queries/Auth";
import TagListModal from "pages/SignupPage/modals/tagListModal";
import { CustomTextField, ToggleButton } from "components";
import MobileHeader from "components/MobileHeader";
import Tiptap from "components/TipTap";
import moment from "moment";
import AddFavouriteIcon from "assets/Icons/add_favourite.svg";
import AddFavouriteFillIcon from "assets/Icons/add_favourite_fill.svg";
import {
  useCreateJournalMutation,
  useGetJournalByIdQuery,
} from "queries/MyJournal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@mui/icons-material/Info";

const EditJournalSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, "Please write title between 50 characters")
    .required("Please write something."),
  addFavourite: yup.boolean(),
  stringTags: yup
    .array()
    .min(1, "Please select atleast one Tag")
    .required("Please select atleast one Tag"),
  imageTags: yup.array(),
  description: yup.mixed().required("Journal entry is required"),
  // .min(1, "Please select atleast one")
  // .required("Please select atleast one"),
});

const FreeStyleSchema = yup.object().shape({
  name: yup
    .string()
    .max(20, "Please write title between 20 characters")
    .required("Please write something."),
  addFavourite: yup.boolean(),
  stringTags: yup.array(),
  imageTags: yup.array(),
  description: yup.mixed().required("Journal entry is required"),
  // .min(1, "Please select atleast one")
  // .required("Please select atleast one"),
});

const FreeStyleScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { journalData, title } = state;

  //states
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedStringTags, setSelectedStringTags] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const {
    name,
    description,
    freestyle,
    is_favourite,
    shared_to_coach,
    tags,
    images,
    id,
  } = journalData ?? {};

  const [shareWithCoach, setShareWithCoach] = useState(shared_to_coach ?? true);

  const {
    watch,
    formState: { errors, isValid },
    setValue,
    register,
    getValues,
    trigger,
  } = useForm({
    defaultValues: id
      ? {
          name: name,
          stringTags: tags ?? [],
          description: description ?? "",
          addFavourite: is_favourite ? true : false,
          imageTags: images ?? [],
        }
      : {},
    resolver: yupResolver(
      title === "FreeStyle" ? FreeStyleSchema : EditJournalSchema
    ),
  });
  const { addFavourite } = watch();
  const { ref: refName, ...RegisterName } = register("name");

  //queries
  const { mutateAsync: postJournal } = useCreateJournalMutation();

  const handleCloseModal = () => {
    return setShowModal(false);
  };

  const handleSetStringTags = (data) => {
    setSelectedStringTags(data);
    setValue("stringTags", data, { shouldValidate: true });
    setShowModal(false);
  };

  const handleTextEditor = (data) => {
    setValue("description", data);
  };

  const { mutateAsync: UploadImages } = UploadJournalImageQuery();

  return (
    <div>
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title={title ?? "Edit Journal"}
      />
      {/* Body */}
      <Grid container className="sm:px-6 px-4">
        <Grid item xs={12} md={8} className="sm:mt-6">
          {Boolean(journalData?.created_at) && (
            <div className="text-sm max-w-lg">
              {moment(journalData?.created_at).format("MM/DD/YYYY HH:mm:ss")}
            </div>
          )}
          <p className="text-hb_blue-700 font-semibold my-2">Enter Title</p>
          <CustomTextField
            variant={"outlined"}
            required
            label=""
            ref={refName}
            {...RegisterName}
            placeholder="Write Here..."
            type="text"
            error={Boolean(errors.name)}
            helperText={errors.name && errors.name.message}
          />
        </Grid>
        <Grid item xs={12} md={8} className="mt-6">
          <p className="text-hb_blue-700 font-semibold my-2">Journal Entry</p>
          <Tiptap
            value={description ?? ""}
            handleTextEditor={handleTextEditor}
          />
          <FormHelperText
            error={Boolean(errors.description)}
            margin="dense"
            className="text-center"
          >
            {errors.description && errors.description.message}
          </FormHelperText>
        </Grid>
        {Boolean(selectedStringTags?.length) && (
          <Grid item xs={12} md={8}>
            <p className="font-semibold py-2">Tags</p>
            <div className="flex flex-wrap gap-3 flex-row">
              {selectedStringTags?.map((it, idx) => (
                <div className="items-center">
                  <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                    {it.name}{" "}
                    <Button
                      onClick={() => {
                        setSelectedStringTags((prev) => {
                          const elem = [...prev];
                          elem.splice(idx, 1);
                          setValue("stringTags", elem, {
                            shouldValidate: true,
                          });

                          return elem;
                        });
                      }}
                      sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: 0,
                        paddingBottom: 0.2,
                      }}
                    >
                      <CancelIcon sx={{ color: "white" }} fontSize="small" />
                    </Button>
                  </p>
                </div>
              ))}
            </div>
          </Grid>
        )}
        {Boolean(selectedImages.length) && (
          <Grid item xs={12} md={8}>
            <p className="font-semibold py-2">Images</p>
            <div className="flex flex-wrap gap-3 flex-row">
              {selectedImages?.map((it, idx) => (
                <div className="items-center relative">
                  <img
                    alt="imgs"
                    src={it.url}
                    style={{ height: 70, width: 70 }}
                  />
                  <Button
                    onClick={() => {
                      setSelectedImages((prev) => {
                        const elem = [...prev];
                        elem.splice(idx, 1);
                        setValue("imageTags", elem, { shouldValidate: true });
                        return elem;
                      });
                    }}
                    sx={{
                      padding: 0,
                      margin: 0,
                      minWidth: 0,
                      paddingBottom: 0.2,
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <CancelIcon
                      sx={{
                        color: "#FF6C44",
                        backgroundColor: "white",
                        borderRadius: 25,
                      }}
                      fontSize="small"
                    />
                  </Button>
                </div>
              ))}
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={8} className="flex flex-row justify-center">
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <img
              alt="add pic"
              src={CameraIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Pictures
          </Button>
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => setShowModal(true)}
          >
            <img
              alt="add tags"
              src={CirclePlusIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Tags
          </Button>
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => {
              setValue("addFavourite", !addFavourite, { shouldValidate: true });
            }}
          >
            <img
              alt="add fav"
              src={addFavourite ? AddFavouriteFillIcon : AddFavouriteIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Favorite
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormHelperText
            error={Boolean(errors.stringTags)}
            margin="dense"
            className="text-center"
          >
            {errors.stringTags && errors.stringTags.message}
          </FormHelperText>
          <div className=" bg-[#F7F7F7] rounded-xl p-4 mt-8 ">
            <div className="flex items-center">
              <p className="text-base text-gray-700">
                Share with Coach/Therapist?
              </p>
              <Tooltip
                title="Share this goal with your coach"
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
              <div className="ml-auto">
                <ToggleButton
                  checked={shareWithCoach}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShareWithCoach(true);
                    } else {
                      setShareWithCoach(false);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <Button
            className="w-full bg-hb_orange-dark mt-4"
            variant="contained"
            onClick={() => {
              if (!isValid) {
                return trigger();
              }
              postJournal(
                {
                  jId: journalData?.id,
                  name: getValues("name"),
                  description: getValues("description"),
                  images: getValues("imageTags")?.map((el, index) =>
                    el?.path ? el.url : el
                  ),
                  tags: getValues("stringTags")?.map((el) => el.id),
                  favourite: getValues("addFavourite") ? 1 : 0,
                  freestyle: freestyle ?? 0,
                  shared_to_coach: +shareWithCoach,
                },
                {
                  onSuccess: () => {
                    navigate(-1);
                  },
                }
              );
            }}
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Done
          </Button>
        </Grid>
      </Grid>
      {showModal && (
        <TagListModal
          open={showModal}
          handleClose={handleCloseModal}
          selectedStringTags={selectedStringTags}
          handleSetStringTags={handleSetStringTags}
        />
      )}
      <input
        ref={fileInputRef}
        accept="image/png, image/jpeg"
        type="file"
        className="hidden"
        onChange={(event) => {
          let file = event.target.files[0];
          if (file.size > 2000 * 1000) {
            window.alert("Please upload a file smaller than 2 MB");
            return false;
          }
          UploadImages(
            {
              type: "image",
              image: event.target.files[0],
            },
            {
              onSuccess: (resp) => {
                const tempArray = [...selectedImages, resp?.data];
                setSelectedImages(tempArray);
                setValue("imageTags", tempArray, { shouldValidate: true });
              },
            }
          );
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </div>
  );
};

export default FreeStyleScreen;
