import React, { useState, useEffect } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import "../index.scss";

const { useChannelStateContext } = require("stream-chat-react");

export const CustomChannelHeader = (props) => {
  const [userName, setUsername] = useState("");

  const { channel } = useChannelStateContext();

  useEffect(() => {
    if (Boolean(channel)) {
      const user = Object.keys(channel.state.members).filter(
        (key) => channel.state.members[key]?.user?.type === "user"
      );
      setUsername(channel.state.members[user]?.user?.name || channel?.data?.name);
    }
  }, [channel]);

  return (
    <>
      <div className="str-chat__header-livestream flex items-center justify-between">
        <div className="flex items-center rounded-t bg-white">
          <ChatIcon
            sx={{ color: "#56CCF2", fontSize: "1.8rem", marginRight: ".5rem" }}
          />
          <p className="text-lg text-hb_blue-800">Message- {userName}</p>
        </div>
      </div>
    </>
  );
};
