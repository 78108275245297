import { Avatar, Badge, Grid, IconButton, useMediaQuery } from "@mui/material";
import HabitBetterLogoMini from "assets/Images/CareBetterLogoWhite.png";
import Message from "assets/Icons/Message.svg";
import { format } from "date-fns";
import MobileBellIcon from "assets/Icons/mobile-bell.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import { useGetNotificationQuery } from "queries/Notification";
import { Fragment, useEffect, useMemo, useState } from "react";
import CommonDialog from "./CommonDialog";
import { ClientContextProvider } from "utils/Context";
import ChatBoxModal from "./ChatBox";
import { StreamChat } from "stream-chat";
import { useGetClientsQuery, useProfileQuery } from "queries/Profile";
import { onMessageListener } from "firebaseConfig";
import userDuoTone from "assets/Icons/userOrange.svg";
import mobMessage from "assets/Icons/mobMessage.svg";

const TopNav = (props) => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const [openChatModal, setOpenChatModal] = useState(false);
  const [totalUnreadMessageCount, setTotalUnreadMessageCount] = useState(0);
  const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY;
  const client = StreamChat.getInstance(apiKey);
  const [clientDetails, setclientDetails] = useState({
    id: "",
  });

  //quries
  const {
    data: fetchedNotificationData,
    dataUpdatedAt,
    refetch,
  } = useGetNotificationQuery();
  const { data: clientChatToken } = useGetClientsQuery();

  //queries
  const { data: fetchedProfile, refetch: profileRefetch } = useProfileQuery();

  useEffect(() => {
    refetch();
    profileRefetch();
  }, []);

  useEffect(() => {
    const setupClient = async () => {
      try {
        await client.connectUser(
          {
            id: clientDetails.id,
          },
          clientChatToken?.token
        );
        client.on((event) => {
          if (event.total_unread_count !== undefined) {
            setTotalUnreadMessageCount(event.total_unread_count);
          }
        });
      } catch (err) {}
    };

    if (Boolean(clientDetails?.id) && clientChatToken?.token) {
      setupClient();
    }
  }, [clientDetails, clientChatToken]);

  useEffect(() => {
    if (fetchedProfile) {
      setclientDetails({
        id: fetchedProfile?.data?.uuid,
      });
    }
  }, [fetchedProfile]);

  //Use useMemo
  const { notificationList } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        notificationList: fetchedNotificationData?.data,
      };
    } else {
      return { notificationList: [] };
    }
  }, [dataUpdatedAt]);

  useEffect(() => {
    // get notification
    onMessageListener()
      .then((payload) => {
        refetch();
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  const MobHeader = () => {
    return (
      <div className="flex flex-col w-screen pt-6 bg-hb_blue-700 rounded-b-3xl z-25 ">
        <Grid
          container
          className="flex flex-row w-screen px-3 items-center justify-between"
        >
          <Grid
            item
            className="flex justify-center align-middle item-center px-3 mr-6"
          >
            <img
              src={HabitBetterLogoMini}
              alt="group-logo"
              style={{
                color: "white",
                height: 24,
                width: 24,
              }}
            />
          </Grid>
          <Grid item className="flex flex-row items-center">
            <IconButton
              onClick={() => {
                setOpenChatModal(true);
              }}
              className=" cursor-pointer hover:bg-slate-600 p-1 rounded-xl"
            >
              {Boolean(totalUnreadMessageCount) ? (
                <Badge
                  sx={{
                    "& .MuiBadge-dot": {
                      padding: "3px",
                      marginTop: "6px",
                      minWidth: "6px",
                      width: "6px",
                      height: "6px",
                      background: "#ff4d4f",
                      marginRight: "2px",
                    },
                  }}
                  className="mx-0"
                  badgeContent={totalUnreadMessageCount}
                  color="orange"
                >
                  <img
                    alt="msg"
                    src={mobMessage}
                    style={{
                      color: "white",
                      height: 30,
                      width: 30,
                    }}
                  />
                </Badge>
              ) : (
                <img
                  alt="msg"
                  src={mobMessage}
                  style={{
                    color: "white",
                    height: 30,
                    width: 30,
                  }}
                />
              )}
            </IconButton>
            <div
              onClick={() => navigate(routes.NOTIFICATION.pathname)}
              className="p-1 cursor-pointer rounded-xl hover:bg-slate-600"
            >
              {Boolean(notificationList?.length) ? (
                <Badge
                  sx={{
                    "& .MuiBadge-dot": {
                      padding: "3px",
                      marginTop: "6px",
                      minWidth: "6px",
                      width: "6px",
                      height: "6px",
                      background: "#ff4d4f",
                      marginRight: "7px",
                    },
                  }}
                  className="mx-0"
                  badgeContent={notificationList?.length}
                  color="orange"
                >
                  <img
                    alt="MobileBellIcon"
                    style={{ height: 26, width: 26 }}
                    src={MobileBellIcon}
                  />
                </Badge>
              ) : (
                <>
                  <img
                    alt="MobileBellIcon"
                    style={{ height: 26, width: 26 }}
                    src={MobileBellIcon}
                  />
                </>
              )}
            </div>
            {openChatModal && (
              <ClientContextProvider>
                <CommonDialog
                  chat
                  // maxWidth={"md"}
                  open={openChatModal}
                  onClose={() => setOpenChatModal(false)}
                >
                  <div className="h-[500px]">
                    <ChatBoxModal
                      isAdd={true}
                      profileData={fetchedProfile?.data}
                      onClose={() => setOpenChatModal(false)}
                      open={openChatModal}
                    />
                  </div>
                </CommonDialog>
              </ClientContextProvider>
            )}
            <div
              onClick={() => navigate("/profile/info")}
              className="pl-2 cursor-pointer rounded-xl hover:bg-slate-600"
            >
              {/* {Boolean(notificationList?.length) ? (
                <Badge
                  sx={{
                    "& .MuiBadge-dot": {
                      padding: "3px",
                      marginTop: "6px",
                      minWidth: "6px",
                      width: "6px",
                      height: "6px",
                      background: "#ff4d4f",
                      marginRight: "5px",
                    },
                  }}
                  className="mx-1"
                  badgeContent={notificationList?.length}
                  color="orange"
                >
                  {Boolean(fetchedProfile?.data?.profile?.avatar) ? (
                    <Avatar
                      alt={"avatar"}
                      src={fetchedProfile?.data?.profile?.avatar ?? userDuoTone}
                      sx={{
                        height: 30,
                        width: 30,
                      }}
                    />
                  ) : (
                    <div className="h-6 w-6 bg-slate-50 items-center justify-center flex rounded-full">
                      <img
                        alt="userDuoTone"
                        style={{ height: 13, width: 13 }}
                        src={userDuoTone}
                      />
                    </div>
                  )}
                </Badge>
              ) : ( */}
              <>
                {Boolean(fetchedProfile?.data?.profile?.avatar) ? (
                  <Avatar
                    alt={"avatar"}
                    src={fetchedProfile?.data?.profile?.avatar ?? userDuoTone}
                    sx={{
                      height: 30,
                      width: 30,
                    }}
                  />
                ) : (
                  <div className="h-6 w-6 bg-slate-50 items-center justify-center flex rounded-full">
                    <img
                      alt="userDuoTone"
                      style={{ height: 13, width: 13 }}
                      src={userDuoTone}
                    />
                  </div>
                )}
              </>
              {/* )} */}
            </div>
          </Grid>
        </Grid>
        {props?.children}
      </div>
    );
  };

  const WebHeader = () => {
    return (
      <Grid
        container
        className="flex flex-row items-center bg-hb_blue-700 shadow-2xl shadow-slate-600 border-b-2 border-b-slate-600 z-50 h-20"
      >
        <Grid
          item
          className="flex justify-center align-middle item-center min-w-[280px] cursor-pointer"
          onClick={() => {
            navigate(routes.HOME.pathname);
          }}
        >
          <img
            src={HabitBetterLogoMini}
            alt="group-logo"
            className="aspect-auto w-16 py-2 text-white"
          />
        </Grid>
        <Grid item className="px-4">
          <p className="text-white text-sm font-normal">
            Hi {fetchedProfile?.data?.name ?? ""},
          </p>
          <p className="text-white text-lg font-semibold">Welcome back 👋</p>
        </Grid>
        <Grid item className="flex flex-row items-center ml-auto">
          <p className="text-white text-lg font-semibold mr-3">
            My Day {format(new Date(), "LLLL dd,yyyy")}
          </p>
          <div
            onClick={() => setOpenChatModal(true)}
            className="mr-2 cursor-pointer hover:bg-slate-600 p-2 rounded-xl"
          >
            {Boolean(totalUnreadMessageCount) ? (
              <Badge
                sx={{
                  "& .MuiBadge-dot": {
                    padding: "3px",
                    marginTop: "6px",
                    minWidth: "6px",
                    width: "6px",
                    height: "6px",
                    background: "#ff4d4f",
                    marginRight: "7px",
                  },
                }}
                className="mx-2"
                badgeContent={totalUnreadMessageCount}
                color="orange"
              >
                <img
                  alt="msg"
                  src={Message}
                  style={{
                    color: "white",
                    height: 30,
                    width: 35,
                  }}
                />
              </Badge>
            ) : (
              <img
                alt="msg"
                src={Message}
                style={{
                  color: "white",
                  height: 30,
                  width: 35,
                }}
              />
            )}
          </div>
          {openChatModal && (
            <ClientContextProvider>
              <CommonDialog
                chat
                // maxWidth={"md"}
                open={openChatModal}
                onClose={() => setOpenChatModal(false)}
              >
                <div className="h-[500px]">
                  <ChatBoxModal
                    isAdd={true}
                    profileData={fetchedProfile?.data}
                    onClose={() => setOpenChatModal(false)}
                    open={openChatModal}
                  />
                </div>
              </CommonDialog>
            </ClientContextProvider>
          )}
        </Grid>
        <div
          onClick={() => navigate(routes.NOTIFICATION.pathname)}
          className="p-2 cursor-pointer rounded-xl hover:bg-slate-600 mr-8"
        >
          {Boolean(notificationList?.length) ? (
            <Badge
              sx={{
                "& .MuiBadge-dot": {
                  padding: "3px",
                  marginTop: "6px",
                  minWidth: "6px",
                  width: "6px",
                  height: "6px",
                  background: "#ff4d4f",
                  marginRight: "7px",
                },
              }}
              className="mx-2"
              badgeContent={notificationList?.length}
              color="orange"
            >
              <img
                alt="MobileBellIcon"
                style={{ height: 30, width: 30 }}
                src={MobileBellIcon}
              />
            </Badge>
          ) : (
            <>
              <img
                alt="MobileBellIcon"
                style={{ height: 30, width: 30 }}
                src={MobileBellIcon}
              />
            </>
          )}
        </div>
      </Grid>
    );
  };

  return <Fragment>{isSmallScreen ? <MobHeader /> : <WebHeader />}</Fragment>;
};
export default TopNav;
