import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGetTagsQuery, usePostCustomTagsQuery } from "queries/Auth";
import { CustomTextField } from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";

const CustomSchema = yup.object().shape(
  {
    isCustomSelected: yup.boolean(),
    custom: yup.string().when("isCustomSelected", {
      is: true,
      then: yup
        .string()
        .min(2, "Please write something.")
        .required("Please write something."),
      otherwise: yup.string(),
    }),
  },
  ["isCustomSelected", "custom"]
);

const TagListModal = (props) => {
  const { open, handleClose, handleSetStringTags, selectedStringTags } = props;

  const {
    setValue,
    getValues,
    formState: { errors, isValid },
    register,
    trigger,
  } = useForm({
    resolver: yupResolver(CustomSchema),
    defaultValues: { isCustomSelected: false, custom: "" },
  });

  const { ref: refCustom, ...RegisterCustom } = register("custom");

  const { data, isFetching, refetch } = useGetTagsQuery();
  const { mutate: addCustomTag, isLoading } = usePostCustomTagsQuery();

  const [tagsList, setTagsList] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  useEffect(() => {
    if (data) {
      setTagsList([...data.data, { name: "Custom" }]);
      setSelectedCheckbox(selectedStringTags);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [open]);


  const checkCustom = () => {
    if (getValues("isCustomSelected")) {
      const formData = new FormData();
      formData.append("name", getValues("custom"));
      formData.append("type", "Custom");
      addCustomTag(formData, {
        onSuccess: (response) => {
          const elem = [...selectedCheckbox];
          elem[elem.length - 1] = response?.data;
          handleSetStringTags(elem.filter((it) => it.name !== "Custom"));
        },
      });
    } else {
      handleSetStringTags(selectedCheckbox);
    }
  };

  const renderCheckTypography = (item, idx) => {

    return (
      <div
        className="flex flex-row items-start mt-1"
        key={idx?.toString() + "taglist"}
      >
        <Checkbox
          onChange={(_e) => {
            const elem = [...selectedCheckbox];
            const elemIdx = elem.indexOf(item);
            if (elemIdx > -1) {
              elem.splice(elemIdx, 1);
            } else {
              elem.push(item);
            }
            if (item.name === "Custom") {
              setValue("isCustomSelected", !getValues("isCustomSelected"), {
                shouldValidate: true,
              });
            }
            setSelectedCheckbox(elem);
          }}
          checked={selectedCheckbox.find((it) => it.id === item.id)}
          sx={{
            color: "#FF6C44",
            "&.Mui-checked": {
              color: "#FF6C44",
            },
            padding: 0,
            paddingRight: 1,
            paddingTop: 0.25,
          }}
        />
        <p className="text-lg font-normal">{item.name}</p>
      </div>
    );
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle className="font-semibold" color="primary">
          <Grid
            container
            xs={12}
            direction="row"
            className="justify-between"
            alignItems="center"
          >
            Select Tags
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent className="p-6 no-scrollbar overflow-y-auto sm:w-[550px]">
          <>
            <div className="container m-auto grid grid-cols-2 sm:grid-cols-3">
              {tagsList?.map((it) => renderCheckTypography(it))}
            </div>
            <form id="custom-form" noValidate>
              <Grid item className="mt-2">
                <CustomTextField
                  variant={"outlined"}
                  required
                  disabled={!getValues("isCustomSelected")}
                  label=""
                  rows={5}
                  multiline
                  placeholder="Write Here..."
                  tyoe="text"
                  InputLabelProps={{
                    maxLength: 20,
                  }}
                  ref={refCustom}
                  {...RegisterCustom}
                  error={Boolean(errors.custom)}
                  helperText={errors.custom && errors.custom.message}
                />
              </Grid>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  if (isValid) {
                    checkCustom();
                  } else {
                    trigger();
                  }
                }}
                // form="custom-form"
                loading={isLoading}
                // type="submit"
                className="bg-hb_orange-dark container m-auto grid w-1/2"
              >
                Save
              </LoadingButton>
            </form>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default TagListModal;
