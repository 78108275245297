import React, { useState, memo } from "react";
import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import GroupLogo from "assets/Images/GroupLogo.svg";
import GroupLogoBg from "assets/Images/GroupLogoBg.svg";
import HabitBetterLogoMini from "assets/Images/CareBetterLogoWhite.png";
import LoginFormStepper from "./LoginFormStepper";

const LoginPage = () => {
  const isSmallScreen = useMediaQuery("(max-width:650px)");

  /* States */

  return (
    <div className="flex-center h-screen w-screen">
      <Grid container className="flex items-center">
        {isSmallScreen || (
          <Grid item xs={6}>
            <Box
              style={{
                backgroundImage: `url(${GroupLogoBg})`,
                backgroundSize: "cover",
                height: "100vh",
                color: "#f5f5f5",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                paddingLeft: "15%",
              }}
            >
              <Grid container xs={6}>
                <img
                  src={HabitBetterLogoMini}
                  alt="group-logo"
                  style={{
                    color: "white",
                    marginTop: "20%",
                    height: 60,
                    width: 60,
                  }}
                />
                <Box style={{ marginTop: "20%" }}>
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    lineHeight={"60px"}
                    fontStyle={{ textAlign: "start", size: 48 }}
                  >
                    Welcome to CareBetter
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={400}
                    style={{ marginTop: "2%" }}
                    fontStyle={{ textAlign: "start", size: 16 }}
                  >
                    CareBetter is the ultimate client success tool for
                    therapists and coaches.
                  </Typography>
                </Box>
                <img
                  src={GroupLogo}
                  alt="group-logo"
                  style={{ marginTop: "5%" }}
                />
              </Grid>
            </Box>
          </Grid>
        )}
        <Grid item xs={isSmallScreen ? 12 : 6}>
          <div className="flex h-screen overflow-y-auto ">
            <Paper
              variant=""
              sx={{ height: "auto" }}
              className="w-full px-4 sm:px-8 md:px-16 lg:px-28"
            >
              <LoginFormStepper />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(LoginPage);
