import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios } from "utils";
import QuereyKeys from "./queryKeys";

//setup intent
const StripeIntent = (data) => {
  const method = "POST";
  const url = `setup-intent/`;
  return axios({ method, url, data });
};
export const useStripeIntentQuery = () => {
  //   const navigate = useNavigate();
  return useMutation(StripeIntent, {
    onSuccess: (response) => {},
  });
};

//list all saved card
const AllSavedCards = () => {
  const method = "GET";
  const url = "/api/cards";

  return axios({
    method,
    url,
  });
};

export const GetAllSavedCardsAPI = () => {
  return useQuery([QuereyKeys.ALL_SAVED_CARD], AllSavedCards, {
    enabled: true,
  });
};

// GET Primary card
const PrimaryCard = () => {
  const method = "GET";
  const url = "/api/cards/default";

  return axios({
    method,
    url,
  });
};

export const useGetPrimaryCardAPI = () => {
  return useQuery([QuereyKeys.PRIMARY_CARD], PrimaryCard, {
    enabled: false,
  });
};

//add new card
const SaveNewCardDetalis = (data) => {
  const method = "POST";
  let url = `/api/cards`;
  return axios({ method, url, data });
};

export const useSaveNewCardDetalisAPI = () => {
  const queryClient = useQueryClient();

  return useMutation(SaveNewCardDetalis, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.ALL_SAVED_CARD);
      queryClient.invalidateQueries(QuereyKeys.PRIMARY_CARD);
    },
  });
};

//make card primary
const SelectPrimaryCard = (payload) => {
  const method = "POST";
  let url = `/api/cards/${payload?.primaryCardId}/primary`;
  const data = {
    is_primary: payload?.is_primary,
    // type:'card',
  };
  return axios({ method, url, data });
};

export const SelectPrimaryCardAPI = () => {
  const queryClient = useQueryClient();

  return useMutation(SelectPrimaryCard, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.ALL_SAVED_CARD);
    },
  });
};

//deletecard api
const DeleteCard = (data) => {
  const method = "DELETE";
  let url = `/api/cards/${data?.primaryCardId}`;

  return axios({ method, url, data });
};

export const DeletePaymentMethodAPI = () => {
  const queryClient = useQueryClient();

  //   const navigate = useNavigate();
  return useMutation(DeleteCard, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.ALL_SAVED_CARD);
    },
    onError: (error) => {
      // console.log('primary error is :', error)
    },
  });
};

//pay Invoice api
const PayInvoice = (payload) => {
  const method = "POST";
  let url = `/api/appointment/${payload?.id}/payment`;

  return axios({ method, url, data: payload.body });
};

export const usePayInvoiceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(PayInvoice, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.INVOICE_LIST);
    },
  });
};
