import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { ErrorBoundary } from "components";
import React, { forwardRef } from "react";

const PREFIX = "LabelledSelectField";

const classes = {
  inputFormRoot: `${PREFIX}-inputFormRoot`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelFormControl: `${PREFIX}-inputLabelFormControl`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.inputFormRoot}`]: {
    display: "flex",
  },

  [`& .${classes.inputLabelRoot}`]: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#464E5F",
    lineHeight: "1.3125rem",
  },

  [`& .${classes.inputLabelFormControl}`]: {
    position: "static",
    transform: "inherit",
    textAlign: "left",
    marginBottom: theme.spacing(1),
  },
  /* selectInput: {}, */
}));

const LabelledSelectField = forwardRef((props, ref) => {
  const {
    selectFieldClasses,
    selectData,
    label,
    labelColor,
    variant,
    error,
    helperText,
    className,
    size,
    placeholder,
    options,
    // ref,
    multiple,
    required,
    disableCloseOnSelect,
    openOnFocus,
    tooltip,
    disabled,
    ...restOfSelectFieldProps
  } = props;

  return (
    <div className={`flex flex-col w-full ${selectFieldClasses}`}>
      <ErrorBoundary>
        <StyledFormControl
          classes={{
            root: classes.inputFormRoot,
          }}
          className={className}
          sx={{ width: "100%" }}
        >
          <InputLabel
            classes={{
              root: classes.inputLabelRoot,
              formControl: classes.inputLabelFormControl,
            }}
            required={required}
            shrink
            disabled={disabled}
            color={labelColor}
          >
            {label}
          </InputLabel>

          <div>
            <Autocomplete
              disableCloseOnSelect={multiple && disableCloseOnSelect}
              fullWidth
              sx={{ width: "100%" }}
              multiple={multiple}
              size={size}
              options={options}
              openOnFocus={openOnFocus}
              disabled={disabled}
              renderInput={(params) => (
                <Tooltip title={tooltip} enterTouchDelay={0}>
                  <TextField
                    {...params}
                    variant={variant}
                    placeholder={placeholder}
                    ref={ref}
                    style={{
                      // padding: ".15rem 0",
                      width: "100%",
                    }}
                  />
                </Tooltip>
              )}
              {...restOfSelectFieldProps}
            />
          </div>

          <FormHelperText
            error={Boolean(error)}
            margin="dense"
            variant={variant}
            focused={Boolean(error)}
          >
            {helperText}
          </FormHelperText>
        </StyledFormControl>
      </ErrorBoundary>
    </div>
  );
});

LabelledSelectField.defaultProps = {
  selectFieldClasses: "",
  label: "",
  labelColor: "componentTitle",
  variant: "outlined",
  // ref: null,
  error: false,
  helperText: "",
  className: null,
  placeholder: "",
  options: [],
  size: "medium",
  required: false,
  multiple: false,
  disableCloseOnSelect: true,
  openOnFocus: true,
  tooltip: "",
};

export default LabelledSelectField;
