import { Avatar, Button, Grid } from "@mui/material";
import {
  useGetNonAssociatedCoachQuery,
  usePostSendInviteMutation,
} from "queries/Coach";
import GreenTickIcon from "assets/Icons/GreenTick.svg";
import React, { useMemo, useState } from "react";
import MobileHeader from "components/MobileHeader";
import { useNavigate } from "react-router-dom";
import CoachCard from "./components/CoachCard";
import { routes } from "Routes/RouteConstants";
import { ConfirmDialog } from "components";
import SearchBar from "components/SearchBar/SearchBar";
import { useDebounce } from "hooks/useDebounce";

const CoachScreen = () => {
  const navigate = useNavigate();

  //use state
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [searchJournal, setSearchJournal] = useState(null);
  const debText = useDebounce(searchJournal, 1000);
  //quries
  const { data: fetchedNonAssociatedCoachData, dataUpdatedAt } =
    useGetNonAssociatedCoachQuery({ q: debText?.length > 0 ? debText : null,});
  const { mutateAsync: postSendInvite } = usePostSendInviteMutation();

  //Use useMemo
  const { nonAssociatedCoachData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        nonAssociatedCoachData: fetchedNonAssociatedCoachData?.data,
      };
    } else {
      return { nonAssociatedCoachData: [] };
    }
  }, [dataUpdatedAt]);

  const sendInvite = (item) => {
    postSendInvite(
      {
        coach_id: item.id,
      },
      { onSuccess: () => setShowConfirmModal(true) }
    );
  };

  return (
    <div className="px-4 w-full mt-4 sm:pl-8 sm:mt-8">
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Coach"
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="GreenTickIcon"
              style={{ height: 60, width: 60 }}
              src={GreenTickIcon}
            />
            <p className="font-semibold text-base mt-4">Success</p>
            <p className="font-normal text-base mt-10 text-center ">
              Coach invite request sent succesfully
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        continueEdit={() => setShowConfirmModal(false)}
      />
      <div className="hidden sm:flex flex-row justify-between items-center px-4 h-16 bg-gray-100 rounded-xl">
        <p className="text-base font-medium">Coach</p>
        <Button
          variant="contained"
          className="bg-hb_blue-700 rounded-3xl min-w-max px-4 w-3/12 font-medium text-sm h-10"
          sx={{
            "&:hover": {
              backgroundColor: "#264265!important",
            },
          }}
          onClick={() => navigate(routes.INVITATIONS.pathname)}
        >
          Invitations
        </Button>
      </div>
      <SearchBar
          className="-mt-0 sm:-mt-6 sm:absolute sm:mx-3 sm:w-96 sm:right-0 sm:top-32"
          value={searchJournal}
          onChange={(e) => setSearchJournal(e.target.value)}
          showFilter={false}
        />
      <div>
        {nonAssociatedCoachData?.map((item) => {
          return (
            <CoachCard
              image={item?.avatar}
              name={item.name}
              address={item?.city}
              invite={item?.invite_status}
              onClick={
                item?.invite_status === "invite sent"
                  ? null
                  : (event) => {
                      event.stopPropagation();
                      sendInvite(item);
                    }
              }
              onClickCard={() =>
                navigate(routes.COACH_DETAIL.pathname, { state: { item } })
              }
            />
          );
        })}
      </div>
      <Button
        variant="contained"
        className="sm:hidden fixed bottom-8 right-6 bg-hb_blue-700 rounded-3xl min-w-max px-4 w-3/12 font-medium text-sm h-10 z-50"
        sx={{
          "&:hover": {
            backgroundColor: "#264265!important",
          },
        }}
        onClick={() => navigate(routes.INVITATIONS.pathname)}
      >
        Invitations
      </Button>
    </div>
  );
};

export default CoachScreen;
