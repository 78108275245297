import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios } from "utils";
import QuereyKeys from "./queryKeys";

//list Plans
const SubscriptionPlans = () => {
  const method = "GET";
  const url = "/api/subscription/plans";

  return axios({
    method,
    url,
  });
};

export const GetSubscriptionPlansAPI = () => {
  return useQuery([QuereyKeys.SUBSCRIPTION_PLANS], SubscriptionPlans, {
    enabled: true,
  });
};

//Subscribed Plan
const SubscribedPlan = () => {
  const method = "GET";
  const url = "/api/subscription/plans/selected";

  return axios({
    method,
    url,
  });
};

export const useGetSubscribedPlanAPI = () => {
  return useQuery([QuereyKeys.SUBSCRIBED_PLAN], SubscribedPlan, {
    enabled: true,
  });
};

// create subscription api
const CreateSubscription = (body) => {
  const method = "POST";
  const url = `/api/subscription/create`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const useCreateSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(CreateSubscription, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.SUBSCRIBED_PLAN);
      queryClient.invalidateQueries(QuereyKeys.PROFILE);
    },
  });
};

// create coach subscription api
const CoachCreateSubscription = (body) => {
  const method = "POST";
  const url = `/api/subscription/coach`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const useCoachCreateSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(CoachCreateSubscription, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.SUBSCRIBED_PLAN);
      queryClient.invalidateQueries(QuereyKeys.PROFILE);
    },
  });
};

// Update subscription api
const UpdateSubscription = (body) => {
  const method = "PUT";
  const url = `/api/subscription/update`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const useUpdateSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdateSubscription, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.SUBSCRIBED_PLAN);
      queryClient.invalidateQueries(QuereyKeys.PROFILE);
    },
  });
};

// Cancel subscription api
const CancelSubscription = (body) => {
  const method = "DELETE";
  const url = `/api/subscription`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const useCancelSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(CancelSubscription, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.SUBSCRIBED_PLAN);
      queryClient.invalidateQueries(QuereyKeys.PROFILE);
    },
  });
};

// coupon code
const CheckCouponCode = (body) => {
  const method = "POST";
  const url = `api/subscription/coupon`;
  return axios({ method, url, data: body });
};
export const CheckCouponCodeQuery = () => {
  return useMutation(CheckCouponCode, {
    onSuccess: (res) => {
      // queryClient.invalidateQueries("COUPON_LIST_TABLE");
    },
  });
};
