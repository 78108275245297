import { lazy, Suspense, useMemo } from "react";
import {
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getLocalStorageItem, localStorageKeys } from "hooks";
import RouteConstants from "Routes/RouteConstants";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { Layout, SeoHelmet } from "components";
import { routes } from "Routes/RouteConstants";
import { Backdrop } from "@mui/material";
import Loader from "components/Loader";
import Lottie from "react-lottie";

const DefaultScreen = lazy(() => import("pages/DefaultScreen"));

const LottieOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};

const RoutesPage = () => {
  const runningAPICount = useIsFetching();
  const mutatingAPICount = useIsMutating();

  const PublicRoutes = useMemo(
    () => (
      <>
        {Object.values(routes)
          .filter(
            (routeDetails) => routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`public-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
      </>
    ),
    []
  );
  const PrivateRoutes = useMemo(
    () => (
      <>
        {Object.values(routes)
          .filter(
            (routeDetails) => !routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => {
            const { children } = routeDetails;

            return (
              <Route
                key={`private-route-${routeDetails.pathname}`}
                path={routeDetails.pathname}
                element={
                  <Suspense fallback={null}>{routeDetails.element}</Suspense>
                }
              >
                {children &&
                  children.map((routeKey) => {
                    const childRouteDetails = routes[routeKey];
                    return (
                      <Route
                        key={`private-child-route-${childRouteDetails.pathname}`}
                        path={childRouteDetails.pathname}
                        index={childRouteDetails.index}
                        element={
                          <Suspense fallback={null}>
                            {childRouteDetails.element}
                          </Suspense>
                        }
                      />
                    );
                  })}
              </Route>
            );
          })}
      </>
    ),
    []
  );

  return (
    <>
      <div className="">
        <SeoHelmet />
        <Routes>
          {PublicRoutes}
          <Route element={<ProtectedRoutes />}>
            {PrivateRoutes}
            <Route path="*" element={<DefaultScreen />} />
          </Route>
          <Route path="*" element={<DefaultScreen />} />
        </Routes>
        <Backdrop
          open={Boolean(runningAPICount || mutatingAPICount)}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 100,
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <Lottie options={LottieOptions} height={150} width={"100%"} />
        </Backdrop>
      </div>
    </>
  );
};

const ProtectedRoutes = () => {
  const location = useLocation();
  const isLoggedIn = getLocalStorageItem(localStorageKeys.AUTH_TOKEN);
  return Boolean(isLoggedIn) ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={RouteConstants.HOME} replace state={{ from: location }} />
  );
};

export default RoutesPage;
