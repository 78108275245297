import ReactPlayer from "react-player/youtube";
import {
  Grid,
  Button,
  useMediaQuery,
  Tooltip,
  Typography,
  Checkbox,
  IconButton,
  Autocomplete,
  TextField,
  Modal,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { RatingOptions } from "../SignupForm";
import { useEffect } from "react";

/* Step-4 */
const Professional = (props) => {
  const { setFormStep } = props;
  //states
  // const isSmallScreen = useMediaQuery("(max-width:950px)");
  // const { search } = useLocation();
  // const paramEmaill = search.split("=")[1];

  const {
    watch,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useFormContext();

  useEffect(() => {
    trigger();
  }, []);

  const renderRatingOptionsMeter = (items) => {
    return (
      <Grid container xs={12} className="flex flex-row mt-3">
        <Grid item xs={6} className="flex flex-row">
          <p className="text-base font-normal">{items.name}</p>
          <Tooltip title={items.tooltipMessage} enterTouchDelay={0}>
            <InfoIcon sx={{ color: "#FF6C44", marginLeft: 1 }} />
          </Tooltip>
        </Grid>
        <Grid item xs={6} className="flex gap-3">
          {items.options?.map((it, idx) => {
            return (
              <Button
                variant="outlined"
                onClick={() => {
                  setValue(`professional.${items.value}`, it.value, {
                    shouldValidate: true,
                  });
                }}
                sx={{
                  height: 32,
                  width: 32,
                  borderRadius: 32,
                  borderColor: it.color,
                  color:
                    getValues(`professional.${items.value}`) === it.value
                      ? "white"
                      : it.color,
                  backgroundColor:
                    getValues(`professional.${items.value}`) === it.value
                      ? it.color + "!important"
                      : "transparent",
                  padding: 0,
                  minWidth: 32,
                }}
              >
                {it.value}
              </Button>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container className="mt-10">
        <Grid item xs={12} md={10} className="overflow-hidden rounded-2xl">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=9m0ZsMoFL4o"
            height={280}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <p className="text-xl font-semibold mt-6">
            It's easy to get on autopilot at work check-ins allow you to
            identify
            <br /> what's working and what needs to change.
          </p>
        </Grid>
        <Grid item xs={12} md={10}>
          <p className="text-base font-normal mt-6 bg-hb_secondary-100 p-3 rounded-xl">
            Your professional life has to do with how you feel about yourself
            regarding your career, education or work.
            <br /> Measuring your work life helps you identify areas you can
            improve through a change of habits habits or simply gaining
            perspective.
            <br /> Score your work life in each each of these dimensions.
          </p>
        </Grid>
        <Grid item xs={12} md={9}>
          <p className="text-base font-semibold mt-6 mb-2 text-hb_orange-dark">
            Professional
          </p>
          {RatingOptions?.map((it) => renderRatingOptionsMeter(it))}
        </Grid>

        <Grid item xs={12} md={8} className="my-4">
          <Button
            className="w-full bg-hb_orange-dark mt-4"
            variant="contained"
            form="signup-form"
            type="submit"
            disabled={!isValid}
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Next
          </Button>
          <Button
            className="w-full mt-2 text-hb_orange-dark underline underline-offset-2"
            variant="text"
            onClick={() => {
              setValue("isSkipProfessional", true, { shouldValidate: true });
              setFormStep(5);
            }}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default Professional;
