import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const ConfirmDialog = (props) => {
  const { open, handleClose, continueEdit, message, type } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog"
      maxWidth={"sm"}
      fullWidth={false}
      sx={{
        "& .MuiPaper-root": {
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "1rem",
        },
      }}
      className="items-between flex h-full flex-col"
    >
      <DialogTitle id="confirmation-dialog-title text-center">
        {message}
      </DialogTitle>

      {type && type === "yesno" && (
        <DialogActions>
          <Button
            onClick={continueEdit}
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              background: "#FF6C44 !important",
              color: "white",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            YES
          </Button>
          <Button
            onClick={handleClose}
            disableElevation
            variant="outlined"
            color="primary"
            sx={{
              background: "white !important",
              color: "#FF6C44",
              border: "1px solid #FF6C44",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            NO
          </Button>
        </DialogActions>
      )}
      {type && type === "cancelsubmit" && (
        <DialogActions>
          <Button
            onClick={handleClose}
            disableElevation
            variant="outlined"
            color="primary"
            sx={{
              background: "white !important",
              color: "#FF6C44",
              border: "1px solid #FF6C44",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            Cancel
          </Button>
        </DialogActions>
      )}
      {type && type === "uploadFilesWarning" && (
        <DialogActions>
          <Button
            onClick={continueEdit}
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              background: "#FF6C44 !important",
              color: "white",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            Retry
          </Button>
          <Button
            onClick={handleClose}
            disableElevation
            variant="outlined"
            color="primary"
            sx={{
              background: "white !important",
              color: "#FF6C44",
              border: "1px solid #FF6C44",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            Close
          </Button>
        </DialogActions>
      )}
      {type && type === "acceptDeny" && (
        <DialogActions className="justify-center">
          <Button
            onClick={continueEdit}
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              background: "#FF6C44 !important",
              color: "white",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            Accept
          </Button>
          <Button
            onClick={handleClose}
            disableElevation
            variant="outlined"
            color="primary"
            sx={{
              background: "white !important",
              color: "#FF6C44",
              border: "1px solid #FF6C44",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            Deny
          </Button>
        </DialogActions>
      )}
      {type && type === "ok" && (
        <DialogActions className="justify-center">
          <Button
            onClick={continueEdit}
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              background: "#FF6C44 !important",
              color: "white",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  open: false,
  handleClose: () => null,
  message: "",
  type: "yesno",
  maxWidth: "xs",
  buttonSize: "large",
};

export default ConfirmDialog;
