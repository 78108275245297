import React, { useEffect, useState, memo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { EMAIL_NOT_VERIFIED } from "utils/Enums";
import { useLocalStorage } from "hooks";
import { useNavigate } from "react-router-dom";
import {
  useForgotPasswordQuery,
  useLoginQuery,
  useResetPasswordQuery,
  VerifyForgotPasswordOtpQuery,
  VerifyOtpQuery,
} from "queries/Auth";
import { routes } from "Routes/RouteConstants";
import VerifyOtpForm from "pages/SignupPage/components/VerifyOtp";
import SendOtp from "./components/SendOtp";
import ConfirmPasswordForm from "./components/ConfirmPassword";
import LoginFormMain from "./components/LoginFormMain";
import { toast } from "react-toastify";

const LoginFormSchema = yup.object().shape({
  email: yup.string().required("Email is a required field"),
  password: yup.string().required("Password is a required field"),
});
const SendOtpSchema = yup.object().shape({
  email: yup.string().required("Email is a required field"),
});
const OtpFormSchema = yup.object().shape({
  otp: yup
    .string()
    .min(4, "One Time Password should be atleast 4 digits")
    .required("One Time Password is required"),
});
const passwordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),

  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const LoginFormStepper = () => {
  const validationSchema = [
    LoginFormSchema,
    SendOtpSchema,
    OtpFormSchema,
    passwordValidationSchema,
  ];
  const navigate = useNavigate();

  // const isSmallScreen = useMediaQuery("(max-width:650px)");

  //states
  const [unVerifiedUser, setUnVerifiedUser] = useState("");
  const [formStep, setFormStep] = useLocalStorage("loginStep", 0);

  // react-hook-form
  const currentValidationSchema = validationSchema[formStep];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
  });
  const { handleSubmit, watch: loginFormCredentials, setError } = methods;

  //queries
  const { mutate: LoginUser } = useLoginQuery(loginFormCredentials);
  const { mutate: ValidateResetEmail } = useForgotPasswordQuery();
  const { mutateAsync: VerifyOtp } = VerifyForgotPasswordOtpQuery();
  const { mutateAsync: ConfirmPassword } = useResetPasswordQuery();
  const { mutate: sendVerifyEmailOtp } = VerifyOtpQuery();

  //handlers
  const submitLoginForm = (formData) => {
    if (formStep === 0) {
      LoginUser(formData, {
        onSuccess: () => {
          localStorage.setItem("userEmail-forgotPassword", formData.email);
          navigate("/main");
          toast.success("Logged In Successfully");
        },
        onError: (error) => {
          if (error.errors?.email?.length) {
            if (error.errors?.email[0] === EMAIL_NOT_VERIFIED) {
              setUnVerifiedUser(formData.email);
              sendVerifyEmailOtp({
                email: formData.email,
              });
              navigate(`/signup?email=${formData.email}`);
            } else {
              setError("email", {
                type: "custom",
                message: error.errors?.email?.[0],
              });
            }
          } else if (error?.errors?.password?.length) {
            setError("password", {
              type: "custom",
              message: error?.errors?.password[0],
            });
          }
        },
      });
    } else if (formStep === 1) {
      ValidateResetEmail(
        { email: formData.email },
        {
          onSuccess: () => {
            localStorage.setItem("userEmail-forgotPassword", formData.email);
            setFormStep(2);
          },
        }
      );
    } else if (formStep === 2) {
      VerifyOtp(
        { email: formData.email, otp: parseInt(formData?.otp) },
        {
          onSuccess: () => {
            if (unVerifiedUser) {
              navigate(routes.HOME.pathname);
              setFormStep(0);
            } else {
              setFormStep(3);
            }
          },
          onError: (error) => {
            setError("otp", {
              type: "custom",
              message: error?.message,
            });
          },
        }
      );
    } else if (formStep === 3) {
      ConfirmPassword(
        { email: formData.email, password: formData.confirm_password },
        {
          onSuccess: () => {
            setFormStep(0);
          },
        }
      );
    }
  };

  const getUiForCurrentStep = (step) => {
    switch (step) {
      case 0:
        return <LoginFormMain setFormStep={setFormStep} />;
      case 1:
        return <SendOtp setFormStep={setFormStep} />;
      case 2:
        return <VerifyOtpForm isLogin={true} setFormStep={setFormStep} />;
      case 3:
        return <ConfirmPasswordForm setFormStep={setFormStep} />;
      default:
        return;
    }
  };

  useEffect(() => {
    return () => setFormStep(0);
  }, []);

  return (
    <FormProvider {...methods}>
      <form id="login-form" noValidate onSubmit={handleSubmit(submitLoginForm)}>
        {getUiForCurrentStep(formStep)}
      </form>
    </FormProvider>
  );
};

export default memo(LoginFormStepper);
