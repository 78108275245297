import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  useMediaQuery,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTextField, ToggleButton } from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useNavigate } from "react-router-dom";
import { routes } from "Routes/RouteConstants";

const CreateHabitModal = (props) => {
  const { open, handleClose, onClickSelect } = props;
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const navigate = useNavigate();

  const renderMainContent = () => {
    return (
      <div className="flex flex-col">
        <Button
          variant="contained"
          className="text-gray-700 bg-hb_orange-light my-2"
          onClick={onClickSelect}
        >
          Select From Library
        </Button>
        <Button
          variant="contained"
          className="text-gray-700 bg-hb_blue-300 my-2"
          onClick={() =>
            navigate(routes.CREATE_HABIT.pathname, { state: { habitData: {} } })
          }
        >
          Create New Habit
        </Button>
      </div>
    );
  };

  const drawerBleeding = 46;

  const Root = styled("div")(({ theme }) => ({
    height: "100%",
    backgroundColor:
      theme.palette.mode === "light"
        ? grey[100]
        : theme.palette.background.default,
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  }));

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[700] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

  return (
    <>
      {isSmallScreen ? (
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(70% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => true}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: false,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              <div className="flex flex-row justify-between py-1">
                <Typography className="text-base font-semibold p-3 text-gray-500">
                  Select Habit
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    fontSize={"medium"}
                    style={{ color: "#434349", padding: 0, marginRight: 4 }}
                  />
                </IconButton>
              </div>
            </StyledBox>
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              {renderMainContent()}
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      ) : (
        <Dialog open={open}>
          <DialogTitle className="font-semibold" color="primary">
            <Grid
              container
              xs={12}
              direction="row"
              className="justify-between"
              alignItems="center"
            >
              Select Habit
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>

          <DialogContent className="p-6 no-scrollbar overflow-y-auto sm:w-[550px]">
            {renderMainContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default CreateHabitModal;
