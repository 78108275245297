/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  Autocomplete,
  TextField,
  FormHelperText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Today as TodayIcon,
  Cancel as CancelIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import ColorPaletteIcon from "assets/Icons/colorPalette.svg";
import InfoIcon from "@mui/icons-material/Info";
import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FetchCategoriesQuery } from "queries/Auth";
import { CustomTextField, ToggleButton, ToggleSliderTabs } from "components";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/system";
import TagListModal from "pages/SignupPage/modals/tagListModal";
import ColorPickerModal from "pages/SignupPage/modals/colorPickerModal";
import MobileHeader from "components/MobileHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { useCreateHabitMutation } from "queries/Habit";
import { format } from "date-fns";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

//placeholderData
const placeholderDaysData = [
  {
    id: 1,
    day: "Mo",
    value: "monday",
    selected: false,
  },
  {
    id: 2,
    day: "Tu",
    value: "tuesday",
    selected: false,
  },
  {
    id: 3,
    day: "We",
    value: "wednesday",
    selected: false,
  },
  {
    id: 4,
    day: "Th",
    value: "thursday",
    selected: false,
  },
  {
    id: 5,
    day: "Fr",
    value: "friday",
    selected: false,
  },
  {
    id: 6,
    day: "Sa",
    value: "saturday",
    selected: false,
  },
  {
    id: 7,
    day: "Su",
    value: "sunday",
    selected: false,
  },
];

const habitFormSchema = yup.object().shape({
  habitName: yup
    .string()
    .required("Habit name is required")
    .min(3, "Habit name must be at least 3 characters")
    .max(254, "Habit name must be less than 255 characters"),
  habitStartDate: yup.date().nullable().required("Start date is required."),
  habitEndDate: yup
    .date()
    .nullable()
    .test(
      "is-greater",
      "Habit end date must later than habit start date.",
      function (value) {
        const { habitStartDate } = this.parent; // Get the value of the start date field from the parent object
        return !habitStartDate || !value || habitStartDate < value;
      }
    ),
  whyTrack: yup
    .string()
    .min(3, "Please write at least 3 characters"),
  selectHabitColor: yup.string().required("Color is required"),
  notifications: yup.boolean().default(false),
  frequency: yup
    .string()
    .required("Frequency is required")
    .test(
      "len",
      "Maximum frequency should be less than 100",
      (val) => val?.length <= 2
    ),
  frequencyType: yup.string().oneOf(["daily", "weekly"]),
  goal: yup.boolean().default(false),
  goalDueDate: yup
    .date()
    .nullable()
    .when("goal", {
      is: true,
      then: yup.date().required("Goal Due Date is required."),
      otherwise: yup.date().notRequired(),
    }),
  days: yup
    .array()
    .test("len", "Please select atleast one day", function (val) {
      if (this.parent?.frequencyType === "daily") {
        return true;
      }
      return Boolean(val?.find((item) => item.selected));
    }),
  habit_category_id: yup.string().required("Habit Category is required"),
  streakAndGoalsTarget: yup
    .number("Streak and Goals Target is required")
    .typeError("Streak and Goals Target is required")
    .required("Streak and Goals Target is required")
    .min(
      1,
      "Streak and Goals Target should be greater than '0' less than '366' "
    )
    .max(
      365,
      "Streak and Goals Target should be greater than '0' less than '365' "
    ),
  stringTagsHabit: yup
    .array()
    .min(1, "Please select atleast one Tag")
    .required("Please select atleast one Tag"),
  shared_to_coach: yup.boolean().default(false),
  makePublic: yup.boolean().default(false),
  shared_to_friend: yup.boolean().default(false),
  friendEmail: yup.string().when("shared_to_friend", {
    is: true,
    then: yup
      .string()
      .email("Invalid email address")
      .required("Friend email is required"),
    otherwise: yup.string(),
  }),
});

const CreateHabitsScreen = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { habitData } = state;
  //states
  const [habitCategories, setHabitCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showColorModal, setShowColorModal] = useState(false);
  const [selectedStringTags, setSelectedStringTags] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  //quries
  const { data: fetchedHabitCategories } = FetchCategoriesQuery();
  const { mutateAsync: MutateHabit, isSuccess } = useCreateHabitMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const {
    watch,
    formState: { errors, isValid },
    setValue,
    getValues,
    register,
    trigger,
    control,
  } = useForm({
    resolver: yupResolver(habitFormSchema),
    mode: "all",
  });

  const {
    fields: scheduleNotificationsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "scheduleNotificationsSet",
  });

  const {
    frequencyType,
    days,
    notifications,
    category,
    goal,
    shared_to_coach,
    makePublic,
    shared_to_friend,
  } = watch();

  useEffect(() => {
    setValue("days", placeholderDaysData, { shouldValidate: true });
    setValue("frequencyType", "daily", { shouldValidate: true });
  }, []);

  //useEffects
  useEffect(() => {
    const categories = fetchedHabitCategories?.data?.map((categoryItem) => {
      return {
        ...categoryItem,
        type: categoryItem.name,
        label: categoryItem.name,
        value: categoryItem.name,
      };
    });
    setHabitCategories(categories);
  }, [fetchedHabitCategories]);

  const { ref: refHabitName, ...RegisterHabitName } = register("habitName");
  const { ref: refWhyTrack, ...RegisterWhyTrack } = register("whyTrack");
  const { ref: refFriendEmail, ...RegisterFriendEmail } =
    register("friendEmail");

  //Handlers
  const handleSetValue = (key, val) => {
    setValue(key, val, { shouldValidate: true });
  };

  useEffect(() => {
    if (state?.habitData?.name) {
      handleSetValue("habitName", state?.habitData?.name);
      handleSetValue("habitStartDate", state?.habitData?.start_date);
      handleSetValue("habitEndDate", state?.habitData?.end_date);
      handleSetValue("whyTrack", state?.habitData?.why ?? "");
      handleSetValue("frequency", state?.habitData?.frequency);
      handleSetValue("frequencyType", state?.habitData?.period_type);
      handleSetValue("goal", Boolean(state?.habitData?.goal));
      if (habitData?.goal === 1)
        handleSetValue(
          "goalDueDate",
          moment(state?.habitData?.goals?.[0]?.date, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          )
        );
      handleSetValue("selectHabitColor", state?.habitData?.color);
      handleSetValue("habit_category_id", state?.habitData?.category_id);
      handleSetValue("streakAndGoalsTarget", state?.habitData?.streak_target);
      handleSetValue(
        "shared_to_coach",
        Boolean(state?.habitData?.shared_to_coach)
      );
      handleSetValue("makePublic", Boolean(state?.habitData?.public));
      handleSetValue("stringTagsHabit", state?.habitData?.tags);
      setSelectedStringTags(state?.habitData?.tags);
    }
  }, [state?.habitData]);

  const handleDaySelect = (id) => {
    const selectedMappedDays = days?.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    handleSetValue("days", selectedMappedDays);
  };
  const handleUpdateScheduleNotificationSet = (setIdx, field, val) => {
    scheduleNotificationsFields[setIdx][field] = val;
    handleSetValue("scheduleNotificationsSet", scheduleNotificationsFields);
  };

  const handleCloseModal = () => {
    return setShowModal(false);
  };

  const handleCloseColorModal = () => {
    return setShowColorModal(false);
  };

  const handleSelectColor = (data) => {
    setSelectedColor(data);
    handleSetValue("selectHabitColor", data);
    setShowColorModal(false);
  };

  const handleSetStringTags = (data) => {
    setSelectedStringTags(data);
    setValue("stringTagsHabit", data, { shouldValidate: true });
    setShowModal(false);
  };

  const handleSubitForm = () => {
    if (habitData?.id) {
      MutateHabit({
        habit_id: habitData?.id,
        friend_email: getValues().shared_to_friend
          ? getValues().friendEmail
          : null,
        name: getValues().habitName,
        category_id: getValues().habit_category_id,
        ...(getValues().habitStartDate && {
          start_date: format(
            new Date(getValues()?.habitStartDate),
            "yyyy-MM-dd"
          ),
        }),
        color: getValues().selectHabitColor,
        period_type: getValues().frequencyType,
        frequency: getValues().frequency,
        ...(getValues()?.frequencyType === "weekly" && {
          frequency_payload: getValues()
            .days.filter((day) => day?.selected)
            .map((day) => day?.value),
        }),
        notification: getValues().notifications ? 1 : 0,
        notification_timings: getValues()?.scheduleNotificationsSet?.map(
          (notification) => {
            return {
              time: format(notification?.time, "HH:mm:ss"),
              day: notification?.day,
            };
          }
        ),
        streak_target: getValues()?.streakAndGoalsTarget,
        ...(getValues().whyTrack && { why: getValues().whyTrack }),
        ...(getValues().goalDueDate && {
          goal_due_date: format(
            new Date(getValues().goalDueDate),
            "yyyy-MM-dd"
          ),
        }),
        ...(getValues().habitEndDate && {
          end_date: format(new Date(getValues().habitEndDate), "yyyy-MM-dd"),
        }),
        goal: getValues().goal ? 1 : 0,
        shared_to_coach: getValues().shared_to_coach ? 1 : 0,
        public: getValues().makePublic ? 1 : 0,
        shared_to_friend: getValues().shared_to_friend ? 1 : 0,
        tags:
          getValues().stringTagsHabit?.length > 0
            ? getValues().stringTagsHabit?.map((it) => it.id)
            : null,
      });
    } else {
      MutateHabit({
        friend_email: getValues().shared_to_friend
          ? getValues().friendEmail
          : null,
        name: getValues().habitName,
        category_id: getValues().habit_category_id,
        ...(getValues().habitStartDate && {
          start_date: format(
            new Date(getValues()?.habitStartDate),
            "yyyy-MM-dd"
          ),
        }),
        color: getValues().selectHabitColor,
        period_type: getValues().frequencyType,
        frequency: getValues().frequency,
        ...(getValues()?.frequencyType === "weekly" && {
          frequency_payload: getValues()
            .days.filter((day) => day?.selected)
            .map((day) => day?.value),
        }),
        notification: getValues().notifications ? 1 : 0,
        notification_timings: getValues()?.scheduleNotificationsSet?.map(
          (notification) => {
            return {
              time: format(notification?.time, "HH:mm:ss"),
              day: notification?.day,
            };
          }
        ),
        streak_target: getValues()?.streakAndGoalsTarget,
        ...(getValues().whyTrack && { why: getValues().whyTrack }),
        ...(getValues().habitEndDate && {
          end_date: format(new Date(getValues().habitEndDate), "yyyy-MM-dd"),
        }),
        ...(getValues().goalDueDate && {
          goal_due_date: format(
            new Date(getValues().goalDueDate),
            "yyyy-MM-dd"
          ),
        }),
        goal: getValues().goal ? 1 : 0,
        shared_to_coach: getValues().shared_to_coach ? 1 : 0,
        public: getValues().makePublic ? 1 : 0,
        shared_to_friend: getValues().shared_to_friend ? 1 : 0,
        tags:
          getValues().stringTagsHabit?.length > 0
            ? getValues().stringTagsHabit?.map((it) => it.id)
            : null,
      });
    }
  };

  return (
    <div>
      {showModal && (
        <TagListModal
          open={showModal}
          handleClose={handleCloseModal}
          selectedStringTags={selectedStringTags}
          handleSetStringTags={handleSetStringTags}
        />
      )}
      {showColorModal && (
        <ColorPickerModal
          open={showColorModal}
          handleClose={handleCloseColorModal}
          handleSelectColor={handleSelectColor}
        />
      )}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Create Habit"
      />
      <Grid container xs={12} md={5} className="px-8 w-full mt-4">
        <ToggleSliderTabs
          value={frequencyType}
          setSelectedItem={(value) => {
            setValue("frequency", "");
            setValue("frequencyType", value, { shouldValidate: true });
          }}
          options={["daily", "weekly"]}
        />
      </Grid>
      <Grid container className="sm:px-8 w-full flex flex-col sm:flex-row">
        {/* BLOCK 1 */}
        <Grid item container xs={12} md={6} className="px-4 sm:px-0 sm:pr-4">
          <Grid item xs={12} className="my-2">
            <span className="text-sm font-semibold text-gray-600 mb-2">
              Name Your Habit
            </span>
            <CustomTextField
              variant={"outlined"}
              required
              label=""
              placeholder="Write Habit"
              type="text"
              ref={refHabitName}
              {...RegisterHabitName}
              error={Boolean(errors.habitName || errors.selectHabitColor)}
              helperText={
                errors.habitName
                  ? errors.habitName.message
                  : errors.selectHabitColor && errors.selectHabitColor.message
              }
              endAdornment={
                <IconButton
                  onClick={() => setShowColorModal(true)}
                  sx={{
                    padding: 0,
                    margin: 0,
                    minWidth: 0,
                    paddingBottom: 0.2,
                  }}
                >
                  {Boolean(selectedColor) ? (
                    <Box
                      sx={{
                        height: 20,
                        width: 20,
                        borderRadius: 15,
                        backgroundColor: selectedColor,
                      }}
                    ></Box>
                  ) : (
                    <img
                      src={ColorPaletteIcon}
                      style={{ height: 20, width: 20 }}
                      alt="palette"
                    />
                  )}
                </IconButton>
              }
            />
          </Grid>
          <Grid item xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">
              When will You Start?
            </p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                placeholder="Write Habit"
                value={
                  getValues("habitStartDate")
                    ? getValues("habitStartDate")
                    : null
                }
                onChange={(newValue) => {
                  setValue("habitStartDate", newValue, {
                    shouldValidate: true,
                  });
                }}
                error={Boolean(errors.habitStartDate)}
                disablePast={true}
                helperText={
                  errors.habitStartDate && errors.habitStartDate.message
                }
                sx={{
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Write Habit"
                    sx={{
                      width: "100%",
                      // marginTop: "15px",
                    }}
                    error={Boolean(errors.habitStartDate)}
                    helperText={
                      errors.habitStartDate && errors.habitStartDate.message
                    }
                  />
                )}
              />
            </LocalizationProvider>

            <Grid container>
              <Grid item xs={12}>
                <p className="text-sm font-semibold text-gray-600 my-3">
                  Category (Required)
                </p>
                <Autocomplete
                  required
                  value={category}
                  options={habitCategories || []}
                  popupIcon={<KeyboardArrowDownIcon />}
                  onChange={(event, newValue) => {
                    handleSetValue("category", newValue);
                    handleSetValue("habit_category_id", newValue?.id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  forcePopupIcon={true}
                />
                <div className="flex">
                  {Boolean(errors.habit_category_id) && (
                    <FormHelperText error={Boolean(errors.habit_category_id)}>
                      {errors.habit_category_id?.message}
                    </FormHelperText>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <p className="text-sm font-semibold text-gray-600 my-2">
                Does this habit have an end date? (Optional)
              </p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  placeholder="Write Habit"
                  value={
                    getValues("habitEndDate") ? getValues("habitEndDate") : null
                  }
                  onChange={(newValue) => {
                    setValue("habitEndDate", newValue, {
                      shouldValidate: true,
                    });
                  }}
                  sx={{
                    width: "100%",
                  }}
                  error={Boolean(errors.habitEndDate)}
                  disablePast={true}
                  helperText={
                    errors.habitEndDate && errors.habitEndDate.message
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Write Habit"
                      sx={{
                        width: "100%",
                        marginTop: "15px",
                      }}
                      error={Boolean(errors.habitEndDate)}
                      helperText={
                        errors.habitEndDate && errors.habitEndDate.message
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="rounded bg-hb_gray-500 p-4 mt-3">
              <div className="flex items-center">
                <p className="text-base text-gray-700">
                  Share with Coach/Therapist?
                </p>
                <Tooltip
                  title="Share this habit with your coach"
                  enterTouchDelay={0}
                >
                  <IconButton>
                    <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                  </IconButton>
                </Tooltip>
                <div className="ml-auto">
                  <ToggleButton
                    checked={shared_to_coach}
                    onChange={(e) => {
                      handleSetValue("shared_to_coach", e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="rounded bg-hb_gray-500 p-4 mt-3">
              <div className="flex items-center">
                <p className="text-base text-gray-700">
                  Share with Friends/Family
                </p>
                <Tooltip title="Share with Friends/Family" enterTouchDelay={0}>
                  <IconButton>
                    <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                  </IconButton>
                </Tooltip>
                <div className="ml-auto">
                  <ToggleButton
                    checked={shared_to_friend}
                    onChange={(e) => {
                      handleSetValue("shared_to_friend", e.target.checked);
                    }}
                  />
                </div>
              </div>
              {Boolean(shared_to_friend) && (
                <>
                  <p className="text-sm font-semibold text-gray-600 my-2">
                    Friend Email
                  </p>
                  <CustomTextField
                    label=""
                    placeholder="Email"
                    variant="outlined"
                    type="text"
                    ref={refFriendEmail}
                    {...RegisterFriendEmail}
                    error={Boolean(errors.friendEmail)}
                    helperText={
                      errors.friendEmail && errors.friendEmail.message
                    }
                    className="bg-white"
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </>
              )}
            </div>
            <div className="rounded bg-hb_gray-500 p-4 mt-3">
              <div className="flex items-center">
                <p className="text-base text-gray-700">Make Note Public?</p>
                <Tooltip title="Make this habit public" enterTouchDelay={0}>
                  <IconButton>
                    <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                  </IconButton>
                </Tooltip>
                <div className="ml-auto">
                  <ToggleButton
                    checked={makePublic}
                    onChange={(e) => {
                      handleSetValue("makePublic", e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* BLOCK 2 */}
        <Grid item container xs={12} md={6} className="px-4 sm:px-0 sm:pl-4">
          <Grid item xs={12} className="my-2">
            <div className="flex items-center space-x-4">
              <p
                className="text-base text-hb_black-100"
                style={Boolean(errors?.frequency) ? { color: "#d32f2f" } : null}
              >
                Each {frequencyType === "weekly" ? "week" : "day"}
                <span
                  className="text-hb_blue-350"
                  style={
                    Boolean(errors?.frequency) ? { color: "#d32f2f" } : null
                  }
                >
                  {" "}
                  *
                </span>
              </p>
              <input
                type="number"
                className="hide-spinners h-12 w-12 rounded bg-hb_gray-500 p-2 text-center"
                placeholder="#"
                {...register("frequency")}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
              />
            </div>
            {Boolean(errors?.frequency) && (
              <div>
                <p style={{ color: "#d32f2f", fontSize: ".8rem" }}>
                  {errors.frequency?.message}
                </p>
              </div>
            )}

            {frequencyType === "weekly" && (
              <div className="my-6">
                <div
                  className="flex items-center justify-between bg-hb_blue-700"
                  style={{
                    maxWidth: "350px",
                    borderRadius: "20px",
                    padding: ".3rem",
                  }}
                >
                  {days?.map((item) => (
                    <div
                      className={`${
                        item.selected
                          ? "rounded-full bg-white text-hb_blue-700"
                          : "text-white"
                      } flex cursor-pointer items-center justify-center text-xs `}
                      style={{ width: "35px", height: "35px" }}
                      key={item.id}
                      onClick={() => handleDaySelect(item.id)}
                    >
                      {item.day}
                    </div>
                  ))}
                </div>
                <div className="flex">
                  {Boolean(errors.days) && (
                    <FormHelperText error={Boolean(errors.days)}>
                      {errors.days?.message}
                    </FormHelperText>
                  )}
                </div>
              </div>
            )}
            <div className="rounded bg-hb_gray-500  p-4 mt-3">
              <div className="flex items-center">
                <p className="text-base text-gray-700">Get Notifications?</p>
                <Tooltip title="You will get Notifications" enterTouchDelay={0}>
                  <IconButton>
                    <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                  </IconButton>
                </Tooltip>
                <div className="ml-auto">
                  <ToggleButton
                    checked={notifications}
                    onChange={(e) => {
                      if (e.target.checked) {
                        append({
                          time: "",
                          day: "",
                        });
                      } else {
                        remove();
                      }
                      handleSetValue("notifications", e.target.checked);
                    }}
                  />
                </div>
              </div>

              {notifications && (
                <>
                  {scheduleNotificationsFields?.map((field, index) => {
                    return (
                      <Grid
                        container
                        className="mt-0 mb-4 "
                        spacing={1}
                        key={field.id}
                      >
                        <Grid
                          item
                          xs={scheduleNotificationsFields?.length > 1 ? 5.5 : 6}
                          className="flex items-center justify-center "
                        >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              PopperProps={{
                                style: {
                                  zIndex: props.customZIndex,
                                },
                              }}
                              label="Select time"
                              value={field.time}
                              onChange={(newValue) => {
                                handleUpdateScheduleNotificationSet(
                                  index,
                                  "time",
                                  newValue
                                );
                              }}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <TextField
                                  label="Select Time"
                                  className="mt-1"
                                  ref={inputRef}
                                  {...inputProps}
                                  InputProps={InputProps}
                                  sx={{
                                    background: "white",
                                    "& .MuiOutlinedInput-input": {
                                      fontSize: "1rem",
                                      color: "#264265",
                                      marginTop: "-5px",
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid
                          item
                          xs={scheduleNotificationsFields?.length > 1 ? 5.5 : 6}
                          className="flex items-center justify-center"
                        >
                          <FormControl fullWidth margin="dense">
                            <InputLabel id="day-label">Select Day</InputLabel>
                            <Select
                              labelId="day-label"
                              label="Select Day"
                              id="day-label-select"
                              value={field.day}
                              onChange={(e) => {
                                handleUpdateScheduleNotificationSet(
                                  index,
                                  "day",
                                  e.target.value
                                );
                              }}
                              IconComponent={TodayIcon}
                              sx={{
                                "& .MuiSelect-select": {
                                  backgroundColor: "white !important",
                                },

                                "& .MuiSelect-iconOpen": {
                                  transform: "rotate(0deg) !important",
                                  WebkitTransform: "rotate(0deg) !important",
                                },
                              }}
                            >
                              <MenuItem value={"everyday"}>Everyday</MenuItem>
                              <MenuItem value={"sunday"}>Sunday</MenuItem>
                              <MenuItem value={"monday"}>Monday</MenuItem>
                              <MenuItem value={"tuesday"}>Tuesday</MenuItem>
                              <MenuItem value={"wednesday"}>Wednesday</MenuItem>
                              <MenuItem value={"thursday"}>Thursday</MenuItem>
                              <MenuItem value={"friday"}>Friday</MenuItem>
                              <MenuItem value={"saturday"}>Saturday</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {scheduleNotificationsFields?.length > 1 && (
                          <Grid item xs={1} className="flex items-center">
                            <div>
                              <IconButton
                                className="p-0"
                                onClick={() => remove(index)}
                              >
                                <CancelIcon className="text-hb_blue-800 p-0" />
                              </IconButton>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}

                  <div className="flex justify-center">
                    <div className="text-center">
                      <IconButton onClick={() => append({ time: "", day: "" })}>
                        <AddCircleIcon
                          className="text-hb_orange-dark"
                          fontSize="large"
                        />
                      </IconButton>
                      <p>Add More</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="my-4 flex items-center space-x-4">
              <p
                className="text-base text-hb_black-100"
                style={
                  Boolean(errors?.streakAndGoalsTarget)
                    ? { color: "#d32f2f" }
                    : null
                }
              >
                Streaks & Goals Target
                <span
                  className="text-hb_blue-350"
                  style={
                    Boolean(errors?.streakAndGoalsTarget)
                      ? { color: "#d32f2f" }
                      : null
                  }
                >
                  *
                </span>
              </p>
              <input
                type="number"
                className="hide-spinners h-12 w-12 rounded bg-hb_gray-500 p-2 text-center"
                placeholder="#"
                {...register("streakAndGoalsTarget")}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
              />
            </div>
            {Boolean(errors?.streakAndGoalsTarget) && (
              <div>
                <p style={{ color: "#d32f2f", fontSize: ".8rem" }}>
                  {errors.streakAndGoalsTarget?.message}
                </p>
              </div>
            )}

            <div className="rounded bg-hb_gray-500 p-4 flex mt-3 justify-between">
              <div className="flex items-center">
                <p className="text-base text-gray-700">
                  Make this habit streak a Goal?
                </p>
                <Tooltip
                  title="Make this habit streak a Goal"
                  enterTouchDelay={0}
                >
                  <IconButton>
                    <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="ml-4 flex items-center">
                <ToggleButton
                  checked={goal}
                  onChange={(e) => {
                    handleSetValue("goal", e.target.checked);
                  }}
                />
              </div>
            </div>
            {Boolean(goal) && (
              <Grid item xs={12} className="my-2">
                <p className="text-sm font-semibold text-gray-600 mb-2">
                  Goal Due Date
                </p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    placeholder="Write Habit"
                    value={
                      getValues("goalDueDate") ? getValues("goalDueDate") : null
                    }
                    onChange={(newValue) => {
                      setValue("goalDueDate", newValue, {
                        shouldValidate: true,
                      });
                    }}
                    error={Boolean(errors.goalDueDate)}
                    disablePast={true}
                    helperText={
                      errors.goalDueDate && errors.goalDueDate.message
                    }
                    sx={{
                      width: "100%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Write Habit"
                        sx={{
                          width: "100%",
                          // marginTop: "15px",
                        }}
                        error={Boolean(errors.goalDueDate)}
                        helperText={
                          errors.goalDueDate && errors.goalDueDate.message
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}

            <div className="my-4">
              <p className="text-sm font-semibold text-gray-600 my-2">
                Why are you tracking this?
              </p>
              <CustomTextField
                label=""
                multiline={true}
                variant="outlined"
                rows={5}
                type="text"
                ref={refWhyTrack}
                {...RegisterWhyTrack}
                error={Boolean(errors.whyTrack)}
                helperText={errors.whyTrack && errors.whyTrack.message}
                autoComplete="whyTrack"
                className="bg-white"
              />
            </div>
            {Boolean(selectedStringTags?.length) && (
              <Grid item xs={12} md={12} className="mb-3">
                <p className="font-semibold py-2">Tags</p>
                <div className="flex flex-wrap gap-3 flex-row ">
                  {selectedStringTags?.map((it, idx) => (
                    <div className="items-center">
                      <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                        {it.name}{" "}
                        <Button
                          onClick={() => {
                            setSelectedStringTags((prev) => {
                              const elem = [...prev];
                              elem.splice(idx, 1);
                              setValue("stringTagsHabit", elem, {
                                shouldValidate: true,
                              });
                              return elem;
                            });
                          }}
                          sx={{
                            padding: 0,
                            margin: 0,
                            minWidth: 0,
                            paddingBottom: 0.2,
                          }}
                        >
                          <CancelIcon
                            sx={{ color: "white" }}
                            fontSize="small"
                          />
                        </Button>
                      </p>
                    </div>
                  ))}
                </div>
              </Grid>
            )}
            <FormHelperText
              error={Boolean(errors.stringTagsHabit)}
              margin="dense"
              className="ml-8"
            >
              {errors.stringTagsHabit && errors.stringTagsHabit.message}
            </FormHelperText>
            <div className="mb-6 text-center mt-3 justify-around flex flex-row">
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#FF6C44",
                  height: 50,
                  width: "40%",
                }}
                onClick={() => setShowModal(true)}
              >
                <AddCircleIcon fontSize="medium" sx={{ color: "#FF6C44" }} />
                <p className="text-xs text-black font-semibold mx-2">
                  Add Tags
                </p>
              </Button>
              <Button
                className="w-[40%] bg-hb_orange-dark"
                variant="contained"
                onClick={() => {
                  if (isValid) {
                    handleSubitForm();
                  } else {
                    trigger();
                  }
                }}
                sx={{ background: "#FF6C44", color: "white" }}
              >
                Done
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default CreateHabitsScreen;
