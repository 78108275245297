import { Search as SearchIcon } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import FilterIcon from "assets/Icons/filter.svg";

const SearchBar = (props) => {
  const {
    value,
    onChange,
    className,
    onClickFilter,
    showFilter = true,
  } = props;
  return (
    <div className={"flex flex-row items-center " + className}>
      <div className="flex flex-row w-full border bg-white shadow-lg rounded-3xl h-11 px-3 items-center">
        <TextField
          type="text"
          variant="standard"
          sx={{ width: "100%", borderWidthColor: "red" }}
          className="text-black placeholder:text-gray-700"
          placeholder="Search"
          value={value || ""}
          onChange={onChange}
          InputProps={{
            startAdornment: <SearchIcon className="text-hb_blue-800 mr-3" />,
            disableUnderline: true,
          }}
        />
      </div>
      {showFilter && (
        <IconButton
          sx={{ backgroundColor: "white !important", minWidth: 0 }}
          className="flex shadow-lg h-12 w-12 justify-center items-center rounded-full"
          onClick={onClickFilter}
        >
          <img
            alt="add tags"
            src={FilterIcon}
            style={{ height: 16, width: 16 }}
          />
        </IconButton>
      )}
    </div>
  );
};

export default SearchBar;
