/* eslint-disable react-hooks/exhaustive-deps */
import ReactPlayer from "react-player/youtube";
import {
  Grid,
  Button,
  useMediaQuery,
  Tooltip,
  IconButton,
  Autocomplete,
  TextField,
  FormHelperText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Today as TodayIcon,
  Cancel as CancelIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import WhiteheartIcon from "assets/Icons/whiteHeart.svg";
import ColorPaletteIcon from "assets/Icons/colorPalette.svg";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useEffect, useState } from "react";
import { FetchCategoriesQuery } from "queries/Auth";
import TagListModal from "../modals/tagListModal";
import { CustomTextField, ToggleButton, ToggleSliderTabs } from "components";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import ColorPickerModal from "../modals/colorPickerModal";
import { Box } from "@mui/system";
import { useProfileQuery } from "queries/Profile";

//placeholderData
const placeholderDaysData = [
  {
    id: 1,
    day: "Mo",
    value: "monday",
    selected: false,
  },
  {
    id: 2,
    day: "Tu",
    value: "tuesday",
    selected: false,
  },
  {
    id: 3,
    day: "We",
    value: "wednesday",
    selected: false,
  },
  {
    id: 4,
    day: "Th",
    value: "thursday",
    selected: false,
  },
  {
    id: 5,
    day: "Fr",
    value: "friday",
    selected: false,
  },
  {
    id: 6,
    day: "Sa",
    value: "saturday",
    selected: false,
  },
  {
    id: 7,
    day: "Su",
    value: "sunday",
    selected: false,
  },
];

/* Step-8 */
const YourHabits = (props) => {
  const { setFormStep } = props;
  //states
  const isSmallScreen = useMediaQuery("(max-width:950px)");
  const [habitCategories, setHabitCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showColorModal, setShowColorModal] = useState(false);
  const [selectedStringTags, setSelectedStringTags] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  //quries
  const { data: fetchedHabitCategories } = FetchCategoriesQuery();
  const { data: fetchedProfile } = useProfileQuery();

  const {
    watch,
    formState: { errors },
    setValue,
    getValues,
    register,
    control,
  } = useFormContext();

  const {
    fields: scheduleNotificationsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "scheduleNotificationsSet",
  });

  const { frequencyType, days, notifications, category, goal } = watch();

  useEffect(() => {
    setValue("days", placeholderDaysData, { shouldValidate: true });
    setValue("frequencyType", "daily", { shouldValidate: true });
  }, []);

  //useEffects
  useEffect(() => {
    const categories = fetchedHabitCategories?.data?.map((categoryItem) => {
      return {
        ...categoryItem,
        type: categoryItem.name,
        label: categoryItem.name,
        value: categoryItem.name,
      };
    });
    setHabitCategories(categories);
  }, [fetchedHabitCategories]);

  const { ref: refHabitName, ...RegisterHabitName } = register("habitName");

  const { ref: refWhyTrack, ...RegisterWhyTrack } = register("whyTrack");

  //Handlers
  const handleSetValue = (key, val, shouldValidate) => {
    setValue(key, val, { shouldValidate });
  };
  const handleDaySelect = (id) => {
    const selectedMappedDays = days?.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    handleSetValue("days", selectedMappedDays, true);
  };
  const handleUpdateScheduleNotificationSet = (setIdx, field, val) => {
    scheduleNotificationsFields[setIdx][field] = val;
    handleSetValue(
      "scheduleNotificationsSet",
      scheduleNotificationsFields,
      true
    );
  };

  const handleCloseModal = () => {
    return setShowModal(false);
  };

  const handleCloseColorModal = () => {
    return setShowColorModal(false);
  };

  const handleSelectColor = (data) => {
    setSelectedColor(data);
    handleSetValue("selectHabitColor", data, true);
    setShowColorModal(false);
  };

  const handleSetStringTags = (data) => {
    setSelectedStringTags(data);
    setValue("stringTagsHabit", data, { shouldValidate: true });
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <TagListModal
          open={showModal}
          handleClose={handleCloseModal}
          selectedStringTags={selectedStringTags}
          handleSetStringTags={handleSetStringTags}
        />
      )}
      {showColorModal && (
        <ColorPickerModal
          open={showColorModal}
          handleClose={handleCloseColorModal}
          handleSelectColor={handleSelectColor}
        />
      )}
      <div
        className={`flex flex-row items-center justify-end ${
          isSmallScreen ? "w-full" : "w-1/2"
        }  bg-hb_blue-700 h-20 absolute top-0 right-0 z-10`}
      >
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <div>
              <Button variant="contained" {...bindTrigger(popupState)}>
                <img
                  src={WhiteheartIcon}
                  alt="whiteheart"
                  style={{ height: 30, width: 30, marginRight: "15%" }}
                />
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <p className="p-2">
                  {fetchedProfile?.data?.profile?.why &&
                    JSON.parse(fetchedProfile?.data?.profile?.why)?.map(
                      (item) => <p className="p-1 capitalize text-xs">{item}</p>
                    )}
                </p>
              </Popover>
            </div>
          )}
        </PopupState>
      </div>
      <Grid container className="mt-28">
        <Grid item xs={12} md={9}>
          <p className="text-2xl font-semibold my-6 text-hb_blue-700">
            Your Life. Your habits
          </p>
        </Grid>
        <Grid item xs={12} md={10} className="overflow-hidden rounded-2xl">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=9m0ZsMoFL4o"
            height={280}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <p className="text-xl font-semibold mt-6">
            Habits Create sustainable changes
          </p>
        </Grid>
        <Grid item xs={12} md={10}>
          <p className="text-base font-normal mt-6 bg-hb_secondary-100 p-3 rounded-xl">
            Habits Create Sustainable Changes and guess what habit we think you
            should start with? Journaling, we believe in it so much we think you
            should start tracking it now. go ahead and use the form below to
            create your first habit.
          </p>
        </Grid>
        <Grid item xs={12} md={9}>
          <p className="text-xl font-semibold mt-6">Your First Habit</p>
        </Grid>
        <Grid item xs={12} md={9} className="my-2">
          <span className="text-sm font-semibold text-gray-600 mb-2">
            Name Your Habit
          </span>
          <CustomTextField
            variant={"outlined"}
            required
            label=""
            placeholder="Write Habit"
            type="text"
            ref={refHabitName}
            {...RegisterHabitName}
            error={Boolean(errors.habitName || errors.selectHabitColor)}
            helperText={
              errors.habitName
                ? errors.habitName.message
                : errors.selectHabitColor && errors.selectHabitColor.message
            }
            endAdornment={
              <IconButton
                onClick={() => setShowColorModal(true)}
                sx={{
                  padding: 0,
                  margin: 0,
                  minWidth: 0,
                  paddingBottom: 0.2,
                }}
              >
                {Boolean(selectedColor) ? (
                  <Box
                    sx={{
                      height: 20,
                      width: 20,
                      borderRadius: 15,
                      backgroundColor: selectedColor,
                    }}
                  ></Box>
                ) : (
                  <img
                    src={ColorPaletteIcon}
                    style={{ height: 20, width: 20 }}
                    alt="palette"
                  />
                )}
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={12} md={9} className="my-2">
          <p className="text-sm font-semibold text-gray-600 mb-2">
            When will You Start?
          </p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              placeholder="Write Habit"
              value={
                getValues("habitStartDate") ? getValues("habitStartDate") : null
              }
              onChange={(newValue) => {
                setValue("habitStartDate", newValue, {
                  shouldValidate: true,
                });
              }}
              error={Boolean(errors.habitStartDate)}
              disablePast={true}
              helperText={
                errors.habitStartDate && errors.habitStartDate.message
              }
              sx={{
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Write Habit"
                  sx={{
                    width: "100%",
                    // marginTop: "15px",
                  }}
                  error={Boolean(errors.habitStartDate)}
                  helperText={
                    errors.habitStartDate && errors.habitStartDate.message
                  }
                />
              )}
            />
          </LocalizationProvider>

          <Grid container>
            <Grid item xs={12}>
              <p className="text-sm font-semibold text-gray-600 my-3">
                Category (Required)
              </p>
              <Autocomplete
                required
                value={category}
                options={habitCategories || []}
                popupIcon={<KeyboardArrowDownIcon />}
                onChange={(event, newValue) => {
                  handleSetValue("category", newValue, true);
                  handleSetValue("habit_category_id", newValue?.id, true);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                forcePopupIcon={true}
              />
              <div className="flex">
                {Boolean(errors.habit_category_id) && (
                  <FormHelperText error={Boolean(errors.habit_category_id)}>
                    {errors.habit_category_id?.message}
                  </FormHelperText>
                )}
              </div>
            </Grid>
          </Grid>

          <div className="mt-12 mb-6">
            <ToggleSliderTabs
              value={frequencyType}
              setSelectedItem={(value) => {
                setValue("frequency", "");
                setValue("frequencyType", value, { shouldValidate: true });
              }}
              options={["daily", "weekly"]}
            />
          </div>
          <div className="flex items-center space-x-4">
            <p
              className="text-base text-hb_black-100"
              style={Boolean(errors?.frequency) ? { color: "#d32f2f" } : null}
            >
              Each {frequencyType === "weekly" ? "week" : "day"}
              <span
                className="text-hb_blue-350"
                style={Boolean(errors?.frequency) ? { color: "#d32f2f" } : null}
              >
                {" "}
                *
              </span>
            </p>
            <input
              type="number"
              className="hide-spinners h-12 w-12 rounded bg-hb_gray-500 p-2 text-center"
              placeholder="#"
              {...register("frequency")}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              }}
            />
          </div>
          {Boolean(errors?.frequency) && (
            <div>
              <p style={{ color: "#d32f2f", fontSize: ".8rem" }}>
                {errors.frequency?.message}
              </p>
            </div>
          )}

          {frequencyType === "weekly" && (
            <div className="my-6">
              <div
                className="flex items-center justify-between bg-hb_blue-700"
                style={{
                  maxWidth: "350px",
                  borderRadius: "20px",
                  padding: ".3rem",
                }}
              >
                {days?.map((item) => (
                  <div
                    className={`${
                      item.selected
                        ? "rounded-full bg-white text-hb_blue-700"
                        : "text-white"
                    } flex cursor-pointer items-center justify-center text-xs `}
                    style={{ width: "35px", height: "35px" }}
                    key={item.id}
                    onClick={() => handleDaySelect(item.id)}
                  >
                    {item.day}
                  </div>
                ))}
              </div>
              <div className="flex">
                {Boolean(errors.days) && (
                  <FormHelperText error={Boolean(errors.days)}>
                    {errors.days?.message}
                  </FormHelperText>
                )}
              </div>
            </div>
          )}
          <div className="rounded bg-hb_gray-500 -mr-8 p-4 mt-3">
            <div className="flex items-center">
              <p className="text-base text-gray-700">Get Notifications?</p>
              <Tooltip title="You will get Notifications" enterTouchDelay={0}>
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
              <div className="ml-auto">
                <ToggleButton
                  checked={notifications}
                  onChange={(e) => {
                    if (e.target.checked) {
                      append({
                        time: "",
                        day: "",
                      });
                    } else {
                      remove();
                    }
                    handleSetValue("notifications", e.target.checked, true);
                  }}
                />
              </div>
            </div>

            {notifications && (
              <>
                {scheduleNotificationsFields?.map((field, index) => {
                  return (
                    <Grid
                      container
                      className="mt-0 mb-4 "
                      spacing={1}
                      key={field.id}
                    >
                      <Grid
                        item
                        xs={scheduleNotificationsFields.length > 1 ? 5.5 : 6}
                        className="flex items-center justify-center "
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            PopperProps={{
                              style: {
                                zIndex: props.customZIndex,
                              },
                            }}
                            label="Select time"
                            value={field?.time}
                            onChange={(newValue) => {
                              handleUpdateScheduleNotificationSet(
                                index,
                                "time",
                                newValue
                              );
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <TextField
                                label="Select Time"
                                className="mt-1"
                                ref={inputRef}
                                {...inputProps}
                                InputProps={InputProps}
                                sx={{
                                  background: "white",
                                  "& .MuiOutlinedInput-input": {
                                    fontSize: "1rem",
                                    color: "#264265",
                                    marginTop: "-5px",
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={scheduleNotificationsFields.length > 1 ? 5.5 : 6}
                        className="flex items-center justify-center"
                      >
                        <FormControl fullWidth margin="dense">
                          <InputLabel id="day-label">Select Day</InputLabel>
                          <Select
                            labelId="day-label"
                            label="Select Day"
                            id="day-label-select"
                            value={field?.day}
                            onChange={(e) => {
                              handleUpdateScheduleNotificationSet(
                                index,
                                "day",
                                e.target.value
                              );
                            }}
                            IconComponent={TodayIcon}
                            sx={{
                              "& .MuiSelect-select": {
                                backgroundColor: "white !important",
                              },

                              "& .MuiSelect-iconOpen": {
                                transform: "rotate(0deg) !important",
                                WebkitTransform: "rotate(0deg) !important",
                              },
                            }}
                          >
                            <MenuItem value={"everyday"}>Everyday</MenuItem>
                            <MenuItem value={"sunday"}>Sunday</MenuItem>
                            <MenuItem value={"monday"}>Monday</MenuItem>
                            <MenuItem value={"tuesday"}>Tuesday</MenuItem>
                            <MenuItem value={"wednesday"}>Wednesday</MenuItem>
                            <MenuItem value={"thursday"}>Thursday</MenuItem>
                            <MenuItem value={"friday"}>Friday</MenuItem>
                            <MenuItem value={"saturday"}>Saturday</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {scheduleNotificationsFields.length > 1 && (
                        <Grid item xs={1} className="flex items-center">
                          <div>
                            <IconButton
                              className="p-0"
                              onClick={() => remove(index)}
                            >
                              <CancelIcon className="text-hb_blue-800 p-0" />
                            </IconButton>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}

                <div className="flex justify-center">
                  <div className="text-center">
                    <IconButton onClick={() => append({ time: "", day: "" })}>
                      <AddCircleIcon
                        className="text-hb_orange-dark"
                        fontSize="large"
                      />
                    </IconButton>
                    <p>Add More</p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="my-4 flex items-center space-x-4">
            <p
              className="text-base text-hb_black-100"
              style={
                Boolean(errors?.streakAndGoalsTarget)
                  ? { color: "#d32f2f" }
                  : null
              }
            >
              Streaks & Goals Target
              <span
                className="text-hb_blue-350"
                style={
                  Boolean(errors?.streakAndGoalsTarget)
                    ? { color: "#d32f2f" }
                    : null
                }
              >
                *
              </span>
            </p>
            <input
              type="number"
              className="hide-spinners h-12 w-12 rounded bg-hb_gray-500 p-2 text-center"
              placeholder="#"
              {...register("streakAndGoalsTarget")}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              }}
            />
          </div>
          {Boolean(errors?.streakAndGoalsTarget) && (
            <div>
              <p style={{ color: "#d32f2f", fontSize: ".8rem" }}>
                {errors.streakAndGoalsTarget?.message}
              </p>
            </div>
          )}

          <div className="rounded bg-hb_gray-500 p-4 flex mt-3 justify-between">
            <div className="flex items-center">
              <p className="text-base text-gray-700">
                Make this habit streak a Goal?
              </p>
              <Tooltip
                title="Make this habit streak a Goal"
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className="ml-4 flex items-center">
              <ToggleButton
                checked={goal}
                onChange={(e) => {
                  handleSetValue("goal", e.target.checked, true);
                }}
              />
            </div>
          </div>

          {Boolean(selectedStringTags?.length) && (
            <Grid item xs={12} md={12} className="mb-3">
              <p className="font-semibold py-2">Tags</p>
              <div className="flex flex-wrap gap-3 flex-row ">
                {selectedStringTags?.map((it, idx) => (
                  <div className="items-center">
                    <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                      {it.name}{" "}
                      <Button
                        onClick={() => {
                          setSelectedStringTags((prev) => {
                            const elem = [...prev];
                            elem.splice(idx, 1);
                            setValue("stringTagsHabit", elem, {
                              shouldValidate: true,
                            });
                            return elem;
                          });
                        }}
                        sx={{
                          padding: 0,
                          margin: 0,
                          minWidth: 0,
                          paddingBottom: 0.2,
                        }}
                      >
                        <CancelIcon sx={{ color: "white" }} fontSize="small" />
                      </Button>
                    </p>
                  </div>
                ))}
              </div>
            </Grid>
          )}

          <div className="mb-6 text-center mt-3">
            <Button
              variant="outlined"
              aria-label="add-tags"
              sx={{
                borderColor: "#FF6C44",
                minHeight: 0,
                width: "40%",
              }}
              onClick={() => setShowModal(true)}
            >
              <AddCircleIcon fontSize="medium" sx={{ color: "#FF6C44" }} />
              <p className="text-xs text-black font-semibold mx-2">Add Tags</p>
            </Button>
          </div>
          <FormHelperText
            error={Boolean(errors.stringTagsHabit)}
            margin="dense"
            // variant={variant}
          >
            {errors.stringTagsHabit && errors.stringTagsHabit.message}
          </FormHelperText>
          <div className="my-4">
            <p className="text-sm font-semibold text-gray-600 my-2">
              Why are you tracking this?
            </p>
            <CustomTextField
              label=""
              multiline={true}
              variant="outlined"
              rows={5}
              type="text"
              ref={refWhyTrack}
              {...RegisterWhyTrack}
              error={Boolean(errors.whyTrack)}
              helperText={errors.whyTrack && errors.whyTrack.message}
              autoComplete="whyTrack"
              className="bg-white"
            />
          </div>
          <Grid item xs={12}>
            <p className="text-sm font-semibold text-gray-600 my-2">
              Does this habit have an end date? (Optional)
            </p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                placeholder="Write Habit"
                value={
                  getValues("habitEndDate") ? getValues("habitEndDate") : null
                }
                onChange={(newValue) => {
                  setValue("habitEndDate", newValue, {
                    shouldValidate: true,
                  });
                }}
                sx={{
                  width: "100%",
                }}
                error={Boolean(errors.habitEndDate)}
                disablePast={true}
                helperText={errors.habitEndDate && errors.habitEndDate.message}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Write Habit"
                    sx={{
                      width: "100%",
                      marginTop: "15px",
                    }}
                    error={Boolean(errors.habitEndDate)}
                    helperText={
                      errors.habitEndDate && errors.habitEndDate.message
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <div className="rounded bg-hb_gray-500 p-4 mt-3">
            <div className="flex items-center">
              <p className="text-base text-gray-700">
                Share with Coach/Therapist?
              </p>
              <Tooltip
                title="Share this habit with your coach"
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
              <div className="ml-auto">
                <PopupState variant="popover" popupId="share-with-coach">
                  {(popupState) => (
                    <div>
                      <Button {...bindTrigger(popupState)}>
                        <ToggleButton
                          checked={false}
                          {...bindTrigger(popupState)}
                          onChange={(e) => {
                            return;
                          }}
                        />
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <p className="p-2 text-xs">
                          You can enable this toggle after you associate with a
                          coach
                        </p>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </div>
            </div>
          </div>
          <div className="rounded bg-hb_gray-500 p-4 mt-3">
            <div className="flex items-center">
              <p className="text-base text-gray-700">
                Share with Friends/Family
              </p>
              <Tooltip title="Share with Friends/Family" enterTouchDelay={0}>
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
              <div className="ml-auto">
                <PopupState variant="popover" popupId="share-with-friend">
                  {(popupState) => (
                    <div>
                      <Button {...bindTrigger(popupState)}>
                        <ToggleButton
                          checked={false}
                          {...bindTrigger(popupState)}
                          onChange={(e) => {
                            return;
                          }}
                        />
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <p className="p-2 text-xs">
                          You can enable this toggle after you make your profile
                          public.
                        </p>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </div>
            </div>
          </div>

          <div className="rounded bg-hb_gray-500 p-4 mt-3">
            <div className="flex items-center">
              <p className="text-base text-gray-700">Make Note Public?</p>
              <Tooltip title="Make this habit public" enterTouchDelay={0}>
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
              <div className="ml-auto">
                <PopupState variant="popover" popupId="make-habit-public">
                  {(popupState) => (
                    <div>
                      <Button {...bindTrigger(popupState)}>
                        <ToggleButton
                          checked={false}
                          {...bindTrigger(popupState)}
                          onChange={(e) => {
                            return;
                          }}
                        />
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <p className="p-2 text-xs">
                          You can enable this toggle after you make your profile
                          public.
                        </p>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={9}>
          <Button
            className="w-full bg-hb_orange-dark mt-4"
            variant="contained"
            // disabled={!isValid}
            form="signup-form"
            type="submit"
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Next
          </Button>
          <Button
            className="w-full mt-2 text-hb_orange-dark underline underline-offset-2"
            variant="text"
            onClick={() => setFormStep(9)}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default YourHabits;
