import * as React from "react";
import { Button, Grid, IconButton, Slider } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import RefreshIcon from "assets/Icons/Repeat_Icon.svg";
import heartGreenIcon from "assets/Icons/heartGreen.svg";
import { Fragment } from "react";
import MobileHeader from "components/MobileHeader";
import tagIcon from "assets/Icons/tag.svg";
import EditIcon from "assets/Icons/edit.svg";
import { useState } from "react";
import "./index.css";
import { useCreateGoalMutation, useGetGoalByIdQuery } from "queries/Goals";
import CircularBar from "components/CircularBar/CircularBar";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import moment from "moment";
import { routes } from "Routes/RouteConstants";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];

export default function GoalDetailScreen(props) {
  const navigate = useNavigate();
  const { state } = useLocation();

  //usestates
  const [progressValue, setProgressValue] = useState(0);

  const handleChange = (event, newValue) => {
    setProgressValue(newValue);
  };
  function valueText(value) {
    return `${value}`;
  }

  //queries
  const { data: fetchedGoal, dataUpdatedAt } = useGetGoalByIdQuery({
    id: state?.item?.id,
  });
  const { mutateAsync: postGoal } = useCreateGoalMutation();

  //Use useMemo
  const { goalData } = React.useMemo(() => {
    if (dataUpdatedAt) {
      setProgressValue(fetchedGoal?.data?.progress ?? 0);
      return {
        goalData: fetchedGoal?.data,
      };
    } else {
      return { goalData: {} };
    }
  }, [dataUpdatedAt]);

  return (
    <Fragment>
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Goal Detail"
      />
      <Grid
        container
        direction={"column"}
        maxWidth={"sm"}
        className="sm:pl-6 sm:mt-8 px-4"
      >
        <Grid className="border shadow-lg rounded-lg flex flex-col mb-4 p-4">
          <div className="flex flex-row items-center pb-2 ">
            <CircularBar label="Square linecaps" className="w-11 mt-2">
              <CircularProgressbar
                value={goalData?.progress ?? 0}
                text={`${goalData?.progress ?? 0}%`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: "#FF6C44",
                  trailColor: "#FAC1B8",
                  textColor: "#FF6C44",
                  textSize: "26px",
                })}
              />
            </CircularBar>
            <div className="flex flex-col ml-4">
              <p className="">{goalData?.name}</p>
              <p className="text-sm text-gray-500">
                {"Due Date: "}
                {goalData?.date
                  ? moment(goalData.date).format("MM/DD/YYYY")
                  : "-----"}
              </p>
            </div>
            <IconButton
              className="ml-auto"
              onClick={() =>
                navigate(routes.CREATE_GOAL.pathname, {
                  state: { item: goalData },
                })
              }
            >
              <img alt="edit" src={EditIcon} style={{ width: 25 }} />
            </IconButton>
          </div>
          <div className="capitalize text-sm text-gray-700">
            {goalData?.description}
          </div>
          {Boolean(goalData?.tags?.length) && (
            <div className="flex flex-row items-center capitalize text-xs mt-1 text-hb_blue-700">
              <img
                src={tagIcon}
                alt="tagIcon"
                style={{ height: 10, width: 10, marginRight: 10 }}
              />
              <p>{goalData?.tags?.map((el) => el.name).join(", ")}</p>
            </div>
          )}
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <div className="flex flex-col">
            <div className="text-sm font-medium sm:text-left text-center sm:text-base sm:font-semibold text-black">
              Linked Habits
            </div>

            {Boolean(goalData?.manual) ? (
              <div className="mt-8">
                <div className="bg-[#F7F7F7] flex flex-col p-4 rounded-xl">
                  <Slider
                    className="sx:m-6 my-2"
                    aria-label="Custom marks"
                    defaultValue={20}
                    value={progressValue}
                    onChange={handleChange}
                    getAriaValueText={valueText}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    color="green"
                  />
                  <p className="font-medium mt-2 text-gray-700">
                    Progress: {progressValue}
                  </p>
                  <div className="flex flex-row justify-center">
                    <Button
                      variant="contained"
                      className="bg-hb_orange-dark w-1/2"
                      onClick={() => {
                        postGoal(
                          {
                            goal_id: goalData.id,
                            name: goalData?.name,
                            description: goalData?.description,
                            shared_coach: goalData?.shared_coach,
                            manual: goalData?.manual,
                            progress: Number(progressValue),
                            date: goalData?.date,
                            tags: goalData?.tags?.map((el) => el.id),
                            linked_habit: 0,
                            habits: [],
                          },
                          {
                            onSuccess: () => {
                              navigate(routes.GOALS.pathname);
                            },
                          }
                        );
                      }}
                    >
                      Mark Progress
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4 justify-center flex flex-col w-full">
                {goalData?.habits?.map((item) => {
                  return (
                    <div
                      className="flex flex-row p-3 rounded-lg shadow-lg border mb-4 cursor-pointer"
                      style={{ borderColor: item?.color ?? "GrayText" }}
                      onClick={() =>
                        navigate(routes.HABIT_DETAIL.pathname, {
                          state: { item },
                        })
                      }
                    >
                      <img
                        alt="RefreshIcon"
                        style={{ height: 35, width: 35 }}
                        src={RefreshIcon}
                      />
                      <div className="flex flex-col ml-4">
                        <p className="flex flex-row items-center font-semibold">
                          {item?.name}
                          <img
                            alt="green heart"
                            style={{ height: 12, width: 15, marginLeft: 5 }}
                            src={heartGreenIcon}
                          />
                        </p>
                        <p className="text-xs capitalize">
                          {item?.period_type}, Frequency: {item?.frequency ?? 0}
                        </p>
                        <p className="text-xs">
                          Current Streak: {item?.current_streak}
                        </p>
                        <p className="text-xs">
                          Target Streak: {item?.pivot?.streak_target}
                        </p>
                      </div>
                      <CircularBar
                        label="Square linecaps"
                        className="w-11 mt-2 ml-auto"
                      >
                        <CircularProgressbar
                          value={item?.progress ?? 0}
                          text={`${item?.progress ?? 0}%`}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#FF6C44",
                            trailColor: "#FAC1B8",
                            textColor: "#FF6C44",
                            textSize: "26px",
                          })}
                        />
                      </CircularBar>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}
