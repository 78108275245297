import { Avatar, Button, Grid } from "@mui/material";
import {
  useGetCoursesByIdQuery,
  useGetMyCoursesQuery,
  usePostCourseWhyMutation,
  usePostSelectMutation,
  usePostStartCourseMutation,
} from "queries/Courses";
import React, { Fragment, useMemo, useState } from "react";
import MobileHeader from "components/MobileHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import MediIcon from "assets/Icons/meditation.svg";
import WhyModal from "components/WhyModal";

const NewCourseScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  //use state
  const [whyModal, setWhyModal] = useState(false);

  //queries
  const { data, dataUpdatedAt } = useGetCoursesByIdQuery(
    {
      id: state?.course?.id,
    },
    { skip: !Boolean(state?.course?.id) }
  );
  const { data: fetchMyCousres } = useGetMyCoursesQuery();
  const { mutateAsync: PostStartCousrse } = usePostStartCourseMutation();
  const { mutateAsync: selectCourse } = usePostSelectMutation();
  const { mutateAsync: updateWhy } = usePostCourseWhyMutation();

  //Use useMemo
  const { fetchedCourse } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        fetchedCourse: data?.data,
      };
    } else {
      return { fetchedCourse: {} };
    }
  }, [dataUpdatedAt]);

  //handler
  const addCourseWhy = () => {
    selectCourse({
      body: {
        course_id: fetchedCourse.id,
        coach_id: fetchedCourse.coach_id,
      },
    });
  };

  const handleClose = () => {
    setWhyModal(false);
  };
  const handleConfirm = (whyDescription) => {
    setWhyModal(false);
    PostStartCousrse(
      { id: fetchedCourse?.id },
      {
        onSuccess: (data) => {
          !data?.data?.why &&
            updateWhy(
              {
                course_user_id: data?.data?.id,
                why: whyDescription,
              },
              {
                onSuccess: () => {
                  navigate(-1);
                },
              }
            );
        },
      }
    );
  };

  const navigateToCourseBottomsheet = () => {
    !fetchMyCousres?.data?.courseUser?.why
      ? setWhyModal(true)
      : PostStartCousrse(
          { id: fetchedCourse?.id },
          {
            onSuccess: () => {
              navigate(-1);
            },
          }
        );
  };

  const beginCourse = () => {
    if (
      fetchMyCousres?.data?.find((el) => el.course_id === fetchedCourse?.id)
    ) {
      navigateToCourseBottomsheet();
    } else {
      addCourseWhy();
    }
  };

  return (
    <Fragment>
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title={"New Course"}
      />
      {/* Body */}
      <Grid container className="sm:m-8 px-4 sm:px-0 sm:gap-5">
        <Grid item md={8} xs={12} lg={5} sm={8} className="mb-4">
          <div className="flex flex-row items-center">
            <img
              src={fetchedCourse?.course_images?.[0]?.image ?? MediIcon}
              alt="goalCompleteIcon"
              style={{
                height: 100,
                width: 150,
                marginRight: 10,
                borderRadius: 5,
              }}
            />
            <div className="flex flex-col">
              <p className="capitalize text-base font-semibold text-hb_blue-700">
                Goals
              </p>
              <p className="capitalize text-xs font-normal text-gray-500">
                {fetchedCourse?.title}
              </p>
              {(fetchedCourse?.courseUser?.current === 0 ||
                fetchedCourse?.courseUser === null) && (
                <Button
                  variant="contained"
                  className="bg-hb_orange-dark h-9 mt-4 px-4"
                  onClick={beginCourse}
                >
                  Begin Courses
                </Button>
              )}
            </div>
          </div>
          <p className="sm:mt-8 mt-6 bg-hb_light_blue-100 p-3 rounded-lg text-gray-500 text-sm min-h-[100px]">
            {fetchedCourse?.journal_prompt}
          </p>
        </Grid>
        <Grid item md={8} xs={12} sm={8} lg={5}>
          <div className="w-full">
            {fetchedCourse?.lessons?.map((item, index) => {
              return (
                <div
                  className="flex flex-row items-center border rounded-lg mb-4 p-4 gap-5 cursor-pointer"
                  onClick={() =>
                    navigate(routes.SETTING_INSPIRED.pathname, {
                      state: {
                        lesson: item,
                      },
                    })
                  }
                >
                  <Avatar src={item?.thumbnail} alt="goalCompleteIcon" />
                  <div className="flex flex-col">
                    <p className="capitalize text-xs font-normal text-gray-500">
                      Lesson {index + 1}
                    </p>
                    <p className="capitalize text-base font-semibold text-hb_blue-700">
                      {fetchedCourse?.title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <WhyModal
        open={whyModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default NewCourseScreen;
