import { Button, Divider } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const MobileHeader = (props) => {
  const { onClick, title, className, trailing, variant } = props;
  if (variant === "primary") {
    return (
      <>
        <div
          className={`${className} sm:hidden flex flex-row bg-hb_blue-700 h-16 items-center justify-between`}
        >
          <Button onClick={onClick}>
            <ArrowBackIosIcon sx={{ color: "#fff" }} />
          </Button>
          <p className="text-white font-semibold text-xl">{title}</p>
          {trailing ? (
            trailing
          ) : (
            <ArrowBackIosIcon sx={{ color: "transparent", marginRight: 5 }} />
          )}
        </div>
        <Divider light className="sm:hidden -mx-4" />
      </>
    );
  } else if (variant === "secondary") {
    return (
      <>
        <div className={`${className} sm:hidden flex flex-row justify-between mt-4`}>
          <Button onClick={onClick} className="py-0">
            <ArrowBackIosIcon sx={{ color: "#234266" }} />
          </Button>
          <p className="text-hb_blue-700 font-semibold text-xl w-64 text-center truncate">{title}</p>
          {trailing ? (
            trailing
          ) : (
            <ArrowBackIosIcon sx={{ color: "transparent", marginRight: 5 }} />
          )}
        </div>
        <Divider className="sm:hidden mt-2 mb-4" />
      </>
    );
  }
};
MobileHeader.defaultProps = {
  variant: "primary",
  title: "",
  onClick: () => {},
};
export default MobileHeader;
