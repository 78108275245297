import userDuoTone from "assets/Icons/user-duotone 1.svg";
import mapPinIcon from "assets/Icons/orangeMapPin.svg";
import { Avatar, Button, Grid } from "@mui/material";

const CoachCard = (props) => {
  const { image, name, address, invite, onClick, onClickCard } = props;
  return (
    <Grid
      container
      className="flex flex-row mt-6 h-28 bg-gray-100 rounded-xl p-2 sm:p-4 cursor-pointer"
      onClick={onClickCard}
    >
      <Grid item xs={2.5} md={1} className="flex">
        {Boolean(image) ? (
          <Avatar
            src={image}
            className="flex justify-center h-12 w-12 items-center"
          />
        ) : (
          <div className="flex justify-center h-12 w-12 bg-white rounded-full items-center">
            <img
              alt="userDuoTone"
              style={{ height: 20, width: 22 }}
              src={userDuoTone}
            />
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={9}
        md={11}
        className="flex flex-col justify-center sm:justify-between"
      >
        <div className="font-medium text-gray-700 text-sm">
          {name ?? "Surander"}
        </div>
        <div className="font-normal flex flex-col sm:flex-row justify-between text-gray-700 text-sm">
          <div className="font-normal flex flex-row text-gray-700 text-xs my-2">
            <img
              alt="mapPinIcon"
              style={{ height: 16, width: 16, marginRight: 5 }}
              src={mapPinIcon}
            />
            {address ?? "address"}
          </div>
          <div>
            {invite === "invite sent" ? (
              <Button
                variant="contained"
                className="bg-hb_orange-dark rounded-3xl sm:rounded-lg w-28 font-normal text-xs h-8  "
                onClick={onClick}
              >
                Invite Sent
              </Button>
            ) : (
              <Button
                onClick={onClick}
                variant="outlined"
                sx={{ borderColor: "#FF6C44 !important" }}
                className="bg-white text-hb_orange-dark rounded-3xl sm:rounded-lg w-28 font-normal text-xs h-8"
              >
                Send Invite
              </Button>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default CoachCard;
