import { createTheme, responsiveFontSizes } from "@mui/material/styles";

/**
 * Theme
 */
const commonTheme = createTheme({
  palette: {
    primary: {
      main: "#264265",
      // contrastText: "#fff",
    },

    secondary: {
      main: "#264265B3",
    },
    text: {
      main: "#264265",
    },

    primaryBlue: {
      main: "#3699FF",
      contrastText: "#fff",
    },
    secondaryBlue: {
      main: "#789DFE",
      contrastText: "#fff",
    },
    blue: {
      main: "#56CCF2",
      contrastText: "#fff",
    },
    toggleTabs: {
      main: "#234266",
      contrastText: "#fff",
    },
    orange: {
      main: "#ff6c44",
      contrastText: "#fff",
    },
    green: {
      main: "#6FCF97",
      contrastText: "#fff",
    },
    componentTitle: {
      main: "#000000CC",
    },
    listSecondary: {
      main: "#597599",
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          textTransform: "inherit",
          boxShadow: "none",
          padding: "0.75rem",
        },
        contained: {
          "&:hover": {
            backgroundColor: "#FF6C44!important",
          },
        },
        outlined: {
          "&:hover": {
            backgroundColor: "transparent!important",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#597599",
          fontWeight: "500",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    // MuiRadio: {
    //   root: {
    //     color: "grey",
    //     "&.Mui-checked": {
    //       color: "#3699FF",
    //     },
    //   },
    // },
    // MuiStepper: {
    //   root: {
    //     padding: 15,
    //   },
    // },
    // MuiToolbar: {
    //   root: {
    //     minHeight: "56px !important",
    //   },
    // },
    // MuiTooltip: {
    //   tooltip: {
    //     fontSize: 12,
    //   },
    // },
    // MuiDialog: {
    //   container: {
    //     margin: 20,
    //   },
    // },
    // MuiTypography: {
    //   root: {
    //     fontFamily:
    //       '"Poppins", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important',
    //     fontSize: 14,
    //   },
    //   "&:disabled": {
    //     cursor: "not-allowed",
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontsize: "16px",
          "&:disabled": {
            cursor: "not-allowed",
          },
        },
      },
    },
    // MuiFilledInput: {
    //   input: {
    //     /* padding: ".5rem 1rem", */
    //   },
    // },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-focused": {
    //         color: "#3699FF",
    //       },
    //     },
    //     asterisk: {
    //       color: "red",
    //     },
    //   },
    // },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     asterisk: {
    //       color: "red",
    //     },
    //   },
    // },
    /* MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          backgroundColor: "#F1F1F1",
          border: "none",
        },
        input: {
          borderRadius: "6px",
        },
        notchedOutline: {
          border: "none",
        },
      },
    }, */
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: "14px",
        },
        root: {
          // padding: "16.5px 14px",
        },
      },
    },
    // MuiFormControlLabel: {
    //   "&:disabled": {
    //     cursor: "not-allowed !important",
    //   },
    // },
    // MuiTextField: {
    //   root: {
    //     // backgroundColor: "#F3F6F9",
    //     borderRaidus: "6px",
    //     backgroundColor: "red !important",
    //   },
    // },
    // MuiInputAdornment: {
    //   root: {
    //     marginRight: "4px",
    //     padding: "auto",
    //   },
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#ff6c44",
          color: "white",
          svg: {
            fill: "white",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: "#597599",
          fontSize: "12px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            marginBottom: "0px",
            paddingBottom: "0px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#597599",
          fontWeight: "500",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        icon: {
          color: "#FF6C44",
          fontSize: "2rem",
        },
      },
    },
  },
});

// Do your common overrides here
/**
 * Typography - body1
 */
commonTheme.typography.body1.fontSize = "0.8rem";
commonTheme.typography.body1.color = commonTheme.palette.text.primary;

/**
 * Typography - caption
 */
commonTheme.typography.caption.color = commonTheme.palette.text.hint;
/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme);

export default rootTheme;
