import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const LogHabitDateModal = (props) => {
  const { open, handleClose, onClickApply } = props;

  //usestate
  const [date, setDate] = useState(null);

  return (
    <Dialog open={open}>
      <DialogTitle className="font-semibold" color="primary">
        <Grid
          container
          direction="row"
          className="justify-between"
          alignItems="center"
        >
          <div className="text-transparent">h</div>
          Enter log date
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className="sm:w-[550px] w-[350px]">
        <>
          <p className="font-semibold">Enter Date</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              placeholder="MM/DD/YYYY"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              disablePast={false}
              maxDate={new Date()}
              sx={{
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="MM/DD/YYYY"
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <div className="flex justify-center">
            <Button
              variant="contained"
              className="my-4 bg-hb_orange-dark w-1/2"
              disabled={!Boolean(date)}
              onClick={() => onClickApply(date)}
            >
              Apply
            </Button>
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
};
export default LogHabitDateModal;
