import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SketchPicker } from "react-color";

const ColorPickerModal = (props) => {
  const { open, handleClose, handleSelectColor } = props;
  const refColorPicker = useRef(null);
  const [setColor, setSetColor] = useState("");

  return (
    <>
      <Dialog open={open}>
        <DialogTitle className="font-semibold" color="primary">
          <Grid
            container
            xs={12}
            direction="row"
            className="justify-between"
            alignItems="center"
          >
            Pick a color
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent
          sx={{ minWidth: "370px", minHeight: "400px" }}
          className="flex flex-col no-scrollbar overflow-y-auto items-center p-3"
        >
          <SketchPicker
            styles={{ margin: 50 }}
            color={setColor}
            onChangeComplete={({ hex }) => setSetColor(hex)}
          />

          <Button
            className="w-full bg-hb_orange-dark mt-4"
            variant="contained"
            disabled={!Boolean(setColor)}
            sx={{ background: "#FF6C44", color: "white" }}
            onClick={() => {
              handleSelectColor(setColor);
            }}
          >
            Set Color
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ColorPickerModal;
