import userDuoTone from "assets/Icons/user-duotone 1.svg";
import checkInIcon from "assets/Icons/checkIn.svg";
import meditationIcon from "assets/Icons/meditation.svg";
import fileGreenIcon from "assets/Icons/fileGreen.svg";
import tagIcon from "assets/Icons/tag.svg";
import { Avatar, Button, Grid, IconButton } from "@mui/material";
import { JournalType } from "pages/Journal";
import moment from "moment";

const JournalCard = (props) => {
  const {
    type,
    cardKey,
    onClickCard,
    name,
    description,
    tags,
    date,
    image,
    frequency,
    logCount,
    period_type,
    onClickNote,
  } = props;

  let iconName =
    type === JournalType.habits
      ? meditationIcon
      : type === JournalType.checkIn || type === JournalType.custom
      ? checkInIcon
      : checkInIcon;

  return (
    <Grid
      container
      key={cardKey}
      className="flex flex-col mt-6 shadow-lg border border-gray-100 rounded-xl p-2 sm:p-4 cursor-pointer"
      onClick={onClickCard}
    >
      <Grid item className="flex flex-row">
        <Grid className="mr-4">
          {type === JournalType.journals || type === JournalType.lesson ? (
            image.length > 0 ? (
              <Avatar
                src={image}
                className="flex justify-center h-12 w-12 items-center"
              />
            ) : (
              <div className="flex justify-center h-12 w-12 bg-white rounded-full items-center">
                <img
                  alt="meditationIcon"
                  style={{ height: 40, width: 42 }}
                  src={meditationIcon}
                />
              </div>
            )
          ) : (
            <div className="flex justify-center h-12 w-12 bg-white rounded-full items-center">
              <img
                alt="iconName"
                style={{ height: 40, width: 42 }}
                src={iconName}
              />
            </div>
          )}
        </Grid>
        <Grid className="flex flex-col">
          <p className="capitalize text-base font-semibold text-hb_blue-700">
            {name}
          </p>
          <p className="capitalize text-sm text-hb_blue-700 my-1">
            {description}
          </p>
          <p className="capitalize text-sm text-hb_orange-dark">
            {moment(date).format("MM/DD/YYYY")}
          </p>
        </Grid>
        <Grid className="flex ml-auto">
          {(type === JournalType.checkIn || type === JournalType.habits) && (
            <div>
              {type === JournalType.habits && (
                <div>
                  <p>{logCount + "/" + frequency}</p>
                  <p>{period_type}</p>
                </div>
              )}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onClickNote();
                }}
              >
                <img
                  alt="fileGreenIcon"
                  style={{ height: 24, width: 26 }}
                  src={fileGreenIcon}
                />
              </IconButton>
            </div>
          )}
        </Grid>
      </Grid>
      {Boolean(tags?.length) && (
        <div className="flex flex-row items-center capitalize text-xs mt-3 text-hb_blue-700">
          <img
            src={tagIcon}
            alt="tagIcon"
            style={{ height: 10, width: 10, marginRight: 10 }}
          />
          <p>{tags?.map((el) => el.name).join(", ")}</p>
        </div>
      )}
    </Grid>
  );
};
export default JournalCard;
