import React, { useEffect, useMemo, useState } from "react";
import RightOrangeIcon from "assets/Icons/RightOrange.svg";
import RightGreenIcon from "assets/Icons/RightGreen.svg";
import fabBox from "assets/Icons/favBox.png";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import computerIcon from "assets/Icons/computer.png";
import barIcon from "assets/Icons/bars.png";
import fileInvoice from "assets/Icons/fileInvoice.svg";
import {
  useDocumentsList,
  useDashboardNotificationList,
} from "queries/Documents";
import TopNav from "components/TopNav";
import CreateHabitModal from "components/CreateHabitModal";
import MeditationIcon from "assets/Icons/meditation.svg";
import format from "date-fns/format";
import CircularBar from "components/CircularBar/CircularBar";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { routes } from "Routes/RouteConstants";
import { useNavigate } from "react-router-dom";
import {
  useGetHabitProgressQuery,
  useGetHabitTodayQuery,
  usePostLogHabitsMutation,
} from "queries/Habit";
import HabitCard from "components/HabitCard";
import {
  useGetCoursesByIdQuery,
  useGetCurrentCoursesQuery,
  useGetDailyPracticeQuery,
} from "queries/Courses";
import moment from "moment";
import NoteModal from "pages/Habits/noteModal";
import { useProfileQuery } from "queries/Profile";
import { ConfirmDialog } from "components";
import { useLocalStorage } from "hooks";

const HomeScreen = () => {
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const navigate = useNavigate();

  //usestates
  const [openHabitModal, setOpenHabitModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);

  //queries
  const { data: detchedDocumentList, dataUpdatedAt } = useDocumentsList();
  const { data: fetchedHabitToday } = useGetHabitTodayQuery();
  const { data: fetchedHabitProgress } = useGetHabitProgressQuery();
  const { data: fetchedProfile } = useProfileQuery();
  const { data: dailyPracticeData } = useGetDailyPracticeQuery({});
  const { data: fetchedCurrentCourses } = useGetCurrentCoursesQuery();
  const { data: fetchCourseByIdData, refetch } = useGetCoursesByIdQuery({
    id: fetchedCurrentCourses?.data?.course?.id,
  });
  const { data: fetchedDashboardNotificationData } =
    useDashboardNotificationList();
  const { mutateAsync: postLog } = usePostLogHabitsMutation();

  useEffect(() => {
    if (fetchedCurrentCourses?.data) {
      refetch();
    }
  }, [fetchedCurrentCourses?.data]);


  const [selectedTab, setSelectedTab] = useLocalStorage(
    "profile-data",
    {}
  );

  //Use useMemo
  const { documentList } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        documentList: detchedDocumentList?.data?.filter(
          (el) => el.signature_status !== "complete"
        ),
      };
    } else {
      return { documentList: [] };
    }
  }, [dataUpdatedAt]);

  useEffect(()=>{
    if(fetchedProfile){
      setSelectedTab(fetchedProfile)
    }
  },[fetchedProfile])

  const addLog = (item) => {
    postLog({
      id: item.id,
      body: {
        date: moment().format("YYYY-MM-DD"),
      },
    });
  };

  const navigateToSettingInspired = (item) => {
    navigate(routes.SETTING_INSPIRED.pathname, {
      state: {
        lesson: item,
      },
    });
  };

  const renderTypography = (text, onClick) => {
    return (
      <div
        className="flex flex-row px-4 bg-hb_orange-light p-2 rounded-xl items-center justify-between mt-2 cursor-pointer"
        onClick={onClick}
      >
        <p className="text-lg font-normal">{text}</p>
        <img
          width={14}
          style={{ height: 20, width: 20, marginLeft: 10 }}
          src={RightOrangeIcon}
          alt="RightOrangeIcon"
        />
      </div>
    );
  };

  const renderDocument = () => {
    return (
      <Grid item xs={12} sm={6}>
        <p className=" mt-1 font-semibold text-hb_blue-700 text-base mb-1">
          Documents
        </p>
        <div
          onClick={() => navigate("/profile/documents")}
          className="flex justify-between px-4 mt-2 py-4 bg-hb_light_green-50 rounded-xl cursor-pointer shadow-lg items-center"
        >
          <div className="flex text-base font-semibold">
            <div className="mr-2 sm:mr-5 inline">
              <img
                className="inline-block"
                src={fileInvoice}
                alt="File Invoice"
              />
            </div>
            Documents Needs Attention
          </div>
          <div className="flex">
            <img
              width={14}
              style={{ height: 20, width: 20, marginLeft: 10 }}
              src={RightGreenIcon}
              alt="RightGreenIcon"
            />
          </div>
        </div>
      </Grid>
    );
  };

  const renderDashboardNotification = () => {
    return (
      <Grid item xs={12} sm={6} className="mb-2">
        <p className=" mt-1 font-semibold text-hb_blue-700 text-base mb-1">
          Notifications
        </p>
        <Card sx={isSmallScreen ? {} : { maxHeight: "55vh", overflow: "auto" }}>
          {fetchedDashboardNotificationData?.data?.map((item) => {
            if (!currentCourse && item?.data?.screen === "course") {
              setCurrentCourse(item?.data?.course_id);
            }
            if (
              (item?.data?.screen === "course" &&
                currentCourse === item?.data?.course_id) ||
              item?.data?.screen !== "course"
            ) {
              return (
                <div
                  onClick={() => {
                    item?.data?.screen === "course"
                      ? navigate(routes.NEW_COURSES.pathname, {
                          state: {
                            course: { id: item?.data?.course_id },
                          },
                        })
                      : navigate(routes.DASH_NOTIFICATION.pathname, {
                          state: {
                            type: item?.data?.screen,
                            custom_check_in_id:
                              item?.data?.screen === "GAD-7"
                                ? 1
                                : item?.data?.screen === "PHQ-9"
                                ? 2
                                : item?.data?.custom_check_in_id,
                            notificationId: item?.id,
                          },
                        });
                  }}
                  className="flex justify-between px-4 mt-2 py-4 bg-hb_light_green-50 rounded-xl cursor-pointer shadow-lg items-center"
                >
                  <div className="flex text-sm font-medium">
                    <div
                      className="mr-2 sm:mr-4 inline"
                      style={{ height: 20, weight: 20 }}
                    >
                      {item?.data?.screen === "course" ? (
                        <img
                          className="inline-block"
                          src={computerIcon}
                          width={20}
                          alt="computerIcon"
                          style={{ height: 20, weight: 20 }}
                        />
                      ) : (
                        <img
                          className="inline-block"
                          width={20}
                          src={barIcon}
                          style={{ height: 20, weight: 20 }}
                          alt="barIcon"
                        />
                      )}
                    </div>
                    {item?.data?.description}
                  </div>
                  <div className="flex">
                    <img
                      width={14}
                      style={{ height: 20, width: 20, marginLeft: 10 }}
                      src={RightGreenIcon}
                      alt="RightGreenIcon"
                    />
                  </div>
                </div>
              );
            }
          })}
        </Card>
      </Grid>
    );
  };
  const renderFabButton = () => {
    return (
      <div className="fixed bottom-28 right-4 z-50">
        <PopupState variant="popover" popupId="home-fab-button">
          {(popupState) => {
            const { isOpen } = { ...popupState };
            return (
              <div>
                <Button
                  sx={{
                    padding: 0,
                    minWidth: 0,
                    margin: 0,
                    borderRadius: 80,
                  }}
                  className={!isOpen ? "duration-500 rotate-45" : ""}
                  {...bindTrigger(popupState)}
                >
                  <img
                    src={fabBox}
                    alt="fabBox"
                    style={{
                      height: 60,
                      width: 60,
                      borderRadius: 80,
                    }}
                  />
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Button
                    className="p-3 text-xs flex justify-start w-full"
                    onClick={() => {
                      if (dailyPracticeData?.data) {
                        navigate(routes.SETTING_INSPIRED.pathname, {
                          state: {
                            lesson: {
                              ...dailyPracticeData?.data,
                              type: "daily",
                            },
                          },
                        });
                      } else {
                        setShowConfirmModal(true);
                      }
                    }}
                  >
                    Daily Prompt
                  </Button>
                  <Divider />
                  <Button
                    className="p-3 text-xs flex justify-start w-full"
                    onClick={() => {
                      navigate(routes.EDIT_JOURNAL.pathname, {
                        state: { journalData: {}, title: "FreeStyle" },
                      });
                    }}
                  >
                    Blank Journal Entry
                  </Button>
                  <Divider />
                  <Button
                    className="p-3 text-xs flex justify-start w-full"
                    onClick={() => {
                      setOpenHabitModal(true);
                    }}
                  >
                    Create Habit
                  </Button>
                  <Divider />
                  <Button
                    className="p-3 text-xs flex justify-start w-full"
                    onClick={() => {
                      navigate(routes.HABITS.pathname, {
                        state: {
                          selectedTab: "Browse Habits",
                        },
                      });
                    }}
                  >
                    Browse Habit
                  </Button>
                  <Divider />
                  <Button
                    className="p-3 text-xs flex justify-start w-full"
                    onClick={() => {
                      navigate(routes.CREATE_GOAL.pathname);
                    }}
                  >
                    Set a Goal
                  </Button>
                  <Divider />
                  <Button
                    className="p-3 text-xs flex justify-start w-full"
                    onClick={() => {
                      navigate(routes.GOALS.pathname, {
                        state: { selectedTab: "Check-In" },
                      });
                    }}
                  >
                    Check-In
                  </Button>
                </Popover>
              </div>
            );
          }}
        </PopupState>
      </div>
    );
  };

  const renderDashBoard = (
    <div>
      <div className="flex flex-col shadow-lg p-3 rounded-lg border">
        <p className=" mt-1 font-semibold text-hb_blue-700 text-base mb-1">
          What do you need today?
        </p>
        {renderTypography("Check-In With Myself", () => {
          navigate(routes.GOALS.pathname, {
            state: { selectedTab: "Check-In" },
          });
        })}
        {renderTypography("A Little Inspiration", () => {
          if (dailyPracticeData?.data) {
            navigate(routes.SETTING_INSPIRED.pathname, {
              state: {
                lesson: { ...dailyPracticeData?.data, type: "daily" },
              },
            });
          } else {
            setShowConfirmModal(true);
          }
        })}
        {renderTypography("A Blank Page for my thoughts...", () =>
          navigate(routes.EDIT_JOURNAL.pathname, {
            state: { journalData: {}, title: "FreeStyle" },
          })
        )}
        {renderTypography("Assigned Daily Prompts and Courses", () => {
          navigate(routes.PRACTICE.pathname);
        })}
      </div>
      {fetchedProfile?.data?.subscribed === 0 && (
        <div className="bg-hb_light_blue-100 rounded-lg border flex flex-row items-center mt-6 p-3 shadow-lg">
          <p className="text-base font-medium text-gray-600 w-[280px] pr-1">
            Subscribe to start your 30-day free trial.
          </p>
          <Button
            className="ml-auto h-8 bg-hb_orange-dark mr-2"
            variant="contained"
            onClick={() => navigate(routes.SUBSCRIPTION.pathname)}
          >
            <p>Start</p>
            <img
              alt="right"
              src={RightOrangeIcon}
              style={{ height: 25, width: 20, marginLeft: 5 }}
            />
          </Button>
        </div>
      )}

      {Boolean(fetchedCurrentCourses?.data) && (
        <div className="flex flex-col shadow-lg p-3 rounded-lg border mt-4">
          <p className=" mt-1 font-semibold text-hb_blue-700 text-base mb-1">
            Next Lesson
          </p>
          <div className="bg-hb_orange-light rounded-lg flex flex-row items-center">
            <img
              src={
                fetchedCurrentCourses?.data?.next_lesson?.thumbnail ??
                MeditationIcon
              }
              alt="goalCompleteIcon"
              style={{
                height: 62,
                width: 62,
                marginRight: 10,
                padding: 10,
              }}
            />
            <div>
              <p className="text-base font-medium">
                {fetchedCurrentCourses?.data?.course?.title}
              </p>
              <p className="text-sm text-hb_gray-800">
                {fetchedCurrentCourses?.data?.next_lesson?.title}
              </p>
            </div>
            <Button
              className="ml-auto h-8 bg-hb_orange-dark mr-2"
              variant="contained"
              onClick={() =>
                navigateToSettingInspired(
                  fetchedCurrentCourses?.data?.next_lesson
                )
              }
            >
              <p>Start</p>
              <img
                alt="right"
                src={RightOrangeIcon}
                style={{ height: 25, width: 20, marginLeft: 5 }}
              />
            </Button>
          </div>
        </div>
      )}
      <Card sx={isSmallScreen ? {} : { maxHeight: "30vh", overflow: "auto" }}>
        {fetchCourseByIdData?.data?.lessons?.find(
          (item) => item?.lesson_user
        ) && (
          <div className="flex flex-col shadow-lg p-3 rounded-lg border mt-4">
            <Grid md={8} xs={12} sm={8} lg={5}>
              <p className=" mt-1 font-semibold text-hb_blue-700 text-base mb-1">
                Completed Lesson
              </p>
              <div className="w-full">
                {fetchCourseByIdData?.data?.lessons?.map((item, index) => {
                  if (item?.lesson_user) {
                    return (
                      <div
                        className="flex flex-row items-center border rounded-lg mb-4 p-4 gap-5 cursor-pointer"
                        onClick={() =>
                          navigate(routes.SETTING_INSPIRED.pathname, {
                            state: {
                              lesson: item,
                            },
                          })
                        }
                      >
                        <Avatar src={item?.thumbnail} alt="goalCompleteIcon" />
                        <div className="flex flex-col">
                          <p className="capitalize text-xs font-normal text-gray-500">
                            Lesson {index + 1}
                          </p>
                          <p className="capitalize text-base font-semibold text-hb_blue-700">
                            {fetchCourseByIdData?.data?.title}
                          </p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </Grid>
          </div>
        )}
      </Card>
    </div>
  );

  const renderHabitList = (
    <div className="flex flex-col shadow-lg p-3 border rounded-lg">
      <Card sx={isSmallScreen ? {} : { maxHeight: "72vh", overflow: "auto" }}>
        <div className="flex flex-row bg-gray-100 justify-between p-3 rounded-lg items-center mb-4">
          <p>My Habits</p>
          <CircularBar
            label="Square linecaps"
            className="w-11 bg-white rounded-3xl"
          >
            <CircularProgressbar
              value={fetchedHabitProgress?.data ?? 0}
              text={`${fetchedHabitProgress?.data ?? 0}%`}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "#FF6C44",
                trailColor: "#FF6C442E",
                textColor: "#234266",
                textSize: "26px",
              })}
            />
          </CircularBar>
        </div>
        {fetchedHabitToday?.data?.map((item) => {
          return (
            <HabitCard
              key={item?.id}
              habit={item}
              variant="habit"
              onClickCheck={() => addLog(item)}
              onClickFile={() => setOpenNoteModal(item)}
              onClickCard={() =>
                navigate(routes.HABIT_DETAIL.pathname, { state: { item } })
              }
            />
          );
        })}
        <div className="flex flex-row justify-center">
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => setOpenHabitModal(true)}
            sx={{ minWidth: 0 }}
          >
            <img
              alt="add tags"
              src={CirclePlusIcon}
              style={{ height: 35, width: 35 }}
            />
            Create Habit
          </Button>
        </div>
      </Card>
    </div>
  );

  const renderDocs = (
    <div>
      <div className="flex flex-col shadow-lg p-3 rounded-lg border">
        {renderDashboardNotification()}
      </div>
      {documentList?.length > 0 && (
        <div className="flex flex-col shadow-lg p-3 rounded-lg border mt-4">
          {renderDocument()}
        </div>
      )}
    </div>
  );

  return (
    <div className="mb-24">
      {isSmallScreen && (
        <TopNav
          children={
            <div className="flex flex-col items-center -mt-10 mb-4">
              <p className="text-white text-xl font-semibold mr-3">My Day</p>
              <p className="text-white text-xs mr-3">
                {format(new Date(), "LLLL dd,yyyy")}
              </p>
            </div>
          }
        />
      )}
      <div className="flex sm:flex-row flex-col sm:grid-cols-3 gap-4 sm:m-8 m-4">
        {/* Block 1 */}
        {renderDashBoard}
        {/* Block 2 */}
        {renderHabitList}
        {/* Block 3 */}
        {renderDocs}
      </div>

      {/* Floating Btton */}
      {renderFabButton()}
      {/* Modal */}
      <CreateHabitModal
        open={openHabitModal}
        handleClose={() => setOpenHabitModal(false)}
        onClickSelect={() => {
          navigate(routes.HABITS.pathname, {
            state: {
              selectedTab: "Browse Habits",
            },
          });
        }}
      />
      <NoteModal
        open={openNoteModal}
        handleClose={() => setOpenNoteModal(null)}
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base mt-4">Habbit Better</p>
            <p className="font-normal text-base mt-10 text-center ">
              No Practice found
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        continueEdit={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default HomeScreen;
