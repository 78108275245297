import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios, getLocalStorageItem, localStorageKeys } from "utils";
import QuereyKeys from "./queryKeys";
import removeNullProperties from "utils/helpers/removeNullProperties";

// get journal data
const GetMyJournal = (queryArg) => {
  let query = "";
  queryArg?.tags?.map((el) => {
    if (query.length < 1) {
      query += "?tags[]=" + el;
    } else {
      query += "&tags[]=" + el;
    }
    return el;
  });
  queryArg?.type?.map((el) => {
    if (query.length < 1) {
      query += "?type[]=" + el;
    } else {
      query += "&type[]=" + el;
    }
    return el;
  });
  const method = "GET";
  const url = `/api/journal/my${query}`;
  return axios({
    method,
    url,
    params: removeNullProperties({
      ...queryArg,
      tags: null,
      category: null,
    }),
  });
};

export const useMyJournalQuery = (payload) => {
  return useQuery(
    [QuereyKeys.MY_JOURNAL, payload],
    () => GetMyJournal(payload),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

// get journal data by id
const GetJournalById = (queryArg) => {
  const method = "GET";
  const url = `/api/journal/${queryArg?.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetJournalByIdQuery = (payload) => {
  return useQuery(
    [QuereyKeys.JOURNAL_BY_ID, payload],
    () => GetJournalById(payload),
    {
      enabled: !Boolean(payload?.skip),
    }
  );
};

// POST/PUT Journal api
const CreateJournal = (body) => {
  const method = Boolean(body?.jId) ? "PUT" : "POST";
  const url = `/api/journal`;
  return axios({ method, url, data: removeNullProperties(body) });
};

export const useCreateJournalMutation = () => {
  return useMutation(CreateJournal);
};
