import styled from "@emotion/styled/macro";
import { TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { ErrorBoundary } from "components";
import React, { useMemo, useState } from "react";

function ToggleSliderTabs(props) {
  const {
    options,
    value: selectedItem,
    setSelectedItem,
    className,
    iconList,
  } = props;

  const isControlled = useMemo(
    () => Boolean(setSelectedItem),
    [setSelectedItem]
  );
  const [selectedTab, setSelectedTab] = useState(options[0]);

  const handleChangeTab = (event, value) => {
    if (isControlled) {
      setSelectedItem(value);
    } else {
      setSelectedTab(value);
    }
  };
  return (
    <ErrorBoundary>
      <StyledTabs
        centered
        selectionFollowsFocus
        textColor="primary"
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            height: "0px",
            display: "none",
          },
        }}
        value={isControlled ? selectedItem : selectedTab}
        onChange={handleChangeTab}
        className={className}
      >
        {options.map((solutionsItem, solutionIndex) => (
          <StyledTab
            disableFocusRipple
            disableRipple
            label={solutionsItem}
            value={solutionsItem}
            key={solutionsItem}
            icon={iconList?.length > 0 ? iconList[solutionIndex] : null}
            iconPosition="start"
          />
        ))}
      </StyledTabs>
    </ErrorBoundary>
  );
}

export default ToggleSliderTabs;

ToggleSliderTabs.defaultProps = {
  options: [],
  selectedItem: "",
  className: "",
};

const StyledTabs = styled(Tabs)`
  border-radius: 40px;
  background: #234266;
  padding: 4px;
  border: none;
  display: flex;
  transition: all 0.5s cubic-bezier(0.15, 0.88, 0.15, 0.97);
  min-height: auto;

  &.MuiTabs-indicator {
    display: none;
    border: none;
  }

  width: 100% !important;
`;

const StyledTab = styled(Tab)`
  max-height: 32px;
  min-height: 32px;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  background: #234266;
  padding: auto;
  border: none;
  display: flex;
  transition: all 0.5s cubic-bezier(0.15, 0.88, 0.15, 0.97);
  text-transform: capitalize;
  min-width: unset;
  font-weight: normal;

  &.Mui-selected {
    color: #234266;
    background-color: white;
    transition: all 0.5s cubic-bezier(0.15, 0.88, 0.15, 0.97);
    &:hover {
      // color: ;
    }
  }

  &:hover {
    // color: red;
  }
`;
