import { lazy } from "react";
import HomeIcon from "assets/Icons/Home.svg";
import FavouriteIcon from "assets/Icons/Favourite.svg";
import FavouriteUnSelIcon from "assets/Icons/FavouriteUnSelected.svg";
import JournalIcon from "assets/Icons/Journal.svg";
import JournalUnSelIcon from "assets/Icons/JournalUnSelected.svg";
import GoalsIcon from "assets/Icons/Goals.svg";
import GoalsUnSelectedIcon from "assets/Icons/GoalsUnSelected.svg";
import HabitsIcon from "assets/Icons/Habits.svg";
import HabitsUnSelectedIcon from "assets/Icons/HabitsUnSelected.svg";
import LoginPage from "pages/LoginPage/index";
import SignUpPage from "pages/SignupPage/index";
import HomeScreen from "pages/Home";
import FavouriteScreen from "pages/Favourite";
import JournalScreen from "pages/Journal";
import GoalsScreen from "pages/Goals";
import HabitsScreen from "pages/Habits";
import EditProfile from "pages/Profile/ProfileDetails/EditProfile";
import CoachScreen from "pages/Coach";
import CoachDetailScreen from "pages/Coach/CoachDetail";
import InvitationScreen from "pages/Coach/Invitations";
import SubscriptionScreen from "pages/Subscription";
import PaymentMethodScreen from "pages/PaymentMethod";
import AddCardScreen from "pages/PaymentMethod/AddCard";
import PublicUserScreen from "pages/Profile/ProfileDetails/PublicUsers";
import PublicUserDetailScreen from "pages/Profile/ProfileDetails/PublicUsers/publicUserDetail";
import NotificationScreen from "pages/Notification";
import CreateGoalScreen from "pages/Goals/components/createGoal";
import JournalDetailScreen from "pages/Journal/journalDetail";
import FreeStyleScreen from "pages/Home/freeStyle";
import NewCourseScreen from "pages/Courses/newCourses";
import CreateHabitsScreen from "pages/Habits/createHabit";
import SelectCategoryScreen from "pages/Habits/selectCategory";
import InspiredSettingScreen from "pages/Courses/inspiredSetting";
import HabitDetailScreen from "pages/Habits/habitDetail";
import LinkedHabitScreen from "pages/Goals/components/linkedHabit";
import GoalDetailScreen from "pages/Goals/components/goalDetail";
import BrowseHabitDetailScreen from "pages/Habits/browseHabitDetail";
import CoachPracticeScreen from "pages/Practice";
import DashBoardNotificationsScreen from "pages/Notification/DashBoardNotifications";

//profile
const Profile = lazy(() => import("pages/Profile/index"));
const ProfileDetails = lazy(() => import("pages/Profile/ProfileDetails"));
const ProfileAppointments = lazy(() =>
  import("pages/Profile/ProfileAppointments")
);
const ProfileSchedule = lazy(() => import("pages/Profile/ProfileSchedule"));
const ProfileInvoices = lazy(() => import("pages/Profile/ProfileInvoices"));
const ProfileDocuments = lazy(() => import("pages/Profile/ProfileDocuments"));
const ProfilePreferences = lazy(() =>
  import("pages/Profile/ProfilePreferences")
);

const RouteConstants = {
  REDIRECTOR: "/",
  HOME: "/home",
};

export const mobHeaderShowList = [
  "/main",
  "/favorite",
  "/journal",
  "/goals",
  "/habits",
  "/",
];

export const NavigationLinks = [
  {
    name: "Home",
    icon: (
      <img
        alt="home-icon"
        src={HomeIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    selectedIcon: (
      <img
        alt="home-icon"
        src={HomeIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    redirectLink: "/main",
  },
  {
    name: "Favorite",
    icon: (
      <img
        alt="fav-icon"
        src={FavouriteIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    selectedIcon: (
      <img
        alt="fav-icon"
        src={FavouriteIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    redirectLink: "/favorite",
  },
  {
    name: "Journal",
    icon: (
      <img
        alt="jour-icon"
        src={JournalIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    selectedIcon: (
      <img
        alt="jour-icon"
        src={JournalIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    redirectLink: "/journal",
  },
  {
    name: "Goals",
    icon: (
      <img
        alt="goal-icon"
        src={GoalsIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    selectedIcon: (
      <img
        alt="goal-icon"
        src={GoalsIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    redirectLink: "/goals",
  },
  {
    name: "Habits",
    icon: (
      <img
        alt="habit-icon"
        src={HabitsIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    selectedIcon: (
      <img
        alt="habit-icon"
        src={HabitsIcon}
        style={{ height: 20, width: 20, margin: 10 }}
      />
    ),
    redirectLink: "/habits",
  },
];

export const BottomNavigationLinks = [
  {
    name: "Favorite",
    icon: (
      <img
        alt="fav-icon"
        src={FavouriteUnSelIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    selectedIcon: (
      <img
        alt="fav-icon"
        src={FavouriteIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    redirectLink: "/favorite",
  },
  {
    name: "Journal",
    icon: (
      <img
        alt="jour-icon"
        src={JournalUnSelIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    selectedIcon: (
      <img
        alt="jour-icon"
        src={JournalIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    redirectLink: "/journal",
  },
  {
    name: "Home",
    icon: (
      <img
        alt="home-icon"
        src={HomeIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    selectedIcon: (
      <img
        alt="home-icon"
        src={HomeIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    redirectLink: "/main",
  },
  {
    name: "Goals",
    icon: (
      <img
        alt="goal-icon"
        src={GoalsUnSelectedIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    selectedIcon: (
      <img
        alt="goal-icon"
        src={GoalsIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    redirectLink: "/goals",
  },
  {
    name: "Habits",
    icon: (
      <img
        alt="habit-icon"
        src={HabitsUnSelectedIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    selectedIcon: (
      <img
        alt="habit-icon"
        src={HabitsIcon}
        style={{ height: 22, width: 22, margin: 3 }}
      />
    ),
    redirectLink: "/habits",
  },
];

export const routes = {
  REDIRECTOR: {
    pathname: "/",
    title: "Main",
    isPublic: false,
    element: <HomeScreen />,
  },
  LOGIN: {
    pathname: "/home",
    title: "Home",
    isPublic: true,
    element: <LoginPage />,
  },
  SIGN_UP: {
    pathname: "/signup",
    title: "SignUp",
    isPublic: true,
    element: <SignUpPage />,
  },
  HOME: {
    pathname: "/main",
    title: "Home",
    isPublic: false,
    element: <HomeScreen />,
  },
  FAVOURITE: {
    pathname: "/favorite",
    title: "Favorite",
    isPublic: false,
    element: <FavouriteScreen />,
  },
  JOUNRAL: {
    pathname: "/journal",
    title: "Journal",
    isPublic: false,
    element: <JournalScreen />,
  },
  GOALS: {
    pathname: "/goals",
    title: "Goals",
    isPublic: false,
    element: <GoalsScreen />,
  },
  HABITS: {
    pathname: "/habits",
    title: "Habits",
    isPublic: false,
    element: <HabitsScreen />,
  },
  COACH: {
    pathname: "/coach",
    title: "Coach",
    isPublic: false,
    element: <CoachScreen />,
  },
  COACH_DETAIL: {
    pathname: "/coach-detail",
    title: "Coach Detail",
    isPublic: false,
    element: <CoachDetailScreen />,
  },
  INVITATIONS: {
    pathname: "/invitations",
    title: "Invitations",
    isPublic: false,
    element: <InvitationScreen />,
  },
  SUBSCRIPTION: {
    pathname: "/subscription",
    title: "Subscription",
    isPublic: false,
    element: <SubscriptionScreen />,
  },
  PAYMENT_METHOD: {
    pathname: "/payment",
    title: "Payment Method",
    isPublic: false,
    element: <PaymentMethodScreen />,
  },
  ADD_CARD: {
    pathname: "/add-card",
    title: "Add Card",
    isPublic: false,
    element: <AddCardScreen />,
  },
  NOTIFICATION: {
    pathname: "/notification",
    title: "Notification",
    isPublic: false,
    element: <NotificationScreen />,
  },
  DASH_NOTIFICATION: {
    pathname: "/dash-notification",
    title: "Notification",
    isPublic: false,
    element: <DashBoardNotificationsScreen />,
  },
  //PRACTICE
  PRACTICE: {
    pathname: "/practice",
    title: "Practice",
    isPublic: false,
    element: <CoachPracticeScreen />,
  },
  //PROFILE
  PUBLIC_USERS: {
    pathname: "/public-users",
    title: "Users",
    isPublic: false,
    element: <PublicUserScreen />,
  },
  PUBLIC_USERS_DETAIL: {
    pathname: "/public-users-detail",
    title: "Users Detail",
    isPublic: false,
    element: <PublicUserDetailScreen />,
  },
  PROFILE_INFORMATION: {
    pathname: "/profile",
    title: "Profile",
    element: <Profile />,
    children: [
      "PROFILE_DETAILS",
      "PROFILE_APPOINTMENTS",
      "PROFILE_SCHEDULE_SESSION",
      "PROFILE_INVOICES",
      "PROFILE_DOCUMENTS",
      "PROFILE_PREFERENCES",
    ],
  },
  PROFILE_DETAILS: {
    pathname: "info",
    title: "Profile",
    index: true,
    element: <ProfileDetails />,
  },
  PROFILE_APPOINTMENTS: {
    pathname: "appointments",
    title: "Appointments",
    element: <ProfileAppointments />,
  },
  PROFILE_SCHEDULE_SESSION: {
    pathname: "schedule-session",
    title: "Schedule Session",
    element: <ProfileSchedule />,
  },
  PROFILE_INVOICES: {
    pathname: "invoices",
    title: "Invoices",
    element: <ProfileInvoices />,
  },
  PROFILE_DOCUMENTS: {
    pathname: "documents",
    title: "Documents",
    element: <ProfileDocuments />,
  },
  PROFILE_PREFERENCES: {
    pathname: "preferences",
    title: "Preferences",
    element: <ProfilePreferences />,
  },
  EDIT_PROFILE: {
    pathname: "/edit-profile",
    title: "Edit Profile",
    isPublic: false,
    element: <EditProfile />,
  },
  //GOALS
  CREATE_GOAL: {
    pathname: "/create-goal",
    title: "Create Goal",
    isPublic: false,
    element: <CreateGoalScreen />,
  },
  GOAL_DETAIL: {
    pathname: "/goal-detail",
    title: "Goal Detail",
    isPublic: false,
    element: <GoalDetailScreen />,
  },
  LINKED_HABIT: {
    pathname: "/linked-habit",
    title: "Linked Habit",
    isPublic: false,
    element: <LinkedHabitScreen />,
  },
  //JOURNAL AND COURSES
  JOURNAL_DETAIL: {
    pathname: "/journal-detail",
    title: "Journal Detail",
    isPublic: false,
    element: <JournalDetailScreen />,
  },
  EDIT_JOURNAL: {
    pathname: "/edit-journal",
    title: "Edit Journal",
    isPublic: false,
    element: <FreeStyleScreen />,
  },
  //COURSES
  NEW_COURSES: {
    pathname: "/new-courses",
    title: "Courses",
    isPublic: false,
    element: <NewCourseScreen />,
  },
  SETTING_INSPIRED: {
    pathname: "/setting-inspired",
    title: "Setting Inspired",
    isPublic: false,
    element: <InspiredSettingScreen />,
  },
  //HABITS
  CREATE_HABIT: {
    pathname: "/create-habit",
    title: "Create Habit",
    isPublic: false,
    element: <CreateHabitsScreen />,
  },
  SELECT_CATEGORY: {
    pathname: "/select-category",
    title: "Select Category",
    isPublic: false,
    element: <SelectCategoryScreen />,
  },
  HABIT_DETAIL: {
    pathname: "/habit-detail",
    title: "Habit Detail",
    isPublic: false,
    element: <HabitDetailScreen />,
  },
  BROWSE_DETAIL: {
    pathname: "/browse-detail",
    title: "Browse Detail",
    isPublic: false,
    element: <BrowseHabitDetailScreen />,
  },
};

export const routeKey = Object.assign(
  {},
  ...Object.keys(routes).map((routeKey) => ({
    [routes[routeKey].pathname]: routeKey,
  }))
);

export const pageDetails = () =>
  routes[routeKey[window.location.pathname]] || {};

export default RouteConstants;
