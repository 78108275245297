import { Button, Grid, Typography } from "@mui/material";
import { CustomTextField, PasswordField } from "components";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const ConfirmPasswordForm = (props) => {
  const { setFormStep } = props;

  //react-hook-form
  const {
    register,
    formState: { errors },
    setFocus,
  } = useFormContext();
  const { ref: refPassword, ...RegisterPassword } = register("password");
  const { ref: refConfirmPassword, ...RegisterConfirmPassword } =
    register("confirm_password");

  //useEffects
  useEffect(() => {
    setFocus("password");
  }, [setFocus]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Typography
        variant="body1"
        className="mb-8 text-2xl text-center font-medium text-gray-600"
      >
        Enter your new password.
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12} className="mb-6">
          <span className="text-sm font-semibold text-gray-600">Password</span>
          <PasswordField
            variant="outlined"
            placeholder="Password"
            type="password"
            ref={refPassword}
            {...RegisterPassword}
            error={Boolean(errors.password)}
            helperText={errors && errors.password && errors.password.message}
            autoComplete="password"
          />
        </Grid>
        <Grid item xs={12} md={12} className="mb-6">
          <span className="text-sm font-semibold text-gray-600">
            Confirm Password
          </span>
          <PasswordField
            variant="outlined"
            placeholder="Confirm Password"
            type="password"
            ref={refConfirmPassword}
            {...RegisterConfirmPassword}
            error={Boolean(errors.confirm_password)}
            helperText={
              errors &&
              errors.confirm_password &&
              errors.confirm_password.message
            }
            autoComplete="confirm_password"
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        form="login-form"
        className="w-3/5 mt-8 bg-hb_orange-dark"
        variant="contained"
        sx={{ background: "#FF6C44", color: "white" }}
      >
        Change Password
      </Button>
      <Button onClick={() => setFormStep(0)} className="underline">Go back</Button>
    </div>
  );
};
export default ConfirmPasswordForm;
