import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "utils";
import QuereyKeys from "./queryKeys";
import { getLocalStorageItem, localStorageKeys } from "hooks";
import removeNullProperties from "utils/helpers/removeNullProperties";

export const getDocumentsList = () => {
  const method = "GET";
  const url = `api/documents`;
  return axios({
    method,
    url,
  });
};

export const useDocumentsList = () => {
  return useQuery([QuereyKeys.DOCUMENTS_LIST], getDocumentsList, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {
      console.log(res);
    },
  });
};

export const getDocumentById = (queryKey) => {
  const method = "GET";
  const url = `api/documents/${queryKey?.queryKey[1]}`;
  return axios({
    method,
    url,
  });
};

export const useDocumentById = (id) => {
  return useQuery([QuereyKeys.DOCUMENT, id], getDocumentById, {
    enabled:
      Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)) && Boolean(id),
    onSuccess: (res) => {
      console.log(res);
    },
  });
};

const UpdateEsignDocument = (body) => {
  const method = "POST";
  const url = `api/documents/${body.editId}`;
  return axios({ method, url, data: body?.formData });
};

export const UpdateEsignDocumentMutation = (payload) => {
  return useMutation(UpdateEsignDocument, {
    onError: (error) => {
      console.log(error);
    },
  });
};

const CompleteEsignDocument = (body) => {
  const method = "POST";
  const url = `api/documents/${body?.id}/complete`;
  return axios({ method, url });
};

export const useCompleteEsignDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(CompleteEsignDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(QuereyKeys.DOCUMENTS_LIST);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

// Dashboard notification
export const getDashboardNotificationList = () => {
  const method = "GET";
  const url = `api/notification/dashboard`;
  return axios({
    method,
    url,
  });
};

export const useDashboardNotificationList = () => {
  return useQuery(
    [QuereyKeys.DASHBOARD_NOTIFICATION_LIST],
    getDashboardNotificationList,
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
      onSuccess: (res) => {
        console.log(res);
      },
    }
  );
};

// get GAD notification
export const getGadNotification = (data) => {
  const method = "GET";
  const url = `api/custom/check-ins`;
  return axios({
    method,
    url,
    params: data.params,
  });
};

export const useGetGadNotification = (data) => {
  return useQuery(
    [QuereyKeys.GAD_NOTIFICATION, data],
    () => getGadNotification(data),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
      onSuccess: (res) => {
        console.log(res);
      },
    }
  );
};

// POST GAD Notification
export const postGadNotification = (payload) => {
  const method = "POST";
  const url = `api/custom/check-ins`;
  return axios({
    method,
    url,
    data: removeNullProperties(payload?.body),
  });
};

export const usePostGadNotification = () => {
  return useMutation(postGadNotification);
};

// Update Notification
export const readGadNotification = (payload) => {
  const method = "PUT";
  const url = `api/notification`;
  return axios({
    method,
    url,
    data: removeNullProperties(payload?.body),
  });
};

export const useReadGadNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(readGadNotification, {
    onSuccess: (_response) => {
      queryClient.invalidateQueries(QuereyKeys.DASHBOARD_NOTIFICATION_LIST);
    },
  });
};
