import ReactPlayer from "react-player/youtube";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useFetchFeaturesQuery } from "queries/Auth";

/* Step-2 */
const LetsStart = (props) => {
  const { setFormStep } = props;
  //states
  const { search } = useLocation();
  const paramEmaill = search.split("=")[1];

  //quries
  const { data } = useFetchFeaturesQuery();
  const renderTypography = (text) => {
    return (
      <div className="flex flex-row items-center mt-1">
        <div className="h-3 w-3 bg-hb_green-400 mr-2 rounded-full"></div>
        <p className="text-lg font-normal">{text}</p>
      </div>
    );
  };

  return (
    <div>
      <Grid container className="mt-10">
        <Grid item xs={12} md={9} className="overflow-hidden rounded-2xl">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=9m0ZsMoFL4o"
            height={280}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-2xl font-medium mt-6">
            This is more than building habits...
          </p>
        </Grid>
        <Grid item xs={12} md={8} className="flex flex-row">
          <p className="text-3xl font-semibold my-6">It’s building your</p>
          <p className="text-3xl font-semibold my-6 text-hb_orange-dark ml-1">
            life
          </p>
        </Grid>
        <Grid item xs={12} md={8}>
          {data?.data?.map((item) => renderTypography(item?.title))}
        </Grid>
        <Grid item xs={12} md={8}>
          <Button
            className="w-full bg-hb_orange-dark my-12"
            variant="contained"
            onClick={() => setFormStep(3)}
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Let’s Start
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default LetsStart;
