import styled from "@emotion/styled/macro";
import React from "react";

function ToggleButton({ checked = false, onChange }) {
  return (
    <Wrapper>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider round"></span>
      </label>
    </Wrapper>
  );
}

export default ToggleButton;

const Wrapper = styled.div`
  label.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
    margin: 0;
  }

  label.switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #ff6c442e;
    &:before {
      background-color: #ff6c44;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #ff6c442e;
  }

  input:checked + .slider:before {
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
