import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  axios,
  getLocalStorageItem,
  localStorageKeys,
  setLocalStorageItem,
} from "utils";
import removeNullProperties from "utils/helpers/removeNullProperties";
import QuereyKeys from "./queryKeys";
import * as Sentry from "@sentry/react";

// get profile data
const GetProfile = () => {
  const method = "GET";
  const url = "/api/profile";
  return axios({
    method,
    url,
  });
};

export const useProfileQuery = () => {
  return useQuery([QuereyKeys.PROFILE], GetProfile, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: (response) => {
      Sentry.setUser({
        id: response?.data?.uuid,
        email: response?.data?.email,
      });
      setLocalStorageItem(localStorageKeys.USER_DETAILS, response.data);
    },
  });
};

// upload profile image api
const UploadProfileImage = (body) => {
  const method = "POST";
  const url = `/api/profile/image`;
  const formData = new FormData();
  formData.append("avatar", body?.image);
  return axios({
    method,
    url,
    data: formData,
    contentType: "multipart/form-data",
  });
};

export const UploadProfileImageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(UploadProfileImage, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.PROFILE);
    },
  });
};

// update profile info
const UpdateProfile = (body) => {
  const method = "PUT";
  const url = `/api/profile`;
  return axios({
    method,
    url,
    data: removeNullProperties(body),
  });
};

export const UpdateProfileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdateProfile, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.PROFILE);
    },
  });
};

// get public profile data
const GetPublicProfile = (data) => {
  const method = "GET";
  const url = "/api/users/public";
  return axios({
    method,
    url,
    params: { search: data.search },
  });
};

export const useGetPublicUsersQuery = (data) => {
  return useQuery(
    [QuereyKeys.PUBLIC_PROFILE, data.search],
    () => GetPublicProfile(data),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

// get invoice list
const GetInvoice = () => {
  const method = "GET";
  const url = "/api/invoices";
  return axios({
    method,
    url,
  });
};

export const useGetInvoicesQuery = () => {
  return useQuery([QuereyKeys.INVOICE_LIST], GetInvoice, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};

// get preference api
const GetPreference = () => {
  const method = "GET";
  const url = "/api/profile/preferences";
  return axios({
    method,
    url,
  });
};

export const useGetPreferencesQuery = () => {
  return useQuery([QuereyKeys.PREFERENCE], GetPreference, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};

// update preferences
const UpdatePreference = (body) => {
  const method = "PUT";
  const url = `/api/profile/preferences`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const UpdatePreferenceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdatePreference, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.PREFERENCE);
    },
  });
};

// get client token
const GetClient = () => {
  const method = "GET";
  const url = "/api/client/token";
  return axios({
    method,
    url,
  });
};

export const useGetClientsQuery = () => {
  return useQuery([QuereyKeys.CLIENT_TOKEN], GetClient, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
  });
};
