import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useGetHabitsListByIdQuery,
  usePostBrowseHabitsMutation,
} from "queries/Habit";
import HabitCard from "components/HabitCard";
import moment from "moment";
import { ConfirmDialog } from "components";
import GreenTickIcon from "assets/Icons/GreenTick.svg";
import AlertIcon from "assets/Icons/alert.svg";
import MobileHeader from "components/MobileHeader";

const SelectCategoryScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);
  //use state
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  //queries
  const { data, dataUpdatedAt } = useGetHabitsListByIdQuery({
    id: state?.data?.category_id,
  });
  const {
    mutateAsync: addBrowseHabit,
    data: addBrowseHabitData,
    error: addBrowseHabitError,
  } = usePostBrowseHabitsMutation();

  //Use useMemo
  const { browseHabitData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        browseHabitData: data?.data,
      };
    } else {
      return { browseHabitData: [] };
    }
  }, [dataUpdatedAt]);

  useEffect(() => {
    if (addBrowseHabitData) {
      setShowSuccessModal(true);
    }
  }, [addBrowseHabitData]);

  useEffect(() => {
    if (addBrowseHabitError) {
      setShowErrorModal(true);
    }
  }, [addBrowseHabitError]);

  return (
    <Fragment>
      <div className="sm:m-6 sm:px-4 px-0 sm:gap-5">
        <MobileHeader
          title={state?.title}
          variant={"secondary"}
          onClick={() => navigate(-1)}
        />

        <div className="flex flex-col sm:flex-row gap-4 px-4">
          {browseHabitData?.map((item) => (
            <HabitCard
              key={item?.id}
              habit={item}
              onClickPlus={() =>
                addBrowseHabit({
                  id: item?.id,
                  body: {
                    coach_id: item?.coach_id,
                    color: item.color,
                    start_date:
                      item?.start_date ?? moment().format("YYYY-MM-DD"),
                    why: item?.why,
                    name: item?.name,
                    frequency_payload: item?.frequency_payload,
                    period_type: item?.period_type,
                    frequency: item?.frequency,
                    streak_target: item?.streak_target,
                    tags:
                      item.tags.length > 0
                        ? item?.tags?.map((el) => el.id)
                        : null,
                    notification: item?.notification,
                    notification_timings: item?.notification_timings,
                    goal: item?.goal,
                    shared_to_coach: item?.shared_to_coach,
                    public: item?.public,
                    end_date: item?.end_date,
                    category_id: item?.category_id,
                  },
                }).catch((e) => {
                  console.log("e", e);
                })
              }
            />
          ))}
        </div>
      </div>
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="GreenTickIcon"
              style={{ height: 60, width: 60 }}
              src={GreenTickIcon}
            />
            <p className="font-semibold text-base mt-4">Success</p>
            <p className="font-normal text-base mt-10 text-center">
              Habit added succesfully!
            </p>
          </div>
        }
        open={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        continueEdit={() => setShowSuccessModal(false)}
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="AlertIcon"
              style={{ height: 60, width: 60 }}
              src={AlertIcon}
            />
            <p className="font-semibold text-base mt-4">Alert!</p>
            <p className="font-normal text-base mt-10 text-center ">
              Already taken this Habit!
            </p>
          </div>
        }
        open={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        continueEdit={() => setShowErrorModal(false)}
      />
    </Fragment>
  );
};

export default SelectCategoryScreen;
