import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { CustomTextField, ToggleButton } from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddressAutocompleteTextfield from "components/AddressAutocompleteTextfield";
import MobileHeader from "components/MobileHeader";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { UpdateProfileMutation, useProfileQuery } from "queries/Profile";
import { format } from "date-fns";

const EditProfileFormSchema = yup.object().shape({
  email: yup.string(),
  name: yup
    .string()
    .required("Name is Required")
    .test("numbers_not_allowed", "Numbers are not allowed", (value) => {
      return !/[^a-zA-Z, ]/.test(value);
    })
    .min(4, "Name must be at least 3 characters long")
    .max(30, "Name must be less than 30 characters long")
    .nullable(),
  street: yup.string("Address is required").nullable(),
  city: yup.string("City is required").nullable(),
  state: yup.string("State is required").nullable(),
  bio: yup
    .string("Bio is required")
    // .max(250, "250 characters limit exceeds")
    .nullable(),
  dob: yup.string("Date of birth is required").nullable(),
  why: yup
    .string("Please write something")
    // .max(250, "250 characters limit exceeds")
    .nullable(),
  zip_code: yup
    .string("Zip code is required")
    .max(5, "Zip code must be 5 digits")
    .nullable(),
});

const EditProfile = () => {
  const navigate = useNavigate();

  //state
  const [publicProfile, setPublicProfile] = useState(false);
  //form-control
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(EditProfileFormSchema),
    mode: "all",
  });
  const { zip_code, city, state, bio, why, name } = watch();

  //queries
  const { data: fetchedProfile, dataUpdatedAt } = useProfileQuery();
  const { mutateAsync: UpdateProfile } = UpdateProfileMutation();

  //refs
  const { ref: refRegisterName, ...RegisterSignupName } = register("name");
  const { ref: refRegisterEmail, ...RegisterSignupEmail } = register("email");
  const { ref: refCity, ...RegisterCity } = register("city");
  const { ref: refState, ...RegisterState } = register("state");
  const { ref: refZipCode, ...RegisterZipCode } = register("zip_code");
  const { ref: refBio, ...RegisterBio } = register("bio");
  const { ref: refWhy, ...RegisterWhy } = register("why");

  //handlers
  const submitEditProfileForm = (formData) => {
    UpdateProfile(
      {
        name: formData.name,
        public: publicProfile ? 1 : 0,
        street: formData.street?.length === 0 ? null : formData.street,
        address: formData.street?.length === 0 ? null : formData.street,
        city: formData.city?.length === 0 ? null : formData.city,
        state: formData.state?.length === 0 ? null : formData.state,
        zip_code: formData.zip_code?.length === 0 ? null : formData.zip_code,
        bio: formData.bio?.length === 0 ? null : formData.bio,
        why: formData.why?.length === 0 ? null : [formData.why],
        dob:
          formData.dob?.length === 0
            ? null
            : format(new Date(formData?.dob), "yyyy-MM-dd"),
      },
      {
        onSuccess: () => {
          navigate(-1);
        },
      }
    );
  };

  const handleGoogleAddress = (address) => {
    setValue("state", address.state);
    setValue("street", address.address);
    setValue("city", address.city);
    setValue("zip_code", address.postal_code);
  };
  const handleManualStreetAddress = (street) => {
    setValue("street", street);
  };
  const handleSetValue = (key, value) => {
    setValue(key, value, { shouldValidate: true });
  };

  //Use useMemo
  const { profileData } = useMemo(() => {
    if (dataUpdatedAt) {
      handleSetValue("name", fetchedProfile?.data?.name);
      handleSetValue("email", fetchedProfile?.data?.email);
      handleSetValue(
        "street",
        fetchedProfile?.data?.profile?.address ??
          fetchedProfile?.data?.profile?.street
      );
      handleSetValue("state", fetchedProfile?.data?.profile?.state);
      handleSetValue("city", fetchedProfile?.data?.profile?.city);
      handleSetValue("zip_code", fetchedProfile?.data?.profile?.zip_code);
      handleSetValue(
        "why",
        JSON.parse(fetchedProfile?.data?.profile?.why)?.join(", ")
      );
      handleSetValue("bio", fetchedProfile?.data?.profile?.bio);
      handleSetValue("dob", fetchedProfile?.data?.profile?.dob);
      setPublicProfile(Boolean(fetchedProfile?.data?.profile?.public));
      return {
        profileData: fetchedProfile?.data,
      };
    } else {
      return { profileData: {} };
    }
  }, [dataUpdatedAt]);

  return (
    <form
      id="edit-profile-form"
      noValidate
      onSubmit={handleSubmit(submitEditProfileForm)}
    >
      <div className="ml-4 mr-4 sm:ml-8 ">
        {/* Header */}
        <MobileHeader
          variant="secondary"
          onClick={() => navigate(-1)}
          title="Edit Profile"
        />
        <div>
          <div className="hidden sm:flex flex-row mt-8 mb-4 w-9/10 h-14 justify-between px-4 items-center shadow-lg overflow-x-hidden border-solid border-[1px] border-borders-light rounded-[12px]">
            <div className="flex flex-row items-center sm:w-1/3 w-full">
              <p className="text-base font-medium">Make Profile Public?</p>
              <Tooltip title="Share with Friends/Family" enterTouchDelay={0}>
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
              <div className="ml-auto">
                <ToggleButton
                  checked={publicProfile}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPublicProfile(true);
                    } else {
                      setPublicProfile(false);
                    }
                  }}
                />
              </div>
            </div>
            <Button
              variant="contained"
              form="edit-profile-form"
              type="submit"
              className="bg-hb_orange-dark h-10 w-1/5"
            >
              Save
            </Button>
          </div>
        </div>
        {!!fetchedProfile?.data?.profile?.public_request &&
          !fetchedProfile?.data?.profile?.public && (
            <p className="hidden sm:flex text-red-600">
              * Requested for Approval from Admin.
            </p>
          )}

        {/* Form Body */}
        <Grid
          xs={12}
          container
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Grid item md={3.8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">Name</p>
            <CustomTextField
              variant={"outlined"}
              required
              label=""
              InputLabelProps={{
                maxLength: 25,
              }}
              placeholder="Enter Name"
              type="text"
              ref={refRegisterName}
              value={name}
              onChange={(e) => {
                const formattedName = e.target.value.replace(
                  /[^A-Za-z\s]/g,
                  ""
                );
                return setValue("name", formattedName);
              }}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name.message}
              autoComplete="name"
            />
          </Grid>
          <Grid item md={3.8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">Email</p>
            <CustomTextField
              variant={"outlined"}
              required
              disabled
              label=""
              placeholder="Enter Email ID"
              type="email"
              ref={refRegisterEmail}
              {...RegisterSignupEmail}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              autoComplete="email"
            />
          </Grid>
          <Grid item md={3.8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">Address</p>
            <AddressAutocompleteTextfield
              variant={"outlined"}
              handleGoogleAddress={handleGoogleAddress}
              label=""
              placeholder="Enter Your Address"
              defaultValue={getValues("street")}
              error={Boolean(errors.street)}
              helperText={errors.street && errors.street.message}
              handleManualStreetAddress={handleManualStreetAddress}
            />
          </Grid>
          <Grid item md={3.8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">City</p>
            <CustomTextField
              required
              label=""
              variant={"outlined"}
              placeholder="City"
              InputLabelProps={{
                shrink: Boolean(city),
              }}
              inputRef={refCity}
              value={city}
              onChange={(e) => {
                const formattedName = e.target.value.replace(
                  /[^A-Za-z\s]/g,
                  ""
                );
                return setValue("city", formattedName);
              }}
              // {...RegisterCity}
              error={Boolean(errors.city)}
              helperText={errors.city && errors.city.message}
              autoComplete="city"
            />
          </Grid>
          <Grid item md={3.8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">State</p>
            <CustomTextField
              label=""
              variant={"outlined"}
              placeholder="State"
              InputLabelProps={{
                shrink: Boolean(state),
              }}
              inputRef={refState}
              value={state}
              onChange={(e) => {
                const formattedName = e.target.value.replace(
                  /[^A-Za-z\s]/g,
                  ""
                );
                return setValue("state", formattedName);
              }}
              // {...RegisterState}
              error={Boolean(errors.state)}
              helperText={errors.state && errors.state.message}
              autoComplete="state"
            />
          </Grid>
          <Grid item md={3.8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">Zip code</p>
            <CustomTextField
              type="text"
              variant={"outlined"}
              placeholder="Zip code"
              InputLabelProps={{
                shrink: Boolean(zip_code),
                maxLength: 5,
              }}
              inputRef={refZipCode}
              value={zip_code}
              onChange={(e) => {
                const formattedName = e.target.value.replace(/[^0-9]/g, "");
                return setValue("zip_code", formattedName);
              }}
              // {...RegisterZipCode}
              error={Boolean(errors.zip_code)}
              helperText={errors.zip_code && errors.zip_code.message}
              autoComplete="zip_code"
            />
          </Grid>
          <Grid item md={3.8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">
              Date of Birth
            </p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                placeholder="Date of birth"
                value={getValues("dob") ? getValues("dob") : null}
                onChange={(newValue) => {
                  setValue("dob", newValue, {
                    shouldValidate: true,
                  });
                }}
                error={Boolean(errors.dob)}
                helperText={errors.dob && errors.dob.message}
                sx={{
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Date of Birth"
                    sx={{
                      width: "100%",
                    }}
                    error={Boolean(errors.dob)}
                    helperText={errors.dob && errors.dob.message}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">My Bio</p>
            <CustomTextField
              type="text"
              variant={"outlined"}
              placeholder="Enter Your Bio"
              InputLabelProps={{
                shrink: Boolean(bio),
                // maxLength: 250,
              }}
              multiline
              rows={5}
              inputRef={refBio}
              {...RegisterBio}
              error={Boolean(errors.bio)}
              helperText={errors.bio && errors.bio.message}
            />
          </Grid>
          <Grid item md={8} xs={12} className="my-2">
            <p className="text-sm font-semibold text-gray-600 mb-2">
              Why CareBetter?
            </p>
            <CustomTextField
              type="text"
              variant={"outlined"}
              placeholder="write something"
              InputLabelProps={{
                shrink: Boolean(why),
                maxLength: 250,
              }}
              multiline
              rows={5}
              inputRef={refWhy}
              {...RegisterWhy}
              error={Boolean(errors.why)}
              helperText={errors.why && errors.why.message}
            />
          </Grid>

          <div className="sm:hidden flex flex-row items-center bg-slate-200 p-2 w-full">
            <p className="text-sm font-normal">Make Profile Public?</p>
            <Tooltip title="Share with Friends/Family" enterTouchDelay={0}>
              <IconButton>
                <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
              </IconButton>
            </Tooltip>
            <div className="ml-2">
              <ToggleButton
                checked={publicProfile}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPublicProfile(true);
                  } else {
                    setPublicProfile(false);
                  }
                }}
              />
            </div>
          </div>
          {!!fetchedProfile?.data?.profile?.public_request &&
            !fetchedProfile?.data?.profile?.public && (
              <p className="sm:hidden text-red-600 text-xs mt-2">
                * Requested for Approval from Admin.
              </p>
            )}
          <Button
            variant="contained"
            form="edit-profile-form"
            type="submit"
            className="sm:hidden bg-hb_orange-dark h-10 w-full my-5"
          >
            Save
          </Button>
        </Grid>
      </div>
    </form>
  );
};
export default EditProfile;
