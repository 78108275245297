import { Avatar, Grid, useMediaQuery } from "@mui/material";
import MobileHeader from "components/MobileHeader";
import { useNavigate, useLocation } from "react-router-dom";
import CircularBar from "components/CircularBar/CircularBar";
import React from "react";
import AvatarIcon from "assets/Images/DefaultUser.svg";
import CalenderIcon from "assets/Icons/calender.svg";
import EnvelopIcon from "assets/Icons/envelop.svg";
import MapPin from "assets/Icons/mapPinFilled.svg";
import MobileCalenderIcon from "assets/Icons/mobile-calendar.svg";
import MobileMailIcon from "assets/Icons/mobile-mail.svg";
import MobileMapPinIcon from "assets/Icons/mobile-map-pin.svg";
import "react-circular-progressbar/dist/styles.css";
import { format } from "date-fns";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { routes } from "Routes/RouteConstants";

const PublicUserDetailScreen = () => {
  const navigate = useNavigate();
  const { state: userData } = useLocation();
  const { item } = userData;
  const { name, email, profile_completion, profile } = item || {};
  const { avatar, dob, city, state, why, bio, points } = profile || {};
  //use state

  const isSmallScreen = useMediaQuery("(max-width:650px)");

  return (
    <div>
      <MobileHeader
        variant="primary"
        onClick={() => navigate(-1)}
        title="User Detail"
      />
      <Grid container md={8} xs={12}>
        <div className="shadow-lg rounded-xl ml-3 sm:ml-8 mr-3 p-4 sm:mt-6">
          <div className="bg-hb_blue-700 -mx-7 sm:mx-0 px-4 -mt-4 pt-3 rounded-b-[32px] pb-8 sm:bg-white ">
            <div className="flex flex-row justify-center sm:justify-between mb-5">
              <div className="flex relative">
                <Avatar
                  alt={"avatar"}
                  src={avatar ?? AvatarIcon}
                  sx={{
                    height: 100,
                    width: 100,
                    borderWidth: 1,
                    borderColor: "whitesmoke",
                    marginRight: 0,
                  }}
                />
              </div>
            </div>
            <p className="text-xl font-semibold mb-4 text-center text-white sm:text-gray-700 sm:text-left">
              {name}
            </p>
            {Boolean(isSmallScreen) ? (
              // Mobile Button
              <div className="flex flex-col items-center mb-4">
                <div className="font-light text-xs flex flex-row  items-center text-white ">
                  <img
                    alt="edit"
                    style={{ height: 12, width: 18, marginRight: 3 }}
                    src={MobileMailIcon}
                  />
                  {email}
                </div>
                <div className="flex flex-row items-center my-2">
                  <div className="font-light text-xs flex flex-row text-white ">
                    <img
                      alt="edit"
                      style={{ height: 12, width: 18, marginRight: 3 }}
                      src={MobileMapPinIcon}
                    />
                    {Boolean(city) ? (
                      <p>
                        {city}, {state}
                      </p>
                    ) : (
                      <p>---</p>
                    )}
                  </div>
                  <div className="h-1 w-1 bg-hb_blue-600 rounded-full mx-2" />
                  <div className="font-light text-xs flex flex-row text-white ">
                    <img
                      alt="edit"
                      style={{ height: 12, width: 18, marginRight: 3 }}
                      src={MobileCalenderIcon}
                    />
                    {Boolean(dob) ? (
                      <p>{format(new Date(dob), "MM/dd/yyyy")}</p>
                    ) : (
                      <p>---</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-row justify-between flex-wrap mb-4">
                <div className="font-normal flex flex-row text-sm items-center text-white sm:text-gray-700 ">
                  <img
                    alt="edit"
                    style={{ height: 20, width: 22, marginRight: 7 }}
                    src={EnvelopIcon}
                  />
                  {email}
                </div>
                <div className="font-normal flex flex-row text-sm text-white sm:text-gray-700">
                  <img
                    alt="edit"
                    style={{ height: 20, width: 22, marginRight: 7 }}
                    src={MapPin}
                  />
                  {Boolean(city) ? (
                    <p>
                      {city}, {state}
                    </p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
                <div className="font-normal flex flex-row text-sm text-white sm:text-gray-700">
                  <img
                    alt="edit"
                    style={{ height: 20, width: 22, marginRight: 7 }}
                    src={CalenderIcon}
                  />
                  {Boolean(dob) ? (
                    <p>{format(new Date(dob), "MM/dd/yyyy")}</p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="h-16 w-auto flex flex-row bg-hb_light_blue-100 shadow-lg rounded-lg items-center -mt-8 sm:mt-0">
            <CircularBar label="Square linecaps" className="w-11 mx-3">
              <CircularProgressbar
                value={profile_completion ?? 0}
                text={`${profile_completion ?? 0}%`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: "#234266",
                  trailColor: "#FFFFFF",
                  textColor: "#234266",
                  textSize: "26px",
                })}
              />
            </CircularBar>
            <div>
              <p className="text-hb_blue-700 text-sm font-semibold">
                CareBetter
              </p>
              <p className="text-hb_blue-700 text-xs font-medium">
                {points ?? 0} Points
              </p>
            </div>
          </div>
          <p className="text-base font-semibold my-3">My Bio</p>
          {Boolean(bio) && (
            <p className="bg-hb_light_blue-100 p-2 rounded-lg min-h-[100px] font-normal text-gray-700 text-sm">
              {bio}
            </p>
          )}
          <div className="flex flex-row my-3 justify-between">
            <p className="text-gray-800 font-semibold text-sm">
              Why CareBetter?
            </p>
          </div>
          <p className="bg-hb_orange-light p-2 rounded-lg min-h-[100px] font-normal text-gray-700 text-sm">
            {Boolean(why) && JSON.parse(why).join(", ")}
          </p>
        </div>
      </Grid>
    </div>
  );
};

export default PublicUserDetailScreen;
