import { format } from "date-fns";
import { useEffect, useState } from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import moment from "moment";

const CheckInsGraph = ({ clientCheckinsData, showCheckIn }) => {
  const StandardGraph = () => {
    const clientCheckins = clientCheckinsData?.data?.["check-in"];
    const barGraphList = clientCheckinsData?.data?.["habit"];
    const [data, setData] = useState([]);
    const [barGraph, setBarGraph] = useState([]);

    useEffect(() => {
      let graphData = [];

      if (clientCheckins?.confidence) {
        let unsortedArr = Object.keys(clientCheckins?.confidence);
        let sortedArr = unsortedArr.sort(function (a, b) {
          return b > a ? -1 : 1;
        });
        sortedArr?.slice(-6)?.forEach((key) => {
          graphData.push({
            xLabel: key,
            growth:
              clientCheckins?.growth[key] === 0
                ? null
                : clientCheckins?.growth[key],
            stress:
              clientCheckins?.stress[key] === 0
                ? null
                : clientCheckins?.stress[key],
            satisfaction:
              clientCheckins?.satisfaction[key] === 0
                ? null
                : clientCheckins?.satisfaction[key],
            confidence:
              clientCheckins?.confidence[key] === 0
                ? null
                : clientCheckins?.confidence[key],
          });
        });
      }
      setData([
        {
          xLabel: moment().subtract(5, "days").format("YYYY/MM/DD")?.toString(),
          growth: 0,
          stress: 0,
          satisfaction: 0,
          confidence: 0,
        },
        ...graphData,
      ]);
    }, [clientCheckins]);

    useEffect(() => {
      let barData = [];

      if (barGraphList) {
        let unsortedArr = Object.keys(barGraphList);
        let sortedArr = unsortedArr.sort(function (a, b) {
          return b > a ? -1 : 1;
        });
        sortedArr?.slice(-6)?.forEach((key, idx) => {

            barData.push({
              name: key,
              value: barGraphList[key] * 4,
            });

        });
      }
      setBarGraph([
        {
          name: moment().subtract(5, "days").format("YYYY/MM/DD")?.toString(),
          value: 0,
        },
        ...barData,
      ]);
    }, [barGraphList]);

    return (
      <div
        style={{
          width: "100%",
          height: 350,
          marginTop: "1rem",
          position: "relative",
        }}
      >
        <div className="z-20 absolute h-full w-full">
          <ResponsiveContainer>
            <AreaChart
              width={730}
              height={250}
              data={data}
              margin={{ top: 0, right: 0, left: -20, bottom: 30 }}
            >
              <defs>
                <linearGradient id="colorGrowth" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FAC1B8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FEEAE3" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorStress" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#6FCF9759" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#E8F7F2" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                  id="colorSatisfaction"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#2D9CDB59" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#56CCF21F" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                  id="colorConfidence"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#2D9CDB59" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#56CCF21F" stopOpacity={0} />
                </linearGradient>
              </defs>
              {/* <XAxis
                dataKey="xLabel"
                axisLine={false}
                tickLine={false}
                // ticks={
                //   (clientCheckins && Object.keys(clientCheckins?.personal)) || []
                // }
                tickFormatter={(tickItem) => {
                  if (tickItem && tickItem !== "auto")
                    return format(new Date(tickItem), "MM/dd");
                  else return tickItem;
                }}
              /> */}
              <YAxis
                ticks={[0, 0.25, 0.5, 0.75, 1]}
                tickFormatter={(tick) => {
                  return `${tick * 100}`;
                }}
                padding={{ right: 10 }}
                dataKey="yLabel"
                axisLine={false}
                tickLine={false}
              />
              <CartesianGrid
                strokeDasharray=""
                vertical={false}
                horizontal={false}
              />
              <Tooltip />
              {showCheckIn && (
                <Area
                  type="monotone"
                  dataKey="satisfaction"
                  stroke="#56CCF2"
                  fillOpacity={1}
                  fill="url(#colorSatisfaction)"
                  connectNulls={true}
                  name="Satisfaction"
                  dot={{
                    stroke: "#56CCF2",
                    strokeWidth: 1,
                    r: 4,
                    strokeDasharray: "",
                    fill: "#fff",
                  }}
                />
              )}
              {showCheckIn && (
                <Area
                  type="monotone"
                  dataKey="growth"
                  stroke="#739E91"
                  fillOpacity={1}
                  fill="url(#colorGrowth)"
                  connectNulls={true}
                  name="Growth"
                  dot={{
                    stroke: "#739E91",
                    strokeWidth: 1,
                    r: 4,
                    strokeDasharray: "",
                    fill: "#fff",
                  }}
                />
              )}
              {showCheckIn && (
                <Area
                  type="monotone"
                  dataKey="stress"
                  stroke="#F37777"
                  fillOpacity={1}
                  fill="url(#colorStress)"
                  name="Stress"
                  connectNulls={true}
                  dot={{
                    stroke: "#F37777",
                    strokeWidth: 1,
                    r: 4,
                    strokeDasharray: "",
                    fill: "#fff",
                  }}
                />
              )}
              {showCheckIn && (
                <Area
                  type="monotone"
                  dataKey="confidence"
                  stroke="#F18F24"
                  fillOpacity={1}
                  fill="url(#colorConfidence)"
                  name="Confidence"
                  dot={{
                    stroke: "#F18F24",
                    strokeWidth: 1,
                    r: 4,
                    strokeDasharray: "",
                    fill: "#fff",
                  }}
                  connectNulls={true}
                />
              )}
              <Legend
                verticalAlign="bottom"
                height={36}
                iconType="circle"
                align="center"
                wrapperStyle={{
                  position: "relative",
                  bottom: "30px",
                  left: "0px",
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="z-10 absolute h-full w-full">
          <ResponsiveContainer>
            <BarChart
              width={730}
              height={250}
              margin={{ top: 0, right: 10, left: -20, bottom: 32 }}
              data={barGraph}
            >
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickFormatter={(tickItem) => {
                  if (tickItem && tickItem !== "auto")
                    return format(new Date(tickItem), "MM/dd");
                  else return tickItem;
                }}
              />
              <Bar dataKey="value" fill="#23426630" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };
  return <>{StandardGraph()}</>;
};

export default CheckInsGraph;
