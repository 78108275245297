import TopNav from "components/TopNav";
import Sidebar from "components/Sidebar/Sidebar";
import { Grid, useMediaQuery } from "@mui/material";
import bg from "assets/Images/bg.png";
import { mobHeaderShowList, pageDetails } from "Routes/RouteConstants";
import { useLocation } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { useProfileQuery } from "queries/Profile";
import Bottombar from "components/Bottombar/Bottombar";

const Layout = ({ children }) => {
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const { pathname } = useLocation();
  const [showMobHeader, setShowMobHeader] = useState(false);

  //queries
  const { data: fetchedProfile } = useProfileQuery();

  const { hideSideNav = false } = useMemo(() => {
    return pageDetails();
  }, [pathname]);

  useEffect(() => {
    if (pathname) {
      const showHeader = mobHeaderShowList?.includes(pathname);
      if (showHeader) {
        setShowMobHeader(true);
      } else {
        setShowMobHeader(false);
      }
    }
  }, [pathname]);

  return isSmallScreen ? (
    <Grid container direction="column">
      {children}
      {showMobHeader && (
        <div className="fixed bottom-0">
          <Bottombar />
        </div>
      )}
    </Grid>
  ) : (
    <div className="grid g-container">
      <div className="header bg-hb_blue-700 !important">
        <TopNav />
      </div>

      {!hideSideNav && (
        <div className="sidebar bg-hb_blue-700">
          <Sidebar profileData={fetchedProfile?.data} />
        </div>
      )}
      <div className="flex bg-banner">
        <img src={bg} alt="Background" />
      </div>
      <div className="bg-white grow content overflow-x-auto">
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
