import { Avatar, Button, Grid, useMediaQuery } from "@mui/material";
import {
  useGetCoachDetailQuery,
  usePostSendInviteMutation,
} from "queries/Coach";
import MapPin from "assets/Icons/orangeMapPin.svg";
import userDuoTone from "assets/Icons/user-duotone 1.svg";
import AvatarIcon from "assets/Images/DefaultUser.svg";
import GreenTickIcon from "assets/Icons/GreenTick.svg";
import React, { useEffect, useMemo, useState } from "react";
import EnvelopIcon from "assets/Icons/envelop.svg";
import MobileHeader from "components/MobileHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { ConfirmDialog } from "components";
import { toast } from "react-toastify";

const CoachDetailScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { item } = state;
  const isSmallScreen = useMediaQuery("(max-width:650px)");

  //use state
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  //quries
  const {
    data: fetchedCoachDetailData,
    dataUpdatedAt,
    refetch,
  } = useGetCoachDetailQuery({ id: item?.id });
  const { mutateAsync: postSendInvite } = usePostSendInviteMutation();

  //use effects
  useEffect(() => {
    if (item?.id) {
      refetch();
    }
  }, [item]);

  //Use useMemo
  const { coachDetailDataData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        coachDetailDataData: fetchedCoachDetailData?.data,
      };
    } else {
      return { coachDetailDataData: {} };
    }
  }, [dataUpdatedAt, item?.id]);

  return (
    <div className="px-4 w-full sm:pl-8 sm:mt-8">
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="GreenTickIcon"
              style={{ height: 60, width: 60 }}
              src={GreenTickIcon}
            />
            <p className="font-semibold text-base mt-4">Success</p>
            <p className="font-normal text-base mt-10 text-center ">
              Coach invite request sent succesfully
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        continueEdit={() => setShowConfirmModal(false)}
      />
      <>
        {isSmallScreen ? (
          <div>
            {/* Header */}
            <MobileHeader
              variant="secondary"
              onClick={() => navigate(-1)}
              title={item?.name}
            />
            {/* Body */}
            <div className="flex flex-col items-center justify-between p-4 rounded-lg font-normal text-gray-700 text-sm">
              <Avatar
                alt={"avatar"}
                src={coachDetailDataData?.avatar ?? AvatarIcon}
                sx={{
                  height: 100,
                  width: 100,
                  borderWidth: 1,
                  borderColor: "whitesmoke",
                  marginRight: 0,
                }}
              />

              <p className="font-semibold mt-3 text-gray-700 text-sm">
                {coachDetailDataData.name}
              </p>

              <div className="font-normal items-center gap-1 mt-2 flex flex-row">
                <img
                  alt="MapPin"
                  style={{ height: 12, width: 15 }}
                  src={MapPin}
                />
                <p className="font-normal text-gray-700 text-xs">
                  {coachDetailDataData.city}
                </p>
              </div>
              <div className="font-normal gap-1 my-2 flex flex-row ">
                <img
                  alt="MapPin"
                  style={{ height: 15, width: 15 }}
                  src={EnvelopIcon}
                />
                <p className="font-normal text-gray-700 text-xs">
                  {Boolean(coachDetailDataData.created_at) &&
                    format(
                      new Date(coachDetailDataData.created_at),
                      "MM/dd/yyyy"
                    )}
                </p>
              </div>
              <>
                {Boolean(coachDetailDataData.bio) ? (
                  <>
                    <p className="text-base font-medium my-3">My Bio</p>
                    <p className="bg-hb_light_blue-100 p-2 rounded-lg min-h-[100px] font-normal text-gray-700 text-sm">
                      <div
                        className="p-2"
                        dangerouslySetInnerHTML={{
                          __html: coachDetailDataData?.bio,
                        }}
                      />
                    </p>
                  </>
                ) : null}
              </>
              <div className="w-full mt-4">
                {item?.invite_status === "invite sent" ? (
                  <Button
                    variant="outlined"
                    sx={{ borderColor: "#FF6C44 !important" }}
                    className="bg-white text-hb_orange-dark rounded-lg w-full font-medium text-base"
                  >
                    Invite Sent
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="bg-hb_orange-dark rounded-lg w-full font-medium text-base"
                    onClick={() => {
                      postSendInvite(
                        {
                          coach_id: coachDetailDataData.id,
                        },
                        { onSuccess: () => setShowConfirmModal(true) }
                      );
                    }}
                  >
                    Send Invite
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] p-3">
            <div className="flex flex-row items-center justify-between p-4 mt-4 rounded-lg h-20 font-normal text-gray-700 text-sm">
              <div className="font-normal gap-4 flex flex-row text-gray-700 text-sm">
                <img
                  alt="userDuoTone"
                  style={{ height: 20, width: 22 }}
                  src={userDuoTone}
                />
                <p className="font-medium text-gray-700 text-sm">
                  {coachDetailDataData.name}
                </p>
              </div>
              <div className="font-normal gap-4 flex flex-row text-gray-700 text-sm">
                <img
                  alt="MapPin"
                  style={{ height: 20, width: 22 }}
                  src={MapPin}
                />
                <p className="font-normal text-gray-700 text-sm">
                  {coachDetailDataData.city}
                </p>
              </div>
              <div className="font-normal gap-4 flex flex-row text-gray-700 text-sm">
                <img
                  alt="MapPin"
                  style={{ height: 20, width: 22 }}
                  src={EnvelopIcon}
                />
                <p className="font-normal text-gray-700 text-sm">
                  {Boolean(coachDetailDataData.created_at) &&
                    format(
                      new Date(coachDetailDataData.created_at),
                      "MM/dd/yyyy"
                    )}
                </p>
              </div>
              {/* <div>
                {item?.invite_status === "invite sent" ? (
                  <Button
                    variant="outlined"
                    sx={{ borderColor: "#FF6C44 !important" }}
                    className="bg-white text-hb_orange-dark rounded-lg w-52 font-medium text-base"
                  >
                    Invite Sent
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="bg-hb_orange-dark rounded-lg w-52 font-medium text-base "
                    onClick={() => {
                      postSendInvite(
                        {
                          coach_id: coachDetailDataData.id,
                        },
                        { onSuccess: () => setShowConfirmModal(true) }
                      ).catch((e) => {
                        console.log("e------->>>", e);
                        toast.error(e?.message);
                      });
                    }}
                  >
                    Send Invite
                  </Button>
                )}
              </div> */}
            </div>
            <p className="text-base font-medium my-3">My Bio</p>
            <>
              {Boolean(coachDetailDataData.bio) ? (
                <p className="bg-hb_light_blue-100 p-2 rounded-lg min-h-[100px] font-normal text-gray-700 text-sm">
                  <div
                    className="p-2"
                    dangerouslySetInnerHTML={{
                      __html: coachDetailDataData?.bio,
                    }}
                  />
                </p>
              ) : (
                <p className="bg-hb_light_blue-100 p-2 rounded-lg min-h-[100px] font-normal text-gray-700 text-sm">
                  Bio not available
                </p>
              )}
            </>
          </div>
        )}
      </>
    </div>
  );
};

export default CoachDetailScreen;
