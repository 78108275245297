import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLocalStorageItem, localStorageKeys } from "hooks";
import { axios } from "utils";
import removeNullProperties from "utils/helpers/removeNullProperties";
import QuereyKeys from "./queryKeys";

// get habit list data
const GetBrowseHabits = (queryArg) => {
  let query = "";
  queryArg?.tags?.map((el) => {
    if (query.length < 1) {
      query += "?tags[]=" + el;
    } else {
      query += "&tags[]=" + el;
    }
    return el;
  });
  queryArg?.categories?.map((el) => {
    if (query.length < 1) {
      query += "?categories[]=" + el;
    } else {
      query += "&categories[]=" + el;
    }
    return el;
  });
  const method = "GET";
  const url = `/api/habits/browse${query}`;
  return axios({
    method,
    url,
    params: queryArg,
  });
};

export const useGetBrowseHabitsQuery = (data) => {
  return useQuery(
    [QuereyKeys.HABITS_BROWSE, data],
    () => GetBrowseHabits(data),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

// get habit list data by Id
const GetHabitsListById = (queryArg) => {
  const method = "GET";
  const url = `/api/habits/browse/category/${queryArg.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetHabitsListByIdQuery = (data) => {
  return useQuery(
    [QuereyKeys.HABITS_BY_ID, data],
    () => GetHabitsListById(data),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

//mutation create/update Habit api
const CreateHabit = (body) => {
  const method = Boolean(body?.habit_id) ? "PUT" : "POST";
  const url = `/api/habits`;
  return axios({ method, url, data: removeNullProperties(body) });
};

export const useCreateHabitMutation = () => {
  return useMutation(CreateHabit);
};

// POST Browse Habits api
const PostBrowseHabits = (payload) => {
  const method = "POST";
  const url = `/api/habits/${payload.id}/browse`;
  return axios({ method, url, data: removeNullProperties(payload.body) });
};

export const usePostBrowseHabitsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(PostBrowseHabits, {
    onSuccess: (_response) => {
      queryClient.invalidateQueries(QuereyKeys.HABITS, { tags: [] });
    },
  });
};

//GET graph data
const GetHabitGraph = (queryArg) => {
  let query = "";
  queryArg.check_in_type?.map((el) => {
    if (query.length < 1) {
      query += "?check_in_type[]=" + el;
    } else {
      query += "&check_in_type[]=" + el;
    }
    return el;
  });
  queryArg.habits?.map((el) => {
    if (query.length < 1) {
      query += "?habits[]=" + el;
    } else {
      query += "&habits[]=" + el;
    }
    return el;
  });

  const method = "GET";
  const url = `api/habits/graph${query}`;
  return axios({
    method,
    url,
    params: queryArg,
  });
};

export const useGetHabitGraphQuery = (payload) => {
  return useQuery(
    [QuereyKeys.HABIT_GRAPH, payload],
    () => GetHabitGraph(payload),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
      onSuccess: () => {},
      onError: (e) => {
        console.error(e);
      },
    }
  );
};

//GET Habit data list
const GetHabit = (queryArg) => {
  let query = "";
  queryArg.tags?.map((el) => {
    if (query.length < 1) {
      query += "?tags[]=" + el;
    } else {
      query += "&tags[]=" + el;
    }
    return el;
  });
  queryArg.categories?.map((el) => {
    if (query.length < 1) {
      query += "?categories[]=" + el;
    } else {
      query += "&categories[]=" + el;
    }
    return el;
  });

  const method = "GET";
  const url = `api/habits${query}`;
  return axios({
    method,
    url,
    params: removeNullProperties({
      ...queryArg,
      tags: null,
      categories: null,
    }),
  });
};

export const useGetHabitQuery = (payload) => {
  return useQuery([QuereyKeys.HABIT_LIST, payload], () => GetHabit(payload), {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: () => {},
    onError: (e) => {},
  });
};

// POST Log Habits api
const PostLogHabits = (payload) => {
  const method = "POST";
  const url = `/api/habit/${payload.id}/log`;
  return axios({ method, url, data: removeNullProperties(payload.body) });
};

export const usePostLogHabitsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(PostLogHabits, {
    onSuccess: (_response) => {
      queryClient.invalidateQueries(QuereyKeys.HABIT_LIST, { tags: [] });
    },
  });
};

// POST AddNoteHabit api
const AddNoteHabit = (queryArg) => {
  const method = "POST";
  const url = `/api/habit/${queryArg.id}/notes`;
  return axios({ method, url, data: removeNullProperties(queryArg.body) });
};

export const useAddNoteHabitMutation = () => {
  return useMutation(AddNoteHabit);
};

// get habit  data by Id
const GetHabitsDataById = (queryArg) => {
  const method = "GET";
  const url = `/api/habit/${queryArg.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetHabitsDataByIdQuery = (data) => {
  return useQuery(
    [QuereyKeys.HABITS_DATA_BY_ID, data],
    () => GetHabitsDataById(data),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

// GET Log Habits by id api
const getLogHabits = (payload) => {
  const method = "GET";
  const url = `/api/habit/${payload.id}/log`;
  return axios({ method, url });
};

export const useGetLogHabitsQuery = (data) => {
  return useQuery(
    [QuereyKeys.HABITS_LOG_DATA_BY_ID, data],
    () => getLogHabits(data),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    }
  );
};

//GET graph data by ID
const GetHabitGraphById = (queryArg) => {
  let query = "";
  queryArg.check_in_type?.map((el) => {
    if (query.length < 1) {
      query += "?check_in_type[]=" + el;
    } else {
      query += "&check_in_type[]=" + el;
    }
    return el;
  });

  const method = "GET";
  const url = `api/habits/${queryArg.id}/graph${query}`;
  return axios({
    method,
    url,
    // params: removeNullProperties({
    //   ...queryArg,
    //   check_in_type: null,
    // }),
  });
};

export const useGetHabitGraphByIdQuery = (payload) => {
  return useQuery(
    [QuereyKeys.HABIT_GRAPH_BY_ID, payload],
    () => GetHabitGraphById(payload),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
      onSuccess: () => {},
      onError: (e) => {},
    }
  );
};

//GET Habit Today data list
const GetHabitToday = () => {
  const method = "GET";
  const url = `api/habits/today`;
  return axios({
    method,
    url,
  });
};

export const useGetHabitTodayQuery = () => {
  return useQuery([QuereyKeys.HABIT_TODAY_LIST], GetHabitToday, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: () => {},
    onError: (e) => {},
  });
};

//GET Overall Progress
const GetHabitProgress = () => {
  const method = "GET";
  const url = `api/habits/overall-progress`;
  return axios({
    method,
    url,
  });
};

export const useGetHabitProgressQuery = () => {
  return useQuery([QuereyKeys.OVER_ALL_PROGRESS], GetHabitProgress, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: () => {},
    onError: (e) => {},
  });
};

//GET Download CSV
const GetHabitDownload = (queryArg) => {
  const method = "GET";
  const url = `api/habits/export/${queryArg?.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetHabitDownloadQuery = (payload) => {
  return useQuery(
    [QuereyKeys.HABIT_DOWNLOAD, payload],
    () => GetHabitDownload(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => {},
      onError: (e) => {},
    }
  );
};

// DELETE Habit api
const DeleteHabit = (queryArg) => {
  const method = "DELETE";
  const url = `/api/habit/${queryArg.id}`;
  return axios({ method, url });
};

export const useDeleteHabitMutation = () => {
  return useMutation(DeleteHabit);
};

// remove log count Habit api
const RemoveLogCountHabit = (queryArg) => {
  const method = "POST";
  const url = `/api/habit/${queryArg.id}/log/remove`;
  return axios({ method, url, data: queryArg.body });
};

export const useRemoveLogCountHabitMutation = () => {
  return useMutation(RemoveLogCountHabit);
};
///habit/${queryArg.id}/log/remove
