import React from 'react';
import { Helmet } from 'react-helmet';
import { pageDetails } from 'Routes/RouteConstants';

const SeoHelmet = () => {
  const DefaultDescription = '';

  return (
    <div>
      <Helmet>
        <title>
          {pageDetails?.title ? `${pageDetails.title} | ` : ''}
          {DefaultDescription}
        </title>
        <meta
          name="description"
          content={pageDetails?.description || DefaultDescription}
        />
      </Helmet>
    </div>
  );
};

export default SeoHelmet;
