import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useDebounce } from "hooks/useDebounce";

const TenorGIFs = (props) => {
  const { open, handleClose, handleConfirm } = props;
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const apiKey = "6TB6L1H9WM9V";
  const [gifs, setGIFs] = useState([]);
  const [selectedGif, setSelectedGif] = useState("");
  const [query, setQuery] = useState("");

  const fetchGIFs = async (searchTerm) => {
    try {
      const url = `https://g.tenor.com/v1/search?key=${apiKey}&q=${searchTerm}&limit=10`;
      const response = await axios.get(url);
      const data = response.data.results;

      setGIFs(data);
    } catch (error) {
      console.error("Error fetching GIFs:", error);
    }
  };
  const debText = useDebounce(query, 1000);

  useEffect(() => {
    fetchGIFs(debText);
  }, [debText]);

  return (
    <div>
      {isSmallScreen ? (
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(85% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => true}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: false,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              <div className="flex flex-col justify-between pt-4 px-4">
                <h2 className="mb-4">Tenor GIFs</h2>
                <div className="flex flex-row w-full border bg-white shadow-lg rounded-3xl h-11 px-3 mr-3 items-center mb-4">
                  <TextField
                    type="text"
                    variant="standard"
                    sx={{ width: "100%", borderWidthColor: "red" }}
                    className="text-black placeholder:text-gray-700"
                    placeholder="Search"
                    value={query || ""}
                    onChange={(event) => setQuery(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon className="text-hb_blue-800 mr-3" />
                      ),
                      disableUnderline: true,
                    }}
                  />
                </div>
                <div className="justify-center flex-wrap overflow-y-scroll grid grid-cols-3">
                  {gifs.map((gif) => {
                    const selected = gif.media[0].gif.url === selectedGif;
                    return (
                      <Button
                        variant="outlined"
                        onClick={() => setSelectedGif(gif.media[0].gif.url)}
                        sx={
                          selected
                            ? { borderWidth: 2, borderColor: "#FF6C44" }
                            : { borderWidth: 2, borderColor: "transparent" }
                        }
                      >
                        <img
                          key={gif.id}
                          src={gif.media[0].gif.url}
                          alt={gif.title}
                          style={{ height: 100, width: 125 }}
                        />
                      </Button>
                    );
                  })}
                </div>
                <DialogActions className="justify-center">
                  <Button
                    onClick={() => handleConfirm(selectedGif)}
                    disabled={!Boolean(selectedGif)}
                    variant="contained"
                    color="primary"
                    sx={{
                      background: "#FF6C44 !important",
                      color: "white",
                      minWidth: "150px",
                    }}
                    className="px-6 py-2"
                  >
                    OK
                  </Button>
                  <Button
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    className="px-6 py-2"
                    sx={{
                      color: "#FF6C44",
                      minWidth: "150px",
                      borderColor: "#FF6C44",
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </div>
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="confirmation-dialog"
          maxWidth={"sm"}
          fullWidth={false}
          sx={{
            "& .MuiPaper-root": {
              minHeight: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "1rem",
            },
          }}
          className="items-between flex h-full flex-col"
        >
          <h2 className="mb-4">Tenor GIFs</h2>
          <div className="flex flex-row w-full border bg-white shadow-lg rounded-3xl h-11 px-3 mr-3 items-center mb-4">
            <TextField
              type="text"
              variant="standard"
              sx={{ width: "100%", borderWidthColor: "red" }}
              className="text-black placeholder:text-gray-700"
              placeholder="Search"
              value={query || ""}
              onChange={(event) => setQuery(event.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchIcon className="text-hb_blue-800 mr-3" />
                ),
                disableUnderline: true,
              }}
            />
          </div>
          <div className="justify-center flex flex-wrap">
            {gifs.map((gif) => {
              const selected = gif.media[0].gif.url === selectedGif;
              return (
                <Button
                  variant="outlined"
                  onClick={() => setSelectedGif(gif.media[0].gif.url)}
                  sx={
                    selected
                      ? { borderWidth: 2, borderColor: "#FF6C44" }
                      : { borderWidth: 2, borderColor: "transparent" }
                  }
                >
                  <img
                    key={gif.id}
                    src={gif.media[0].gif.url}
                    alt={gif.title}
                    style={{ height: 100, width: 125 }}
                  />
                </Button>
              );
            })}
          </div>
          <DialogActions className="justify-center">
            <Button
              onClick={() => handleConfirm(selectedGif)}
              disabled={!Boolean(selectedGif)}
              variant="contained"
              color="primary"
              sx={{
                background: "#FF6C44 !important",
                color: "white",
                minWidth: "150px",
              }}
              className="px-6 py-2"
            >
              OK
            </Button>
            <Button
              onClick={() => handleClose()}
              variant="outlined"
              color="primary"
              className="px-6 py-2"
              sx={{
                color: "#FF6C44",
                minWidth: "150px",
                borderColor: "#FF6C44",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

TenorGIFs.propTypes = {
  window: PropTypes.func,
};
const drawerBleeding = 46;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[700] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default TenorGIFs;
