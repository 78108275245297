import axios from "axios";
import {
  getLocalStorageItem,
  localStorageKeys,
  getPathnameLocalStorageItem,
} from "hooks";
import { toast } from "react-toastify";
import { routes } from "Routes/RouteConstants";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    "X-CSRFTOKEN":
      "hIESQXzSEZxPitbMXJTmcpHf6k0Q4LUXHS8Ms9pxN3MBrquuwKbIgpGqvIgihFWy",
  },
});

const handleLogoutRedirectUser = () => {
  window.location = routes.LOGIN.pathname;
  localStorage.removeItem(localStorageKeys.AUTH_TOKEN);
};

AxiosInstance.interceptors.request.use(async (config) => {
  const authToken = await getLocalStorageItem(localStorageKeys.AUTH_TOKEN);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
      timezone: timezone,
    };
  } else {
    config.headers = {
      ...config.headers,
      timezone: timezone,
    };
  }
  if (config && config.data && config.method === "get") {
    config.url = config.url + getPathnameLocalStorageItem();
    config.data = { ...config.data };
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (
      (error?.response?.data?.error === "Unauthenticated." &&
        error.response.status === 401) ||
      (error?.response?.data?.message === "Unauthorized" &&
        error.response.status === 403)
    ) {
      localStorage.clear();
      handleLogoutRedirectUser();
    } else {
      // Show toast message for other errors
      toast.error(error?.response?.data?.message);
    }
    return Promise.reject(error.response.data);
  }
);

export default AxiosInstance;
