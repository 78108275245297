import * as React from "react";
import { Button, useMediaQuery } from "@mui/material";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import { Fragment } from "react";
import WhitePlusIcon from "assets/Icons/plusWhite.svg";
import CloudIcon from "assets/Icons/cloud.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import GoalCard from "components/GoalCard";

export default function MyGoalsScreen(props) {
  const { myGoalList, loading, setPage, page, fetchedMyGoalData } = props;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  var loadMore =
    fetchedMyGoalData?.data?.current_page < fetchedMyGoalData?.data?.last_page;

  const getMorePost = async () => {
    if (loading) {
      return;
    }
    if (loadMore) {
      setPage(page + 1);
    }
  };

  //render
  return (
    <div id="scrollableDiv" style={{ height: "70vh", overflow: "auto" }}>
      <InfiniteScroll
        dataLength={page * 10} //This is important field to render the next data
        next={getMorePost}
        hasMore={loadMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
        refreshFunction={() => {
          setPage(1);
        }}
        endMessage={
          <div className="flex flex-col w-full h-full justify-center items-center mt-4 my-12">
            {Boolean(myGoalList.length === 0) && (
              <div>
                <img
                  alt="CloudIcon"
                  className="mt-[15%]"
                  style={{ height: 118, width: 152 }}
                  src={CloudIcon}
                />
                <p className="text-base font-semibold my-4 text-center">
                  No Goal found
                </p>
              </div>
            )}
            {isSmallScreen ? (
              <Button
                sx={{
                  alignSelf: "center",
                  flexDirection: "column",
                  color: "black",
                }}
                onClick={() => navigate(routes.CREATE_GOAL.pathname)}
              >
                <img
                  alt="CirclePlusIcon"
                  style={{ height: 40, width: 40 }}
                  src={CirclePlusIcon}
                />
                Add Goal
              </Button>
            ) : (
              <Button
                sx={{
                  alignSelf: "center",
                  color: "white",
                }}
                className="gap-4 w-1/3 h-12 bg-hb_orange-dark"
                onClick={() => navigate(routes.CREATE_GOAL.pathname)}
                variant="contained"
              >
                <img
                  alt="WhitePlusIcon"
                  style={{ height: 35, width: 35 }}
                  src={WhitePlusIcon}
                />
                Add Goal
              </Button>
            )}
          </div>
        }
        pullDownToRefresh
        pullDownToRefreshThreshold={80}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      >
        {myGoalList?.map((item) => (
          <div
            className="mt-4 cursor-pointer"
            onClick={() =>
              navigate(routes.GOAL_DETAIL.pathname, { state: { item } })
            }
          >
            <GoalCard {...item} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}
