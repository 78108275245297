import { Grid } from "@mui/material";
import { useGetDailyPromptsQuery, useGetMyCoursesQuery } from "queries/Courses";
import React, { Fragment, useEffect, useState } from "react";
import CloudIcon from "assets/Icons/cloud.svg";
import MediIcon from "assets/Icons/meditation.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import moment from "moment";

const DailyPromptsScreen = () => {
  const navigate = useNavigate();
  //use state
  const [nextCourse, setNextCourse] = useState([]);

  //queries
  const { data } = useGetDailyPromptsQuery();

  useEffect(() => {
    if (data) setNextCourse(data?.data);
  }, [data]);

  //render
  const renderCourseCard = (item, current = false) => {
    return (
      <div
        className={
          "flex flex-row cursor-pointer items-center justify-between p-3 rounded-lg mb-4 border shadow-lg min-w-fit"
        }
        onClick={() =>
          navigate(routes.SETTING_INSPIRED.pathname, {
            state: {
              lesson: { ...item, type: "daily" },
            },
          })
        }
      >
        <div className="flex flex-row items-center">
          <img
            src={item?.lesson?.thumbnail ?? MediIcon}
            alt="goalCompleteIcon"
            style={{
              height: 42,
              width: 62,
              marginRight: 10,
              borderRadius: 5,
            }}
          />
          <div className="flex flex-col">
            <p className="capitalize text-base font-semibold text-hb_blue-700">
              {item?.lesson?.title}
            </p>
            <p className="text-xs text-gray-500 h-8 overflow-hidden">
              {item?.lesson?.journal_prompt ?? ""}
            </p>
            <p className="text-xs text-hb_orange-dark">
              {item?.created_at &&
                moment(item?.created_at).format("MM/DD/YYYY")}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Grid container className="sm:m-6 px-4 sm:px-0 sm:gap-5">
        <Grid item md={8} xs={12} lg={8} sm={8}>
          {nextCourse?.map((item) => renderCourseCard(item, false))}
          {!Boolean(nextCourse) && (
            <div className="flex flex-col justify-center items-center mt-4 my-12">
              <div>
                <img
                  alt="CloudIcon"
                  className="mt-[15%]"
                  style={{ height: 118, width: 152 }}
                  src={CloudIcon}
                />
                <p className="text-base font-semibold my-4 text-center">
                  No Course found
                </p>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DailyPromptsScreen;
