import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
const firebaseConfig = {
  apiKey: "AIzaSyCLN9HCuNelFn-lMRpDyFFVZUAbWRoy5pA",
  authDomain: "habitbetter-app.firebaseapp.com",
  projectId: "habitbetter-app",
  storageBucket: "habitbetter-app.appspot.com",
  messagingSenderId: "661258147734",
  appId: "1:661258147734:web:228bafa9fab32819f82fb7",
  measurementId: "G-DV3WEX9SNH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const auth = getAuth(app);
export const gProvider = new GoogleAuthProvider();
export const fProvider = new FacebookAuthProvider();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPI_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("requestFcmToken", currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
