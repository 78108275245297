import { useMediaQuery } from "@mui/material";
import SwipeableEdgeDrawer from "components/Filter";
import SearchBar from "components/SearchBar/SearchBar";
import TopNav from "components/TopNav";
import MeditationIcon from "assets/Icons/meditation.svg";
import { useDebounce } from "hooks/useDebounce";
import { useAllFavoriteQuery } from "queries/Courses";
import React, { Fragment, useState } from "react";
import { routes } from "Routes/RouteConstants";
import { useNavigate } from "react-router-dom";
import CloudIcon from "assets/Icons/cloud.svg";

const FavouriteScreen = () => {
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const navigate = useNavigate();

  //use states
  const [searchFav, setSearchFav] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState({
    tags: [],
    categories: [],
    type: [],
  });

  const debText = useDebounce(searchFav, 1000);

  //queries
  const { data, dataUpdatedAt } = useAllFavoriteQuery({
    page: page,
    perpage: 20,
    q: debText?.length > 0 ? debText : null,
    tags:
      filterData?.tags?.length > 0 ? filterData?.tags.map((el) => el.id) : null,
  });

  const navigateToDetails = (item) => {
    navigate(routes.JOURNAL_DETAIL.pathname, {
      state: { journalData: item, type: "" },
    });
  };

  const navigateToSettingInspired = (item) => {
    navigate(routes.SETTING_INSPIRED.pathname, {
      state: {
        lesson: item,
        fromScreen: "FAVORITE",
      },
    });
  };

  const handleFilter = (data) => {
    setFilterData(data);
    setOpen(false);
  };

  //render
  const renderFavoriteCard = (item) => {
    let subTitle =
      item?.favouritable_type === "journals"
        ? item?.favouritable.description?.replace(/<\/?[^>]+>/gi, " ")
        : item?.favouritable?.journal_entry?.replace(/<\/?[^>]+>/gi, " ");
    let title =
      item?.favouritable_type === "journals"
        ? item?.favouritable.name
        : item?.favouritable?.lesson?.title;
    let images =
      item?.favouritable_type === "journals"
        ? item?.favouritable.journalImages
        : item?.favouritable?.images;
    return (
      <div
        className={
          "flex flex-row items-center justify-between p-3 rounded-lg my-4 border mx-4 shadow-lg cursor-pointer"
        }
        onClick={() => {
          item?.favouritable_type === "journals"
            ? navigateToDetails(item?.favouritable)
            : navigateToSettingInspired(item?.favouritable?.lesson);
        }}
      >
        <div className="flex flex-row items-center">
          <img
            src={
              images && images?.length > 0 ? images[0]?.image : MeditationIcon
            }
            alt="goalCompleteIcon"
            style={{
              height: 62,
              width: 62,
              marginRight: 10,
              borderRadius: 62,
            }}
          />
          <div className="flex flex-col">
            <p className="capitalize text-xs font-semibold text-gray-600">
              {subTitle}
            </p>
            <p className="capitalize text-sm font-semibold text-hb_blue-700">
              {title}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="sm:m-6">
      {isSmallScreen && (
        <TopNav
          children={
            <div className="flex flex-col items-center -mt-8 mb-14">
              <p className="text-white text-xl font-semibold mr-3">Favorite</p>
            </div>
          }
        />
      )}
      <SearchBar
        className="-mt-6 sm:absolute sm:mx-3 sm:w-96 sm:right-0 sm:top-32"
        value={searchFav}
        onChange={(e) => setSearchFav(e.target.value)}
        onClickFilter={() => setOpen(true)}
      />
      {Boolean(data?.data?.data?.length) ? (
        data?.data?.data?.map((item) => renderFavoriteCard(item))
      ) : (
        <div className="items-center flex flex-col">
          <img
            alt="CloudIcon"
            className="mt-[15%]"
            style={{ height: 118, width: 152 }}
            src={CloudIcon}
          />
          <p className="text-base font-semibold my-4 text-center">
            No Favorite Found!
          </p>
        </div>
      )}
      <SwipeableEdgeDrawer
        open={open}
        handleClose={() => setOpen(false)}
        filters={["tags"]}
        onClickDone={handleFilter}
      />
    </div>
  );
};

export default FavouriteScreen;
