const QuereyKeys = {
  DASHBOARD_NOTIFICATION: "DASHBOARD_NOTIFICATION",
  //tags
  TAGS_LIST: "TAGS_LIST",
  CATEGORY_LIST: "CATEGORY_LIST",
  FEATURES_LIST: "FEATURES_LIST",
  WHY_HABITBETTER: "WHY_HABITBETTER",
  PROFILE: "PROFILE",
  APPIONTMENT: "APPIONTMENT",
  SCHEDULED_APPIONTMENT: "SCHEDULED_APPIONTMENT",
  COACH: "COACH_PROFILE",
  COACH_LIST: "COACH_LIST",
  SCHEDULE_SESSION: "SCHEDULE_SESSION",
  GAD_NOTIFICATION: "GAD_NOTIFICATION",
  //profile,
  PUBLIC_PROFILE: "PUBLIC_PROFILE",
  COACH_DETAIL: "COACH_DETAIL",
  NON_ASSOCIATED_COACH: "NON_ASSOCIATED_COACH",
  INVITE_COACH: "INVITE_COACH",
  INVITE_COACH_REQUEST: "INVITE_COACH_REQUEST",
  ASSOCIATED_COACH: "ASSOCIATED_COACH",
  DELETE_ASSOCIATED_COACH : "DELETE_ASSOCIATED_COACH",
  DOCUMENTS_LIST: "DOCUMENTS_LIST",
  DASHBOARD_NOTIFICATION_LIST: "DASHBOARD_NOTIFICATION_LIST",
  DOCUMENT: "DOCUMENT",
  ALL_SAVED_CARD: "ALL_SAVED_CARD",
  PRIMARY_CARD: "PRIMARY_CARD",
  //subscription
  SUBSCRIPTION_PLANS: "SUBSCRIPTION_PLANS",
  SUBSCRIBED_PLAN: "SUBSCRIBED_PLAN",
  NOTIFICATION: "NOTIFICATION",
  INVOICE_LIST: "INVOICE_LIST",
  PREFERENCE: "PREFERENCE",
  CLIENT_TOKEN: "CLIENT_TOKEN",
  //journal
  MY_JOURNAL: "MY_JOURNAL",
  SESSIONS: "SESSIONS",
  JOURNAL_BY_ID: "JOURNAL_BY_ID",
  //goals
  MY_GOALS: "MY_GOALS",
  GOAL_BY_ID: "GOAL_BY_ID",
  //courses
  MY_COURSES: "MY_COURSES",
  COMPLETE_COURSES: "COMPLETE_COURSES",
  ALL_COURSES: "ALL_COURSES",
  COURSE_BY_ID: "COURSE_BY_ID",
  COURSES_LESSON: "COURSES_LESSON",
  CURRENT_COURSE: "CURRENT_COURSE",
  DAILY_PROMPTS: "DAILY_PROMPTS",
  DAILY_PRACTICE_ID: "DAILY_PRACTICE_ID",
  DAILY_PRACTICE: "DAILY_PRACTICE",
  // habit
  HABITS_BROWSE: "HABITS_BROWSE",
  HABITS_BY_ID: "HABITS_BY_ID",
  HABIT_GRAPH: "HABIT_GRAPH",
  HABIT_LIST: "HABIT_LIST",
  HABITS_DATA_BY_ID: "HABITS_DATA_BY_ID",
  HABITS_LOG_DATA_BY_ID: "HABITS_LOG_DATA_BY_ID",
  HABIT_TODAY_LIST: "HABIT_TODAY_LIST",
  OVER_ALL_PROGRESS: "OVER_ALL_PROGRESS",
  HABIT_DOWNLOAD: "HABIT_DOWNLOAD",
  //favorite
  FAVORITE_LIST: "FAVORITE_LIST",
};

export default QuereyKeys;
