import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useToast from "utils/responseToast";
import { routes } from "Routes/RouteConstants";
import { axios, localStorageKeys, setLocalStorageItem } from "utils";
import QuereyKeys from "./queryKeys";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";

//login api
const ValidateLoginCredentials = (data) => {
  const requestFcmToken = localStorage.getItem("requestFcmToken");
  const method = "POST";
  const url = `api/login`;
  const payload = {
    ...data,
    device_token: Boolean(requestFcmToken) ? requestFcmToken : uuidv4(),
  };
  localStorage.clear();
  return axios({ method, url, data: payload });
};

export const useLoginQuery = () => {
  return useMutation(ValidateLoginCredentials, {
    onSuccess: (response) => {
      const { token, user } = response?.data;
      setLocalStorageItem(localStorageKeys.AUTH_TOKEN, token);
      const user_uuid = user?.uuid;
      const email = user?.email;
      if (user_uuid && email) {
        Sentry.setUser({ id: user_uuid, email: email });
      }
      // setLocalStorageItem(localStorageKeys.USER_DETAILS, user);
    },
  });
};

//social login
const SocialLogin = (payload) => {
  const method = "POST";
  const url = `api/social`;
  localStorage.clear();
  return axios({ method, url, data: payload.social });
};

export const useSocialLoginQuery = () => {
  return useMutation(SocialLogin, {
    onSuccess: (response) => {
      const { token } = response?.data;
      setLocalStorageItem(localStorageKeys.AUTH_TOKEN, token);
    },
  });
};

//forgot password
const ForgotPassword = (data) => {
  const method = "POST";
  const url = `/api/forgot-password`;
  return axios({ method, url, data });
};

export const useForgotPasswordQuery = () => {
  return useMutation(ForgotPassword, {
    onSuccess: (response) => {
      localStorage.clear();
    },
    // onError: (error) =>
    //   responseToast.toastError(error, 'Error while trying to log-in'),
  });
};

//reset password
const ResetPassword = (data) => {
  const method = "POST";
  const url = `/api/reset-password`;
  return axios({ method, url, data });
};

export const useResetPasswordQuery = () => {
  return useMutation(ResetPassword, {
    onSuccess: (response) => {},
    // onError: (error) =>
    //   responseToast.toastError(error, 'Error while trying to log-in'),
  });
};
const ForgotPasswordOTP = (data) => {
  const method = "POST";
  const url = !("password" in data)
    ? `/auth/reset-password/`
    : `/auth/forgot-password/`;
  return axios({
    method,
    url,
    data,
  });
};

export const useForgotPasswordOTPQuery = () => {
  /**
   * Forgot password  & send One Time Password
   */
  return useMutation(ForgotPasswordOTP);
};

const LogoutUser = () => {
  const method = "POST";
  const url = `/api/logout`;
  return axios({
    method,
    url,
  });
};

export const useLogoutUserQuery = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(LogoutUser, {
    onSettled: () => {
      localStorage.clear();
      queryClient.clear();
      Sentry.setUser(null);
      navigate(routes.LOGIN.pathname);
    },
  });
};

const SignupUser = (body) => {
  const requestFcmToken = localStorage.getItem("requestFcmToken");
  const method = "POST";
  const url = `/api/register`;
  const payload = {
    ...body,
    device_token: Boolean(requestFcmToken) ? requestFcmToken : uuidv4(),
  };
  return axios({ method, url, data: payload });
};
const VerifyOtp = (body) => {
  const method = "POST";
  const url = `/api/email/verify`;
  return axios({ method, url, data: body });
};
const ResendOtp = (body) => {
  const method = "POST";
  const url = `/api/email/verify-otp`;
  return axios({ method, url, data: body });
};

const ForgotPasswordVerify = (body) => {
  const method = "POST";
  const url = `/api/verify-otp`;
  return axios({ method, url, data: body });
};

export const SignupUserQuery = () => {
  return useMutation(SignupUser, {
    onSuccess: (response) => {
      // const { token } = response?.data;
      // setLocalStorageItem(localStorageKeys.AUTH_TOKEN, token);
    },
    onError: ErrorHandler,
  });
};
export const VerifyOtpQuery = () => {
  return useMutation(VerifyOtp, {
    onSuccess: (response) => {
      localStorage.clear();
      // queryClient.invalidateQueries();
      localStorage.setItem("token", response.token);
    },
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error, navigateUrl) => {
  const responseToast = useToast();
  //   const navigate = useNavigate();
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};

export const VerifyForgotPasswordOtpQuery = () => {
  const responseToast = useToast();
  return useMutation(ForgotPasswordVerify, {
    onSuccess: (response) => {
      localStorage.clear();
    },
    onError: (error) => {
      responseToast.toastError(error.message);
      //   ErrorHandler(error, url);
    },
  });
};

export const ResendOtpQuery = () => {
  return useMutation(ResendOtp, {
    onSuccess: (response) => {
      const { token } = response?.data;
      setLocalStorageItem(localStorageKeys.AUTH_TOKEN, token);
      // setLocalStorageItem(localStorageKeys.USER_DETAILS, user);
    },
    onError: ErrorHandler,
  });
};

// const ErrorHandler = (error) => {};

// tags get api
const GetTags = () => {
  const method = "GET";
  const url = `/api/on-boarding/tags`;
  return axios({
    method,
    url,
  });
};

export const useGetTagsQuery = () => {
  return useQuery([QuereyKeys.TAGS_LIST], GetTags, { enabled: true });
};

//POST Custom tags api
const PostCustomTags = (body) => {
  const method = "POST";
  const url = `/api/tags/custom`;

  return axios({
    method,
    url,
    data: body,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const usePostCustomTagsQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(PostCustomTags, {
    onSuccess: (_response) => {
      queryClient.invalidateQueries(QuereyKeys.TAGS_LIST);
    },
    onError: ErrorHandler,
  });
};

// upload image api

const UploadJournalImage = (body) => {
  const method = "POST";
  const url = `/api/journal/image`;
  const formData = new FormData();
  formData.append("image", body?.image);
  return axios({
    method,
    url,
    data: formData,
    contentType: "multipart/form-data",
  });
};

export const UploadJournalImageQuery = () => {
  return useMutation(UploadJournalImage, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

// fetch categories api
const FetchCategories = () => {
  const method = "GET";
  const url = `/api/categories`;
  return axios({ method, url });
};

export const FetchCategoriesQuery = () => {
  return useQuery([QuereyKeys.CATEGORY_LIST], FetchCategories, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

// fetch features api
const FetchFeatures = () => {
  const method = "GET";
  const url = `/api/on-boarding/features`;
  return axios({ method, url });
};

export const useFetchFeaturesQuery = () => {
  return useQuery([QuereyKeys.FEATURES_LIST], FetchFeatures, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

// fetch why habitbetter api
const FetchWhyHabitbetter = () => {
  const method = "GET";
  const url = `/api/on-boarding/why`;
  return axios({ method, url });
};

export const useFetchWhyHabitbetterQuery = () => {
  return useQuery([QuereyKeys.WHY_HABITBETTER], FetchWhyHabitbetter, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

// PUT why habitbetter api
const AnswerWhyHabitbetter = (body) => {
  const method = "PUT";
  const url = `/api/on-boarding/why`;
  return axios({ method, url, data: body });
};

export const useAnswerWhyHabitbetterMutation = () => {
  return useMutation(AnswerWhyHabitbetter, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

// POST CheckIn habitbetter api
const CheckInPost = (body) => {
  const method = "POST";
  const url = `/api/on-boarding/check-in`;
  return axios({ method, url, data: body });
};

export const useCheckInPostMutation = () => {
  return useMutation(CheckInPost, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

// POST Journaling habitbetter api
const JournalingPost = (body) => {
  const method = "POST";
  const url = `/api/on-boarding/journal`;
  return axios({ method, url, data: body });
};

export const useJournalingPostMutation = () => {
  return useMutation(JournalingPost, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

// POST Habit habitbetter api
const HabitPost = (body) => {
  const method = "POST";
  const url = `/api/on-boarding/habit`;
  return axios({ method, url, data: body });
};

export const useHabitPostMutation = () => {
  return useMutation(HabitPost, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
