import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import WhitePlusIcon from "assets/Icons/plusWhite.svg";
import Calender from "assets/Icons/CalenderOrange.svg";
import GoalInProgress from "assets/Icons/goalInProgress.svg";
import HashIcon from "assets/Icons/hash.svg";
import BellIcon from "assets/Icons/bell-03.svg";
import MobileHeader from "components/MobileHeader";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { ConfirmDialog, ToggleButton } from "components";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import GreenTickIcon from "assets/Icons/GreenTick.svg";
import AlertIcon from "assets/Icons/alert.svg";
import { usePostBrowseHabitsMutation } from "queries/Habit";
import "./styles.scss";

const BrowseHabitDetailScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { item: habitData } = state;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const {
    mutateAsync: postHabit,
    data: addBrowseHabitData,
    error: addBrowseHabitError,
  } = usePostBrowseHabitsMutation();

  useEffect(() => {
    if (addBrowseHabitData) {
      setShowSuccessModal(true);
    }
  }, [addBrowseHabitData]);

  useEffect(() => {
    if (addBrowseHabitError) {
      setShowErrorModal(true);
    }
  }, [addBrowseHabitError]);

  const setPostHabit = () => {
    postHabit({
      id: habitData?.id,
      body: {
        coach_id: habitData?.coach_id,
        color: habitData.color,
        start_date: habitData?.start_date ?? moment().format("YYYY-MM-DD"),
        why: habitData?.why,
        name: habitData?.name,
        frequency_payload: habitData?.frequency_payload,
        period_type: habitData?.period_type,
        frequency: habitData?.frequency,
        streak_target: habitData?.streak_target,
        tags:
          habitData?.tags?.length > 0
            ? habitData?.tags?.map((el) => el.id)
            : null,
        notification: habitData?.notification,
        notification_timings: habitData?.notification_timings,
        goal: habitData?.goal,
        shared_to_coach: habitData?.shared_to_coach,
        public: 0,
        end_date: habitData?.end_date,
        category_id: habitData?.category_id,
      },
    }).catch((e) => console.log(e));
  };

  function submitButon() {
    return (
      <Button
        sx={{
          color: "white",
        }}
        className="gap-4 sm:w-[25%] w-full h-12 bg-hb_orange-dark sm:mr-12"
        variant="contained"
        onClick={setPostHabit}
      >
        <img
          alt="WhitePlusIcon"
          style={{ height: 35, width: 35 }}
          src={WhitePlusIcon}
        />
        Create Habit
      </Button>
    );
  }

  return (
    <div>
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title={habitData?.name}
      />
      {/* Body */}
      <div className="sm:mx-6 mx-4">
        <div className="hidden sm:flex w-full justify-end mt-6">
          {submitButon()}
        </div>
        <div className="flex flex-col sm:flex-row sm:grid sm:grid-cols-2 w-full sm:mt-8 bg-gray-100 rounded-lg gap-4 p-4">
          <div className="bg-white h-28 p-3 rounded-lg font-semibold  border ">
            <div className="flex flex-row items-start gap-4">
              <img alt="" style={{ width: 25 }} src={Calender} />
              <p>Daily Habit:</p>
            </div>
            <p className="text-gray-500 m-1">
              {habitData?.period_type === "weekly"
                ? habitData?.frequency_payload?.join(", ")
                : ""}
            </p>
          </div>
          <div className="bg-white h-28 p-3 rounded-lg font-semibold  border ">
            <div className="flex flex-row items-start gap-4">
              <img alt="" style={{ width: 25 }} src={HashIcon} />
              <p>Frequency</p>
            </div>
            <p className="text-gray-500 m-1">
              {habitData?.frequency.toString()}
            </p>
          </div>
          <div className="bg-white h-28 p-3 rounded-lg font-semibold  border ">
            <div className="flex flex-row items-start gap-4">
              <img alt="" style={{ width: 25 }} src={BellIcon} />
              <p>Notifications:</p>
            </div>
            <p className="text-gray-500 m-1">
              {habitData?.notification
                ? habitData?.notification_timings
                    ?.map((el) =>
                      moment(el.time, "HH:mm:ss").format("HH:mm:ss A")
                    )
                    .join(", ")
                : "---"}
            </p>
          </div>
          <div className="bg-white h-28 p-3 rounded-lg font-semibold  border ">
            <div className="flex flex-row items-start gap-4">
              <img alt="" style={{ width: 25 }} src={GoalInProgress} />
              <p>Streak Target</p>
            </div>
            <p className="text-gray-500 m-1">
              {habitData?.streak_target.toString()}
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:grid sm:grid-cols-2 mt-4 gap-4">
          <div className="bg-hb_orange-light p-4 rounded-lg min-h-[100px] flex-col flex shadow-lg">
            <p className=" text-gray-700 text-base font-semibold">
              Why am I Tracking This?
            </p>
            <p className="text-sm font-normal my-2 text-gray-500 m-1">
              {habitData?.why}
            </p>
          </div>
          {Boolean(habitData?.tags?.length) && (
            <Grid item xs={12} md={12} className="mb-3">
              <p className="font-semibold py-2">Tags</p>
              <div className="flex flex-wrap gap-3 flex-row ">
                {habitData?.tags?.map((it, idx) => (
                  <div className="items-center">
                    <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                      {it.name}
                    </p>
                  </div>
                ))}
              </div>
            </Grid>
          )}
        </div>
        <div className="bg-[#F7F7F7] rounded-xl p-4 mt-8 sm:w-1/2 w-full">
          <div className="flex items-center">
            <p className="text-base text-gray-700">
              Share with Coach/Therapist?
            </p>
            <Tooltip
              title="Share this habit with your coach"
              enterTouchDelay={0}
            >
              <IconButton>
                <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
              </IconButton>
            </Tooltip>
            <div className="ml-auto">
              <ToggleButton checked={habitData?.shared_to_coach === 1} />
            </div>
          </div>
        </div>
        <div className="bg-[#F7F7F7] rounded-xl p-4 mt-8 sm:w-1/2 w-full">
          <div className="flex items-center">
            <p className="text-base text-gray-700">Make Habit Public?</p>
            <Tooltip title="Make this habit public?" enterTouchDelay={0}>
              <IconButton>
                <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
              </IconButton>
            </Tooltip>
            <div className="ml-auto">
              <ToggleButton checked={habitData?.public === 1} />
            </div>
          </div>
        </div>
        <div className="flex sm:hidden my-6">{submitButon()}</div>
      </div>
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="GreenTickIcon"
              style={{ height: 60, width: 60 }}
              src={GreenTickIcon}
            />
            <p className="font-semibold text-base mt-4">Success</p>
            <p className="font-normal text-base mt-10 text-center">
              Habit added succesfully!
            </p>
          </div>
        }
        open={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        continueEdit={() => setShowSuccessModal(false)}
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="AlertIcon"
              style={{ height: 60, width: 60 }}
              src={AlertIcon}
            />
            <p className="font-semibold text-base mt-4">Alert!</p>
            <p className="font-normal text-base mt-10 text-center ">
              Already taken this Habit!
            </p>
          </div>
        }
        open={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        continueEdit={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default BrowseHabitDetailScreen;
