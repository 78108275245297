import "./styles.scss";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useRef, useState } from "react";
import { Button, IconButton } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import OrderedList from "@tiptap/extension-ordered-list";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GifBoxIcon from "@mui/icons-material/GifBox";
import Image from "@tiptap/extension-image";
import TenorGIFs from "components/TenorGifs";
import BulletList from "@tiptap/extension-bullet-list";

const MenuBar = ({ editor, setTenorModal, handleTextEditor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="border bg-hb_gray-200 rounded-t-lg flex justify-center h-10">
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          editor.chain().focus().toggleBold().run();
        }}
        className={editor.isActive("bold") ? "is-active text-blue-400" : ""}
      >
        <FormatBoldIcon />
      </IconButton>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          editor.chain().focus().toggleItalic().run();
        }}
        className={editor.isActive("italic") ? "is-active text-blue-400" : ""}
      >
        <FormatItalicIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          setTenorModal(true);
        }}
      >
        <GifBoxIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={
          editor.isActive("bulletList") ? "is-active text-blue-400" : ""
        }
      >
        <FormatListBulletedIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={
          editor.isActive("orderedList") ? "is-active text-blue-400" : ""
        }
      >
        <FormatListNumberedIcon />
      </IconButton>
      <Button
        variant="contained"
        className="bg-hb_blue-700"
        onClick={() => handleTextEditor(editor.getHTML())}
      >
        Done
      </Button>
    </div>
  );
};

export default function TipTap(props) {
  const { handleTextEditor } = props;
  const [tenorModal, setTenorModal] = useState(false);

  //handler
  const handleClose = () => {
    setTenorModal(false);
  };
  const handleConfirm = (data) => {
    editor.chain().focus().setImage({ src: data }).run();
    setTenorModal(false);
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      BulletList.configure({
        HTMLAttributes: {
          class: "list-disc pl-4",
        },
      }),
      OrderedList.configure({
        HTMLAttributes: {
          class: "list-decimal pl-4",
        },
      }),
      Image,
    ],
    editorProps: {
      attributes: {
        class: "Editor border",
      },
    },
    autofocus: "end",
    onUpdate({ editor }) {
      handleTextEditor(editor.getHTML());
    },
    content: props?.value,
  });

  return (
    <div>
      <MenuBar
        editor={editor}
        setTenorModal={setTenorModal}
        handleTextEditor={handleTextEditor}
      />
      <EditorContent editor={editor} />
      {tenorModal && (
        <TenorGIFs
          open={tenorModal}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
}
