import { IconButton, List, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { BottomNavigationLinks } from "Routes/RouteConstants";
import { useNavigate } from "react-router";
import "react-circular-progressbar/dist/styles.css";
import { useLocation } from "react-router";
import HabitBetterLogoMini from "assets/Images/CareBetterLogoWhite.png";

export default function Bottombar(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (NavigationItem) => {
    navigate(NavigationItem.redirectLink);
  };

  const displayDrawerLinks = (NavigationItem, NavigationIndex) => {
    if (NavigationIndex === 2) {
      return (
        <div key={NavigationIndex?.toString() + "1NavigationIndex1"}>
          <div className="no-scrollbar relative bottom-9 right-2 bg-white flex items-center justify-center rounded-full h-18 w-18 p-2">
            <IconButton
              sx={{ backgroundColor: "#FF6C44 !important", minWidth: 0 }}
              className="flex items-center justify-center rounded-full w-14 h-14"
              onClick={
                "redirectLink" in NavigationItem
                  ? () => handleNavigate(NavigationItem)
                  : null
              }
            >
              <img
                src={HabitBetterLogoMini}
                alt="group-logo"
                style={{
                  height: 25,
                  width: 25,
                  marginLeft: 5,
                }}
              />
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <div key={NavigationIndex?.toString() + "1NavigationIndex1"}>
        <div className=" no-scrollbar ">
          <List
            component="nav"
            sx={{
              boxSizing: "border-box",
            }}
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              sx={{
                borderRadius: 2,
                flexDirection: "column",
              }}
              onClick={
                "redirectLink" in NavigationItem
                  ? () => handleNavigate(NavigationItem)
                  : null
              }
            >
              {location.pathname.includes(NavigationItem.redirectLink)
                ? NavigationItem.selectedIcon
                : NavigationItem.icon}
              <ListItemText
                primary={NavigationItem.name}
                primaryTypographyProps={{
                  style: {
                    color: location.pathname.includes(
                      NavigationItem.redirectLink
                    )
                      ? "white"
                      : "#FFFFFF45",
                    fontWeight: "400",
                  },
                }}
              />
            </ListItemButton>
          </List>
        </div>
      </div>
    );
  };
  return (
    <>
      <List className="flex flex-row w-screen justify-between rounded-t-3xl p-0 bg-hb_blue-700">
        {BottomNavigationLinks?.map(displayDrawerLinks)}
      </List>
    </>
  );
}
