import ReactPlayer from "react-player/youtube";
import {
  Grid,
  Button,
  useMediaQuery,
  Tooltip,
  Typography,
  Checkbox,
  IconButton,
  Autocomplete,
  TextField,
  Modal,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import FacebookIcon1 from "assets/Icons/facebook 1.png";
import InstagramIcon1 from "assets/Icons/instagram 1.png";
import { routes } from "Routes/RouteConstants";

/* Step-9 */
const KeepTryingNewThings = (props) => {
  //states
  const isSmallScreen = useMediaQuery("(max-width:950px)");
  const { search } = useLocation();
  const paramEmaill = search.split("=")[1];
  const navigate = useNavigate();

  const renderTypography = (text) => {
    return (
      <div className="flex flex-row items-center mt-1">
        <div className="h-3 w-3 bg-hb_green-400 mr-2 rounded-full"></div>
        <p className="text-lg font-normal">{text}</p>
      </div>
    );
  };

  return (
    <div>
      <Grid container className="mt-10">
        <Grid item xs={12} md={9} className="overflow-hidden rounded-2xl">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=9m0ZsMoFL4o"
            height={280}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-2xl font-semibold mt-6">
            Keep Trying New Things, Keep Learning, Find New Joy
          </p>
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-base font-normal mt-6 bg-hb_secondary-100 p-3 rounded-xl">
            Check out new course when you get a notification on the home screen.
            <br />
            Add inspiring lessons and prompts to your Favourites.
          </p>
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-2xl font font-semibold mt-6 mb-2">
            Join the Community!
          </p>
        </Grid>
        <Grid item xs={12} md={8}>
          {renderTypography("Workshop & Challenges")}
          {renderTypography("Daily Podcast for extra inspiration ")}
          {renderTypography("Find like mind for support ")}
          {renderTypography("Get tips and tricks from Founders")}
          {renderTypography("Just hang out and tell funny stories")}
        </Grid>
        <Grid item xs={12} md={8} className="flex flex-row justify-center mt-4">
          <a
            href="https://www.facebook.com/Habitbetter/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={FacebookIcon1}
              alt="fb-icon"
              style={{ height: 38, width: 38, marginRight: 10 }}
            />
          </a>
          <a
            href="https://instagram.com/habitbetter?utm_medium=copy_link"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={InstagramIcon1}
              alt="insta-icon"
              style={{ height: 38, width: 38 }}
            />
          </a>
        </Grid>
        <Grid item xs={12} md={8}>
          <Button
            className="w-full bg-hb_orange-dark my-4"
            variant="contained"
            onClick={() => navigate("/profile/info")}
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Go To CareBetter!
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default KeepTryingNewThings;
