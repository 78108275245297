import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { NavigationLinks } from "Routes/RouteConstants";
import { useNavigate } from "react-router";
import "react-circular-progressbar/dist/styles.css";
import AvatarIcon from "assets/Images/DefaultUser.svg";
import LogoutButton from "assets/Icons/LogoutButton.svg";
import { useLocation } from "react-router";
import CircularBar from "components/CircularBar/CircularBar";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useLogoutUserQuery } from "queries/Auth";

export default function Sidebar(props) {
  const { profileData } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { mutateAsync: logoutUser } = useLogoutUserQuery();

  const handleNavigate = (NavigationItem) => {
    navigate(NavigationItem.redirectLink);
  };

  const displayDrawerLinks = (NavigationItem, NavigationIndex) => {
    return (
      <div key={NavigationIndex?.toString() + "1NavigationIndex1"}>
        <div className=" no-scrollbar ">
          <List
            component="nav"
            sx={{ padding: "8px 16px", boxSizing: "border-box" }}
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              // selected={isItemSelected}
              sx={{
                backgroundColor: location.pathname.includes(
                  NavigationItem.redirectLink
                )
                  ? "#597599"
                  : "",
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: "#597599!important",
                },
              }}
              onClick={
                "redirectLink" in NavigationItem
                  ? () => handleNavigate(NavigationItem)
                  : null
              }
            >
              {NavigationItem.icon}
              <ListItemText
                primary={NavigationItem.name}
                primaryTypographyProps={{
                  style: { color: "white", fontWeight: "600" },
                }}
              />
            </ListItemButton>
          </List>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex flex-col h-5/6 justify-between">
        {/* Drawer */}
        <div>
          <List>{NavigationLinks?.map(displayDrawerLinks)}</List>
        </div>
        {/* Bottom options */}
        <div>
          <div className="h-14 w-auto flex p-1 flex-row bg-hb_light_blue-100 mx-3 rounded-lg items-center">
            <CircularBar label="Square linecaps" className="w-11 mx-3">
              <CircularProgressbar
                value={profileData?.profile_completion ?? 0}
                text={`${profileData?.profile_completion ?? 0}%`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: "#234266",
                  trailColor: "#FFFFFF",
                  textColor: "#234266",
                  textSize: "26px",
                })}
              />
            </CircularBar>
            <div>
              <p className="text-hb_blue-700 text-sm font-medium">CareBetter</p>
              <p className="text-hb_blue-700 text-xs font-medium">
                {profileData?.profile?.points ?? 0} Points
              </p>
            </div>
          </div>

          {/* Logout */}
          <div className="h-14 mt-9 w-auto flex p-1 flex-row ml-3 items-center">
            <Button onClick={() => navigate("/profile/info")}>
              <Avatar
                alt={"avatar"}
                src={profileData?.profile?.avatar ?? AvatarIcon}
                sx={{
                  height: 40,
                  width: 40,
                  borderWidth: 1,
                  borderColor: "whitesmoke",
                  marginRight: 1,
                }}
              />
              <div>
                <p className="text-white text-left text-sm font-medium">
                  {profileData?.name ?? ""}
                </p>
                <p className="text-white text-xs text-left font-regular w-36 truncate">
                  {profileData?.email ?? ""}
                </p>
              </div>
            </Button>
            <Button
              sx={{
                minWidth: 0,
                padding: 0,
                "&:hover": {
                  backgroundColor: "#597599!important",
                },
              }}
              onClick={() => logoutUser()}
            >
              <img
                alt="logout"
                style={{ height: 50, width: 50 }}
                src={LogoutButton}
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
