import { DialogTitle, IconButton, Typography } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import React from "react";
import { styled } from "@mui/material/styles";
import Styles from "./CommonDialog.module.scss";
import CloseIcon from "@mui/icons-material/Close";

const StyledCommonDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  margin: "0rem 0rem 1.5rem 0rem",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1, 0),
  borderBottom: "1px solid #434349",
}));

const CommonDialogTitle = (props) => {
  const { onClose, title } = props;

  return (
    <StyledCommonDialogTitle>
      <span className={Styles.dialogTitleText}>{title}</span>
      {onClose ? (
        <span className="flex items-center justify-center mr-4 cursor-pointer">
          <IconButton onClick={onClose}>
            <CloseIcon fontSize={"large"} style={{ color: "#434349" }} />
          </IconButton>
        </span>
      ) : undefined}
    </StyledCommonDialogTitle>
  );
};

export default CommonDialogTitle;

CommonDialogTitle.defaultProps = {
  onClose: () => null,
};
