import { Button, Checkbox, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useMemo, useState } from "react";
import MobileHeader from "components/MobileHeader";
import VisaImg from "assets/Images/visa.png";
import MasterCardImg from "assets/Images/mastercard.png";
import EmptyCardsPlaceholder from "assets/Images/EmptyCards.svg";
import { useNavigate, useLocation } from "react-router-dom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  DeletePaymentMethodAPI,
  GetAllSavedCardsAPI,
  SelectPrimaryCardAPI,
  useGetPrimaryCardAPI,
  usePayInvoiceMutation,
} from "queries/Payment";
import { routes } from "Routes/RouteConstants";
import {
  useCreateSubscriptionMutation,
  useGetSubscribedPlanAPI,
  useUpdateSubscriptionMutation,
} from "queries/Subscription";
import { ConfirmDialog } from "components";
import Success from "components/Success";
import Lottie from "react-lottie";

const PaymentMethodScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { planData: productData } = state || {};
  const cardIssuerImage = {
    visa: VisaImg,
    mastercard: MasterCardImg,
  };
  const LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: Success,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice",
    },
  };

  //use state
  const [primary, setPrimary] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  //quries
  const { data: listPaymentMethods, dataUpdatedAt } = GetAllSavedCardsAPI();
  const {
    data: primaryCardFetched,
    dataUpdatedAt: primaryDataUpdatedAt,
    refetch,
  } = useGetPrimaryCardAPI();
  const {
    data: subscribedPlanFetched,
    dataUpdatedAt: subscribedPlanDataUpdatedAt,
  } = useGetSubscribedPlanAPI();
  const { mutate: selectPrimaryCard } = SelectPrimaryCardAPI();
  const { mutate: deleteSavedCard } = DeletePaymentMethodAPI();
  const { mutate: postSubscription } = useCreateSubscriptionMutation();
  const { mutate: updateSubscription } = useUpdateSubscriptionMutation();
  const { mutate: payInvoice } = usePayInvoiceMutation();

  //Use useMemo
  const { subscribedPlanData } = useMemo(() => {
    if (subscribedPlanDataUpdatedAt) {
      return {
        subscribedPlanData: subscribedPlanFetched?.data,
      };
    } else {
      return { subscribedPlanData: {} };
    }
  }, [subscribedPlanDataUpdatedAt]);

  const { paymentcardData } = useMemo(() => {
    if (dataUpdatedAt) {
      if (listPaymentMethods?.data?.data.length) {
        refetch();
      }
      return {
        paymentcardData: listPaymentMethods?.data?.data,
      };
    } else {
      return { paymentcardData: [] };
    }
  }, [dataUpdatedAt]);

  const { primaryCardData } = useMemo(() => {
    if (primaryDataUpdatedAt) {
      setPrimary(primaryCardFetched?.data?.id);
      return {};
    } else {
      return { primaryCardData: {} };
    }
  }, [primaryDataUpdatedAt]);

  const handleSelectPrimaryCard = (event, card) => {
    const isPrimary = event.target.checked;
    const payload = {
      primaryCardId: card?.id,
      is_primary: isPrimary,
    };
    selectPrimaryCard(payload);
  };
  const handleDeleteCard = (card) => {
    const payload = {
      primaryCardId: card?.id,
    };
    deleteSavedCard(payload);
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const navigateToSuccess = () => {
    if (state?.fromScreen === routes.PROFILE_INVOICES.pathname) {
      const invoiceData = state?.invoiceData;
      payInvoice(
        {
          id: invoiceData?.id,
          body: {
            payment_method: primary,
          },
        },
        {
          onSuccess: () => {
            navigate(-1);
          },
        }
      );
      return;
    }
    if (subscribedPlanData?.currentPlan) {
      subscribedPlanData.currentPlan?.stripe_status !== "canceled"
        ? updateSubscription(
            {
              paymentMethod: primary,
              oldPlan: subscribedPlanData.currentPlan.stripe_price,
              plan: productData.stripe_price_id,
            },
            {
              onSuccess: () => {
                setShowSuccessModal(true);
              },
            }
          )
        : postSubscription(
            {
              paymentMethod: primary,
              plan: productData.stripe_price_id,
              interval: productData.interval,
              // code: null,
            },
            {
              onSuccess: () => {
                setShowSuccessModal(true);
              },
            }
          );
    } else {
      postSubscription(
        {
          paymentMethod: primary,
          plan: productData.stripe_price_id,
          interval: productData.interval,
          // code: null,
        },
        {
          onSuccess: () => {
            setShowSuccessModal(true);
          },
        }
      );
    }
  };

  return (
    <div className="px-4 w-full mt-4 sm:pl-8 sm:mt-8">
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Payments Method"
      />
      <p className="text-base font-medium mb-8">Payments Method</p>
      {paymentcardData?.map((cardData) => (
        <div className="mb-4 items-start flex justify-between h-28 p-4 border-2 border-solid rounded-[5px] border-hb_blue-600">
          <div className="flex cursor-pointer gap-6 items-center justify-between">
            <img
              src={cardIssuerImage[cardData?.card?.brand]}
              style={{ width: "40px" }}
              alt="card_image"
            />
            <div>
              <Typography className="text-[14px] text-hb_blue-500">
                {capitalize(cardData?.card?.brand)} ending in{" "}
                {cardData?.card?.last4}
              </Typography>
              <Typography className="text-[12px] text-hb_blue-400 font-normal">
                Expiry {cardData?.card?.exp_month}/{cardData?.card?.exp_year}
              </Typography>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                if (!Boolean(primary === cardData?.id)) {
                  handleDeleteCard(cardData);
                }
              }}
            >
              <Typography
                className={
                  primary === cardData?.id
                    ? "text-[#00B894] text-[12px]"
                    : "text-[14px] font-semibold text-[#eb0e0ee3]"
                }
              >
                {primary === cardData?.id ? "Primary" : "Remove"}
              </Typography>
            </div>
            <Checkbox
              checked={primary === cardData?.id}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon sx={{ color: "#00B894" }} />}
              onChange={(event) => handleSelectPrimaryCard(event, cardData)}
            />
          </div>
        </div>
      ))}

      {paymentcardData && paymentcardData.length === 0 ? (
        <div className="flex flex-col items-center justify-center space-y-4 my-8">
          <div className="text-center">
            <img alt="no-cards" src={EmptyCardsPlaceholder} />
            <p className="mt-5">No cards found</p>
          </div>
          <div>
            <Button
              variant="contained"
              className="bg-hb_orange-dark"
              style={{ paddingBlock: ".5rem", paddingInline: "1rem" }}
              onClick={() => navigate(routes.ADD_CARD.pathname)}
            >
              Add new card
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-end gap-4 w-full">
          <Button
            variant="outlined"
            className="w-2/3 sm:w-2/5 border-hb_orange-dark text-hb_orange-dark hover:border-hb_orange-dark"
            style={{ paddingBlock: ".5rem", paddingInline: "1rem" }}
            onClick={() => navigate(routes.ADD_CARD.pathname)}
          >
            + Add new payment method
          </Button>
          <Button
            className="bg-hb_orange-dark w-2/3 sm:w-2/5"
            variant="contained"
            style={{ paddingBlock: ".5rem", paddingInline: "1rem" }}
            onClick={navigateToSuccess}
          >
            Pay
          </Button>
        </div>
      )}
      {/* Success modal */}
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base">Success</p>
            <Lottie options={LottieOptions} height={150} width={"100%"} />
            <p className="font-normal text-base mt-8 text-center w-72">
              You are Successfully Subscribed
            </p>
          </div>
        }
        open={showSuccessModal}
        handleClose={() => {
          setShowSuccessModal(false);
        }}
        continueEdit={() => {
          setShowSuccessModal(false);
          navigate("/profile/info");
        }}
      />
    </div>
  );
};

export default PaymentMethodScreen;
