import * as React from "react";
import { Button, Checkbox, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Fragment } from "react";
import MobileHeader from "components/MobileHeader";
import WhitePlusIcon from "assets/Icons/plusWhite.svg";
import "./index.css";
import { useCreateGoalMutation } from "queries/Goals";
import CloudIcon from "assets/Icons/cloud.svg";
import { useGetHabitQuery } from "queries/Habit";
import { CustomTextField } from "components";
import { routes } from "Routes/RouteConstants";

export default function LinkedHabitScreen(props) {
  const navigate = useNavigate();
  const { state } = useLocation();

  //usestates
  const [linkedData, setlinkedData] = React.useState([]);

  //queries
  const { data: fetchedHabitData } = useGetHabitQuery({ page: 1, perpage: 20 });

  //useeffects
  // React.useEffect(() => {
  //   let arr = [];
  //   if (fetchedHabitData?.data?.data?.length) {
  //     arr = fetchedHabitData?.data?.data?.map((item, idx) => {
  //       if (state?.linkedHabits?.find((ele) => ele?.id === item?.id)) {
  //         return { checked: true, streak_target: item?.streak_target, ...item };
  //       } else {
  //         return {
  //           checked: false,
  //           streak_target: item?.streak_target,
  //           ...item,
  //         };
  //       }
  //     });
  //     setlinkedData(arr);
  //   }
  // }, [fetchedHabitData]);

  React.useEffect(() => {
    if (fetchedHabitData?.data?.data?.length) {
      const updatedData = fetchedHabitData.data.data.map((item) => {
        const foundLinkedHabit = state.linkedHabits.find(
          (ele) => ele.id === item.id
        );
        return {
          checked: Boolean(foundLinkedHabit),
          streak_target: item.streak_target,
          ...item,
        };
      });
      setlinkedData(updatedData);
    }
  }, [fetchedHabitData, state.linkedHabits]);

  return (
    <Fragment>
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Select Habits"
      />
      <Grid
        container
        className="sm:ml-6 sm:mt-8 sm:mx-4 gap-4"
        sx={{ paddingX: "16px" }}
      >
        <div className="flex flex-row justify-between w-full max-w-4xl">
          <p className="text-gray-800">Habits</p>
          <p className="text-gray-800">Streak Target</p>
        </div>

        {Boolean(linkedData?.length) ? (
          <div className="flex flex-col w-full max-w-4xl">
            {linkedData?.map((item, idx) => {
              return (
                <div className="flex flex-row justify-between  items-center">
                  <div className="flex flex-row items-center">
                    <Checkbox
                      onChange={(e) => {
                        let dataArr = [...linkedData];
                        dataArr[idx].checked = e.target.checked;
                        setlinkedData(dataArr);
                      }}
                      checked={linkedData[idx]?.checked}
                      sx={{
                        color: "#FF6C44",
                        "&.Mui-checked": {
                          color: "#FF6C44",
                        },
                        padding: 0,
                        paddingRight: 1,
                        paddingTop: 0.25,
                      }}
                    />
                    <p className="text-gray-800">{item.name}</p>
                  </div>
                  <CustomTextField
                    variant={"outlined"}
                    required
                    label=""
                    value={linkedData[idx]?.streak_target}
                    onChange={(e) => {
                      let dataArr = [...linkedData];
                      dataArr[idx].streak_target = e.target.value;
                      setlinkedData(dataArr);
                    }}
                    placeholder="#"
                    type="number"
                    InputLabelProps={{
                      maxLength: 3,
                    }}
                    className="max-w-[55px] mx-4"
                  />
                </div>
              );
            })}
            <Button
              sx={{
                alignSelf: "center",
                color: "white",
              }}
              className="gap-4 w-[25%] h-12 bg-hb_orange-dark"
              variant="contained"
              onClick={() =>
                navigate(routes.CREATE_HABIT.pathname, {
                  state: { habitData: {} },
                })
              }
            >
              <img
                alt="WhitePlusIcon"
                style={{ height: 35, width: 35 }}
                src={WhitePlusIcon}
              />
              Create New Habit
            </Button>
          </div>
        ) : (
          <div className="flex flex-col w-full max-w-4xl justify-center items-center mt-4 my-12">
            <div>
              <img
                alt="CloudIcon"
                className="mt-[15%]"
                style={{ height: 118, width: 152 }}
                src={CloudIcon}
              />
              <p className="text-base font-semibold my-4 text-center">
                No Habit Found!
              </p>
            </div>
            <Button
              sx={{
                alignSelf: "center",
                color: "white",
              }}
              className="gap-4 w-[25%] h-12 bg-hb_orange-dark"
              variant="contained"
              onClick={() =>
                navigate(routes.CREATE_HABIT.pathname, {
                  state: { habitData: {} },
                })
              }
            >
              <img
                alt="WhitePlusIcon"
                style={{ height: 35, width: 35 }}
                src={WhitePlusIcon}
              />
              Create Habit
            </Button>
          </div>
        )}
        <div className="flex w-full max-w-4xl justify-center">
          <Button
            className="my-4 sm:w-[50%] w-full bg-hb_orange-dark"
            variant="contained"
            onClick={() => {
              let dataArr = linkedData?.filter((it) => it.checked === true);
              if (dataArr?.length) {
                navigate(routes.CREATE_GOAL.pathname, {
                  state: { ...state, linkedHabit: dataArr },
                });
              } else {
                navigate(-1);
              }
            }}
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Link Habit
          </Button>
        </div>
      </Grid>
    </Fragment>
  );
}
