import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LogCountModal = (props) => {
  const { open, handleClose, onClickApply } = props;

  //usestate
  const [text, setText] = useState(null);

  return (
    <Dialog open={open}>
      <DialogTitle className="font-semibold" color="primary">
        <Grid
          container
          direction="row"
          className="justify-between"
          alignItems="center"
        >
          <div className="text-transparent">h</div>
          Enter log count to remove
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className="sm:w-[550px] w-[350px]">
        <>
          <p className="font-semibold">Enter Frequency</p>
          <TextField
            variant="outlined"
            placeholder="Enter Frequency"
            type="number"
            value={text}
            onChange={(e) => setText(e.target.value)}
            sx={{
              width: "100%",
            }}
          />
          <div className="flex justify-center">
            <Button
              variant="contained"
              className="my-4 bg-hb_orange-dark w-1/2"
              disabled={!Boolean(text)}
              onClick={() => onClickApply(text)}
            >
              Apply
            </Button>
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
};
export default LogCountModal;
