import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CameraIcon from "assets/Icons/camera.svg";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import {
  Avatar,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useForm } from "react-hook-form";
import { UploadJournalImageQuery } from "queries/Auth";
import TagListModal from "pages/SignupPage/modals/tagListModal";
import MobileHeader from "components/MobileHeader";
import Tiptap from "components/TipTap";
import moment from "moment";
import AddFavouriteIcon from "assets/Icons/add_favourite.svg";
import AddFavouriteFillIcon from "assets/Icons/add_favourite_fill.svg";
import * as yup from "yup";
import ReactPlayer from "react-player";
import {
  useGetCourseLessonByIdQuery,
  useGetDailyPracticeByIdQuery,
  useSetCompleteCourseLessonByIdMutation,
  useSetCompleteDailyPracticeMutation,
} from "queries/Courses";
import { ToggleButton } from "components";
import { yupResolver } from "@hookform/resolvers/yup";

const FreeStyleSchema = yup.object().shape({
  addFavourite: yup.boolean().default(false),
  stringTags: yup.array(),
  // .min(1, "Please select atleast one Tag")
  // .required("Please select atleast one Tag"),
  imageTags: yup.array(),
  description: yup.string().required("Journal entry is required"),
});

const InspiredSettingScreen = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:650px)");

  const { state } = useLocation();
  const {
    description,
    is_favourite,
    shared_to_coach,
    tags,
    course_id,
    id,
    type,
  } = state?.lesson || {};

  //states
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedStringTags, setSelectedStringTags] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [shareWithCoach, setShareWithCoach] = useState(shared_to_coach ?? true);
  const [lessonData, setLessonData] = useState([]);

  const {
    watch,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    setError,
  } = useForm({
    defaultValues: state?.lesson?.id
      ? {
          stringTags: tags,
          description: description ?? "",
          addFavourite: is_favourite
            ? true
            : state?.fromScreen === "FAVORITE"
            ? true
            : false,
        }
      : {},
    resolver: yupResolver(FreeStyleSchema),
  });
  const { addFavourite } = watch();

  //queries
  const { data, dataUpdatedAt } = useGetCourseLessonByIdQuery({
    course_id: course_id,
    lesson_id: id,
    type,
  });
  const { data: dailyPracticeData } = useGetDailyPracticeByIdQuery({
    id,
    type,
  });
  const { mutateAsync: postCompleteLesson } =
    useSetCompleteCourseLessonByIdMutation();
  const { mutateAsync: postCompleteDailyPractice } =
    useSetCompleteDailyPracticeMutation();
  const { mutateAsync: UploadImages } = UploadJournalImageQuery();

  useEffect(() => {
    if (data) {
      setLessonData(data?.data);
    }
  }, [dataUpdatedAt]);

  useEffect(() => {
    if (dailyPracticeData) {
      setLessonData(dailyPracticeData?.data);
    }
  }, [dailyPracticeData]);

  const handleCloseModal = () => {
    return setShowModal(false);
  };

  const handleSetStringTags = (data) => {
    setSelectedStringTags(data);
    setValue("stringTags", data, { shouldValidate: true });
    setShowModal(false);
  };

  const handleTextEditor = (data) => {
    setValue("description", data);
  };

  // console.log("errors--->", errors);

  //render
  const renderVideoContainer = (item) => {
    return (
      <Grid xs={12} md={8} className="overflow-hidden rounded-2xl my-4">
        <ReactPlayer
          url={item?.video}
          height={isSmallScreen ? "25vh" : "40vh"}
          width={"100%"}
          controls
        />
      </Grid>
    );
  };

  return (
    <div>
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title={Boolean(type === "daily") ? "Daily Practice" : "Lesson"}
      />
      {/* Body */}
      <Grid container className="px-4 sm:px-8 sm:pt-6">
        {state?.lesson?.videos?.length > 0 &&
          renderVideoContainer(state?.lesson?.videos[0])}
        <Grid item xs={12} md={8} className="sm:mt-2">
          <p className="text-sm">
            {Boolean(type === "daily")
              ? dailyPracticeData?.data?.lesson?.title
              : lessonData?.title}
          </p>
          {Boolean(
            dailyPracticeData?.data?.lesson?.journal_prompt ||
              lessonData?.journal_prompt
          ) && (
            <p className="sm:mt-8 mt-1 bg-hb_light_blue-100 p-3 rounded-lg text-gray-700 text-sm min-h-[100px]">
              {Boolean(type === "daily")
                ? dailyPracticeData?.data?.lesson?.journal_prompt
                : lessonData?.journal_prompt}
            </p>
          )}
        </Grid>
        <Grid item xs={12} md={8} className="mt-3">
          <p className="text-sm mb-1">Journal Entry</p>
          <Tiptap
            value={description ?? ""}
            handleTextEditor={handleTextEditor}
          />
          <FormHelperText
            error={Boolean(errors.description)}
            margin="dense"
            className="text-center"
          >
            {errors.description && errors.description.message}
          </FormHelperText>
        </Grid>
        {Boolean(selectedStringTags?.length) && (
          <Grid item xs={12} md={8}>
            <p className="font-semibold py-2">Tags</p>
            <div className="flex flex-wrap gap-3 flex-row">
              {selectedStringTags?.map((it, idx) => (
                <div className="items-center">
                  <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                    {it?.name}{" "}
                    <Button
                      onClick={() => {
                        setSelectedStringTags((prev) => {
                          const elem = [...prev];
                          elem.splice(idx, 1);
                          setValue("stringTags", elem, {
                            shouldValidate: true,
                          });

                          return elem;
                        });
                      }}
                      sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: 0,
                        paddingBottom: 0.2,
                      }}
                    >
                      <CancelIcon sx={{ color: "white" }} fontSize="small" />
                    </Button>
                  </p>
                </div>
              ))}
            </div>
          </Grid>
        )}
        {Boolean(selectedImages.length) && (
          <Grid item xs={12} md={8}>
            <p className="font-semibold py-2">Images</p>
            <div className="flex flex-wrap gap-3 flex-row">
              {selectedImages?.map((it, idx) => (
                <div className="items-center relative">
                  <img
                    alt="imgs"
                    src={it.url}
                    style={{ height: 70, width: 70 }}
                  />
                  <Button
                    onClick={() => {
                      setSelectedImages((prev) => {
                        const elem = [...prev];
                        elem.splice(idx, 1);
                        setValue("imageTags", elem, { shouldValidate: true });
                        return elem;
                      });
                    }}
                    sx={{
                      padding: 0,
                      margin: 0,
                      minWidth: 0,
                      paddingBottom: 0.2,
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <CancelIcon
                      sx={{
                        color: "#FF6C44",
                        backgroundColor: "white",
                        borderRadius: 25,
                      }}
                      fontSize="small"
                    />
                  </Button>
                </div>
              ))}
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={8} className="flex flex-row justify-center">
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <img
              alt="add pic"
              src={CameraIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Pictures
          </Button>
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => setShowModal(true)}
          >
            <img
              alt="add tags"
              src={CirclePlusIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Tags
          </Button>
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => {
              setValue("addFavourite", !addFavourite, { shouldValidate: true });
            }}
          >
            <img
              alt="add fav"
              src={addFavourite ? AddFavouriteFillIcon : AddFavouriteIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Favorite
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormHelperText
            error={Boolean(errors.stringTags)}
            margin="dense"
            // variant={variant}
          >
            {errors.stringTags && errors.stringTags.message}
          </FormHelperText>
          <div className=" bg-[#F7F7F7] rounded-xl p-4 mt-8 ">
            <div className="flex items-center">
              <p className="text-base text-gray-700">
                Share with Coach/Therapist?
              </p>
              <Tooltip
                title="Share this goal with your coach"
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                </IconButton>
              </Tooltip>
              <div className="ml-auto">
                <ToggleButton
                  checked={shareWithCoach}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShareWithCoach(true);
                    } else {
                      setShareWithCoach(false);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <Button
            className="w-full bg-hb_orange-dark my-4"
            variant="contained"
            onClick={() => {
              if (!isValid) {
                return trigger();
              }
              Boolean(type === "daily")
                ? postCompleteDailyPractice(
                    {
                      body: {
                        daily_practice_id: dailyPracticeData?.data?.id,
                        entry: getValues("description"),
                        favourite: getValues("addFavourite") ? 1 : 0,
                        tags:
                          getValues("stringTags")?.length > 0
                            ? getValues("stringTags")?.map((el) => el.id)
                            : null,
                        images:
                          getValues("imageTags")?.length > 0
                            ? getValues("imageTags")?.map((el, index) =>
                                el?.path ? el.url : el
                              )
                            : null,
                        shared_to_coach: +shareWithCoach,
                      },
                    },
                    {
                      onSuccess: () => {
                        navigate(-1);
                      },
                      onError: (error) => {
                        if (error?.errors?.entry[0]) {
                          setError("description", {
                            type: "custom",
                            message: error?.errors?.entry[0],
                          });
                        }
                      },
                    }
                  )
                : postCompleteLesson(
                    {
                      course_id: lessonData?.course_id,
                      lesson_id: lessonData?.id,
                      body: {
                        entry: getValues("description"),
                        favourite: getValues("addFavourite") ? 1 : 0,
                        tags:
                          getValues("stringTags")?.length > 0
                            ? getValues("stringTags")?.map((el) => el.id)
                            : null,
                        images:
                          getValues("imageTags")?.length > 0
                            ? getValues("imageTags")?.map((el, index) =>
                                el?.path ? el.url : el
                              )
                            : null,
                        shared_to_coach: +shareWithCoach,
                      },
                    },
                    {
                      onSuccess: () => {
                        navigate(-1);
                      },
                      onError: (error) => {
                        if (error?.errors?.entry[0]) {
                          setError("description", {
                            type: "custom",
                            message: error?.errors?.entry[0],
                          });
                        }
                      },
                    }
                  );
            }}
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Complete
          </Button>
          {Boolean(lessonData?.lesson_user_all?.length > 0) && (
            <div>
              {lessonData?.lesson_user_all?.map((item) => {
                return (
                  <div className="shadow-lg rounded-lg border p-2 mb-4">
                    <div className="flex flex-row gap-2">
                      {item?.images?.length > 0 && (
                        <Avatar
                          size={50}
                          src={item?.images?.[0]?.image}
                          alt="asd"
                        />
                      )}
                      <div
                        className="p-2"
                        dangerouslySetInnerHTML={{
                          __html: item?.journal_entry,
                        }}
                      />
                    </div>

                    <p className="text-xs text-hb_orange-dark">
                      {moment(item?.updated_at).format("MM/DD/YYYY")}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>
      {showModal && (
        <TagListModal
          open={showModal}
          handleClose={handleCloseModal}
          handleSetStringTags={handleSetStringTags}
          selectedStringTags={selectedStringTags}
        />
      )}
      <input
        ref={fileInputRef}
        accept="image/png, image/jpeg"
        type="file"
        className="hidden"
        onChange={(event) => {
          let file = event.target.files[0];
          if (file.size > 2000 * 1000) {
            window.alert("Please upload a file smaller than 2 MB");
            return false;
          }
          UploadImages(
            {
              type: "image",
              image: event.target.files[0],
            },
            {
              onSuccess: (resp) => {
                const tempArray = [...selectedImages, resp?.data];
                setSelectedImages(tempArray);
                setValue("imageTags", tempArray, { shouldValidate: true });
              },
            }
          );
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </div>
  );
};

export default InspiredSettingScreen;
