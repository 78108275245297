/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@mui/material";
import {
  useGetInviteCoachQuery,
  useGetInviteRequestCoachQuery,
  useSubscribeCoachDeleteQuery,
  useSubscribeCoachMutation,
} from "queries/Coach";
import userDuoTone from "assets/Icons/user-duotone 1.svg";
import magnifyIcon from "assets/Icons/magnify.svg";
import React, { useMemo, useState } from "react";
import MobileHeader from "components/MobileHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { ConfirmDialog, ToggleSliderTabs } from "components";
import { toast } from "react-toastify";

const InvitationScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  //use state
  const [selectedTab, setSelectedTab] = useState(state?.selectedTab ?? "Sent");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState({});
  const [toDeleteCoach, setToDeleteCoach] = useState(null);

  //quries
  const { data: fetchedInvitecoachData, dataUpdatedAt } =
    useGetInviteCoachQuery();
  const {
    data: fetchedInvitecoachRequestData,
    dataUpdatedAt: InviteRequestCoachDataUpdatedAt,
  } = useGetInviteRequestCoachQuery();
  const { mutateAsync: subscribeCoach } = useSubscribeCoachMutation();
  const { mutateAsync: deleteCoach } = useSubscribeCoachDeleteQuery();

  //Use useMemo
  const { inviteCoach } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        inviteCoach: fetchedInvitecoachData?.data,
      };
    } else {
      return { inviteCoach: [] };
    }
  }, [dataUpdatedAt]);

  const { inviteCoachRequest } = useMemo(() => {
    if (InviteRequestCoachDataUpdatedAt) {
      return {
        inviteCoachRequest: fetchedInvitecoachRequestData?.data,
      };
    } else {
      return { inviteCoachRequest: [] };
    }
  }, [InviteRequestCoachDataUpdatedAt]);

  const renderList = (data) => {
    return (
      <div>
        {Boolean(data.length === 0) ? (
          <div className="flex flex-col w-full min-h-[60vh] justify-center items-center">
            <img
              alt="magnifyIcon"
              style={{ height: 80, width: 80 }}
              src={magnifyIcon}
            />
            <p className="text-lg font-semibold mt-8">No results to show</p>
          </div>
        ) : (
          data?.map((item) => {
            return (
              <div
                className="bg-hb_gray-1000 w-full p-4 mt-4 flex flex-row  items-center rounded-lg cursor-pointer"
                onClick={() => {
                  if (item?.status !== "accept" && !!item?.invited_at) {
                    setShowConfirmModal(true);
                    setSelectedCoach(item);
                  }
                }}
              >
                <div className="flex justify-center h-12 w-12 bg-white rounded-full items-center text-sm text-gray-700 font-medium">
                  <img
                    alt="userDuoTone"
                    style={
                      item?.user_avatar
                        ? { height: 45, width: 45, borderRadius: 45 }
                        : { height: 20, width: 22 }
                    }
                    src={item?.user_avatar ?? userDuoTone}
                  />
                </div>
                <p className="ml-4">{item?.coach}</p>
                {Boolean(item?.status !== "accept" && !!item?.invited_at) && (
                  <Button
                    className="bg-hb_orange-dark ml-auto text-white w-36"
                    onClick={(event) => {
                      event.stopPropagation();
                      subscribeCoach(
                        {
                          username: item?.username,
                        },
                        {
                          onSuccess: () => {
                            setSelectedCoach({});
                            setShowSuccessModal(true);
                          },
                        }
                      ).catch((e) => {
                        toast.error(e?.message);
                      });
                    }}
                  >
                    <p>Accept</p>
                  </Button>
                )}
                {item?.status === "accept" && (
                  <Button
                    className="bg-hb_orange-dark ml-auto text-white w-36"
                    onClick={(event) => {
                      event.stopPropagation();
                      setToDeleteCoach(item);
                    }}
                  >
                    <p>Disconnect</p>
                  </Button>
                )}
              </div>
            );
          })
        )}
      </div>
    );
  };

  const renderScene = (scene) => {
    switch (scene) {
      case "Connected":
        return renderList(
          inviteCoach?.filter((el) => el?.status === "accept") ?? []
        );
      case "Sent":
        return renderList(inviteCoachRequest ?? []);
      case "Pending":
        return renderList(
          inviteCoach?.filter((el) => el?.status !== "accept") ?? []
        );
      default:
        return;
    }
  };

  return (
    <div className="px-4 w-full mt-4 sm:pl-8 sm:mt-8">
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Invitations"
      />
      <Grid container xs={12} md={6} className="mt-5">
        <ToggleSliderTabs
          options={["Connected", "Sent", "Pending"]}
          value={selectedTab}
          setSelectedItem={(currentTab) => {
            setSelectedTab(currentTab);
          }}
        />
      </Grid>
      {renderScene(selectedTab)}
      {/* Accept modal */}
      <ConfirmDialog
        type={"acceptDeny"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base">Alert!</p>
            <p className="font-normal text-base mt-8 text-center w-72">
              {`Coach ${selectedCoach?.username} has invited you to accept his services.`}
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => {
          setShowConfirmModal(false);
        }}
        continueEdit={() => {
          subscribeCoach(
            {
              username: selectedCoach?.username,
            },
            {
              onSuccess: () => {
                setShowConfirmModal(false);
                setSelectedCoach({});
                setShowSuccessModal(true);
              },
            }
          ).catch((e) => {
            toast.error(e?.message);
          });
        }}
      />
      {/* Success modal */}
      <ConfirmDialog
        type={"yesno"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base">Success</p>
            <p className="font-normal text-base mt-8 text-center w-72">
              Are you sure you want to remove {toDeleteCoach?.coach}
            </p>
          </div>
        }
        open={Boolean(toDeleteCoach)}
        handleClose={() => {
          setToDeleteCoach(null);
        }}
        continueEdit={() => {
          deleteCoach(
            {
              coach_id: toDeleteCoach?.coach_id,
            },
            {
              onSuccess: (res) => {
                setToDeleteCoach(null);
              },
              onError: (err) => {
                console.log("err", err);
              },
            }
          );
        }}
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base">Success</p>
            <p className="font-normal text-base mt-8 text-center w-72">
              Coach Invite Accepted succesfully
            </p>
          </div>
        }
        open={showSuccessModal}
        handleClose={() => {
          setShowSuccessModal(false);
        }}
        continueEdit={() => {
          navigate("/profile/info");
        }}
      />
    </div>
  );
};

export default InvitationScreen;
