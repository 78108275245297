import { Grid, useMediaQuery } from "@mui/material";
import { ToggleSliderTabs } from "components";
import SearchBar from "components/SearchBar/SearchBar";
import TopNav from "components/TopNav";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { useMyJournalQuery } from "queries/MyJournal";
import InfiniteScroll from "react-infinite-scroll-component";
import SwipeableEdgeDrawer from "components/Filter";
import JournalCard from "components/JournalCard";
import MyCourse from "./myCourse";
import Courses from "./courses";
import { useNavigate } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import { useDebounce } from "hooks/useDebounce";
import { useLocalStorage } from "hooks";

export const JournalType = {
  habits: "habits",
  checkIn: "checkins",
  journals: "journals",
  lesson: "lesson_users",
  goals: "goals",
  custom: "custom_check_ins",
};

const JournalScreen = () => {
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const navigate = useNavigate();
  //use states
  const [searchJournal, setSearchJournal] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useLocalStorage(
    "Journal-Tab",
    "My Journal"
  );

  const [filterData, setFilterData] = useState({
    tags: [],
    categories: [],
    type: [],
  });
  const [myJournalList, setMyJournalList] = useState([]);

  const debText = useDebounce(searchJournal, 1000);

  //queris
  const { data, dataUpdatedAt, isFetching, isLoading } = useMyJournalQuery({
    page: page,
    perpage: 20,
    q: debText?.length > 0 ? debText : null,
    tags:
      filterData?.tags?.length > 0 ? filterData?.tags.map((el) => el.id) : null,
    type: filterData?.type?.length > 0 ? filterData?.type : null,
  });

  useEffect(() => {
    if (data?.data) {
      if (data?.data?.current_page === 1) {
        setMyJournalList(data?.data?.data);
      } else if (data?.data?.current_page > 1) {
        setMyJournalList([...myJournalList, ...data?.data?.data]);
      }
    }
  }, [dataUpdatedAt]);

  //handler
  const navigateToDetails = (item, index) => {
    navigate(routes.JOURNAL_DETAIL.pathname, {
      state: { journalData: item, type: "" },
    });
  };

  const navigateToDetailsLesson = (item) => {
    navigate(routes.JOURNAL_DETAIL.pathname, {
      state: { journalData: item, type: "individual" },
    });
  };

  const navigateToHabitDetail = (item) => {
    navigate(routes.HABIT_DETAIL.pathname, {
      state: { item },
    });
  };

  const navigateToHabitDetailNote = (item) => {
    navigate(routes.HABIT_DETAIL.pathname, {
      state: { item, isNote: true },
    });
  };

  const navigateToCheckIn = (item) => {
    navigate(routes.GOALS.pathname, {
      state: { selectedTab: "Check-In", data: item },
    });
  };

  const navigateToCheckInNote = (item) => {
    navigate(routes.GOALS.pathname, {
      state: { selectedTab: "Check-In", data: item, isNote: true },
    });
  };

  const navigateToGoalDetail = (item, index) => {
    navigate(routes.GOAL_DETAIL.pathname, {
      state: { item },
    });
  };
  const navigateToGAD = (item) => {
    // navigate(routes.HOME.GAD_CHECK_IN, {
    //   type: item?.type,
    //   custom_check_in_id:
    //     item?.type === "GAD-7" ? 1 : item?.type === "PHQ-9" ? 2 : item?.id,
    //   data: item,
    //   onSubmit: () => {
    //     setPage(1);
    //   },
    // });
  };

  const handleFilter = (data) => {
    setFilterData(data);
    setOpen(false);
  };

  //render
  const renderMyJournal = (item, index) => {
    let journalType = item?.ujournalable_type;
    if (!item?.ujournalable) {
      return null;
    }
    switch (journalType) {
      case JournalType.journals:
        return (
          <JournalCard
            type={journalType}
            cardKey={item?.ujournalable.id}
            onClickCard={() => navigateToDetails(item, index)}
            name={item?.ujournalable?.name}
            description={item?.ujournalable?.description.replace(
              /<\/?[^>]+>/gi,
              " "
            )}
            tags={item?.ujournalable?.tags ?? []}
            date={item?.ujournalable?.created_at}
            image={
              item?.journalImages.length > 0
                ? item?.journalImages[0]?.image
                : ""
            }
          />
        );
      case JournalType.checkIn:
        return (
          <JournalCard
            type={journalType}
            cardKey={item?.ujournalable?.id}
            onClickCard={() => navigateToCheckIn(item)}
            name={item?.ujournalable?.name}
            description={item?.ujournalable?.notes}
            tags={item?.ujournalable?.tags ?? []}
            date={item?.ujournalable?.created_at}
            image={""}
            onClickNote={() => navigateToCheckInNote(item)}
          />
        );
      case JournalType.habits:
        return item?.ujournalable?.id ? (
          <JournalCard
            type={journalType}
            cardKey={item?.ujournalable?.id}
            onClickCard={() => navigateToHabitDetail(item.ujournalable)}
            name={item?.ujournalable?.name}
            description={null}
            tags={item?.ujournalable?.tags ?? []}
            date={item?.created_at}
            image={item?.ujournalable?.journal_images?.[0]?.image ?? ""}
            frequency={item?.ujournalable?.frequency}
            logCount={item?.ujournalable?.todayLogCount}
            period_type={item?.ujournalable?.period_type}
            onClickNote={() => navigateToHabitDetailNote(item.ujournalable)}
          />
        ) : null;
      case JournalType.lesson:
        if (
          item?.ujournalable?.type === "individual" ||
          item?.ujournalable?.type === "daily_prompt" ||
          item?.ujournalable?.type === "coach_daily_prompt"
        ) {
          return (
            <JournalCard
              type={journalType}
              cardKey={item?.ujournalable?.id}
              // onClickCard={() => navigateToDetails(item.ujournalable, index)}
              name={item?.ujournalable?.lesson?.title ?? ""}
              description={
                item?.ujournalable?.journal_entry?.replace(
                  /<\/?[^>]+>/gi,
                  " "
                ) ?? ""
              }
              tags={item?.ujournalable?.tags ?? []}
              date={item?.ujournalable?.created_at ?? ""}
              image={
                item?.ujournalable?.lesson?.thumbnail?.length > 0
                  ? item?.ujournalable?.lesson?.thumbnail
                  : ""
              }
              onClickCard={() => navigateToDetailsLesson(item?.ujournalable)}
            />
          );
        }
        return (
          <JournalCard
            type={journalType}
            cardKey={item?.ujournalable?.id}
            // onClickCard={() => navigateToDetails(item.ujournalable, index)}
            name={item?.ujournalable?.lesson?.title ?? ""}
            description={
              item?.ujournalable?.journal_entry?.replace(/<\/?[^>]+>/gi, " ") ??
              ""
            }
            tags={item?.ujournalable?.tags ?? []}
            date={item?.ujournalable?.created_at ?? ""}
            image={
              item?.ujournalable?.lesson?.thumbnail?.length > 0
                ? item?.ujournalable?.lesson?.thumbnail
                : ""
            }
            onClickCard={() => navigateToDetailsLesson(item?.ujournalable)}
          />
        );
      case JournalType.goals:
        return (
          <JournalCard
            type={journalType}
            cardKey={item?.ujournalable?.id}
            onClickCard={() => navigateToGoalDetail(item.ujournalable, index)}
            name={item?.ujournalable?.name}
            description={item?.ujournalable?.description}
            tags={item?.ujournalable?.tags ?? []}
            date={item?.created_at}
            image={
              item?.journalImages?.length > 0
                ? item?.journalImages[0]?.image
                : ""
            }
          />
        );
      case JournalType.custom:
        return (
          <JournalCard
            type={journalType}
            cardKey={item?.ujournalable?.id}
            onClickCard={() => navigateToGAD(item.ujournalable)}
            name={item?.ujournalable?.title ?? ""}
            description={item?.ujournalable?.summary ?? ""}
            tags={item?.ujournalable?.tags ?? []}
            date={item?.created_at}
            image={""}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {isSmallScreen && (
        <TopNav
          children={
            <div className="flex flex-col items-center -mt-8 mb-12">
              <p className="text-white text-xl font-semibold mr-3">
                My Journal
              </p>
              <Grid container xs={11} className="mt-4">
                <ToggleSliderTabs
                  options={["My Journal", "My Courses", "Courses"]}
                  value={selectedTab}
                  className="text-xs"
                  setSelectedItem={(currentTab) => {
                    setSelectedTab(currentTab);
                  }}
                  iconList={[
                    null,
                    null,
                    <SearchIcon
                      className={
                        selectedTab === "Courses"
                          ? "text-hb_blue-700"
                          : "text-hb_white-100"
                      }
                    />,
                  ]}
                  className="bg-hb_white-100 text-xl !important"
                />
              </Grid>
            </div>
          }
        />
      )}
      <Grid container md={6} className="hidden sm:flex mt-6 ml-8">
        <ToggleSliderTabs
          options={["My Journal", "My Courses", "Courses"]}
          value={selectedTab}
          setSelectedItem={(currentTab) => {
            setSelectedTab(currentTab);
          }}
          iconList={[
            null,
            null,
            <SearchIcon
              className={
                selectedTab === "Courses" ? "text-hb_blue-700" : "text-white"
              }
            />,
          ]}
        />
      </Grid>
      {selectedTab === "My Journal" && (
        <SearchBar
          className="-mt-6 sm:absolute sm:mx-3 sm:w-96 sm:right-0 sm:top-32"
          value={searchJournal}
          onChange={(e) => setSearchJournal(e.target.value)}
          onClickFilter={() => setOpen(true)}
        />
      )}

      {selectedTab === "My Journal" && (
        <div className="mx-4 mb-20">
          <InfiniteScroll
            dataLength={myJournalList.length} //This is important field to render the next data
            next={() => {
              if (isFetching || isLoading) {
                return;
              }
              if (data?.data?.current_page < data?.data?.last_page) {
                setPage(page + 1);
              }
            }}
            hasMore={
              data?.data?.current_page < data?.data?.last_page ? true : false
            }
            loader={<h4>Loading...</h4>}
            // below props only if you need pull down functionality
            refreshFunction={() => setPage(1)}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8595; Pull down to refresh
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {myJournalList?.map((item, idx) => renderMyJournal(item, idx))}
          </InfiniteScroll>
        </div>
      )}
      {selectedTab === "My Courses" && <MyCourse />}
      {selectedTab === "Courses" && <Courses setSelectedTab={setSelectedTab} />}
      <SwipeableEdgeDrawer
        open={open}
        handleClose={() => setOpen(false)}
        onClickDone={handleFilter}
        filters={["tags", "type"]}
      />
    </Fragment>
  );
};

export default JournalScreen;
