import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const WhyModal = (props) => {
  const { open, handleClose, handleConfirm } = props;
  const isSmallScreen = useMediaQuery("(max-width:650px)");

  const [description, setDescription] = useState("");

  const renderMainView = () => {
    return (
      <div className="flex flex-col justify-between pt-4 sm:pt-0 px-4">
        <h2 className="my-4 sm:my-2 text-center font-semibold text-hb_orange-dark text-base">
          Why are you taking this course?
        </h2>
        <div className="flex flex-row w-full bg-gray-100 mr-3 items-center mb-4">
          <TextField
            type="text"
            variant="outlined"
            sx={{ width: "100%" }}
            className="text-black placeholder:text-gray-700 rounded-lg"
            placeholder="Write here..."
            multiline
            rows={5}
            value={description || ""}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>

        <DialogActions className="justify-center">
          <Button
            onClick={() => handleConfirm(description)}
            disabled={!Boolean(description)}
            variant="contained"
            color="primary"
            sx={{
              background: "#FF6C44 !important",
              color: "white",
              minWidth: "150px",
            }}
            className="px-6 py-2"
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              setDescription("");
              handleClose();
            }}
            variant="outlined"
            color="primary"
            className="px-6 py-2"
            sx={{
              color: "#FF6C44",
              minWidth: "150px",
              borderColor: "#FF6C44",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </div>
    );
  };

  return (
    <div>
      {isSmallScreen ? (
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(35% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => true}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: false,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              {renderMainView()}
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="confirmation-dialog"
          maxWidth={"sm"}
          fullWidth={false}
          sx={{
            "& .MuiPaper-root": {
              minHeight: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "1rem",
            },
          }}
          className="items-between flex h-full flex-col"
        >
          {renderMainView()}
        </Dialog>
      )}
    </div>
  );
};

WhyModal.propTypes = {
  window: PropTypes.func,
};
const drawerBleeding = 46;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[700] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default WhyModal;
