import React from "react";

function CircularBar({className="w-20", ...props}) {
  return (
    <div className={className}>
      <div>{props.children}</div>
      <div>
        <p>{props.description}</p>
      </div>
    </div>
  );
}

export default CircularBar;
