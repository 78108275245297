import { Grid } from "@mui/material";
import { ToggleSliderTabs } from "components";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileHeader from "components/MobileHeader";
import PracticeCourseScreen from "./practiceCourse";
import DailyPromptsScreen from "./dailyPrompts";

const CoachPracticeScreen = () => {
  const navigate = useNavigate();
  //use states
  const [selectedTab, setSelectedTab] = useState("Courses");
  
  return (
    <Fragment>
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title={"Coach Practice"}
      />
      {/* Body */}
      <Grid container md={6} className="sm:flex sm:mt-6 sm:ml-3 px-4">
        <ToggleSliderTabs
          options={["Daily Prompts", "Courses"]}
          value={selectedTab}
          setSelectedItem={(currentTab) => {
            setSelectedTab(currentTab);
          }}
        />
      </Grid>
      {selectedTab === "Daily Prompts" && <DailyPromptsScreen />}
      {selectedTab === "Courses" && <PracticeCourseScreen />}
    </Fragment>
  );
};

export default CoachPracticeScreen;
