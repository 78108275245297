import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useGetHabitQuery } from "queries/Habit";

const checkinType1 = [
  { name: "Personal", id: -1 },
  { name: "Professional", id: -2 },
  { name: "Social", id: -3 },
];
function CheckInSettingModal(props) {
  const { open, handleClose, onClickDone } = props;
  const isSmallScreen = useMediaQuery("(max-width:650px)");

  //usestates
  const [selectedCheckbox, setSelectedCheckbox] = useState({
    checkinType: [],
    habitTrack: [],
  });

  //queries
  const { data: fetchedHabitData } = useGetHabitQuery({ page: 1, perpage: 20 });

  const renderCheckTypography = (item, el) => {
    return (
      <>
        <div className="flex flex-row items-start py-2">
          <Checkbox
            onChange={(_e) => {
              const elem = { ...selectedCheckbox };
              const elemIdx = elem?.[el]?.indexOf(item);
              if (elemIdx > -1) {
                elem?.[el]?.splice(elemIdx, 1);
              } else {
                elem?.[el]?.push(item);
              }
              setSelectedCheckbox(elem);
            }}
            sx={{
              color: "#FF6C44",
              "&.Mui-checked": {
                color: "#FF6C44",
              },
              padding: 0,
              paddingRight: 1,
              paddingTop: 0.25,
            }}
          />
          <p className="text-base font-normal">{item.name}</p>
        </div>
        <Divider />
      </>
    );
  };

  const renderMainContent = () => {
    return (
      <div className="flex flex-col">
        <p className="text-base capitalize font-semibold py-2 mt-2 text-gray-800">
          Check-ins
        </p>
        <Divider />
        {checkinType1?.map((it) => renderCheckTypography(it, "checkinType"))}
        <p className="text-base capitalize font-semibold py-2 mt-2 text-gray-800">
          Habit Trackers
        </p>
        <Divider />
        {fetchedHabitData?.data?.data?.map((it) =>
          renderCheckTypography(it, "habitTrack")
        )}
        <Button
          variant="contained"
          onClick={() => onClickDone(selectedCheckbox)}
          className="bg-hb_orange-dark mt-4"
        >
          Done
        </Button>
      </div>
    );
  };

  return (
    <div>
      {isSmallScreen ? (
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(70% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => true}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: false,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              <div className="flex flex-row justify-between py-1">
                <Typography className="text-base font-semibold p-3 text-gray-500">
                  Check-in Settings
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    fontSize={"medium"}
                    style={{ color: "#434349", padding: 0, marginRight: 4 }}
                  />
                </IconButton>
              </div>
            </StyledBox>
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              {renderMainContent()}
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      ) : (
        <SwipeableDrawer
          onOpen={() => true}
          anchor={"left"}
          open={open}
          onClose={handleClose}
        >
          <div className="w-64 mx-4">
            <div className="flex flex-row justify-between py-1">
              <Typography className="text-base font-semibold p-1 text-gray-500">
                Check-in Settings
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon
                  fontSize={"medium"}
                  style={{ color: "#434349", padding: 0, marginRight: 4 }}
                />
              </IconButton>
            </div>
            {renderMainContent()}
          </div>
        </SwipeableDrawer>
      )}
    </div>
  );
}

CheckInSettingModal.propTypes = {
  window: PropTypes.func,
};
const drawerBleeding = 46;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[700] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const JournalType = {
  habits: "habits",
  checkIn: "checkins",
  journals: "journals",
  lesson: "lesson_users",
  goals: "goals",
  custom: "custom_check_ins",
};

const typeFilterData = [
  {
    name: "Journal Entries",
    value: JournalType.journals,
  },
  {
    name: "Habit Trackers",
    value: JournalType.habits,
  },
  {
    name: "Check-Ins",
    value: JournalType.checkIn,
  },
  {
    name: "Lessons",
    value: JournalType.lesson,
  },
  {
    name: "Goals",
    value: JournalType.goals,
  },
];

export default CheckInSettingModal;
