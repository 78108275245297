import { FormControl, FormHelperText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

const getAddressObject = (address_components) => {
  const ShouldBeComponent = {
    street_number: ["street_number", "sublocality_level_3"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    state: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  let address = {
    street_number: "",
    postal_code: "",
    street: "",
    state: "",
    city: "",
    country: "",
  };

  address_components &&
    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

  // Fix the shape to match our schema
  address.address = address.street_number + " " + address.street;
  delete address.street_number;
  delete address.street;
  return address;
};

const AddressAutocompleteTextfield = (props) => {
  const {
    label,
    variant,
    type,
    error,
    helperText,
    className,
    // ref,
    required,
    fullWidth,
    size,
    name,
    disabled,
    capitalize,
    handleGoogleAddress,
    defaultValue,
    handleManualStreetAddress,
    ...restOfTextFieldProps
  } = props;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
    onPlaceSelected: (place) => {
      const formattedAddress = getAddressObject(place.address_components);
      handleGoogleAddress(formattedAddress, place.address_components);
      setValue(formattedAddress.address);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  return (
    <FormControl
      className={`${className} ${fullWidth ? "w-full" : ""} mui-textfield`}
    >
      <TextField
        variant={variant ?? "standard"}
        fullWidth={fullWidth}
        error={Boolean(error)}
        value={value}
        label={label}
        onChange={(event) => {
          handleManualStreetAddress(event.target.value);
          setValue(event.target.value);
        }}
        inputRef={ref}
        {...restOfTextFieldProps}
      />
      <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

AddressAutocompleteTextfield.defaultProps = {
  label: "",
  variant: "standard",
  ref: null,
  type: "text",
  error: false,
  helperText: "",
  className: null,
  // ref: null,
  required: false,
  fullWidth: true,
  size: "medium",
  disabled: false,
  defaultValue: "",
};

export default AddressAutocompleteTextfield;
