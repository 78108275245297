import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import "utils/StripeCardComponent/StripeCardComponent.scss";
import { Button, Grid } from "@mui/material";
import { useMemo } from "react";
import MobileHeader from "components/MobileHeader";
import { useNavigate } from "react-router-dom";
import { useSaveNewCardDetalisAPI } from "queries/Payment";

const CardElementWrapper = ({ children }) => {
  return (
    <Grid item className="!mb-2 !mt-2 rounded-lg border-2 px-4 py-3">
      {children}
    </Grid>
  );
};

const AddCardScreen = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  //queries
  const { mutateAsync: AddNewCard } = useSaveNewCardDetalisAPI();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    if (payload.paymentMethod?.id) {
      const reqBody = {
        paymentMethod: payload.paymentMethod?.id,
        primary: 1,
      };
      AddNewCard(reqBody, { onSuccess: () => navigate(-1) });
    }
  };

  const cardNumberOption = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          // fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#AAB7C4",
          },
        },
        invalid: {
          color: "#9E2146",
        },
      },
      placeholder: "Card number",
    }),
    []
  );
  const cardExpiryOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          // fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#AAB7C4",
          },
        },
        invalid: {
          color: "#9E2146",
        },
      },
      placeholder: "Expiration date (MM/YY)",
    }),
    []
  );
  const cardCvvOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          // fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#AAB7C4",
          },
        },
        invalid: {
          color: "#9E2146",
        },
      },
      placeholder: "Security code (CVV)",
    }),
    []
  );

  return (
    <form onSubmit={handleSubmit} className="stripe-card-component flex w-full">
      <div className="px-4 w-full mt-4 sm:pl-8 sm:mt-8 ">
        {/* Header */}
        <MobileHeader
          variant="secondary"
          onClick={() => navigate(-1)}
          title="Add Card"
        />
        <p className="text-base font-medium mb-4">Payments Method</p>
        <Grid container md={5} xs={12} direction={"column"}>
          <div className="my-1">
            <span className="text-sm font-semibold text-gray-600">
              Card number
            </span>
            <CardElementWrapper>
              <CardNumberElement options={cardNumberOption} />
            </CardElementWrapper>
          </div>
          <div className="my-1">
            <span className="text-sm font-semibold text-gray-600">Expiry</span>
            <CardElementWrapper>
              <CardExpiryElement options={cardExpiryOption} />
            </CardElementWrapper>
          </div>
          <div className="my-1">
            <span className="text-sm font-semibold text-gray-600">CVV</span>
            <CardElementWrapper>
              <CardCvcElement options={cardCvvOption} />
            </CardElementWrapper>
          </div>
          <div className="mt-6">
            <Button
              variant="outlined"
              type="submit"
              fullWidth
              disabled={!stripe}
              className="text-hb_orange-dark sm:w-10/12"
              style={{ paddingBlock: ".5rem", borderColor: "#FF6C44" }}
            >
              + ADD CARD
            </Button>
          </div>
        </Grid>
      </div>
    </form>
  );
};
export default AddCardScreen;
