import ReactPlayer from "react-player/youtube";
import { Grid, Button, FormHelperText } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CameraIcon from "assets/Icons/camera.svg";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import AddFavouriteIcon from "assets/Icons/add_favourite.svg";
import AddFavouriteFillIcon from "assets/Icons/add_favourite_fill.svg";
import { useFormContext } from "react-hook-form";
import { useRef, useState } from "react";
import TagListModal from "../modals/tagListModal";
import { CustomTextField } from "components";
import { UploadJournalImageQuery } from "queries/Auth";

/* Step-7 */
const Journaling = (props) => {
  const { setFormStep } = props;
  //states
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedStringTags, setSelectedStringTags] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const {
    watch,
    formState: { errors, isValid },
    setValue,
    register,
  } = useFormContext();

  const { addFavourite } = watch();

  const { ref: refJourniling, ...RegisterJourniling } = register("journiling");

  const handleCloseModal = () => {
    return setShowModal(false);
  };

  const handleSetStringTags = (data) => {
    setSelectedStringTags(data);
    setValue("stringTags", data, { shouldValidate: true });
    setShowModal(false);
  };

  const { mutateAsync: UploadImages } = UploadJournalImageQuery();

  return (
    <div>
      {showModal && (
        <TagListModal
          open={showModal}
          handleClose={handleCloseModal}
          selectedStringTags={selectedStringTags}
          handleSetStringTags={handleSetStringTags}
        />
      )}
      <input
        ref={fileInputRef}
        accept="image/png, image/jpeg"
        type="file"
        className="hidden"
        onChange={(event) => {
          let file = event.target.files[0];
          if (file.size > 2000 * 1000) {
            window.alert("Please upload a file smaller than 2 MB");
            return false;
          }
          UploadImages(
            {
              type: "image",
              image: event.target.files[0],
            },
            {
              onSuccess: (resp) => {
                const tempArray = [...selectedImages, resp?.data];
                setSelectedImages(tempArray);
                setValue("imageTags", tempArray, { shouldValidate: true });
              },
            }
          );
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <Grid container className="mt-10">
        <Grid item xs={12} md={8}>
          <p className="text-2xl font-semibold mt-2 mb-4 text-hb_blue-700">
            Why Journaling?
          </p>
        </Grid>
        <Grid item xs={12} md={9} className="overflow-hidden rounded-2xl">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=9m0ZsMoFL4o"
            height={280}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-xl font-semibold mt-6">
            Journaling is a Keystone habit.
          </p>
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-base font-normal mt-6 bg-hb_secondary-100 p-3 rounded-xl">
            What are you feeling about journaling? Have you ever tried? <br />
            Why or why not? Are you open to giving it a shot?
            <br /> We promise to make it worth your time.
          </p>
        </Grid>
        {/* <Grid item xs={12} md={8}>
            <p className="text-base font-semibold mt-6">Journal Entry</p>
          </Grid> */}

        <Grid item xs={12} md={8} className="mt-6">
          {/* <div className="bg-gray-200 h-12 rounded-t-2xl justify-between px-4 items-center flex flex-row">
              <div>B I</div>
              <Button variant="contained" className="bg-hb_blue-700 h-8 w-1/3 text-xs">Done</Button>
            </div> */}
          <CustomTextField
            variant={"outlined"}
            required
            label=""
            ref={refJourniling}
            rows={5}
            multiline
            {...RegisterJourniling}
            placeholder="Write Here..."
            type="text"
            error={Boolean(errors.journiling)}
            helperText={errors.journiling && errors.journiling.message}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-base mt-2 text-gray-600">
            The Daily Practice offers short prompts for any time, any day, and
            any mood.
            <br /> Guided journaling Courses allow you to dive deep and build a
            specific habit or skill
          </p>
        </Grid>
        {Boolean(selectedStringTags?.length) && (
          <Grid item xs={12} md={8}>
            <p className="font-semibold py-2">Tags</p>
            <div className="flex flex-wrap gap-3 flex-row">
              {selectedStringTags?.map((it, idx) => (
                <div className="items-center">
                  <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                    {it.name}{" "}
                    <Button
                      onClick={() => {
                        setSelectedStringTags((prev) => {
                          const elem = [...prev];
                          elem.splice(idx, 1);
                          setValue("stringTags", elem, {
                            shouldValidate: true,
                          });

                          return elem;
                        });
                      }}
                      sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: 0,
                        paddingBottom: 0.2,
                      }}
                    >
                      <CancelIcon sx={{ color: "white" }} fontSize="small" />
                    </Button>
                  </p>
                </div>
              ))}
            </div>
          </Grid>
        )}
        {Boolean(selectedImages.length) && (
          <Grid item xs={12} md={8}>
            <p className="font-semibold py-2">Images</p>
            <div className="flex flex-wrap gap-3 flex-row">
              {selectedImages?.map((it, idx) => (
                <div className="items-center relative">
                  <img
                    alt="imgs"
                    src={it.url}
                    style={{ height: 70, width: 70 }}
                  />
                  <Button
                    onClick={() => {
                      setSelectedImages((prev) => {
                        const elem = [...prev];
                        elem.splice(idx, 1);
                        setValue("imageTags", elem, { shouldValidate: true });
                        return elem;
                      });
                    }}
                    sx={{
                      padding: 0,
                      margin: 0,
                      minWidth: 0,
                      paddingBottom: 0.2,
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <CancelIcon
                      sx={{
                        color: "#FF6C44",
                        backgroundColor: "white",
                        borderRadius: 25,
                      }}
                      fontSize="small"
                    />
                  </Button>
                </div>
              ))}
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={8} className="flex flex-row justify-center">
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <img
              alt="add pic"
              src={CameraIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Pictures
          </Button>
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => setShowModal(true)}
          >
            <img
              alt="add tags"
              src={CirclePlusIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Tags
          </Button>
          <Button
            className="flex flex-col text-gray-900"
            onClick={() => {
              setValue("addFavourite", !addFavourite, { shouldValidate: true });
            }}
          >
            <img
              alt="add fav"
              src={addFavourite ? AddFavouriteFillIcon : AddFavouriteIcon}
              style={{ height: 35, width: 35 }}
            />
            Add Favorite
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormHelperText
            error={Boolean(errors.stringTags)}
            margin="dense"
            // variant={variant}
          >
            {errors.stringTags && errors.stringTags.message}
          </FormHelperText>
          <Button
            className="w-full bg-hb_orange-dark mt-4"
            variant="contained"
            // disabled={!isValid}
            form="signup-form"
            type="submit"
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Next
          </Button>
          <Button
            className="w-full mt-2 text-hb_orange-dark underline underline-offset-2"
            variant="text"
            onClick={() => setFormStep(8)}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default Journaling;
