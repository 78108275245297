import { Button, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import { ConfirmDialog, ToggleButton } from "components";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import SettingOrangeIcon from "assets/Icons/settingOrange.svg";
import InfoCircleIcon from "assets/Icons/infoCircle.svg";
import EditIcon from "assets/Icons/editGray.svg";
import ShareIcon from "assets/Icons/share-04.svg";
import TrashIcon from "assets/Icons/trash-04.svg";
import CircularBar from "components/CircularBar/CircularBar";
import InfoIcon from "@mui/icons-material/Info";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useNavigate, useLocation } from "react-router-dom";
import goalInProgressIcon from "assets/Icons/goalInProgress.svg";
import CheckInsGraph from "components/CheckIns/CheckInsGraph";
import {
  useCreateHabitMutation,
  useDeleteHabitMutation,
  useGetHabitDownloadQuery,
  useGetHabitGraphByIdQuery,
  useGetHabitsDataByIdQuery,
  useGetLogHabitsQuery,
  usePostLogHabitsMutation,
  useRemoveLogCountHabitMutation,
} from "queries/Habit";
import moment from "moment";
import CheckInSettingModal from "components/CheckInSetting";
import { Calendar, DateObject } from "react-multi-date-picker";
import MobileHeader from "components/MobileHeader";
import AlertIcon from "assets/Icons/alert.svg";
import { routes } from "Routes/RouteConstants";
import LogHabitDateModal from "./component/logHabitModal";
import LogCountModal from "./component/logCountModal";
import { useProfileQuery } from "queries/Profile";

const SelectDate = ({
  weekDays,
  values,
  setSelectedDate,
  setShowLogCountModal,
  markDate,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex sm:mb-4 sm:justify-center items-start justify-center flex-wrap">
        <div>
          <Calendar
            id="calender-id"
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
            weekDays={weekDays}
            value={values}
            onFocusedDateChange={(dateFocused, dateClicked) => {
              const date = dateClicked?.format?.();
              setSelectedDate(date);
              setShowLogCountModal(true);
              markDate();
              return;
            }}
            format="YYYY-MM-DD"
            showOtherDays
            highlightToday={false}
            mapDays={({ date, selectedDate, isSameDate }) => {
              let props = {};
              selectedDate?.map((it) => {
                if (isSameDate(date, it))
                  props.style = {
                    ...props.style,
                    color: "white",
                    backgroundColor: it?.color,
                    fontWeight: "normal",
                  };
              });
              
              return props;
            }}
          />
        </div>
      </div>
      <div className="flex flex-row ml-6 justify-start gap-4 m-4 text-sm">
        <p className="flex flex-row items-center gap-2">
          <div className="h-3 w-3 bg-[#6FCF97] rounded-full" />
          Complete
        </p>
        <p className="flex flex-row items-center gap-2">
          <div className="h-3 w-3 bg-[#f6be19] rounded-full" />
          Partial
        </p>
        <p className="flex flex-row items-center gap-2">
          <div className="h-3 w-3 bg-[#CF6F6F] rounded-full" />
          Missed
        </p>
      </div>
    </div>
  );
};

const HabitDetailScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  //use states

  const [openCheckInModal, setOpenCheckInModal] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(true);
  const [shareWithCoach, setShareWithCoach] = useState(false);
  const [shareWithFamily, setShareWithFamily] = useState(false);
  const [makePublic, setMakePublic] = useState(false);
  const [csvId, setCsvId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRemoveLogModal, setShowRemoveLogModal] = useState(false);
  const [showEnterLogModal, setShowEnterLogModal] = useState(false);
  const [showLogCountModal, setShowLogCountModal] = useState(false);
  const [selectedCheckIn, setSelectedCheckIn] = useState({
    checkinType: [],
    habitTrack: [],
  });

  //queries
  const { data, dataUpdatedAt } = useGetHabitsDataByIdQuery({
    id: state?.item?.id,
  });
  const { data: graphData } = useGetHabitGraphByIdQuery({
    id: state?.item?.id,
    check_in_type: selectedCheckIn?.checkinType?.map((it) => it?.name) ?? [],
  });
  const { data: logData, refetch } = useGetLogHabitsQuery({
    id: state?.item?.id,
  });
  const { data: fetchedProfile } = useProfileQuery();
  const { data: csvData } = useGetHabitDownloadQuery({ id: csvId });
  const { mutateAsync: postLog } = usePostLogHabitsMutation();
  const { mutateAsync: deleteHabit } = useDeleteHabitMutation();
  const { mutateAsync: removeLogCountHabit } = useRemoveLogCountHabitMutation();
  const { mutateAsync: MutateHabit } = useCreateHabitMutation();

  //Use useMemo
  const { habitData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        habitData: data?.data,
      };
    } else {
      return { habitData: {} };
    }
  }, [dataUpdatedAt]);

  // useEffect(() => {
  //   if (state?.isNote) {
  //     setFocus("journiling");
  //   }
  // }, [state]);

  useEffect(() => {
    if (habitData) {
      setShareWithCoach(Boolean(habitData?.shared_to_coach));
      setShareWithFamily(Boolean(habitData?.shared_to_friend));
      setMakePublic(Boolean(habitData?.public));
    }
  }, [habitData]);

  useEffect(() => {
    if (csvData) {
      const link = document.createElement("a");
      link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`;
      link.download = "habit.csv";
      link.click();
      setCsvId(null);
    }
  }, [csvData]);

  const notificationList = habitData?.notification_timings
    ? habitData.notification_timings?.map(
        (el, index) =>
          `${moment(el.time, "HH:mm:ss").format("hh:mm A")} (${el.day})`
      )
    : null;

  //handler
  const addLog = (code) => {
    setShowEnterLogModal(false);
    postLog(
      {
        id: habitData?.id,
        body: { date: moment(code, "MM/DD/YYYY").format("YYYY-MM-DD") },
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };
  const logCount = (code) => {
    removeLogCountHabit(
      {
        id: habitData?.id,
        body: {
          date: selectedDate,
          logCount: Number(code),
        },
      },
      {
        onSuccess: () => {
          refetch();
          setShowLogCountModal(false);
          setSelectedDate(null);
        },
        onError: () => {
          setShowLogCountModal(false);
          setSelectedDate(null);
        },
      }
    ).catch((e) => {
      if (e?.errors?.date?.[0]) window.alert(e?.errors?.date?.[0]);
    });
  };

  const handleCheckIn = (data) => {
    setSelectedCheckIn(data);
    setOpenCheckInModal(false);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#FF6C44",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FF6C44",
      fontSize: 16,
      fontWeight: "normal",
    },
  }));

  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  //usestates
  const [values, setValues] = useState([]);

  const markDate = ()=>{
    let markedData = [...values];
    if (logData?.data?.length > 0) {
      logData?.data?.map((el, index) => {
        let completion = Number(el.completion);
        let check =
          moment(el.date, "YYYY-MM-DD").diff(
            moment(habitData?.start_date, "YYYY-MM-DD"),
            "days"
          ) >= 0;
        let checkDate = new DateObject(el.date);
        if (check) {
          checkDate.color =
            completion === 1
              ? "#6FCF97"
              : completion === 0
              ? "#CF6F6F"
              : "#f6be19";
          markedData = [...markedData, checkDate];
        }
      });
    }
    setValues(markedData);
  }

  //useeffects
  useEffect(() => {
    if (logData?.data && habitData) {
      markDate();
    }
  }, [logData, habitData]);

  //handler

  const handleShareWithCoach = (value) => {
    if (!fetchedProfile?.data?.profile?.hasCoach) {
      return setShowRemoveLogModal(
        "You can enable this toggle after you associate with a coach"
      );
    }
    if (value) {
      MutateHabit(
        {
          habit_id: habitData?.id,
          shared_to_coach: 1,
        },
        {
          onSuccess: () => {
            setShareWithCoach(true);
          },
        }
      );
    } else {
      MutateHabit(
        {
          habit_id: habitData?.id,
          shared_to_coach: 0,
        },
        {
          onSuccess: () => {
            setShareWithCoach(false);
          },
        }
      );
    }
  };

  const handleShareWithFamily = (value) => {
    if (!fetchedProfile?.data?.profile?.public) {
      return setShowRemoveLogModal(
        "You can enable this toggle after you make your profile public."
      );
    }
    if (value) {
      MutateHabit(
        {
          habit_id: habitData?.id,
          shared_to_friend: 1,
        },
        {
          onSuccess: () => {
            setShareWithFamily(true);
          },
        }
      );
    } else {
      MutateHabit(
        {
          habit_id: habitData?.id,
          shared_to_friend: 0,
        },
        {
          onSuccess: () => {
            setShareWithFamily(false);
          },
        }
      );
    }
  };

  const handleMakePublic = (value) => {
    if (!fetchedProfile?.data?.profile?.public) {
      return setShowRemoveLogModal(
        "You can enable this toggle after you associate with a coach"
      );
    }

    if (value) {
      MutateHabit(
        {
          habit_id: habitData?.id,
          public: 1,
        },
        {
          onSuccess: () => {
            setMakePublic(true);
          },
        }
      );
    } else {
      MutateHabit(
        {
          habit_id: habitData?.id,
          public: 0,
        },
        {
          onSuccess: () => {
            setMakePublic(false);
          },
        }
      );
    }
  };

  const progressCard = () => {
    return (
      <div className="flex flex-col rounded-lg shadow-lg border p-3 m-6 sm:m-4 mt-2">
        <div className="flex flex-row items-center pb-2 ">
          <CircularBar label="Square linecaps" className="w-11 mt-2">
            <CircularProgressbar
              value={habitData?.progress ?? 0}
              text={`${habitData?.progress ?? 0}%`}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "#FF6C44",
                trailColor: "#FAC1B8",
                textColor: "#FF6C44",
                textSize: "26px",
              })}
            />
          </CircularBar>
          <div className="flex flex-col ml-4">
            <p className="text-xl">{habitData?.name}</p>
          </div>
        </div>
        {Boolean(habitData?.goals?.length) && (
          <p className="text-sm text-gray-500">Linked Goals:</p>
        )}
        {habitData?.goals?.map((item) => {
          return (
            <div className="flex flex-row items-center">
              <img
                src={goalInProgressIcon}
                alt="goalCompleteIcon"
                style={{ height: 20, width: 20, marginRight: 10 }}
              />
              <p className="capitalize text-xs text-hb_blue-700 truncate w-20">
                {item?.name}
              </p>
              <CircularBar label="Square linecaps" className="w-6">
                <CircularProgressbar
                  value={item?.progress ?? 0}
                  text={`${item?.progress ?? 0}%`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#FF6C44",
                    trailColor: "#FAC1B8",
                    textColor: "#FF6C44",
                    textSize: "26px",
                  })}
                />
              </CircularBar>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Habit Detail"
      />
      <div className="flex flex-row flex-wrap w-full sm:mt-8">
        <div className="w-full sm:max-w-max sm:min-w-[600px]">
          {progressCard()}
          <SelectDate
            weekDays={weekDays}
            values={values}
            setSelectedDate={setSelectedDate}
            setShowLogCountModal={setShowLogCountModal}
            markDate={markDate}
          />
          <div className="border shadow-xl p-3 rounded-xl m-4 mt-2 flex flex-col">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-base">
                Habit Completion & Check-Ins
              </p>
              <div className="ml-auto w-[100px]">
                <BootstrapTooltip
                  title="I want a physical memory of my journal (book we ship with photos, check-ins, etc in a book)"
                  enterTouchDelay={0}
                  style={{ fontSize: "16px" }}
                >
                  <IconButton
                    sx={{ minWidth: 0, minHeight: 0, height: 45, width: 45 }}
                  >
                    <img src={InfoCircleIcon} alt="infoCircle" />
                  </IconButton>
                </BootstrapTooltip>
                <IconButton
                  sx={{ minWidth: 0, minHeight: 0, height: 45, width: 45 }}
                  onClick={() => setOpenCheckInModal(true)}
                >
                  <img src={SettingOrangeIcon} alt="SettingOrangeIcon" />
                </IconButton>
              </div>
            </div>
            <CheckInsGraph
              clientCheckinsData={graphData}
              showCheckIn={showCheckIn}
            />
            <div className="flex flex-row h-12 bg-[#F7F7F7] rounded-xl p-3 mt-4">
            <p className="text-base text-gray-700">Show Check-Ins</p>
            <div className="ml-auto">
              <ToggleButton
                checked={showCheckIn}
                onChange={(e) => {
                  if (e.target.checked) {
                    setShowCheckIn(true);
                  } else {
                    setShowCheckIn(false);
                  }
                }}
              />
            </div>
          </div>
          </div>
        </div>
        <div className="mt-2 w-full sm:max-w-[550px] px-4 ">
          <p className="text-sm mt-2">Streak</p>
          <div className="flex flex-row justify-between border shadow-lg rounded-lg p-3 mt-4 text-sm">
            <p>Taget: {habitData?.streak_target}</p>
            <p>Current: {habitData?.current_streak}</p>
            <p>Longest: {habitData?.longest_streak}</p>
          </div>
          {habitData?.why && (
            <>
              <p className="text-sm mt-4">Why am I tracking this?</p>
              <div className="border shadow-lg rounded-lg p-3 mt-4 text-sm">
                {habitData?.why}
              </div>
            </>
          )}
          <p className="text-sm mt-4 capitalize">
            {habitData?.period_type}, Frequency:{habitData?.frequency ?? 0}
          </p>
          <p className="text-sm">
            Notifications:
            {notificationList ? notificationList.join(", ") : "--"}
          </p>
          {habitData?.tags?.length > 0 && (
            <>
              <p className="text-sm mt-4">Tags</p>
              <div className="border shadow-lg rounded-lg p-3 mt-4 text-sm">
                {habitData?.tags &&
                  habitData?.tags?.map((el) => el.name).join(", ")}
              </div>
            </>
          )}
          <div className="flex flex-row justify-between mt-4 text-sm">
            <Button
              variant="outlined"
              className="text-hb_orange-dark border-dashed border-hb_orange-dark w-[45%]"
              onClick={() =>
                setShowRemoveLogModal(
                  "Press on the date in the calendar above to remove log of the that date."
                )
              }
            >
              Remove Log
            </Button>
            <Button
              variant="conatined"
              className="bg-hb_orange-dark text-white w-[45%]"
              onClick={() => setShowEnterLogModal(true)}
            >
              Log Habit
            </Button>
          </div>
          <div className="flex flex-row justify-between my-4 text-sm">
            <Button
              variant="outlined"
              className="text-hb_text-100 border-gray-200  text-xs lg:px-4 sm:px-8"
              onClick={() =>
                navigate(routes.CREATE_HABIT.pathname, { state: { habitData } })
              }
            >
              <img
                alt="editHabit"
                style={{ height: 18, width: 18, marginRight: 2 }}
                src={EditIcon}
              />
              Edit Habit
            </Button>
            <Button
              variant="outlined"
              className="text-[#128439] border-gray-200 text-xs lg:px-4 sm:px-8"
              onClick={() => setCsvId(state?.item?.id)}
            >
              <img
                alt="editHabit"
                style={{ height: 18, width: 18, marginRight: 2 }}
                src={ShareIcon}
              />
              Export to CSV
            </Button>
            <Button
              variant="outlined"
              className="text-hb_text-100 border-gray-200 text-xs lg:px-4 sm:px-8"
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              <img
                alt="editHabit"
                style={{ height: 18, width: 18, marginRight: 2 }}
                src={TrashIcon}
              />
              Archive Habit
            </Button>
          </div>
          <div className="flex flex-row h-12 bg-[#F7F7F7] rounded-xl p-3 mb-4">
            <p className="text-base text-gray-700">
              Share with Coach/Therapist?
            </p>
            <Tooltip
              title="Share this habit with your coach"
              enterTouchDelay={0}
            >
              <IconButton>
                <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
              </IconButton>
            </Tooltip>
            <div className="ml-auto">
              <ToggleButton
                checked={shareWithCoach}
                onChange={(e) => {
                  handleShareWithCoach(e.target.checked);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row h-12 bg-[#F7F7F7] rounded-xl p-3 mb-4">
            <p className="text-base text-gray-700">Share with Friends/Family</p>
            <Tooltip title="Share with Friends/Family" enterTouchDelay={0}>
              <IconButton>
                <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
              </IconButton>
            </Tooltip>
            <div className="ml-auto">
              <ToggleButton
                checked={shareWithFamily}
                onChange={(e) => {
                  handleShareWithFamily(e.target.checked);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row h-12 bg-[#F7F7F7] rounded-xl p-3 mb-4">
            <p className="text-base text-gray-700">Make Note Public?</p>
            <Tooltip title="Make this habit public" enterTouchDelay={0}>
              <IconButton>
                <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
              </IconButton>
            </Tooltip>
            <div className="ml-auto">
              <ToggleButton
                checked={makePublic}
                onChange={(e) => {
                  handleMakePublic(e.target.checked);
                }}
              />
            </div>
          </div>
          {habitData?.notes?.length > 0 && (
            <div>
              <p className="font-semibold">My Habit Notes</p>
              {habitData?.notes?.map((item) => {
                return (
                  <div className="rounded-lg shadow-lg border my-2 p-3 flex flex-col flex-wrap overflow-x-auto ">
                    <p className="text-hb_orange-dark text-sm">
                      {moment(item.updated_at).format("MMMM DD YYYY")}
                    </p>
                    <p className="sm:max-w-[100%] max-w-[320px] break-words">
                      {item.note}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* MODAL */}
      <CheckInSettingModal
        open={openCheckInModal}
        handleClose={() => setOpenCheckInModal(false)}
        onClickDone={handleCheckIn}
      />
      <ConfirmDialog
        type={"acceptDeny"}
        message={
          <div className="flex flex-col items-center">
            <img
              alt="AlertIcon"
              style={{ height: 60, width: 60 }}
              src={AlertIcon}
            />
            <p className="font-semibold text-base">Alert!</p>
            <p className="font-normal text-base mt-8 text-center w-72">
              Are you sure you want to delete this habit?
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => {
          setShowConfirmModal(false);
        }}
        continueEdit={() => {
          deleteHabit(
            {
              id: state?.item?.id,
            },
            {
              onSuccess: () => {
                navigate(-1);
              },
            }
          );
        }}
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base mt-4">Info</p>
            <p className="font-normal text-base mt-10 text-center sm:w-[550px] w-[300px]">
              {showRemoveLogModal ? showRemoveLogModal : ""}
            </p>
          </div>
        }
        open={Boolean(showRemoveLogModal)}
        handleClose={() => setShowRemoveLogModal("")}
        continueEdit={() => setShowRemoveLogModal("")}
      />
      <LogHabitDateModal
        open={showEnterLogModal}
        handleClose={() => setShowEnterLogModal(false)}
        onClickApply={addLog}
      />
      <LogCountModal
        open={showLogCountModal}
        handleClose={() =>{
           setShowLogCountModal(false)
           markDate();
          }}
        onClickApply={logCount}
      />
    </div>
  );
};

export default HabitDetailScreen;
