import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios, getLocalStorageItem, localStorageKeys } from "utils";
import QuereyKeys from "./queryKeys";

// get Notification data
const GetNotification = () => {
  const method = "GET";
  const url = "/api/notification";
  return axios({
    method,
    url,
  });
};

export const useGetNotificationQuery = () => {
  return useQuery([QuereyKeys.NOTIFICATION], GetNotification, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: (response) => {
      // setLocalStorageItem(localStorageKeys.USER_DETAILS, response.data);
    },
  });
};

// get dashboard Notification data
const GetDashBoardNotification = () => {
  const method = "GET";
  const url = "/api/notification/dashboard";
  return axios({
    method,
    url,
  });
};

export const useGetDashBoardNotificationQuery = () => {
  return useQuery(
    [QuereyKeys.DASHBOARD_NOTIFICATION],
    GetDashBoardNotification,
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
      onSuccess: () => {
        // setLocalStorageItem(localStorageKeys.USER_DETAILS, response.data);
      },
    }
  );
};

// update Notification info
const UpdateNotification = (body) => {
  const method = "PUT";
  const url = `/api/notification`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const useUpdateNotificationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdateNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(QuereyKeys.NOTIFICATION);
    },
  });
};
