import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { CustomTextField } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CheckCouponCodeQuery } from "queries/Subscription";

const promotionFormSchema = yup.object().shape({
  code: yup.string().required("Promo Code is required"),
});

export default function PromotionCodeModal(props) {
  const { open, handleClose, product, setSelectedPromoCode } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(promotionFormSchema),
    defaultValues: {
      code: "",
    },
  });

  //queries
  const { mutateAsync: checkCouponCode } = CheckCouponCodeQuery();

  //handler
  const handleValidCouponFormSubmit = (formData) => {
    const payload = {
      code: formData?.code,
      product_id: product?.stripe_product_id,
    };

    checkCouponCode(payload, {
      onSuccess: () => {
        setSelectedPromoCode(formData?.code);
        reset();
        handleClose();
      },
      onError: (error) => {
        setError("code", {
          type: "custom",
          message: error?.message,
        });
      },
    });
  };

  const { ref: refCode, ...RegisterCode } = register("code");
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="sm:ml-[15%]"
    >
      <form onSubmit={handleSubmit(handleValidCouponFormSubmit)}>
        <DialogTitle className="flex items-center justify-between">
          <span className="font-[3px] text-hb_blue-800">
            Enter the coupon code
          </span>
          <span>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize={"medium"} style={{ color: "#434349" }} />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent id="alert-dialog-title">
          <div className="mt-2">
            <CustomTextField
              label="Enter Promo Code"
              type="text"
              variant="outlined"
              inputRef={refCode}
              {...RegisterCode}
              error={Boolean(errors.code)}
              helperText={errors.code && errors.code.message}
              className="bg-white"
              inputProps={{
                maxLength: 255,
              }}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="flex items-center justify-center">
          <Button
            variant="contained"
            color="orange"
            className="m-3 mt-4 p-2 bg-hb_orange-dark"
            fullWidth
            type="submit"
          >
            Apply
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
