import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import { ToggleButton, ToggleSliderTabs } from "components";
import SearchBar from "components/SearchBar/SearchBar";
import TopNav from "components/TopNav";
import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Search as SearchIcon } from "@mui/icons-material";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import SwipeableEdgeDrawer from "components/Filter";
import BrowseHabits from "./browseHabit";
import WhitePlusIcon from "assets/Icons/plusWhite.svg";
import SettingOrangeIcon from "assets/Icons/settingOrange.svg";
import InfoCircleIcon from "assets/Icons/infoCircle.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import CheckInsGraph from "components/CheckIns/CheckInsGraph";
import {
  useGetHabitGraphQuery,
  useGetHabitQuery,
  usePostLogHabitsMutation,
} from "queries/Habit";
import HabitCard from "components/HabitCard";
import moment from "moment";
import CheckInSettingModal from "components/CheckInSetting";
import NoteModal from "./noteModal";
import CreateHabitModal from "components/CreateHabitModal";
import { useDebounce } from "hooks/useDebounce";
import { useLocalStorage } from "hooks";

const HabitsScreen = () => {
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const navigate = useNavigate();
  const { state } = useLocation();

  //use states
  const [searchHabits, setSearchHabits] = useState("");
  const [selectedTab, setSelectedTab] = useLocalStorage(
    "Habit-Tab",
    state?.selectedTab ?? "My Habits"
  );

  const [openHabitModal, setOpenHabitModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCheckInModal, setOpenCheckInModal] = useState(
    state?.openCheckInModal ?? false
  );
  const [openNoteModal, setOpenNoteModal] = useState(false);

  const [showCheckIn, setShowCheckIn] = useState(true);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState({
    tags: [],
    categories: [],
    type: [],
  });
  const [selectedCheckIn, setSelectedCheckIn] = useState({
    checkinType: [],
    habitTrack: [],
  });

  const debText = useDebounce(searchHabits, 1000);

  //queries
  const { data: clientCheckinsData } = useGetHabitGraphQuery({
    check_in_type: selectedCheckIn?.checkinType?.map((it) => it?.name) ?? [],
    habits: selectedCheckIn?.habitTrack?.map((it) => it.id) ?? [],
  });

  const { data, dataUpdatedAt } = useGetHabitQuery({
    page: page,
    perpage: 20,
    q: debText.length > 0 ? debText : null,
    tags:
      filterData?.tags?.length > 0
        ? filterData?.tags?.map((el) => el.id)
        : null,
    categories:
      filterData?.categories?.length > 0 ? filterData?.categories : null,
  });
  const { mutateAsync: postLog } = usePostLogHabitsMutation();

  //Use useMemo
  const { habitList } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        habitList: data?.data?.data,
      };
    } else {
      return { habitList: [] };
    }
  }, [dataUpdatedAt]);

  //handler
  const addLog = (item) => {
    postLog({
      id: item.id,
      body: {
        date: moment().format("YYYY-MM-DD"),
      },
    });
  };

  const handleFilter = (data) => {
    setFilterData(data);
    setOpen(false);
  };

  const handleCheckIn = (data) => {
    setSelectedCheckIn(data);
    setOpenCheckInModal(false);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#FF6C44",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FF6C44",
      fontSize: 16,
      fontWeight: "normal",
    },
  }));

  return (
    <div>
      {isSmallScreen && (
        <TopNav
          children={
            <div className="flex flex-col items-center -mt-8 mb-14">
              <p className="text-white text-xl font-semibold mr-3">Habits</p>
              <Grid container xs={10} className="mt-4">
                <ToggleSliderTabs
                  options={["My Habits", "Browse Habits"]}
                  value={selectedTab}
                  setSelectedItem={(currentTab) => {
                    setSelectedTab(currentTab);
                  }}
                  iconList={[
                    null,
                    null,
                    <SearchIcon
                      className={
                        selectedTab === "Courses"
                          ? "text-hb_blue-700"
                          : "text-hb_white-100"
                      }
                    />,
                  ]}
                  className="bg-hb_white-100 text-xl !important"
                />
              </Grid>
            </div>
          }
        />
      )}
      <Grid
        container
        className="hidden sm:flex flex-row mt-6 pl-8 justify-between"
      >
        <div className="w-1/2 pr-6">
          <ToggleSliderTabs
            options={["My Habits", "Browse Habits"]}
            value={selectedTab}
            setSelectedItem={(currentTab) => {
              setSelectedTab(currentTab);
            }}
            iconList={[
              null,
              null,
              <SearchIcon
                className={
                  selectedTab === "Courses" ? "text-hb_blue-700" : "text-white"
                }
              />,
            ]}
          />
        </div>
        {selectedTab === "My Habits" && (
          <Button
            sx={{
              alignSelf: "center",
              color: "white",
            }}
            className="gap-4 w-[25%] h-12 bg-hb_orange-dark mr-12"
            variant="contained"
            onClick={() => setOpenHabitModal(true)}
          >
            <img
              alt="WhitePlusIcon"
              style={{ height: 35, width: 35 }}
              src={WhitePlusIcon}
            />
            Create Habit
          </Button>
        )}
      </Grid>
      <SearchBar
        className="-mt-6 sm:absolute sm:mx-3 sm:w-96 sm:right-0 sm:top-32"
        value={searchHabits}
        onChange={(e) => setSearchHabits(e.target.value)}
        onClickFilter={() => setOpen(true)}
      />
      {selectedTab === "Browse Habits" ? (
        <BrowseHabits searchText={debText} />
      ) : (
        <div className="flex flex-col sm:pl-4 sm:flex-row w-full">
          <div className="sm:w-1/2">
            <div className="border  shadow-xl p-3 rounded-xl m-4 mt-2 flex flex-col">
              <div className="flex flex-row items-center">
                <p className="font-semibold text-lg">
                  Habit Completion & Check-Ins
                </p>
                <div className="ml-auto w-[100px]">
                  <BootstrapTooltip
                    title="See your Progress! This is a visual of logged habits and Standard Check-In."
                    enterTouchDelay={0}
                    style={{ fontSize: "16px" }}
                  >
                    <IconButton
                      sx={{ minWidth: 0, minHeight: 0, height: 45, width: 45 }}
                    >
                      <img src={InfoCircleIcon} alt="infoCircle" />
                    </IconButton>
                  </BootstrapTooltip>
                  <IconButton
                    sx={{ minWidth: 0, minHeight: 0, height: 45, width: 45 }}
                    onClick={() => setOpenCheckInModal(true)}
                  >
                    <img src={SettingOrangeIcon} alt="SettingOrangeIcon" />
                  </IconButton>
                </div>
              </div>
              <CheckInsGraph
                clientCheckinsData={clientCheckinsData}
                showCheckIn={showCheckIn}
              />
            </div>
          </div>
          <div className="mt-2 w-full sm:w-1/2 sm:pr-12 px-4">
            <div className="flex flex-row h-12 bg-[#F7F7F7] rounded-xl p-3 mb-4">
              <p className="text-base text-gray-700">Show Check-Ins</p>
              <div className="ml-auto">
                <ToggleButton
                  checked={showCheckIn}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowCheckIn(true);
                    } else {
                      setShowCheckIn(false);
                    }
                  }}
                />
              </div>
            </div>
            {habitList?.map((item) => {
              return (
                <HabitCard
                  key={item?.id}
                  habit={item}
                  variant="habit"
                  onClickCheck={() => addLog(item)}
                  onClickFile={() => setOpenNoteModal(item)}
                  onClickCard={() =>
                    navigate(routes.HABIT_DETAIL.pathname, { state: { item } })
                  }
                />
              );
            })}
          </div>
          <div className="flex flex-row justify-center mt-2 mb-32 sm:hidden">
            <Button
              className="flex flex-col text-gray-900"
              onClick={() => setOpenHabitModal(true)}
              sx={{ minWidth: 0 }}
            >
              <img
                alt="add tags"
                src={CirclePlusIcon}
                style={{ height: 35, width: 35 }}
              />
              Create Habit
            </Button>
          </div>
        </div>
      )}
      <SwipeableEdgeDrawer
        open={open}
        handleClose={() => setOpen(false)}
        filters={["tags", "categories"]}
        onClickDone={handleFilter}
      />
      <CheckInSettingModal
        open={openCheckInModal}
        handleClose={() => setOpenCheckInModal(false)}
        onClickDone={handleCheckIn}
      />
      <NoteModal
        open={openNoteModal}
        handleClose={() => setOpenNoteModal(null)}
      />
      <CreateHabitModal
        open={openHabitModal}
        handleClose={() => setOpenHabitModal(false)}
        onClickSelect={() => {
          setSelectedTab("Browse Habits");
          setOpenHabitModal(false);
          return;
        }}
      />
    </div>
  );
};

export default HabitsScreen;
