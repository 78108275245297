//Login
export const EMAIL_NOT_VERIFIED = "email is not verified";

//Log Types (uJournableType)
export const JOURNAL_TYPE_FREESTYLE_JOURNALS = "journals";
export const JOURNAL_TYPE_CHECKINS = "checkins";
export const JOURNAL_TYPE_HABIT_LOGS = "habits";
export const JOURNAL_TYPE_LESSON_COMPLETION = "lesson_users";
export const JOURNAL_TYPE_GOAL_COMPLETION = "goals";
export const JOURNAL_TYPE_CUSTOM_CHECKINS = "custom_check_ins";

//Scheduled appointments
export const APPOINTMENT_STATUS_SCHEDULED = "scheduled";
export const APPOINTMENT_STATUS_SHOW = "show";
export const APPOINTMENT_STATUS_NO_SHOW = "no show";
export const APPOINTMENT_STATUS_LATE_CANCEL = "late cancel";
export const APPOINTMENT_STATUS_CANCELLED = "cancel";
