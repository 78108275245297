import { useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  ResendOtpQuery,
  SignupUserQuery,
  useAnswerWhyHabitbetterMutation,
  useCheckInPostMutation,
  useHabitPostMutation,
  useJournalingPostMutation,
} from "queries/Auth";
import {
  Grid,
  Button,
  useMediaQuery,
  Typography,
  Divider,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomTextField from "components/FormComponents/CustomTextField";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleIcon from "assets/Icons/GoogleIcon.svg";
import FacebookIcon from "assets/Icons/FacebookIcon.svg";
import { useLocalStorage } from "hooks";
import { format } from "date-fns";
import { PasswordField } from "components";
import { routes } from "Routes/RouteConstants";
import { auth, gProvider } from "firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import { useSocialLoginQuery } from "queries/Auth";
import { v4 as uuidv4 } from "uuid";
import LetsStart from "./components/letsStart";
import DecideToHabitbetter from "./components/decideToHabitBetter";
import Professional from "./components/professional";
import Social from "./components/social";
import Personal from "./components/personal";
import Journaling from "./components/journaling";
import YourHabits from "./components/yourHabits";
import KeepTryingNewThings from "./components/keepTryingNewThings";
import VerifyOtpForm from "./components/VerifyOtp";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
export const RatingValues = [
  {
    value: 1,
    color: "#CC252D",
  },
  {
    value: 2,
    color: "#F18F24",
  },
  {
    value: 3,
    color: "#89BD51",
  },
  {
    value: 4,
    color: "#4EA34E",
  },
];

export const RatingOptions = [
  {
    id: 1,
    name: "Satisfaction",
    value: "satisfaction",
    options: RatingValues,
    answer: null,
    tooltipMessage:
      "Rate your Satisfaction where 1 being poor and 4 being great",
  },

  {
    id: 2,
    name: "Stress",
    value: "stress",
    options: RatingValues,
    answer: null,
    tooltipMessage:
      "Rate your Stress where 1 being freaking out and 4 being stress free",
  },

  {
    id: 3,
    name: "Confidence",
    value: "confidence",
    options: RatingValues,
    answer: null,
    tooltipMessage: "Rate your Confidence where 1 being poor and 4 being great",
  },
  {
    id: 4,
    name: "Growth",
    value: "growth",
    options: RatingValues,
    answer: null,
    tooltipMessage: "Rate your Growth where 1 being poor and 4 being great",
  },
];

const SignupFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Name is required")
    .test(
      "valid_characters",
      "Only letters, numbers, spaces, hyphens, and slashes are allowed",
      (value) => {
        return /^[\p{L}\s\-\/\d]+$/u.test(value);
      }
    )
    .min(4, "Name must be at least 4 characters long")
    .max(25, "Name must be less than 25 characters long"),
  email: yup
    .string()
    .trim()
    .required("Email is  Required")
    .email("Please enter a valid email"),
  password: yup
    .string()
    .trim()
    .matches(/^\S*$/, "Whitespace is not allowed in the password")
    .required("Password is Required")
    .max(14, "The password must not be greater than 14 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const OtpFormSchema = yup.object().shape({
  otp: yup
    .string()
    .min(4, "One Time Password should be atleast 4 digits")
    .required("One Time Password is required"),
});

const DecideHabitbetterSchema = yup.object().shape(
  {
    selectedCheckbox: yup.array().min(1, "at least 1").required("required"),
    isSomethingElseSelected: yup.boolean(),
    somethingElse: yup.string().when("isSomethingElseSelected", {
      is: true,
      then: yup
        .string()
        .min(10, "Please describe in atleast 10 character")
        .required("Please write something."),
      otherwise: yup.string(),
    }),
  },
  ["isSomethingElseSelected", "somethingElse"]
);

const ProfessionalSchema = yup.object().shape({
  isSkipProfessional: yup.boolean(),
  professional: yup
    .object()
    .shape({
      satisfaction: yup.number().required("Please select."),
      stress: yup.number().required("Please select."),
      confidence: yup.number().required("Please select."),
      growth: yup.number().required("Please select."),
    })
    .default(undefined)
    .required(),
});

const SocialSchema = yup.object().shape({
  isSkipSocial: yup.boolean(),
  social: yup
    .object()
    .shape({
      satisfaction: yup.number().required("Please select."),
      stress: yup.number().required("Please select."),
      confidence: yup.number().required("Please select."),
      growth: yup.number().required("Please select."),
    })
    .default(undefined)
    .required(),
});

const PersonalSchema = yup.object().shape({
  isSkipPersonal: yup.boolean(),
  personal: yup
    .object()
    .shape({
      satisfaction: yup.number().required("Please select."),
      stress: yup.number().required("Please select."),
      confidence: yup.number().required("Please select."),
      growth: yup.number().required("Please select."),
    })
    .default(undefined)
    .required(),
});

const JournilingSchema = yup.object().shape({
  journiling: yup.string().trim().required("Please write something."),
  addFavourite: yup.boolean().default(false),
  stringTags: yup
    .array()
    .min(1, "Please select atleast one Tag")
    .required("Please select atleast one Tag"),
  imageTags: yup.array(),
});

const habitFormSchema = yup.object().shape({
  habitName: yup
    .string()
    .trim()
    .required("Habit name is required")
    .min(3, "Habit name must be at least 3 characters")
    .max(254, "Habit name must be less than 255 characters"),
  habitStartDate: yup.date().nullable().required("Start Date is required."),
  habitEndDate: yup
    .date()
    .nullable()
    .when(
      "habitStartDate",
      (habitStartDate, schema) =>
        habitStartDate &&
        schema.min(
          habitStartDate,
          "Habit end date must later than habit start date."
        )
    ),
  whyTrack: yup.string().trim().min(3, "Please write at least 3 characters"),
  selectHabitColor: yup.string().required("Color is required"),
  notifications: yup.boolean().default(false),
  frequency: yup
    .string()
    .required("Frequency is required")
    .test(
      "len",
      "Maximum frequency should be less than 100",
      (val) => val.length <= 2
    ),
  frequencyType: yup.string().oneOf(["daily", "weekly"]),
  goal: yup.boolean().default(false),
  days: yup
    .array()
    .test("len", "Please select atleast one day", function (val) {
      if (this.parent?.frequencyType === "daily") {
        return true;
      }
      return Boolean(val.find((item) => item.selected));
    }),
  habit_category_id: yup.string().required("Habit Category is required"),
  streakAndGoalsTarget: yup
    .number("Streak and Goals Target is required")
    .typeError("Streak and Goals Target is required")
    .required("Streak and Goals Target is required")
    .min(
      1,
      "Streak and Goals Target should be greater than '0' less than '366' "
    )
    .max(
      365,
      "Streak and Goals Target should be greater than '0' less than '365' "
    ),
  selectedClients: yup.array(),
  stringTagsHabit: yup
    .array()
    .min(1, "Please select atleast one Tag")
    .required("Please select atleast one Tag"),
});

const SignupForm = () => {
  const validationSchema = [
    SignupFormSchema,
    OtpFormSchema,
    {},
    DecideHabitbetterSchema,
    ProfessionalSchema,
    SocialSchema,
    PersonalSchema,
    JournilingSchema,
    habitFormSchema,
  ];
  const { search, state } = useLocation();
  const paramEmaill = search.split("=")[1];

  //states
  const [formStep, setFormStep] = useLocalStorage("signupStep", 0);

  // react-hook-form
  const currentValidationSchema = validationSchema[formStep];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
  });
  const { handleSubmit, watch: signupFormCredentials, setError } = methods;

  //queries
  const { mutate: SignupUser } = SignupUserQuery(signupFormCredentials);
  const { mutateAsync: VerifyOtp } = ResendOtpQuery();
  const { mutateAsync: AnswerWhyHabitbetter } =
    useAnswerWhyHabitbetterMutation();
  const { mutateAsync: PostCheckInData } = useCheckInPostMutation();
  const { mutateAsync: PostJournalingData } = useJournalingPostMutation();
  const { mutateAsync: PostHabitData, isLoading } = useHabitPostMutation();

  //handlers
  const submitSignupForm = (formData) => {
    if (formStep === 0) {
      SignupUser(formData, {
        onSuccess: () => {
          setFormStep(1);
        },
        onError: (error) => {
          if (error.errors?.email) {
            setError("email", {
              type: "custom",
              message: error.errors?.email?.[0],
            });
          }
        },
      });
    } else if (formStep === 1) {
      VerifyOtp(
        { email: formData.email || paramEmaill, otp: parseInt(formData?.otp) },
        {
          onSuccess: () => {
            setFormStep(2);
          },
          onError: (error) => {
            setError("otp", {
              type: "custom",
              message: error?.message,
            });
          },
        }
      );
    } else if (formStep === 2) {
      setFormStep(3);
    } else if (formStep === 3) {
      let tempArray = formData?.selectedCheckbox?.filter(
        (it) => it !== "Something else.."
      );
      if (formData.isSomethingElseSelected) {
        tempArray = [...tempArray, formData.somethingElse];
      }
      AnswerWhyHabitbetter(
        { why: tempArray },
        {
          onSuccess: () => {
            setFormStep(4);
          },
        }
      );
    } else if (formStep === 4) {
      setFormStep(5);
    } else if (formStep === 5) {
      setFormStep(6);
    } else if (formStep === 6) {
      let reqBody = {
        personal: {
          satisfaction: 0,
          stress: 0,
          confidence: 0,
          growth: 0,
        },
        social: {
          satisfaction: 0,
          stress: 0,
          confidence: 0,
          growth: 0,
        },
        professional: {
          satisfaction: 0,
          stress: 0,
          confidence: 0,
          growth: 0,
        },
      };
      if (!formData.isSkipProfessional) {
        reqBody.personal = formData.personal;
      }
      if (!formData.isSkipSocial) {
        reqBody.social = formData.social;
      }
      if (!formData.isSkipPersonal) {
        reqBody.professional = formData.professional;
      }
      PostCheckInData(reqBody, {
        onSuccess: () => {
          setFormStep(7);
        },
        onError: () => {
          PostCheckInData(reqBody, {
            onSuccess: () => {
              setFormStep(7);
            },
          });
        },
      });
    } else if (formStep === 7) {
      const journalData = {
        name: "personal",
        is_favourite: +formData.addFavourite,
        description: formData.journiling,
        shared_to_coach: 0,
        tags:
          formData?.stringTags?.length > 0
            ? formData?.stringTags?.map((it) => it.id)
            : null,
        images:
          formData?.imageTags?.length > 0
            ? formData?.imageTags?.map((it) => it.url)
            : null,
      };
      if (!journalData.images) {
        delete journalData.images;
      }
      PostJournalingData(journalData, {
        onSuccess: () => {
          setFormStep(8);
        },
      });
    } else if (formStep === 8) {
      const habitReq = {
        name: formData.habitName,
        category_id: formData.habit_category_id,
        ...(formData.habitStartDate && {
          start_date: format(new Date(formData?.habitStartDate), "yyyy-MM-dd"),
        }),
        color: formData.selectHabitColor,
        period_type: formData.frequencyType,
        frequency: formData.frequency,
        ...(formData?.frequencyType === "weekly" && {
          frequency_payload: formData.days
            .filter((day) => day?.selected)
            .map((day) => day?.value),
        }),
        notification: formData.notifications ? 1 : 0,
        notification_timings: formData?.scheduleNotificationsSet?.map(
          (notification) => {
            return {
              time: format(notification?.time, "HH:mm:ss"),
              day: notification?.day,
            };
          }
        ),
        streak_target: formData?.streakAndGoalsTarget,
        ...(formData.whyTrack && { why: formData.whyTrack }),
        ...(formData.habitEndDate && {
          end_date: format(new Date(formData.habitEndDate), "yyyy-MM-dd"),
        }),
        goal: +formData.goal,
        shared_to_coach: 0,
        public: 0,
        tags:
          formData.stringTagsHabit?.length > 0
            ? formData.stringTagsHabit?.map((it) => it.id)
            : null,
      };
      PostHabitData(habitReq, {
        onSuccess: () => {
          setFormStep(9);
        },
      });
    }
  };

  const getUiForCurrentStep = (step) => {
    switch (step) {
      case 0:
        return <SignupFormMain setFormStep={setFormStep} />;
      case 1:
        return <VerifyOtpForm setFormStep={setFormStep} isLogin={false} />;
      case 2:
        return <LetsStart setFormStep={setFormStep} />;
      case 3:
        return <DecideToHabitbetter setFormStep={setFormStep} />;
      case 4:
        return <Professional setFormStep={setFormStep} />;
      case 5:
        return <Social setFormStep={setFormStep} />;
      case 6:
        return <Personal setFormStep={setFormStep} />;
      case 7:
        return <Journaling setFormStep={setFormStep} />;
      case 8:
        return <YourHabits setFormStep={setFormStep} />;
      case 9:
        return <KeepTryingNewThings setFormStep={setFormStep} />;
      default:
        return;
    }
  };

  useEffect(() => {
    return () => setFormStep(0);
  }, []);

  useEffect(() => {
    if (paramEmaill) {
      setFormStep(1);
    }
  }, [paramEmaill]);

  useEffect(() => {
    if (state?.navIndex) {
      setFormStep(state?.navIndex);
    }
  }, [state]);

  return (
    <FormProvider {...methods}>
      <form
        id="signup-form"
        noValidate
        onSubmit={handleSubmit(submitSignupForm)}
      >
        {getUiForCurrentStep(formStep)}
      </form>
    </FormProvider>
  );
};

/* Step-0 */
const SignupFormMain = (props) => {
  const { setFormStep } = props;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const runningAPICount = useIsFetching();
  const mutatingAPICount = useIsMutating();
  const {
    register,
    formState: { errors },
    setFocus,
  } = useFormContext();

  //useEffects
  useEffect(() => {
    setFocus("name");
  }, [setFocus]);
  useEffect(() => {
    localStorage.setItem("otp-timer-signup", 60);
  }, []);

  const { ref: refRegisterName, ...RegisterSignupName } = register("name");
  const { ref: refRegisterEmail, ...RegisterSignupEmail } = register("email");
  const { ref: refRegisterPassword, ...RegisterSignupPassword } =
    register("password");

  //quries
  const { mutateAsync: postSocial } = useSocialLoginQuery();

  const googleLogin = () => {
    signInWithPopup(auth, gProvider).then((data) => {
      postSocial(
        {
          params: {
            // uuid: route?.params?.user_id,
            // habit_id: route?.params?.habit_id,
          },
          social: {
            email: data.user?.email,
            name: data.user?.displayName,
            provider: "google",
            provider_id: data.user.uid,
            device_token: uuidv4(),
          },
        },
        {
          onSuccess: (data) => {
            if (data?.data?.new) {
              // navigate(routes.SIGN_UP.pathname, { state: { navIndex: 2 } });
              setFormStep(2);
            } else {
              navigate("/profile/info");
            }
          },
        }
      );
    });
  };

  return (
    <>
      <div className="sm:w-5/6 md:w-4/5 lg:w-3/4">
        <Typography
          variant="h3"
          fontWeight={700}
          marginTop={"20%"}
          sx={{ color: "#333333" }}
          className={isSmallScreen ? "hidden" : ""}
        >
          Sign up
        </Typography>
        <Button
          variant="outlined"
          className="w-full mt-8 text-base text font-semibold"
          sx={{ display: isSmallScreen ? "none" : "" }}
          onClick={googleLogin}
        >
          <img
            src={GoogleIcon}
            alt="google-logo"
            style={{ paddingRight: 5, height: 24, width: 24 }}
          />
          Continue with Google
        </Button>
        {/* <Button
          variant="contained"
          sx={{ display: isSmallScreen ? "none" : "" }}
          className="w-full mt-4 bg-hb_facebook-900 text-white text-base text font-semibold"
        >
          <img
            src={FacebookIcon}
            alt="facebook-logo"
            style={{ paddingRight: 5, height: 24, width: 24 }}
          />
          Continue with Facebook
        </Button> */}
        {/* Header */}
        <div className="sm:hidden flex flex-row mt-8 justify-between">
          <Button onClick={() => navigate(-1)} className="py-0">
            <ArrowBackIosIcon sx={{ color: "#234266" }} />
          </Button>
          <p className="text-hb_blue-700 font-semibold text-xl">Sign Up</p>
          <ArrowBackIosIcon sx={{ color: "transparent", marginRight: 5 }} />
        </div>
        <Divider className="sm:hidden my-2 -mx-3" />
        {/* Body */}
        <div className="sm:flex flex-col">
          <Typography
            variant="subtitle1"
            fontWeight={400}
            marginY={"3%"}
            sx={{
              color: "#333333",
              textAlign: "center",
              display: isSmallScreen ? "none" : "",
            }}
          >
            or
          </Typography>
          <div className="mb-4 flex w-full justify-center flex-col">
            <Grid container>
              <Grid item xs={12} className="my-2">
                <span className="text-sm font-semibold text-gray-600">
                  Name
                </span>
                <CustomTextField
                  variant={"outlined"}
                  required
                  label=""
                  placeholder="Enter Name"
                  type="text"
                  ref={refRegisterName}
                  {...RegisterSignupName}
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                  autoComplete="name"
                />
              </Grid>
              <Grid item xs={12} className="my-4">
                <span className="text-sm font-semibold text-gray-600">
                  Email
                </span>
                <CustomTextField
                  variant={"outlined"}
                  required
                  label=""
                  placeholder="Enter Email ID"
                  type="email"
                  ref={refRegisterEmail}
                  {...RegisterSignupEmail}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} className="my-4">
                <span className="text-sm font-semibold text-gray-600">
                  Password
                </span>
                <PasswordField
                  variant={"outlined"}
                  required
                  label=""
                  placeholder="Enter Password"
                  type="password"
                  endAdornment
                  ref={refRegisterPassword}
                  {...RegisterSignupPassword}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  autoComplete="password"
                />
              </Grid>
            </Grid>
            <Button
              form="signup-form"
              type="submit"
              className="w-full bg-hb_orange-dark mt-4"
              variant="contained"
              sx={{ background: "#FF6C44", color: "white" }}
              disabled={runningAPICount || mutatingAPICount}
            >
              Sign up
            </Button>
          </div>
          <div className="my-6 flex items-center justify-center">
            <p className="text-sm text-hb_gray-600 underline underline-offset-2">
              Already User?
            </p>
            <Button
              sx={{ color: "#FF6C44" }}
              className="text-sm underline underline-offset-2"
              onClick={() => navigate(routes.LOGIN.pathname)}
            >
              Login
            </Button>
          </div>
        </div>
        <div className="hidden sm:mt-4 sm:flex sm:items-center sm:justify-center ">
          <p className="text-sm text-hb_gray-600 text-center">
            By continuing, you agree to CareBetter's
            <button className="text-sm h-4 text-hb_orange-dark mb-1 ml-1 text-center">
              <a href="https://habitbetter.com/terms-of-use/" target="_/blank">
                {" "}
                Terms & Conditions{" "}
              </a>
            </button>{" "}
          </p>
        </div>
        <div className="hidden sm:flex sm:items-center sm:justify-center pb-8">
          <p className="text-sm text-hb_gray-600 text-center">
            and acknowledge the CareBetter
            <button className="text-sm h-4 text-hb_orange-dark mb-1 ml-1 text-center">
              <a
                href="https://habitbetter.com/privacy-policy/"
                target="_/blank"
              >
                Privacy Policy
              </a>
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignupForm;
