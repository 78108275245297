import { Avatar, Button, Grid, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import MobileHeader from "components/MobileHeader";
import GreenCheckIcon from "assets/Icons/GreenCheckIcon.svg";
import { useNavigate } from "react-router-dom";
import { ToggleSliderTabs } from "components";
import { routes } from "Routes/RouteConstants";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  GetSubscriptionPlansAPI,
  useGetSubscribedPlanAPI,
} from "queries/Subscription";
import PromotionCodeModal from "./PromotionCodeModal";

const SubscriptionScreen = () => {
  const navigate = useNavigate();

  //use state
  const [selectedTab, setSelectedTab] = useState();
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);

  const [selectedPromotionCard, SetselectedPromotionCard] = useState(false);
  //handlers
  const handlePromotionCodeModalOpen = () => {
    SetselectedPromotionCard(true);
  };
  const handlePromotionCodeModalClose = () => {
    SetselectedPromotionCard(false);
  };
  const hanldeDeletePromoCode = () => {
    setSelectedPromoCode(null);
  };

  //quries
  const { data, dataUpdatedAt } = GetSubscriptionPlansAPI();
  // const {
  //   data: subscribedPlanFetched,
  //   dataUpdatedAt: subscribedPlanDataUpdatedAt,
  // } = useGetSubscribedPlanAPI();

  // //Use useMemo
  // const { subscribedPlanData } = useMemo(() => {
  //   if (subscribedPlanDataUpdatedAt) {
  //     return {
  //       subscribedPlanData: subscribedPlanFetched?.data,
  //     };
  //   } else {
  //     return { subscribedPlanData: {} };
  //   }
  // }, [subscribedPlanDataUpdatedAt]);

  const { subscriptionPlanData } = useMemo(() => {
    if (dataUpdatedAt) {
      setSelectedTab(
        data?.data?.prices?.map(
          (item) => `$${item.unit_amount}/${item.interval}`
        )[1]
      );
      return {
        subscriptionPlanData: data?.data,
      };
    } else {
      return { subscriptionPlanData: {} };
    }
  }, [dataUpdatedAt]);

  const renderTypography = (text) => {
    return (
      <div className="flex flex-row items-center gap-2 capitalize text-sm font-normal my-4">
        <img
          src={GreenCheckIcon}
          alt="GreenCheckIcon"
          style={{ height: 20, width: 20 }}
        />
        <p>{text}</p>
      </div>
    );
  };

  return (
    <div className="px-4 w-full mt-4 sm:pl-8 sm:mt-8">
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Subsciption"
      />
      <p className="text-base font-medium">Subscription Plan</p>
      <div className="flex flex-col w-full items-center justify-center mt-5">
        <p className="text-lg font-semibold">In Subscription Plan You Will</p>
        <p className="text-base font-normal my-2">Get Access to:</p>
        <div>
          {Object.values(subscriptionPlanData?.metadata ?? {})?.map((it) =>
            renderTypography(it)
          )}
        </div>
        <Grid container xs={12} md={4} className="mt-5">
          <ToggleSliderTabs
            options={subscriptionPlanData?.prices?.map(
              (item) => `$${item.unit_amount}/${item.interval}`
            )}
            value={selectedTab}
            setSelectedItem={(currentTab) => {
              setSelectedTab(currentTab);
            }}
          />
        </Grid>
        <div className="flex w-full sm:w-2/5 flex-row items-center justify-between text-sm font-normal my-4">
          <button className="text-sm h-4 text-hb_orange-dark mb-1 ml-1 text-center underline">
            <a href="https://habitbetter.com/privacy-policy/" target="_/blank">
              Privacy Policy
            </a>
          </button>
          <button className="text-sm h-4 text-hb_orange-dark mb-1 ml-1 text-center underline">
            <a href="https://habitbetter.com/terms-of-use/" target="_/blank">
              Terms of Use
            </a>
          </button>
        </div>
        {/* {!currentData && ( */}
        <div className="flex cursor-pointer items-center justify-center">
          {selectedPromoCode ? (
            <div
              className="flex items-center justify-center font-semibold"
              style={{ color: "#097969" }}
            >
              <p>{`${selectedPromoCode} Coupon Applied`}</p>
              <IconButton
                aria-label="close"
                onClick={hanldeDeletePromoCode}
                // onDelete={hanldeDeletePromoCode}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <p
              className="text-hb_blue-800"
              onClick={() => handlePromotionCodeModalOpen()}
            >
              Do You have any offer code ?
            </p>
          )}
        </div>
        {/* )} */}
        <Button
          className="sm:w-2/5 w-full bg-hb_orange-dark mt-4"
          variant="contained"
          onClick={() =>
            navigate(routes.PAYMENT_METHOD.pathname, {
              state: {
                planData: subscriptionPlanData?.prices?.find(
                  (item) =>
                    `$${item.unit_amount}/${item.interval}` === selectedTab
                ),
              },
            })
          }
          sx={{ background: "#FF6C44", color: "white" }}
        >
          Buy Now
        </Button>
      </div>
      {/* Modal */}
      <PromotionCodeModal
        open={selectedPromotionCard}
        handleClose={handlePromotionCodeModalClose}
        product={subscriptionPlanData}
        setSelectedPromoCode={setSelectedPromoCode}
      />
    </div>
  );
};

export default SubscriptionScreen;
