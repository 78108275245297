import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { ToggleSliderTabs } from "components";
import SearchBar from "components/SearchBar/SearchBar";
import TopNav from "components/TopNav";
import BarGraphIcon from "assets/Icons/barGraph.svg";
import BarChartIcon from "assets/Icons/barChart.svg";
import SettingWhiteIcon from "assets/Icons/settingWhite.svg";
import React, { Fragment, useEffect, useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import SwipeableEdgeDrawer from "components/Filter";
import CheckInScreen from "./components/checkIn";
import { useLocation, useNavigate } from "react-router-dom";
import SettingOrangeIcon from "assets/Icons/settingOrange.svg";
import { routes } from "Routes/RouteConstants";
import MyGoalsScreen from "./components/myGoals";
import { useMyGoalsQuery } from "queries/Goals";
import { useDebounce } from "hooks/useDebounce";
import { useLocalStorage } from "hooks";

const GoalsScreen = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { state } = useLocation();
  //use states
  const [searchGoals, setSearchGoals] = useState("");
  const [selectedTab, setSelectedTab] = useLocalStorage(
    "Goals-Tab",
    state?.selectedTab ?? "My Goals"
  );
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [myGoalList, setMyGoalList] = useState([]);
  const [filterData, setFilterData] = useState({
    tags: [],
    categories: [],
    type: [],
  });

  const debText = useDebounce(searchGoals, 1000);
  //queries
  const { data, dataUpdatedAt, isFetching, isLoading } = useMyGoalsQuery({
    page: page,
    perpage: 20,
    q: debText.length > 0 ? debText : null,
    tags:
      filterData?.tags?.length > 0
        ? filterData?.tags?.map((el) => el.id)
        : null,
  });

  useEffect(() => {
    if (dataUpdatedAt) {
      if (page === 1 && data) {
        setMyGoalList(data?.data?.data);
      } else {
        setMyGoalList([...myGoalList, ...data?.data?.data]);
      }
    }
  }, [data, dataUpdatedAt]);

  const handleFilter = (data) => {
    setFilterData(data);
    setOpen(false);
  };

  return (
    <Fragment>
      {isSmallScreen && (
        <TopNav
          children={
            <div className="flex flex-col items-center -mt-8 mb-14">
              <p className="text-white text-xl font-semibold mr-3">Goals</p>
              <Grid container xs={10} className="mt-4">
                <ToggleSliderTabs
                  options={["My Goals", "Check-In"]}
                  value={selectedTab}
                  setSelectedItem={(currentTab) => {
                    setSelectedTab(currentTab);
                  }}
                  iconList={[
                    null,
                    null,
                    <SearchIcon
                      className={
                        selectedTab === "Courses"
                          ? "text-hb_blue-700"
                          : "text-hb_white-100"
                      }
                    />,
                  ]}
                  className="bg-hb_white-100 text-xl !important"
                />
              </Grid>
            </div>
          }
        />
      )}
      <Grid container className="hidden sm:flex flex-row mt-6 pl-8">
        <Grid item xs={7}>
          <ToggleSliderTabs
            options={["My Goals", "Check-In"]}
            value={selectedTab}
            setSelectedItem={(currentTab) => {
              setSelectedTab(currentTab);
            }}
            iconList={[
              null,
              null,
              <SearchIcon
                className={
                  selectedTab === "Courses" ? "text-hb_blue-700" : "text-white"
                }
              />,
            ]}
          />
        </Grid>
        {selectedTab === "Check-In" && (
          <Grid item>
            <IconButton
              sx={{ minWidth: 0, minHeight: 0, height: 45, width: 45 }}
              className="rounded-full shadow-lg border ml-4"
              onClick={() => navigate(routes.HABITS.pathname)}
            >
              <img src={BarChartIcon} alt="BarChartIcon" />
            </IconButton>
            <IconButton
              sx={{ minWidth: 0, minHeight: 0, height: 45, width: 45 }}
              className="rounded-full shadow-lg ml-4"
              onClick={() =>
                navigate(routes.HABITS.pathname, {
                  state: {
                    openCheckInModal: true,
                  },
                })
              }
            >
              <img src={SettingOrangeIcon} alt="SettingOrangeIcon" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <div className="">
        {selectedTab === "Check-In" ? (
          <div className="flex justify-center -mt-12 sm:mt-2 bg-hb_blue-700 sm:hidden">
            <IconButton onClick={() => navigate(routes.HABITS.pathname)}>
              <img
                src={BarGraphIcon}
                alt="BarGraphIcon"
                style={{ height: 35, width: 35 }}
              />
            </IconButton>
            <IconButton
              onClick={() =>
                navigate(routes.HABITS.pathname, {
                  state: {
                    openCheckInModal: true,
                  },
                })
              }
            >
              <img
                src={SettingWhiteIcon}
                alt="SettingWhiteIcon"
                style={{ height: 35, width: 35 }}
              />
            </IconButton>
          </div>
        ) : (
          <SearchBar
            className="-mt-6 sm:absolute sm:mx-3 sm:w-96 sm:right-0 sm:top-32"
            value={searchGoals}
            onChange={(e) => setSearchGoals(e.target.value)}
            onClickFilter={() => setOpen(true)}
          />
        )}
      </div>
      {selectedTab === "Check-In" ? (
        <CheckInScreen isSmallScreen={isSmallScreen} />
      ) : (
        <>
          <MyGoalsScreen
            myGoalList={myGoalList}
            loading={isFetching || isLoading}
            setPage={setPage}
            page={page}
            fetchedMyGoalData={data}
          />
        </>
      )}
      <SwipeableEdgeDrawer
        open={open}
        handleClose={() => setOpen(false)}
        filters={["tags"]}
        onClickDone={handleFilter}
      />
    </Fragment>
  );
};

export default GoalsScreen;
