import * as React from "react";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Slider,
  TextField,
  Tooltip,
} from "@mui/material";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import { useNavigate, useLocation } from "react-router-dom";
import CalenderIcon from "assets/Icons/calender.svg";
import RefreshIcon from "assets/Icons/refresh.svg";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MobileHeader from "components/MobileHeader";
import {
  ConfirmDialog,
  CustomTextField,
  ToggleButton,
  ToggleSliderTabs,
} from "components";
import { useState } from "react";
import "./index.css";
import TagListModal from "pages/SignupPage/modals/tagListModal";
import { useCreateGoalMutation } from "queries/Goals";
import moment from "moment";
import { routes } from "Routes/RouteConstants";
import { useEffect } from "react";
import { useProfileQuery } from "queries/Profile";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];
const CustomSchema = yup.object().shape(
  {
    title: yup
      .string()
      .trim()
      .required("Please write something.")
      .max(25, "Word limit exceeds"),
    notes: yup
      .string()
      .trim()
      .required("Please write something.")
      .min(13, "Please write atleast 13 character.")
      .max(800, "Word limit exceeds"),
    goalEndDate: yup.string().required("Please select goal end date."),
    shareWithCoach: yup.boolean().default(false),
    stringTags: yup.array(),
  },
  []
);

export default function CreateGoalScreen(props) {
  const navigate = useNavigate();
  const { state } = useLocation();

  //usestates
  const [showModal, setShowModal] = useState(false);
  const [selectedStringTags, setSelectedStringTags] = useState([]);
  const [linkedHabits, setLinkedHabits] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Manually");
  const [shareWithCoach, setShareWithCoach] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  const handleChange = (event, newValue) => {
    setProgressValue(newValue);
  };

  //use forms
  const {
    setValue,
    getValues,
    formState: { errors, isValid },
    register,
    trigger,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(CustomSchema),
  });

  const { title, notes, goalEndDate } = watch();
  //render
  const { ref: refTitle, ...RegisterTitle } = register("title");
  const { ref: refNotes, ...RegisterNotes } = register("notes");

  //queries
  const { mutateAsync: postGoal } = useCreateGoalMutation();
  const { data: fetchedProfile } = useProfileQuery();

  //useeffects
  useEffect(() => {
    if (state?.item?.id) {
      setValue("title", state?.item?.name);
      setValue("notes", state?.item?.description);
      setValue("goalEndDate", state?.item?.date);
      setValue("stringTags", state?.item?.tags, { shouldValidate: true });
      setProgressValue(state?.item?.progress);
      setShareWithCoach(state?.item?.shared_coach ? true : false);
      setSelectedTab(state?.item?.manual ? "Manually" : "Habits Logged");
      setSelectedStringTags(state?.item?.tags);
      setLinkedHabits(state?.item?.habits);
    }
  }, [state?.item?.id]);

  useEffect(() => {
    if (state?.linkedHabit?.length) {
      setLinkedHabits(state?.linkedHabit);
      setSelectedTab("Habits Logged");
    }
  }, [state?.linkedHabit]);

  function valueText(value) {
    return `${value}`;
  }
  const handleCloseModal = () => {
    return setShowModal(false);
  };

  const handleSetStringTags = (data) => {
    setSelectedStringTags(data);
    setValue("stringTags", data, { shouldValidate: true });
    setShowModal(false);
  };
  console.log("notes", getValues("notes")?.length);
  return (
    <Fragment>
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Create Goal"
      />
      <Grid
        container
        className="sm:pl-6 sm:mt-8 sm:px-4 gap-4"
        sx={{ paddingX: "16px" }}
      >
        <Grid item md={5} sm={10} xs={12}>
          <Grid>
            <div className="text-xs font-medium mb-3">Enter Title</div>
            <CustomTextField
              variant={"outlined"}
              required
              label=""
              ref={refTitle}
              {...RegisterTitle}
              placeholder="Write Here..."
              type="text"
              error={Boolean(errors.title)}
              helperText={errors.title && errors.title.message}
            />
          </Grid>
          <Grid>
            <div className="text-xs font-medium my-3">Enter End Date</div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                placeholder="YYYY-MM-DD"
                value={
                  getValues("goalEndDate") ? getValues("goalEndDate") : null
                }
                onChange={(newValue) => {
                  setValue("goalEndDate", newValue, {
                    shouldValidate: true,
                  });
                }}
                components={{
                  openPickerIcon: (
                    <img
                      alt="CalenderIcon"
                      style={{ height: 20, width: 23 }}
                      src={CalenderIcon}
                    />
                  ),
                }}
                error={Boolean(errors.goalEndDate)}
                disablePast={true}
                helperText={errors.goalEndDate && errors.goalEndDate.message}
                sx={{
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="MM/DD/YYYY"
                    sx={{
                      width: "100%",
                    }}
                    error={Boolean(errors.goalEndDate)}
                    helperText={
                      errors.goalEndDate && errors.goalEndDate.message
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid>
            <div className="text-xs font-medium my-3">Write Goal</div>
            <CustomTextField
              variant={"outlined"}
              required
              label=""
              ref={refNotes}
              {...RegisterNotes}
              InputLabelProps={{
                maxLength: 800,
              }}
              multiline
              rows={6}
              placeholder="Write out your goal in a sentence. Be sure it is specific, measurable, accountable and realistic."
              type="text"
              error={Boolean(errors.notes)}
              helperText={errors.notes && errors.notes.message}
            />
          </Grid>
          {Boolean(selectedStringTags?.length) && (
            <Grid item xs={11} md={8} justifySelf={"center"}>
              <p className="font-semibold py-2">Tags</p>
              <div className="flex flex-wrap gap-3 flex-row">
                {selectedStringTags?.map((it, idx) => (
                  <div className="items-center">
                    <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                      {it.name}
                      <Button
                        onClick={() => {
                          setSelectedStringTags((prev) => {
                            const elem = [...prev];
                            elem.splice(idx, 1);
                            setValue("stringTags", elem, {
                              shouldValidate: true,
                            });

                            return elem;
                          });
                        }}
                        sx={{
                          padding: 0,
                          margin: 0,
                          minWidth: 0,
                          paddingBottom: 0.2,
                        }}
                      >
                        <CancelIcon sx={{ color: "white" }} fontSize="small" />
                      </Button>
                    </p>
                  </div>
                ))}
              </div>
            </Grid>
          )}
          <button
            className="flex sm:flex-row flex-col sm:w-[50%] w-full sm:gap-4 mt-5 text-xs rounded-xl p-0.5 justify-center  sm:border sm:border-hb_orange-dark items-center text-gray-900"
            onClick={() => setShowModal(true)}
          >
            <img
              alt="add tags"
              src={CirclePlusIcon}
              style={{ height: 30, width: 30, margin: 5 }}
            />
            <p>Add Tags</p>
          </button>
          <FormHelperText error={Boolean(errors.stringTags)} margin="dense">
            {errors.stringTags && errors.stringTags.message}
          </FormHelperText>
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <div className="mt-6 flex flex-col sm:ml-4 md:ml-12">
            <div className="text-sm font-medium sm:text-left text-center sm:text-base sm:font-semibold text-black">
              How will you measure progress?
            </div>
            <div className="mt-4 sm:w-[82%]">
              <ToggleSliderTabs
                options={["Manually", "Habits Logged"]}
                value={selectedTab}
                setSelectedItem={(currentTab) => {
                  setSelectedTab(currentTab);
                }}
              />
            </div>
            {selectedTab === "Manually" ? (
              <div className="mt-8">
                <div className="bg-[#F7F7F7] flex flex-col p-4 rounded-xl">
                  <Slider
                    className="sx:m-6 my-2"
                    aria-label="Custom marks"
                    defaultValue={20}
                    value={progressValue}
                    onChange={handleChange}
                    getAriaValueText={valueText}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    color="green"
                  />
                  <p className="font-medium mt-2 text-gray-700">
                    Progress: {progressValue}
                  </p>
                </div>
              </div>
            ) : (
              <div className="mt-8 justify-center flex flex-col w-full">
                <IconButton
                  className="gap-4 text-hb_orange-dark hover:bg-transparent text-lg font-bold"
                  onClick={() =>
                    navigate(routes.LINKED_HABIT.pathname, {
                      state: {
                        ...state,
                        linkedHabits,
                      },
                    })
                  }
                >
                  <img
                    alt="RefreshIcon"
                    style={{ height: 35, width: 35 }}
                    src={RefreshIcon}
                  />
                  Linked Habits
                </IconButton>
                <div className="flex flex-wrap gap-3 flex-row mt-4">
                  {linkedHabits?.map((it, idx) => (
                    <div className="items-center">
                      <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                        {it.name}
                        <Button
                          onClick={() => {
                            setLinkedHabits((prev) => {
                              const elem = [...prev];
                              elem.splice(idx, 1);
                              return elem;
                            });
                          }}
                          sx={{
                            padding: 0,
                            margin: 0,
                            minWidth: 0,
                            paddingBottom: 0.2,
                          }}
                        >
                          <CancelIcon
                            sx={{ color: "white" }}
                            fontSize="small"
                          />
                        </Button>
                      </p>
                    </div>
                  ))}
                  {!Boolean(linkedHabits?.length) && (
                    <FormHelperText className="w-full text-center" error={true}>
                      Please link at least one Habit.
                    </FormHelperText>
                  )}
                </div>
              </div>
            )}

            <div className=" bg-[#F7F7F7] rounded-xl p-4 mt-8 sm:max-w-screen-sm">
              <div className="flex items-center">
                <p className="text-base text-gray-700">
                  Share with Coach/Therapist?
                </p>
                <Tooltip
                  title="Share this goal with your coach"
                  enterTouchDelay={0}
                >
                  <IconButton>
                    <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
                  </IconButton>
                </Tooltip>
                <div className="ml-auto">
                  <ToggleButton
                    checked={shareWithCoach}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setShareWithCoach(true);
                      } else {
                        setShareWithCoach(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full sm:justify-end my-[20%]">
            <Button
              variant="contained"
              className="bg-hb_orange-dark h-12 sm:w-[70%] w-full flex"
              onClick={() => {
                if (fetchedProfile?.data?.subscribed === 0) {
                  return setShowSubscribeModal(true);
                }
                if (!isValid) {
                  return trigger();
                }
                postGoal(
                  {
                    goal_id: state?.item?.id ?? null,
                    name: title,
                    description: notes,
                    shared_coach: shareWithCoach ? 1 : 0,
                    manual: selectedTab === "Manually" ? 1 : 0,
                    progress:
                      selectedTab === "Manually" ? Number(progressValue) : null,
                    date: moment(goalEndDate).format("YYYY-MM-DD"),
                    tags: selectedStringTags?.length
                      ? selectedStringTags.map((el) => el.id)
                      : null,
                    linked_habit: selectedTab === "Habits Logged" ? 1 : 0,
                    habits: linkedHabits.map((el) => {
                      return {
                        habit_id: el.id,
                        streak_target: el.streak_target,
                      };
                    }),
                  },
                  {
                    onSuccess: () => {
                      navigate(routes.GOALS.pathname);
                    },
                  }
                ).catch((e) => {
                  if (e.errors?.date?.[0]) {
                    setError("goalEndDate", {
                      type: "custom",
                      message: e?.errors?.date[0],
                    });
                  }
                });
              }}
            >
              {state?.item?.id ? "Update" : "Done"}
            </Button>
          </div>
        </Grid>
      </Grid>

      {showModal && (
        <TagListModal
          open={showModal}
          handleClose={handleCloseModal}
          selectedStringTags={selectedStringTags}
          handleSetStringTags={handleSetStringTags}
        />
      )}
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base mt-0">CareBetter</p>
            <p className="font-normal text-base my-6 text-center">
              Please subscribe first!
            </p>
          </div>
        }
        open={showSubscribeModal}
        handleClose={() => setShowSubscribeModal(false)}
        continueEdit={() => setShowSubscribeModal(false)}
      />
    </Fragment>
  );
}
