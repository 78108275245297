import { Button, Grid, IconButton } from "@mui/material";
import { useAllCoursesQuery, usePostSelectMutation } from "queries/Courses";
import React, { Fragment, useMemo, useState } from "react";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import { ConfirmDialog } from "components";
import { routes } from "Routes/RouteConstants";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import SearchBar from "components/SearchBar/SearchBar";
import meditationIcon from "assets/Icons/meditation.svg";
import SwipeableEdgeDrawer from "components/Filter";
import { useProfileQuery } from "queries/Profile";

const Courses = (props) => {
  const navigate = useNavigate();
  //use states
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [searchCourses, setSearchCourses] = useState(null);
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    tags: [],
    categories: [],
    type: [],
  });

  const debText = useDebounce(searchCourses, 1000);

  //queries
  const { data, dataUpdatedAt } = useAllCoursesQuery({
    q: debText?.length > 0 ? debText : null,
    tags:
      filterData?.tags?.length > 0 ? filterData?.tags.map((el) => el.id) : null,
    category:
      filterData?.categories?.length > 0
        ? filterData.categories?.map((el) => el.id)
        : null,
  });
  const { mutateAsync: selectCourse } = usePostSelectMutation();
  const { data: fetchedProfile } = useProfileQuery();

  //Use useMemo
  const { completeCourses } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        completeCourses: data?.data,
      };
    } else {
      return { completeCourses: [] };
    }
  }, [dataUpdatedAt]);

  //handler

  const addToMyCourses = (item) => {
    selectCourse(
      {
        course_id: item.id,
        coach_id: item.coach_id,
      },
      {
        onSuccess: () => {
          props.setSelectedTab("My Courses");
        },
        onError: (error) => {
          // console.log(error);
          // window.alert(error?.message);
        },
      }
    ).catch((e) => {
      console.log("e", e);
      setShowConfirmModal(true);
    });
  };

  //render
  const renderCourseCard = (item) => {
    return (
      <div
        className="flex flex-row items-center justify-between p-3 rounded-lg my-3 shadow-lg border"
        onClick={() => {
          if (fetchedProfile?.data?.subscribed === 0) {
            return setShowSubscribeModal(true);
          }
          navigate(routes.NEW_COURSES.pathname, {
            state: {
              course: item,
            },
          });
        }}
      >
        <div className="flex flex-row items-center">
          <img
            src={item?.course_images?.[0]?.image ?? meditationIcon}
            alt="goalCompleteIcon"
            style={{
              height: 42,
              width: 42,
              marginRight: 10,
              borderRadius: 42,
            }}
          />
          <div className="flex flex-col">
            <p className="capitalize text-base font-semibold text-hb_blue-700">
              {item?.title}
            </p>
            {Boolean(item?.days_in_course) && (
              <p className="capitalize text-sm font-normal text-hb_orange-dark my-1">
                <WatchLaterIcon className="text-gray-400 text-xl mr-1 mb-0.5" />
                {item?.days_in_course + " days"}
              </p>
            )}
          </div>
        </div>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            addToMyCourses(item);
          }}
        >
          <img
            alt="CirclePlusIcon"
            style={{ height: 30, width: 30 }}
            src={CirclePlusIcon}
          />
        </IconButton>
      </div>
    );
  };

  const handleFilter = (data) => {
    setFilterData(data);
    setOpen(false);
  };

  return (
    <Fragment>
      <SearchBar
        className="-mt-6 sm:absolute sm:mx-3 sm:w-96 sm:right-0 sm:top-32"
        value={searchCourses}
        onChange={(e) => setSearchCourses(e.target.value)}
        onClickFilter={() => setOpen(true)}
      />
      <Grid container xs={12} className="px-4 mb-20">
        <Grid
          item
          className="flex-col w-full sm:m-6 border-gray-100 flex sm:my-0"
        >
          {Object.entries(completeCourses)?.map(([key, value]) => {
            return (
              <div className="w-full mt-4">
                <div className="text-hb_blue-700 w-full">
                  <p className="text-base font-medium">{key}</p>
                </div>
                {value?.map((item) => renderCourseCard(item))}
              </div>
            );
          })}
        </Grid>
      </Grid>
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base mt-0">Error</p>
            <p className="font-normal text-base my-6 text-center">
              Course is already taken or not completed!
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        continueEdit={() => setShowConfirmModal(false)}
      />
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base mt-0">CareBetter</p>
            <p className="font-normal text-base my-6 text-center">
              Please subscribe first to access this course
            </p>
          </div>
        }
        open={showSubscribeModal}
        handleClose={() => setShowSubscribeModal(false)}
        continueEdit={() => setShowSubscribeModal(false)}
      />
      <SwipeableEdgeDrawer
        open={open}
        handleClose={() => setOpen(false)}
        onClickDone={handleFilter}
        filters={["tags", "categories"]}
      />
    </Fragment>
  );
};

export default Courses;
