import { Button, Grid, Typography } from "@mui/material";
import { CustomTextField } from "components";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const SendOtp = (props) => {
  const { setFormStep } = props;

  //react-hook-form
  const {
    register,
    formState: { errors },
    setFocus,
  } = useFormContext();
  const { ref: refEmail, ...RegisterEmail } = register("email");

  //useEffects
  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Typography
        variant="body1"
        className="mb-8 text-2xl text-center font-medium text-gray-600"
      >
        Enter your email and we will send you an One Time Password.
      </Typography>
      <div className="my-2 w-4/5">
        <span className="text-sm font-semibold text-gray-600">Email</span>
        <CustomTextField
          variant={"outlined"}
          required
          label=""
          placeholder="Enter Email ID"
          type="text"
          ref={refEmail}
          {...RegisterEmail}
          error={Boolean(errors.email)}
          helperText={errors.email && errors.email.message}
          autoComplete="email"
        />
      </div>

      <Button
        type="submit"
        form="login-form"
        className="w-2/5 mt-8 bg-hb_orange-dark"
        variant="contained"
        sx={{ background: "#FF6C44", color: "white" }}
      >
        Send One Time Password
      </Button>
      <Button onClick={() => setFormStep(0)} className="underline">
        Go back
      </Button>
    </div>
  );
};
export default SendOtp;
