import React from "react";
import goalCompleteIcon from "assets/Icons/goalComplete.svg";
import goalInProgressIcon from "assets/Icons/goalInProgress.svg";
import CalenderIcon from "assets/Icons/calender.svg";
import tagIcon from "assets/Icons/tag.svg";
import habitTagIcon from "assets/Icons/habitTag.svg";
import CircularBar from "components/CircularBar/CircularBar";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import moment from "moment";

const GoalCard = (props) => {
  const { progress = 0, name, tags, habits, date } = props;

  return (
    <div className="flex justify-between p-3 rounded-lg mx-4 shadow-[0px_7px_29px_0px_rgba(100,100,111,0.2)]">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <img
            src={progress < 100 ? goalInProgressIcon : goalCompleteIcon}
            alt="goalCompleteIcon"
            style={{ height: 42, width: 42, marginRight: 10 }}
          />
          <div className="flex flex-col">
            <p className="capitalize text-base font-semibold text-hb_blue-700 truncate w-44">
              {name ?? "marthon"}
            </p>
            <p className="capitalize text-xs text-hb_blue-700">Personal</p>
          </div>
        </div>
        {Boolean(tags?.length) && (
          <div className="flex flex-row items-center capitalize text-xs mt-3 text-hb_blue-700">
            <img
              src={tagIcon}
              alt="tagIcon"
              style={{ height: 10, width: 10, marginRight: 10 }}
            />
            <p>{tags?.map((el) => el.name).join(", ")}</p>
          </div>
        )}
        {Boolean(habits?.length) && (
          <div className="flex flex-row items-center capitalize text-xs mt-3 text-hb_blue-700">
            <img
              src={habitTagIcon}
              alt="habitTagIcon"
              style={{ height: 10, width: 10, marginRight: 10 }}
            />
            <p>{habits?.map((el) => el.name).join(", ")}</p>
          </div>
        )}
      </div>
      <div className="flex flex-col items-end">
        <div className="font-normal flex flex-row items-center text-xs text-gray-700">
          <img
            alt="CalenderIcon"
            style={{ height: 15, width: 18, marginRight: 7 }}
            src={CalenderIcon}
          />
          <p>{date ? moment(date).format("MM/DD/YYYY") : "---"}</p>
        </div>
        <CircularBar label="Square linecaps" className="w-11 mt-2">
          <CircularProgressbar
            value={progress ?? 0}
            text={`${progress ?? 0}%`}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#FF6C44",
              trailColor: "#FAC1B8",
              textColor: "#FF6C44",
              textSize: "26px",
            })}
          />
        </CircularBar>
      </div>
    </div>
  );
};

export default GoalCard;
