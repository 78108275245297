import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { RatingOptions } from "pages/SignupPage/SignupForm";
import CirclePlusIcon from "assets/Icons/plus_circle.svg";
import { ConfirmDialog, CustomTextField, ToggleButton } from "components";
import TagListModal from "pages/SignupPage/modals/tagListModal";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useGoalCheckInPostMutation } from "queries/Goals";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import { getLocalStorageItem, localStorageKeys } from "hooks";

const CustomSchema = yup.object().shape(
  {
    personal: yup
      .object()
      .shape({
        satisfaction: yup.number().required("Please select."),
        stress: yup.number().required("Please select."),
        confidence: yup.number().required("Please select."),
        growth: yup.number().required("Please select."),
      })
      .default(undefined)
      .required(),
    professional: yup
      .object()
      .shape({
        satisfaction: yup.number().required("Please select."),
        stress: yup.number().required("Please select."),
        confidence: yup.number().required("Please select."),
        growth: yup.number().required("Please select."),
      })
      .default(undefined)
      .required(),
    social: yup
      .object()
      .shape({
        satisfaction: yup.number().required("Please select."),
        stress: yup.number().required("Please select."),
        confidence: yup.number().required("Please select."),
        growth: yup.number().required("Please select."),
      })
      .default(undefined)
      .required(),
    journiling: yup.string(),
    shareWithCoach: yup.boolean().default(true),
    stringTags: yup.array(),
  },
  []
);

export default function CheckInScreen(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  let userDetail = getLocalStorageItem(localStorageKeys.USER_DETAILS);
  //use forms
  const {
    setValue,
    getValues,
    formState: { errors, isValid },
    register,
    watch,
    setFocus,
  } = useForm({
    resolver: yupResolver(CustomSchema),
    defaultValues: { shareWithCoach: true },
  });

  const { shareWithCoach, personal, social, professional, journiling } =
    watch();
  const { ref: refJourniling, ...RegisterJourniling } = register("journiling");

  //use states
  const [expanded, setExpanded] = useState("panel1");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedStringTags, setSelectedStringTags] = useState([]);

  //queries
  const { mutateAsync: postCheckIn } = useGoalCheckInPostMutation();

  const doPostCheckIn = () => {
    postCheckIn(
      {
        personal: personal,
        professional: professional,
        social: social,
        tags: selectedStringTags?.length
          ? selectedStringTags?.map((el, index) => el.id)
          : null,
        notes: journiling?.length ? journiling : null,
        shared_to_coach: shareWithCoach ? 1 : 0,
      },
      {
        onSuccess: () => {
          if (state?.data?.ujournalable) {
            navigate(routes.JOUNRAL.pathname);
          } else {
            navigate(routes.HOME.pathname);
          }
        },
      }
    );
  };

  //handlers

  const handleDoEdit = () => {
    setShowConfirmModal(true);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (expanded === "panel1") {
      if (
        personal?.satisfaction &&
        personal?.stress &&
        personal?.confidence &&
        personal?.growth &&
        props?.isSmallScreen
      ) {
        setExpanded(isExpanded ? panel : false);
      }
    } else if (expanded === "panel2") {
      if (
        social?.satisfaction &&
        social?.stress &&
        social?.confidence &&
        social?.growth &&
        props?.isSmallScreen
      ) {
        setExpanded(isExpanded ? panel : false);
      }
    } else {
      if (
        professional?.satisfaction &&
        professional?.stress &&
        professional?.confidence &&
        professional?.growth &&
        props?.isSmallScreen
      ) {
        setExpanded(isExpanded ? panel : false);
      }
    }
  };

  const handleCloseModal = () => {
    return setShowModal(false);
  };

  const handleSetStringTags = (data) => {
    setSelectedStringTags(data);
    setValue("stringTags", data, { shouldValidate: true });
    setShowModal(false);
  };

  //useeffets
  useEffect(() => {
    if (
      personal?.satisfaction &&
      personal?.stress &&
      personal?.confidence &&
      personal?.growth &&
      props?.isSmallScreen
    ) {
      setExpanded("panel2");
    }
  }, [
    personal?.satisfaction,
    personal?.stress,
    personal?.confidence,
    personal?.growth,
    props?.isSmallScreen,
  ]);
  useEffect(() => {
    if (
      social?.satisfaction &&
      social?.stress &&
      social?.confidence &&
      social?.growth &&
      props?.isSmallScreen
    ) {
      setExpanded("panel3");
    }
  }, [
    social?.satisfaction,
    social?.stress,
    social?.confidence,
    social?.growth,
    props?.isSmallScreen,
  ]);

  useEffect(() => {
    if (
      professional?.satisfaction &&
      professional?.stress &&
      professional?.confidence &&
      professional?.growth &&
      props?.isSmallScreen
    ) {
      setExpanded(false);
    }
  }, [
    professional?.satisfaction,
    professional?.stress,
    professional?.confidence,
    professional?.growth,
    props?.isSmallScreen,
  ]);

  useEffect(() => {
    if (state?.data?.ujournalable) {
      let ratingData = state?.data?.ujournalable?.ratings;
      setValue("personal", ratingData?.personal);
      setValue("social", ratingData?.social);
      setValue("professional", ratingData?.professional);
      handleSetStringTags(state?.data?.ujournalable.tags ?? []);
      setValue("journiling", state?.data?.ujournalable.notes ?? "");
      setValue("shareWithCoach", !!state?.data?.ujournalable?.shared_to_coach, {
        shouldValidate: true,
      });
    }
  }, [state?.data, state?.data?.ujournalable]);

  useEffect(() => {
    if (state?.isNote) {
      setFocus("journiling");
    }
  }, [state]);

  //render
  const renderRatingOptionsMeter = (items, type) => {
    return (
      <Grid container xs={12} className="flex flex-row mb-4">
        <Grid item xs={6} className="flex flex-row">
          <p className="text-base font-normal">{items.name}</p>
          <Tooltip title={items.tooltipMessage} enterTouchDelay={0}>
            <InfoIcon sx={{ color: "#FF6C44", marginLeft: 1 }} />
          </Tooltip>
        </Grid>
        <Grid item xs={6} className="flex gap-3">
          {items.options?.map((it, idx) => {
            return (
              <IconButton
                onClick={() => {
                  setValue(type + `.${items.value}`, it.value, {
                    shouldValidate: true,
                  });
                }}
                sx={{
                  color:
                    getValues(type + `.${items.value}`) === it.value
                      ? "white"
                      : it.color,
                  backgroundColor:
                    getValues(type + `.${items.value}`) === it.value
                      ? it.color + "!important"
                      : "transparent",
                  padding: 0,
                  minWidth: 32,
                }}
                className="flex justify-center items-center"
              >
                <p
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 32,
                    borderWidth: 1,
                    borderColor: it.color,
                  }}
                  className="text-base pt-0.5"
                >
                  {it.value}
                </p>
              </IconButton>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction={"column"} className="px-4 pt-4 mb-12">
      <Grid
        item
        container
        className="flex flex-col sm:flex-row sm:flex-wrap gap-4"
      >
        <Grid item xs={12} md={3.8} minWidth={350}>
          <Accordion
            expanded={props?.isSmallScreen ? expanded === "panel1" : true}
            onChange={handleChange("panel1")}
            className="rounded-lg"
            disableGutters
            elevation={2}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <p className="text-base font-bold text-hb_orange-dark">
                Personal
              </p>
            </AccordionSummary>
            <AccordionDetails className="w-full">
              {RatingOptions?.map((it) =>
                renderRatingOptionsMeter(it, "personal")
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={3.8} minWidth={350}>
          <Accordion
            expanded={props?.isSmallScreen ? expanded === "panel2" : true}
            onChange={handleChange("panel2")}
            className="rounded-lg"
            disableGutters
            elevation={2}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <p className="text-base font-bold text-hb_orange-dark">Social</p>
            </AccordionSummary>
            <AccordionDetails className="w-full">
              {RatingOptions?.map((it) =>
                renderRatingOptionsMeter(it, "social")
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={3.8} minWidth={350}>
          <Accordion
            expanded={props?.isSmallScreen ? expanded === "panel3" : true}
            onChange={handleChange("panel3")}
            className="rounded-lg min-w-[230px]"
            disableGutters
            elevation={2}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <p className="text-base font-bold text-hb_orange-dark">
                Professional
              </p>
            </AccordionSummary>
            <AccordionDetails className="w-full">
              {RatingOptions?.map((it) =>
                renderRatingOptionsMeter(it, "professional")
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid className="mt-6 sm:max-w-screen-sm">
        <CustomTextField
          variant={"outlined"}
          required
          label=""
          ref={refJourniling}
          rows={5}
          multiline
          {...RegisterJourniling}
          placeholder="Write Here..."
          type="text"
          error={Boolean(errors.journiling)}
          helperText={errors.journiling && errors.journiling.message}
        />
      </Grid>
      {Boolean(selectedStringTags?.length) && (
        <Grid item xs={12} md={8}>
          <p className="font-semibold py-2">Tags</p>
          <div className="flex flex-wrap gap-3 flex-row">
            {selectedStringTags?.map((it, idx) => (
              <div className="items-center">
                <p className="bg-hb_orange-dark rounded-2xl text-xs text-white px-4 py-1">
                  {it.name}{" "}
                  <Button
                    onClick={() => {
                      setSelectedStringTags((prev) => {
                        const elem = [...prev];
                        elem.splice(idx, 1);
                        setValue("stringTags", elem, {
                          shouldValidate: true,
                        });

                        return elem;
                      });
                    }}
                    sx={{
                      padding: 0,
                      margin: 0,
                      minWidth: 0,
                      paddingBottom: 0.2,
                    }}
                  >
                    <CancelIcon sx={{ color: "white" }} fontSize="small" />
                  </Button>
                </p>
              </div>
            ))}
          </div>
        </Grid>
      )}
      <div className="flex justify-center sm:max-w-screen-sm">
        <Button
          className="flex flex-col text-gray-900"
          onClick={() => setShowModal(true)}
        >
          <img
            alt="add tags"
            src={CirclePlusIcon}
            style={{ height: 35, width: 35 }}
          />
          Add Tags
        </Button>
      </div>

      <div className="rounded bg-hb_gray-500 p-4 mt-2 sm:max-w-screen-sm">
        <div className="flex items-center">
          <p className="text-base text-gray-700">Share with Coach/Therapist?</p>
          <Tooltip
            title="Share this Check-In with your coach"
            enterTouchDelay={0}
          >
            <IconButton>
              <InfoIcon sx={{ fontSize: "1rem", color: "#FF6C44" }} />
            </IconButton>
          </Tooltip>
          <div className="ml-auto">
            <ToggleButton
              checked={shareWithCoach}
              onChange={(e) => {
                if (userDetail?.profile?.hasCoach) {
                if (e.target.checked) {
                  setValue("shareWithCoach", true, { shouldValidate: true });
                } else {
                  setValue("shareWithCoach", false, { shouldValidate: true });
                }
              }else{
                alert("Subscribe to a coach to enable.")
              }
              }}
            />
          </div>
        </div>
      </div>
      <Grid item xs={12} md={6}>
        <Button
          className="flex w-full bg-hb_orange-dark mt-4 mb-20 sm:max-w-screen-sm"
          variant="contained"
          disabled={!isValid}
          onClick={state?.data?.ujournalable ? handleDoEdit : doPostCheckIn}
          sx={{ background: "#FF6C44", color: "white" }}
        >
          Submit Check-In
        </Button>
      </Grid>
      <ConfirmDialog
        type={"acceptDeny"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base">Alert!</p>
            <p className="font-normal text-base mt-8 text-center w-72">
              Are you sure you want to edit your check-ins ?
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => {
          setShowConfirmModal(false);
        }}
        continueEdit={() => {
          doPostCheckIn();
        }}
      />
      {showModal && (
        <TagListModal
          open={showModal}
          handleClose={handleCloseModal}
          handleSetStringTags={handleSetStringTags}
          selectedStringTags={selectedStringTags}
        />
      )}
    </Grid>
  );
}
