import ReactPlayer from "react-player/youtube";
import { Grid, Button, Checkbox } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { CustomTextField } from "components";
import { useFetchWhyHabitbetterQuery } from "queries/Auth";

/* Step-3 */
const DecideToHabitbetter = (props) => {
  const { setFormStep } = props;
  //states
  const [checkBoxList, setcheckBoxList] = useState([
    // { id: 1, isChecked: false, text: "I want to be better version of myself" },
    // {
    //   id: 2,
    //   isChecked: false,
    //   text: "I want to build stronger relationship and a richer social life",
    // },
    // {
    //   id: 3,
    //   isChecked: false,
    //   text: "I have big dreams for my career and education",
    // },
    // { id: 4, isChecked: false, text: "Something else.." },
  ]);

  //queries
  const { data, dataUpdatedAt } = useFetchWhyHabitbetterQuery();

  useEffect(() => {
    if (data?.data?.options?.length) {
      let tempArray = JSON.parse(data?.data?.options)?.map((it, idx) => {
        return {
          id: idx,
          isChecked: false,
          text: it,
        };
      });
      setcheckBoxList([
        ...tempArray,
        { id: tempArray?.length, isChecked: false, text: "Something else.." },
      ]);
    }
  }, [dataUpdatedAt, data]);

  const {
    watch,
    formState: { errors, isValid },
    setValue,
    register,
  } = useFormContext();

  const { isSomethingElseSelected } = watch();

  const { ref: refSomethingElse, ...RegisterSomethingElse } =
    register("somethingElse");

  const renderCheckTypography = (item, idx) => {
    return (
      <div className="flex flex-row items-center mt-1">
        <Checkbox
          onChange={(event) => {
            const elem = [...checkBoxList];
            elem[idx].isChecked = event.target.checked;
            if (idx === 3) {
              setValue("isSomethingElseSelected", event.target.checked, {
                shouldValidate: true,
              });
              if (!event.target.checked)
                setValue("somethingElse", "", { shouldValidate: true });
            }
            let checkedTextList = [];
            elem.forEach((it) => {
              if (it.isChecked) checkedTextList.push(it.text);
            });
            setValue("selectedCheckbox", checkedTextList, {
              shouldValidate: true,
            });
            setcheckBoxList(elem);
          }}
          sx={{
            color: "#FF6C44",
            "&.Mui-checked": {
              color: "#FF6C44",
            },
            paddingLeft: 0,
          }}
        />
        <p className="text-lg font-normal">{item.text}</p>
      </div>
    );
  };

  return (
    <div>
      <Grid container className="mt-10">
        <Grid item xs={12} md={9} className="overflow-hidden rounded-2xl">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=9m0ZsMoFL4o"
            height={280}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-2xl font-semibold mt-6">
            Why did you decide to be a part of CareBetter?
          </p>
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="text-base font-semibold mt-6">Here are some options.</p>
        </Grid>
        <Grid item xs={10}>
          {checkBoxList.map((item, idx) => renderCheckTypography(item, idx))}
          {Boolean(isSomethingElseSelected) && (
            <p className="text-base font-normal text-red-600 mb-3">
              What motivated you to start CareBetter?
            </p>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <CustomTextField
            variant={"outlined"}
            required
            disabled={!Boolean(isSomethingElseSelected)}
            label=""
            ref={refSomethingElse}
            rows={5}
            multiline
            {...RegisterSomethingElse}
            placeholder="Write Here..."
            type="somethingElse"
            error={Boolean(errors.somethingElse)}
            helperText={errors.somethingElse && errors.somethingElse.message}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Button
            className="w-full bg-hb_orange-dark mt-4"
            variant="contained"
            disabled={!isValid}
            form="signup-form"
            type="submit"
            sx={{ background: "#FF6C44", color: "white" }}
          >
            Next
          </Button>
          <Button
            className="w-full mt-2 text-hb_orange-dark underline underline-offset-2"
            variant="text"
            onClick={() => setFormStep(4)}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default DecideToHabitbetter;
