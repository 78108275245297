import { useGetNonAssociatedCoachQuery } from "queries/Coach";
import React, { useMemo, useState } from "react";
import MobileHeader from "components/MobileHeader";
import userDuoTone from "assets/Icons/user-duotone 1.svg";
import magnifyIcon from "assets/Icons/magnify.svg";
import { useNavigate } from "react-router-dom";
import { useGetPublicUsersQuery } from "queries/Profile";
import { routes } from "Routes/RouteConstants";
import SearchBar from "components/SearchBar/SearchBar";

const PublicUserScreen = () => {
  const navigate = useNavigate();

  //use state
  const [searchJournal, setSearchJournal] = useState(null);

  //quries
  const { data, dataUpdatedAt } = useGetPublicUsersQuery({
    search: searchJournal,
  });

  //Use useMemo
  const { publicUserListData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        publicUserListData: data?.data,
      };
    } else {
      return { publicUserListData: [] };
    }
  }, [dataUpdatedAt]);

  return (
    <div className="px-4 w-full mt-4 sm:pl-8 sm:mt-8">
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Users"
      />
      <p className="hidden sm:flex text-base font-medium">Users</p>
      <SearchBar
        className="-mt-2 w-full sm:-mt-6 sm:mx-3 sm:absolute sm:w-96 sm:right-0 sm:top-32"
        value={searchJournal}
        onChange={(e) => setSearchJournal(e.target.value)}
        showFilter={false}
      />
      <div>
        {Boolean(publicUserListData.length === 0) ? (
          <div className="flex flex-col w-full min-h-[60vh] justify-center items-center">
            <img
              alt="magnifyIcon"
              style={{ height: 80, width: 80 }}
              src={magnifyIcon}
            />
            <p className="text-lg font-semibold mt-8">No results to show</p>
          </div>
        ) : (
          publicUserListData?.map((item) => {
            return (
              <div
                className="bg-hb_gray-1000 w-full p-4 mt-4 flex flex-row  items-center rounded-lg cursor-pointer"
                onClick={() => {
                  navigate(routes.PUBLIC_USERS_DETAIL.pathname, {
                    state: {
                      item,
                    },
                  });
                }}
              >
                <div className="flex justify-center h-12 w-12 bg-white rounded-full items-center text-sm text-gray-700 font-medium">
                  <img
                    alt="userDuoTone"
                    style={{ height: 20, width: 22 }}
                    src={userDuoTone}
                  />
                </div>
                <p className="ml-4 capitalize">{item?.name}</p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PublicUserScreen;
