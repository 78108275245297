import HabitBetterLogo from "assets/Images/CareBetterWordmark.png";
import GoogleIcon from "assets/Icons/GoogleIcon.svg";
import FacebookIcon from "assets/Icons/FacebookIcon.svg";
import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CustomTextField, PasswordField } from "components";
import GroupLogo from "assets/Images/GroupLogo.svg";
import { routes } from "Routes/RouteConstants";
import { auth, gProvider, fProvider } from "firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import MobileHeader from "components/MobileHeader";
import { useSocialLoginQuery } from "queries/Auth";
import { v4 as uuidv4 } from "uuid";
// import { getLocalStorageItem, localStorageKeys } from "hooks";

const LoginFormMain = (props) => {
  const { setFormStep } = props;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:650px)");

  //states
  const [showMobileLogin, setShowMobileLogin] = useState(false);

  const {
    register,
    formState: { errors },
    setFocus,
    setValue,
    watch: { password },
  } = useFormContext();

  const { ref: refRegisterEmail, ...RegisterLoginEmail } = register("email");
  const { ref: refRegisterPassword } = register("password");
  //quries
  const { mutateAsync: postSocial } = useSocialLoginQuery();

  //useEffects
  useEffect(() => {
    setFocus("email");
  }, []);

  const googleLogin = () => {
    signInWithPopup(auth, gProvider).then((data) => {
      postSocial(
        {
          params: {
            // uuid: route?.params?.user_id,
            // habit_id: route?.params?.habit_id,
          },
          social: {
            email: data.user?.email,
            name: data.user?.displayName,
            provider: "google",
            provider_id: data.user.uid,
            device_token: uuidv4(),
          },
        },
        {
          onSuccess: (data) => {
            if (data?.data?.new) {
              navigate(routes.SIGN_UP.pathname, { state: { navIndex: 2 } });
            } else {
              navigate("/profile/info");
            }
          },
        }
      );
    });
  };

  const facebookLogin = () => {
    signInWithPopup(auth, fProvider).then((data) => {
      console.log("data--->", data);
      // postSocial(
      //   {
      //     params: {
      //       // uuid: route?.params?.user_id,
      //       // habit_id: route?.params?.habit_id,
      //     },
      //     social: {
      //       email: data.user?.email,
      //       name: data.user?.displayName,
      //       provider: "google",
      //       provider_id: data.user.uid,
      //       device_token: uuidv4(),
      //     },
      //   },
      //   {
      //     onSuccess: (data) => {
      //       if (data?.data?.new) {
      //         navigate(routes.SIGN_UP.pathname, { state: { navIndex: 2 } });
      //       } else {
      //         navigate("/profile/info");
      //       }
      //     },
      //   }
      // );
    });
  };

  const loginForm = () => {
    return (
      <Grid container>
        <Grid item xs={12} className="my-2">
          <span className="text-sm font-semibold text-gray-600">
            Username or Email *
          </span>
          <CustomTextField
            variant={"outlined"}
            required
            label=""
            placeholder="Enter Username or Email"
            type="text"
            ref={refRegisterEmail}
            {...RegisterLoginEmail}
            error={Boolean(errors.email)}
            helperText={errors.email && errors.email.message}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12} className="my-2">
          <span className="text-sm font-semibold text-gray-600">
            Password *
          </span>
          <PasswordField
            required
            label=""
            placeholder="Enter Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setValue("password", e.target.value)}
            ref={refRegisterPassword}
            // {...RegisterLoginPassword}
            error={Boolean(errors.password)}
            helperText={errors.password && errors.password.message}
            autoComplete="password"
          />
        </Grid>
      </Grid>
    );
  };

  const MobileLoginComponent = () => {
    return (
      <>
        {/* Header */}
        <MobileHeader
          onClick={() => setShowMobileLogin(false)}
          title="Login"
          variant="secondary"
        />
        {/* Body */}
        <div className="mx-4 mt-4">
          {loginForm()}
          <Button
            className="text-hb_orange-dark"
            sx={{ paddingX: 0 }}
            onClick={() => setFormStep(1)}
            variant="text"
          >
            Forgot Password?
          </Button>
          <div className="mb-4 mt-16 flex w-full justify-center">
            <Button
              form="login-form"
              type="submit"
              className="w-full bg-hb_orange-dark"
              variant="contained"
              sx={{ background: "#FF6C44", color: "white" }}
            >
              Login
            </Button>
          </div>
          <div className="my-6 flex items-center justify-center">
            <p className="text-sm text-hb_gray-800">Not a member yet?</p>
            <Button
              sx={{ color: "#FF6C44" }}
              className="text-sm underline underline-offset-2"
              onClick={() => navigate(routes.SIGN_UP.pathname)}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </>
    );
  };

  const WebViewComponent = () => {
    return (
      <>
        {/* header  */}
        <div className="sm:w-5/6 md:w-4/5 lg:w-3/4">
          <Typography
            variant="h3"
            fontWeight={700}
            marginTop={"20%"}
            sx={{ color: "#333333" }}
            className={isSmallScreen ? "hidden" : ""}
          >
            Login
          </Typography>
          <div className="flex-col items-center justify-center flex mt-8 sm:hidden">
            <img
              src={HabitBetterLogo}
              alt="google-logo"
              style={{ height: 40 }}
            />
            <Typography
              variant="subtitle1"
              fontWeight={400}
              style={{ marginTop: "2%" }}
              fontStyle={{
                textAlign: "center",
                size: 16,
              }}
            >
              CareBetter is the ultimate client success tool for therapists and
              coaches.
            </Typography>
            <img
              src={GroupLogo}
              alt="group-logo"
              style={{ height: "30vh", marginTop: "5%" }}
            />
            <div className="mt-12 flex items-center">
              <p className="text-sm text-center text-hb_gray-600">
                By Continue this you agree CareBetter
                <button className="text-sm text-center text-hb_orange-dark ml-2">
                  <a
                    href="https://habitbetter.com/terms-of-use/"
                    target="_/blank"
                  >
                    Terms of service{" "}
                  </a>
                </button>
              </p>
            </div>
            <div className="flex items-center justify-center ">
              <p className="text-sm text-hb_gray-600 ">
                and Acknowledge CareBetter
                <button className="text-sm text-hb_orange-dark ml-2">
                  <a
                    href="https://habitbetter.com/privacy-policy/"
                    target="_/blank"
                  >
                    Privacy Policy
                  </a>
                </button>
              </p>
            </div>
          </div>
          <Button
            variant="outlined"
            className="w-full mt-8 text-base text font-semibold"
            onClick={googleLogin}
          >
            <img
              src={GoogleIcon}
              alt="google-logo"
              style={{ paddingRight: 5, height: 24, width: 24 }}
            />
            Continue with Google
          </Button>
          {/* <Button
            variant="contained"
            className="w-full mt-4 bg-hb_facebook-900 text-white text-base text font-semibold"
            onClick={facebookLogin}
          >
            <img
              src={FacebookIcon}
              alt="facebook-logo"
              style={{ paddingRight: 5, height: 24, width: 24 }}
            />
            Continue with Facebook
          </Button> */}
          <div className="flex-col sm:hidden">
            {/* mobile login signup button */}
            <div className="flex items-center justify-center gap-4">
              <Button
                variant="outlined"
                className="w-full mt-4 text-black text-base text font-semibold"
                onClick={() => setShowMobileLogin(true)}
              >
                Log In
              </Button>
              <Button
                variant="contained"
                className="w-full mt-4 bg-hb_orange-dark text-white text-base text font-semibold"
                onClick={() => navigate(routes.SIGN_UP.pathname)}
              >
                Sign Up
              </Button>
            </div>
          </div>

          {/* web login signup forgot password button */}

          <div className="hidden sm:flex flex-col">
            <Typography
              variant="subtitle1"
              fontWeight={400}
              marginY={"3%"}
              sx={{ color: "#333333", textAlign: "center" }}
            >
              or
            </Typography>
            {loginForm()}
            <div className="flex ml-auto float-right">
              <Button
                className="text-hb_orange-dark underline underline-offset-2"
                onClick={() => setFormStep(1)}
                variant="text"
              >
                Forgot Password?
              </Button>
            </div>
            <div className="mb-4 mt-16 flex w-full justify-center">
              <Button
                form="login-form"
                type="submit"
                className="w-full bg-hb_orange-dark"
                variant="contained"
                sx={{ background: "#FF6C44", color: "white" }}
              >
                Login
              </Button>
            </div>
            <div className="my-6 flex items-center justify-center">
              <p className="text-sm text-hb_gray-600 underline underline-offset-2">
                New User?
              </p>
              <Button
                sx={{
                  color: "#FF6C44",
                  "&:hover": {
                    color: "primary",
                  },
                }}
                className="text-sm underline underline-offset-2"
                onClick={() => navigate(routes.SIGN_UP.pathname)}
              >
                Sign Up
              </Button>
            </div>
          </div>
          <div className="hidden sm:mt-4 sm:flex sm:items-center sm:justify-center ">
            <p className="text-sm text-hb_gray-600 text-center">
              By continuing, you agree to CareBetter's
              <button className="text-sm h-4 text-hb_orange-dark mb-1 ml-1 text-center">
                <a
                  href="https://habitbetter.com/terms-of-use/"
                  target="_/blank"
                >
                  {" "}
                  Terms & Conditions{" "}
                </a>
              </button>{" "}
            </p>
          </div>
          <div className="hidden sm:flex sm:items-center sm:justify-center pb-8">
            <p className="text-sm text-hb_gray-600 text-center">
              and acknowledge the CareBetter
              <button className="text-sm h-4 text-hb_orange-dark mb-1 ml-1 text-center">
                <a
                  href="https://habitbetter.com/privacy-policy/"
                  target="_/blank"
                >
                  Privacy Policy
                </a>
              </button>
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {showMobileLogin && isSmallScreen ? (
        <MobileLoginComponent />
      ) : (
        <WebViewComponent />
      )}
    </>
  );
};
export default memo(LoginFormMain);
