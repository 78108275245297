/* eslint-disable no-lone-blocks */
import React, { Fragment, useState, useEffect } from "react";
import { Card, Typography, Button, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import MobileHeader from "components/MobileHeader";
import {
  useGetGadNotification,
  usePostGadNotification,
  useReadGadNotification,
} from "queries/Documents";
import { useForm } from "react-hook-form";
import { ConfirmDialog } from "components";

const DashBoardNotificationsScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  //useStates
  const [questionsData, setQuestionsData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  //   //queries
  const { data: gadData } = useGetGadNotification({
    params: {
      type: state?.type,
      custom_check_in_id: state?.custom_check_in_id,
    },
  });
  const { mutateAsync: postCheckIn } = usePostGadNotification();
  const { mutateAsync: readNotification } = useReadGadNotification();

  // console.log("state", state);
  useEffect(() => {
    if (gadData) {
      console.log("gadData", gadData);
    }
  }, [gadData]);

  //handlers
  const defaultValues = {
    questions: new Array(gadData?.data?.questions?.length).fill(""),
    answers: new Array(gadData?.data?.questions?.length).fill(""),
  };
  const { setValue, watch, register } = useForm({
    defaultValues: defaultValues,
  });
  const { questions, answers } = watch();

  useEffect(() => {
    if (gadData) {
      setValue(
        "questions",
        new Array(gadData?.data?.questions?.length).fill("")
      );
      setValue("answers", new Array(gadData?.data?.questions?.length).fill(""));
      setQuestionsData(gadData?.data?.questions ?? []);
    }
  }, [gadData]);

  const submitCheckin = () => {
    const isValid =
      questions?.findIndex(
        (el, ind) =>
          (gadData?.data?.questions[ind]?.question_type === "ratings" ||
            gadData?.data?.questions[ind]?.question_type ===
              "rating_answers") &&
          el?.toString().length < 1
      ) > -1 ||
      answers?.findIndex(
        (el, ind) =>
          (gadData?.data?.questions[ind]?.question_type === "answers" ||
            gadData?.data?.questions[ind]?.question_type ===
              "rating_answers") &&
          el.length < 1
      ) > -1;
    console.log("isValid", isValid);
    if (isValid) {
      setShowConfirmModal(true);
    } else {
      postCheckIn(
        {
          body: {
            custom_check_in_id: state?.custom_check_in_id,
            answer_values: gadData?.data?.questions?.map((el, index) => ({
              check_ins_question_id: el?.id,
              ratings: questions[index]?.length < 1 ? null : questions[index],
              answer: answers[index],
            })),
          },
        },
        {
          onSuccess: () => {
            readNotification({
              body: {
                notification_id: state?.notificationId,
              },
            });
            navigate(-1);
          },
        }
      );
    }
  };

  return (
    <Fragment>
      <MobileHeader
        onClick={() => navigate(-1)}
        title={gadData?.data?.title ?? `${state?.type ?? ""} Check-In`}
        variant="secondary"
      />
      <Card className="flex flex-col p-2 sm:p-4 sm:mx-8 sm:w-2/3">
        <Typography className="hidden sm:flex my-2.5 text-sm font-semibold leading-5 text-hb_text-300">
          {gadData?.data?.title ?? `${state?.type ?? ""} Check-In`}
        </Typography>
        <div>
          <div className="bg-blue-100 px-2 py-3 rounded-md">
            <Typography className="text-sm  font-semibold leading-5 text-[#3B579D] break-words">
              {gadData?.data?.summary}
            </Typography>
            {(gadData?.data?.title === "GAD-7" ||
              gadData?.data?.title === "PHQ-9") && (
              <Typography className="my-2.5 text-sm font-semibold leading-5 text-hb_text-300">
                {
                  "1 = Not at all, 2 = Several Days, 3 = More than Half the days, and 4 = Nearly every day"
                }
              </Typography>
            )}
          </div>
          <div>
            {questionsData?.map((item, index) => {
              return (
                <div className="w-full">
                  <p className="text-base font-medium p-2">{item?.question}</p>
                  <div className="flex flex-row items-end justify-end w-full">
                    {(item?.question_type === "ratings" ||
                      item?.question_type === "rating_answers") &&
                      [
                        {
                          title: "1",
                        },
                        {
                          title: "2",
                        },
                        {
                          title: "3",
                        },
                        {
                          title: "4",
                        },
                      ].map((item, idx) => {
                        return (
                          <Button
                            variant={
                              idx + 1 === questions?.[index]
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setValue(`questions[${index}]`, idx + 1, {
                                shouldValidate: true,
                              });
                            }}
                            sx={{
                              height: 34,
                              width: 34,
                              borderRadius: 34,
                              borderColor: "#234266",
                              color:
                                idx + 1 === questions?.[index]
                                  ? "white"
                                  : "#234266",
                              // backgroundColor:
                              //   idx + 1 === questions?.[index]
                              //     ? "#234266" + "important"
                              //     : "transparent",
                              padding: 0,
                              borderWidth: 1,
                              minWidth: 34,
                              margin: 1,
                            }}
                            className={
                              idx + 1 === questions?.[index]
                                ? "bg-hb_blue-700"
                                : "bg-transparent"
                            }
                          >
                            {idx + 1}
                          </Button>
                        );
                      })}
                  </div>
                  {(item?.question_type === "answers" ||
                    item?.question_type === "rating_answers") && (
                    <TextField
                      variant={"outlined"}
                      required
                      label=""
                      className="w-full sm:w-2/3"
                      value={answers?.[index] ?? ""}
                      placeholder="Your Response"
                      onChange={(e) =>
                        setValue(`answers[${index}]`, e.target.value)
                      }
                      multiline
                      inputProps={{ maxLength: 2000 }}
                      type="text"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex justify-center mt-5">
            <Button
              variant="conatined"
              className="bg-hb_orange-dark text-white w-[100%]"
              onClick={submitCheckin}
            >
              Submit Check-In
            </Button>
          </div>
        </div>
      </Card>
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base mt-4">Reminder</p>
            <p className="font-normal text-base mt-10 text-center ">
              Please fill all the fields or bubbles
            </p>
          </div>
        }
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        continueEdit={() => setShowConfirmModal(false)}
      />
    </Fragment>
  );
};
export default DashBoardNotificationsScreen;
