import { Grid } from "@mui/material";
import CircularBar from "components/CircularBar/CircularBar";
import {
  useGetCompleteCoursesQuery,
  useGetMyCoursesQuery,
  usePostRearrangeMutation,
} from "queries/Courses";
import React, { Fragment, useMemo, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloudIcon from "assets/Icons/cloud.svg";
import MediIcon from "assets/Icons/meditation.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "Routes/RouteConstants";
import { ConfirmDialog } from "components";
import { useProfileQuery } from "queries/Profile";

const MyCourse = () => {
  const navigate = useNavigate();
  //use state
  const [nextCourse, setNextCourse] = useState([]);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  //queries
  const { data, dataUpdatedAt } = useGetMyCoursesQuery();
  const { data: completeCourseData } = useGetCompleteCoursesQuery();
  const { mutateAsync: postRearrange } = usePostRearrangeMutation();
  const { data: fetchedProfile } = useProfileQuery();

  //Use useMemo
  const { currentCourse } = useMemo(() => {
    if (dataUpdatedAt) {
      setNextCourse(data?.data?.filter((el) => el.current === 0));
      return {
        currentCourse: data?.data?.find((el) => el.current === 1),
      };
    } else {
      return { currentCourse: {} };
    }
  }, [dataUpdatedAt]);

  //handler
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      nextCourse,
      result.source.index,
      result.destination.index
    );
    setNextCourse(items);
    postRearrange({
      order: items
        ?.map((el, index) => {
          return {
            course_id: el?.course_id,
            order: index + 2,
          };
        })
        .concat({
          course_id: currentCourse?.course_id,
          order: 1,
        }),
    });
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 0 0`,

    // change background colour if dragging
    background: isDragging ? "transparent" : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "transparent",
    // padding: grid,
    // width: 250,
  });

  //render
  const renderCourseCard = (item, current = false) => {
    return (
      <div
        className={
          current
            ? "flex flex-row cursor-pointer items-center justify-between p-3 rounded-lg my-2 bg-hb_orange-light"
            : "flex flex-row cursor-pointer items-center justify-between p-3 rounded-lg my-2 border border-dashed border-black"
        }
        onClick={() => {
          if (fetchedProfile?.data?.subscribed === 0) {
            return setShowSubscribeModal(true);
          }
          navigate(routes.NEW_COURSES.pathname, {
            state: {
              course: item.course,
            },
          });
        }}
      >
        <div className="flex flex-row items-center">
          <img
            src={item.course?.course_images?.[0]?.image ?? MediIcon}
            alt="goalCompleteIcon"
            style={{
              height: 42,
              width: 62,
              marginRight: 10,
              borderRadius: 5,
            }}
          />
          <div className="flex flex-col">
            <p className="capitalize text-base font-semibold text-hb_blue-700">
              {item?.course?.title}
            </p>
          </div>
        </div>
        {current && (
          <CircularBar label="Square linecaps" className="w-11 mt-2">
            <CircularProgressbar
              value={item?.progress ?? 0}
              text={`${item?.progress ?? 0}%`}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "#FF6C44",
                trailColor: "#FAC1B8",
                textColor: "#FF6C44",
                textSize: "26px",
              })}
            />
          </CircularBar>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <Grid container className="sm:m-6 px-4 sm:px-0 sm:gap-5 mb-20">
        <Grid
          item
          md={8}
          xs={12}
          lg={5}
          sm={8}
          className="shadow-lg border flex-col border-gray-100 flex rounded-lg p-3 my-4 sm:my-0"
        >
          {Boolean(currentCourse?.course?.title) && (
            <div className="w-full">
              <div className="bg-hb_orange-dark text-white rounded-lg w-full p-2">
                <p className="ml-4 text-base font-medium">Current Course</p>
              </div>
              {renderCourseCard(currentCourse, true)}
            </div>
          )}
          {nextCourse?.length > 0 && (
            <div className="w-full">
              <div className="text-hb_orange-dark border border-dashed border-black rounded-lg w-full p-2 my-4">
                <p className="ml-4 text-base font-medium">Up Next</p>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {nextCourse?.map((item, index) => (
                        <Draggable
                          key={item.id?.toString()}
                          draggableId={item.id?.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {renderCourseCard(item, false)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          {!Boolean(nextCourse?.length) &&
            !Boolean(currentCourse?.course?.title) && (
              <div className="flex flex-col justify-center items-center mt-4 my-12">
                <div>
                  <img
                    alt="CloudIcon"
                    className="mt-[15%]"
                    style={{ height: 118, width: 152 }}
                    src={CloudIcon}
                  />
                  <p className="text-base font-semibold my-4 text-center">
                    No Course found
                  </p>
                </div>
              </div>
            )}
        </Grid>
        {completeCourseData?.data?.length > 0 && (
          <Grid
            item
            md={8}
            xs={12}
            sm={8}
            lg={5}
            className="shadow-lg border flex-col border-gray-100 flex rounded-lg p-3"
          >
            <div className="w-full">
              <div className="text-hb_orange-dark border border-dashed border-black rounded-lg w-full p-2">
                <p className="ml-4 text-base font-medium">Complete Courses</p>
              </div>
              {completeCourseData?.data?.map((item) =>
                renderCourseCard(item, false)
              )}
            </div>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        type={"ok"}
        message={
          <div className="flex flex-col items-center">
            <p className="font-semibold text-base mt-0">CareBetter</p>
            <p className="font-normal text-base my-6 text-center">
              Please subscribe first to access this course
            </p>
          </div>
        }
        open={showSubscribeModal}
        handleClose={() => setShowSubscribeModal(false)}
        continueEdit={() => setShowSubscribeModal(false)}
      />
    </Fragment>
  );
};

export default MyCourse;
