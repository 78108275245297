import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios, getLocalStorageItem, localStorageKeys } from "utils";
import { useNavigate } from "react-router-dom";
import QuereyKeys from "./queryKeys";
import { toast } from "react-toastify";
import removeNullProperties from "utils/helpers/removeNullProperties";

export const getCoachList = () => {
  const method = "GET";
  const url = `api/associated/coach`;
  return axios({
    method,
    url,
  });
};

export const useCoachList = () => {
  const navigate = useNavigate();
  return useQuery([QuereyKeys.COACH_LIST], getCoachList, {
    onSuccess: (res) => {
      console.log(res);
    },
    onError: () => {
      navigate("/main");
    },
  });
};

// Get NonAssociatedCoach data
const GetNonAssociatedCoach = (data) => {
  const method = "GET";
  const url = "/api/non-associated/coach";
  return axios({
    method,
    url,
    params: removeNullProperties({
      ...data,
    }),
  });
};

export const useGetNonAssociatedCoachQuery = (data) => {
  return useQuery(
    [QuereyKeys.NON_ASSOCIATED_COACH, data],
    () => GetNonAssociatedCoach(data),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
      onSuccess: (response) => {},
    }
  );
};

// Get AssociatedCoach data
const GetAssociatedCoach = () => {
  const method = "GET";
  const url = "/api/associated/coach";
  return axios({
    method,
    url,
  });
};

export const useGetAssociatedCoachQuery = () => {
  return useQuery([QuereyKeys.ASSOCIATED_COACH], GetAssociatedCoach, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: (response) => {},
  });
};

// Coach Detail
const GetCoachDetail = (payload) => {
  const method = "GET";
  const url = `/api/coach/details/${payload?.id}`;
  return axios({
    method,
    url,
  });
};

export const useGetCoachDetailQuery = (payload) => {
  return useQuery(
    [QuereyKeys.COACH_DETAIL, payload?.id],
    () => GetCoachDetail(payload),
    {
      enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
      onSuccess: (response) => {},
    }
  );
};

// Invite coach
const InviteCoach = (body) => {
  const method = "POST";
  const url = `/api/send/request`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const usePostSendInviteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(InviteCoach, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.NON_ASSOCIATED_COACH);
    },
  });
};

// Subscribe Coach Mutation
const SubscribeCoach = (body) => {
  const method = "POST";
  const url = `/api/subscription/coach`;
  return axios({
    method,
    url,
    data: body,
  });
};

const DeleteCoach = (body) => {
  const method = "DELETE";
  const url = `/api/subscription/coach?coach_id=${body?.coach_id}`;
  return axios({
    method,
    url,
    data: body,
  });
};

export const useSubscribeCoachMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(SubscribeCoach, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.ASSOCIATED_COACH);
    },
    onError: (e) => {
      console.log("error--->>>>>", e);
    },
  });
};

export const useSubscribeCoachDeleteQuery = () => {
  const queryClient = useQueryClient();
  // const handleError = useErrorHandler()
  return useMutation(DeleteCoach, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuereyKeys.ASSOCIATED_COACH);
      toast.success("Deleted successfully!");
    },
    onError: (e) => {
      toast.error(e?.message);
    },
  });
};

// invite coach query
const GetInviteCoach = () => {
  const method = "GET";
  const url = "/api/invite";
  return axios({
    method,
    url,
  });
};

export const useGetInviteCoachQuery = () => {
  return useQuery([QuereyKeys.INVITE_COACH], GetInviteCoach, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: (response) => {},
  });
};

// invite request coach query
const GetInviteRequestCoach = () => {
  const method = "GET";
  const url = "/api/invite/request";
  return axios({
    method,
    url,
  });
};

export const useGetInviteRequestCoachQuery = () => {
  return useQuery([QuereyKeys.INVITE_COACH_REQUEST], GetInviteRequestCoach, {
    enabled: Boolean(getLocalStorageItem(localStorageKeys.AUTH_TOKEN)),
    onSuccess: (response) => {},
  });
};
