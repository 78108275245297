import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorBoundary } from "components";
import React, { forwardRef, useState } from "react";

const PREFIX = "LabelledTextFieldBootStrap";

const classes = {
  inputFormRoot: `${PREFIX}-inputFormRoot`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelFormControl: `${PREFIX}-inputLabelFormControl`,
  outlinedInput: `${PREFIX}-outlinedInput`,
  notchedOutline: `${PREFIX}-notchedOutline`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.inputFormRoot}`]: {
    display: "flex",
  },

  [`& .${classes.inputLabelRoot}`]: {
    fontWeight: "400",
    font: "normal normal 400 16px/25px Poppins",
    fontSize: "16px",
    lineHeight: "1.3125rem",
  },

  [`& .${classes.inputLabelFormControl}`]: {
    position: "static",
    transform: "inherit",
    textAlign: "left",
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.notchedOutline}`]: {
    border: "none",
    width: "100%",
  },
  [`& .${classes.outlinedTextField}`]: {
    background: "#F3F6F9",
    fontSize: "16px !important",
    fontWeight: "400",
    color: "#464E5F",
    borderRadius: "6px",
    width: "100%",
    paddingRight: "0px",
  },
  [`& .${classes.outlinedInput}`]: {
    // background: "#F3F6F9",
    fontSize: "16px !important",
    fontWeight: "400",
    color: "#464E5F",
    borderRadius: "6px",
    paddingRight: "0px",
  },
}));

/**
 * Bootstrap type input textfield
 * takes full width
 *
 * for hide / show password type input : use "LabelledTextFieldWithAdornments" component
 */

const LabelledTextFieldBootStrap = forwardRef((props, ref) => {
  const {
    label,
    variant,
    type,
    error,
    helperText,
    className,
    // ref,
    required,
    fullWidth,
    size,
    disabled,
    labelColor,
    ...restOfTextFieldProps
  } = props;

  return (
    <ErrorBoundary>
      <StyledFormControl
        classes={{
          root: classes.inputFormRoot,
        }}
        className={`${className} ${fullWidth ? "w-full" : ""} mui-textfield`}
        variant="standard"
      >
        <InputLabel
          required={required}
          classes={{
            root: classes.inputLabelRoot,
            formControl: classes.inputLabelFormControl,
          }}
          shrink
          disabled={disabled}
          color={labelColor}
        >
          {label}
        </InputLabel>
        <StyledTextField
          type={type}
          className={classes.outlinedInput}
          fullWidth={fullWidth}
          {...restOfTextFieldProps}
          ref={ref}
          size={size}
          disabled={disabled}
          variant="standard"
        />
        <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
          {helperText}
        </FormHelperText>
      </StyledFormControl>
    </ErrorBoundary>
  );
});

LabelledTextFieldBootStrap.defaultProps = {
  label: "",
  variant: "outlined",
  ref: null,
  type: "text",
  error: false,
  helperText: "",
  className: null,
  // ref: null,
  required: false,
  fullWidth: true,
  size: "medium",
  disabled: false,
  labelColor: "componentTitle",
};

const StyledTextField = styled(TextField)`
  font: normal normal 400 16px/25px Poppins !important;
`;
export default LabelledTextFieldBootStrap;
