import { useContext, useEffect, useState } from "react";
import {
  Chat,
  Channel,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
  ChannelList,
  ChannelHeader,
} from "stream-chat-react";
import { CustomChannelHeader } from "./CustomHeader";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import { CustomMessageInput } from "./CustomInput";
import { useGetClientsQuery } from "queries/Profile";
import { ClientContext } from "utils/Context";

const ChatBoxModal = (props) => {
  const { profileData } = props;
  const { data: clientChatToken } = useGetClientsQuery();
  // const { data: profileDetails } = useProfileQuery();
  const [clientDetails, setclientDetails] = useState({
    id: "",
  });
  const [currentClient] = useContext(ClientContext);

  const [chatConnected, setChatConnected] = useState(false);

  const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY;
  const client = StreamChat.getInstance(apiKey);

  const [channel, setChannel] = useState([]);
  const [filter, setFilter] = useState({});
  const sort = { last_message_at: -1 };

  useEffect(() => {
    const setupClient = async () => {
      try {
        await client.connectUser(
          {
            id: clientDetails.id,
          },
          clientChatToken?.token
        );
        setChatConnected(true);
        const channels = await client.queryChannels(filter, sort, {
          watch: true, // this is the default
          state: true,
        });

        setChannel(channels);
      } catch (err) {}
    };

    if (Boolean(clientDetails?.id) && clientChatToken?.token) {
      setupClient();
    }
  }, [clientDetails, clientChatToken, filter]);

  // useEffects
  useEffect(() => {
    if (profileData) {
      setclientDetails({
        id: profileData?.uuid,
      });
    }
  }, [profileData]);

  useEffect(() => {
    setFilter({
      type: "messaging",
      members: {
        $in: [clientDetails?.id],
      },
    });
  }, [currentClient, clientDetails]);

  if (!chatConnected) return <LoadingIndicator />;

  return (
    <Chat client={client} theme="messaging light" Input={CustomMessageInput}>
      <ChannelList filters={filter} showChannelSearch />
      <Channel>
        <Window>
          <CustomChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default ChatBoxModal;
