import React, { useEffect, useMemo } from "react";
import MobileHeader from "components/MobileHeader";
import { useNavigate } from "react-router-dom";
import magnifyIcon from "assets/Icons/magnify.svg";
import {
  useGetNotificationQuery,
  useUpdateNotificationMutation,
} from "queries/Notification";
import { formatDistance } from "date-fns";
import { Button } from "@mui/material";
import { routes } from "Routes/RouteConstants";

const splitCamelCase = (word) => {
  const wordsArray = word.split(/(?=[A-Z])/);
  return wordsArray.join(" ");
};

const NotificationScreen = () => {
  const navigate = useNavigate();

  //quries
  const {
    data: fetchedNotificationData,
    dataUpdatedAt,
    refetch,
  } = useGetNotificationQuery();
  const { mutateAsync: UpdateNotification } = useUpdateNotificationMutation();

  useEffect(() => {
    refetch();
  }, []);

  //Use useMemo
  const { notificationList } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        notificationList: fetchedNotificationData?.data,
      };
    } else {
      return { notificationList: [] };
    }
  }, [dataUpdatedAt]);

  const clearNotification = () => {
    if (notificationList?.length) {
      notificationList?.map((el) =>
        UpdateNotification({
          notification_id: el?.id,
        })
      );
    }
  };

  const acceptInvite = () => {
    navigate(routes.INVITATIONS.pathname, {
      state: { selectedTab: "Pending" },
    });
  };

  return (
    <div className="px-4 w-full mt-4 sm:pl-8 sm:mt-8">
      {/* Header */}
      <MobileHeader
        variant="secondary"
        onClick={() => navigate(-1)}
        title="Notification"
      />
      <div className="flex flex-row justify-between">
        <p className="hidden sm:flex text-base font-medium">Notification</p>
        {Boolean(notificationList?.length) && (
          <Button
            variant="contained"
            className="bg-hb_orange-dark ml-auto"
            onClick={clearNotification}
          >
            Clear Notifications
          </Button>
        )}
      </div>
      <div>
        {Boolean(notificationList.length === 0) ? (
          <div className="flex flex-col w-full min-h-[60vh] justify-center items-center">
            <img
              alt="magnifyIcon"
              style={{ height: 80, width: 80 }}
              src={magnifyIcon}
            />
            <p className="text-lg font-semibold mt-8">No results to show</p>
          </div>
        ) : (
          notificationList?.map((item) => {
            return (
              <div
                className="bg-hb_gray-1000 w-full py-4 pr-1 mt-4 flex flex-col  items-center rounded-lg cursor-pointer"
                onClick={
                  item.type === `App\\Notifications\\CoachInviteNotification`
                    ? acceptInvite
                    : null
                }
              >
                <div className="flex flex-row w-full justify-between items-center">
                  <p className="ml-4 text-base text-gray-900">
                    {item?.data?.title}
                  </p>
                  <p className="ml-4 text-xs text-gray-400">
                    {formatDistance(new Date(item.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </p>
                </div>
                <p className="w-full px-4 flex-wrap text-left text-xs mt-2 text-gray-600">
                  {item.data.description}
                </p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default NotificationScreen;
