import React from "react";
import RefreshIcon from "assets/Icons/refresh.svg";
import PlusIcon from "assets/Icons/Icon feather-plus-circle.svg";
import { IconButton } from "@mui/material";
import tagIcon from "assets/Icons/tag.svg";
import heartGreenIcon from "assets/Icons/heartGreen.svg";
import AddFileIcon from "assets/Icons/Group 333.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const HabitCard = (props) => {
  const {
    habit,
    onClickPlus,
    onClickCard,
    variant,
    onClickCheck,
    onClickFile,
  } = props;
  // Habit Card
  if (variant === "habit") {
    let iconColor =
      habit.today_log_count === 0
        ? "#CF6F6F"
        : habit.today_log_count >= habit.frequency
        ? "#6FCF97"
        : "#f6be19";
    return (
      <div
        className="mb-4 cursor-pointer rounded-xl border-2 min-w-[300px] overflow-hidden"
        style={{ borderColor: habit.color }}
        onClick={onClickCard}
      >
        <div className="flex h-3 w-full space-x-0.5">
          {new Array(habit.frequency).fill("").map((_, i) => {
            return (
              <div
                className="h-3"
                style={{
                  width: `${100 / habit.frequency}%`,
                  backgroundColor: habit.color + "55",
                  opacity: i + 1 <= habit.logs_count ? 1 : 0.25,
                }}
              ></div>
            );
          })}
        </div>
        <div className="p-3">
          <div className="flex justify-between flex-row">
            <div className="flex flex-row">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onClickCheck();
                }}
              >
                <CheckCircleIcon fontSize="large" sx={{ color: iconColor }} />
              </IconButton>
              <div className="habit-name flex flex-col justify-center">
                <p className="font-semibold flex flex-row items-center">
                  {habit.name}
                  <img
                    alt="green heart"
                    style={{ height: 12, width: 15, marginLeft: 5 }}
                    src={heartGreenIcon}
                  />
                </p>
                <p className="font-normal text-xs">
                  Current Streak:{habit?.current_streak}
                </p>
              </div>
            </div>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onClickFile();
              }}
            >
              <img
                alt="AddFileIcon"
                style={{ height: 35, width: 35 }}
                src={AddFileIcon}
              />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
  //Browse Card
  return (
    <div
      className="mb-4 cursor-pointer rounded-xl border-2 min-w-[300px] overflow-hidden"
      style={{ borderColor: habit.color }}
      onClick={onClickCard}
    >
      <div className="flex h-3 w-full space-x-0.5">
        {new Array(habit.frequency).fill("").map((_, i) => {
          return (
            <div
              className="h-3"
              style={{
                width: `${100 / habit.frequency}%`,
                backgroundColor: habit.color + "55",
                opacity: i + 1 <= habit.logs_count ? 1 : 0.25,
              }}
            ></div>
          );
        })}
      </div>
      <div className="p-3">
        <div className="flex justify-between flex-row">
          <img
            alt="RefreshIcon"
            style={{ height: 35, width: 35, margin: 5 }}
            src={RefreshIcon}
          />
          <div>
            <div className="habit-name flex flex-row text-center items-center">
              <p className="mr-4 font-semibold  text-center">{habit.name}</p>
            </div>
            {Boolean(habit?.tags?.length) && (
              <div className="flex flex-row items-center capitalize text-xs mt-3 text-hb_blue-700">
                <img
                  src={tagIcon}
                  alt="tagIcon"
                  style={{ height: 10, width: 10, marginRight: 5 }}
                />
                <p>{habit?.tags?.map((el) => el.name).join(", ")}</p>
              </div>
            )}
          </div>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClickPlus();
            }}
          >
            <img
              alt="PlusIcon"
              style={{ height: 35, width: 35 }}
              src={PlusIcon}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default HabitCard;
