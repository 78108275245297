import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTextField, ToggleButton } from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useAddNoteHabitMutation } from "queries/Habit";

const CustomSchema = yup.object().shape({
  description: yup
    .string()
    .trim()
    .min(2, "The note must be greater than 2 characters.")
    .max(200, "The note must not be greater than 200 characters.")
    .required("Please write something."),
});

const NoteModal = (props) => {
  const { open, handleClose } = props;
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const [makeNotePublic, setMakeNotePublic] = useState(true);

  const {
    getValues,
    formState: { errors, isValid },
    register,
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(CustomSchema),
    defaultValues: { description: "" },
  });

  const { ref: refDescription, ...RegisterDescription } =
    register("description");
  const { mutateAsync: PostAddNoteHabits } = useAddNoteHabitMutation();

  const submitData = (isLogged) => {
    PostAddNoteHabits(
      {
        id: open?.id,
        body: {
          note: getValues("description"),
          public: makeNotePublic ? 1 : 0,
          log: isLogged,
        },
      },
      {
        onSuccess: () => {
          reset();
          handleClose();
        },
      }
    );
  };

  const renderMainContent = () => {
    return (
      <>
        <form id="description-form" noValidate>
          <Grid item className="mt-2">
            <CustomTextField
              variant={"outlined"}
              required
              label=""
              rows={5}
              multiline
              placeholder="Write Here..."
              tyoe="text"
              InputLabelProps={{
                maxLength: 200,
              }}
              ref={refDescription}
              {...RegisterDescription}
              error={Boolean(errors.description)}
              helperText={errors.description && errors.description.message}
            />
            <div className="flex flex-row h-12 bg-[#F7F7F7] rounded-xl p-3 mb-4">
              <p className="text-base text-gray-700">Make Note Public?</p>
              <div className="ml-auto">
                <ToggleButton
                  checked={makeNotePublic}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setMakeNotePublic(true);
                    } else {
                      setMakeNotePublic(false);
                    }
                  }}
                />
              </div>
            </div>
          </Grid>
          <div className="flex flex-row">
            <LoadingButton
              variant="contained"
              onClick={() => {
                if (isValid) {
                  submitData(1);
                } else {
                  trigger();
                }
              }}
              // loading={isLoading}
              className="bg-hb_orange-dark container m-auto grid w-[40%]"
            >
              Log with Note
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={() => {
                if (isValid) {
                  submitData(0);
                } else {
                  trigger();
                }
              }}
              // loading={isLoading}
              className="bg-hb_orange-dark container m-auto grid w-[40%]"
            >
              Note Only
            </LoadingButton>
          </div>
        </form>
      </>
    );
  };

  const drawerBleeding = 46;

  const Root = styled("div")(({ theme }) => ({
    height: "100%",
    backgroundColor:
      theme.palette.mode === "light"
        ? grey[100]
        : theme.palette.background.default,
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  }));

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[700] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

  return (
    <>
      {isSmallScreen ? (
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(70% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => true}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: false,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              <div className="flex flex-row justify-between py-1">
                <Typography className="text-base font-semibold p-3 text-gray-500">
                  Add Note
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    fontSize={"medium"}
                    style={{ color: "#434349", padding: 0, marginRight: 4 }}
                  />
                </IconButton>
              </div>
            </StyledBox>
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              {renderMainContent()}
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      ) : (
        <Dialog open={open}>
          <DialogTitle className="font-semibold" color="primary">
            <Grid
              container
              xs={12}
              direction="row"
              className="justify-between"
              alignItems="center"
            >
              Add Note
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>

          <DialogContent className="p-6 no-scrollbar overflow-y-auto sm:w-[550px]">
            {renderMainContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default NoteModal;
